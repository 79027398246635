<div class="spinner-wrapper" *ngIf="loader">
    <div class="spinner">
        <div></div>
    </div>
</div>
<!-- Breadcrumb Wrapper -->
<div class="breadcrumb-wrapper">
    <div class="row">
        <div class="col-md-10 col-xs-12 col-md-offset-1">
            <ol>
                <li routerLink="/">
                    <span class="material-icons">home</span>
                </li>
                <li class="child">Quote Details</li>
                <li class="child">Accept Quote</li>
                <li class="child rm-underline">BOL</li>
            </ol>
        </div>
    </div>
</div>

<!-- Section -->

<div class="content-section m-section pt-0 pb-0">
    <div class="col-md-10 col-xs-12 col-md-offset-1 p-0">

        <div class="content-section py-0 pb-0">

            <div class="row">
                <div class="col-md-12 col-xs-12">

                    <div class="success mb-0">
                        Thank you for your business. A member of our Truckload Team will contact you shortly.
                    </div>

                </div>
            </div>

        </div>

    </div>
</div>
<div class="content-section m-section pt-0 pb-0" id="bol-info-section">
    <div class="col-md-10 col-xs-12 col-md-offset-1 p-0" [formGroup]="bol">

        <div class="row">

            <div class="col-md-3 col-xs-12 mt-3">
                <img [src]="'/assets/LinkExLogo.svg'" class="print-bol" alt="">
            </div>
            <div class="col-md-6 col-xs-12 mt-3">
                <h4 class="header-text text-blue text-center">Load #: {{bolData.bolInfo.resp.customerLoadNum}} </h4>
            </div>
            <div class="col-md-3 col-xs-12 mt-3">

            </div>

        </div>

        <div class="row">
            <div class="col-md-6 col-xs-12">
                <div class="address-box">
                    <div class="header-text">
                        Ship From
                    </div>
                    <div class="content-text">
                        <span class="bold"> Name: {{bolData.tenderPayload.pickup.location.name || ''}}</span> <br>
                        <span class="bold"> Address: {{bolData.tenderPayload.pickup.location.address1 || ''}} {{bolData.tenderPayload.pickup.location.address2 || ''}}</span> <br>
                        <span class="bold"> City/State/Zip: {{bolData.tenderFormValue.pickupShipperCity || ''}}/{{bolData.tenderFormValue.pickupShipperState || ''}}/{{bolData.tenderPayload.pickup.location.zip || ''}}</span> <br>
                        <span class="bold"> Contact Email: {{bolData.tenderFormValue.pickupEmail || ''}}</span> <br>
                        <span class="bold"> Phone: {{bolData.tenderFormValue.pickupPhone || ''}}</span> <br>
                        <span class="bold"> Shipper Ref: {{bolData.tenderFormValue.shipperReference || ''}} </span>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-xs-12">
                <div class="address-box">
                    <div class="header-text">
                        Ship To
                    </div>
                    <div class="content-text">
                        <span class="bold"> Name: {{bolData.tenderPayload.dropoff.location.name}}</span> <br>
                        <span class="bold"> Address: {{bolData.tenderPayload.dropoff.location.address1}} {{bolData.tenderPayload.dropoff.location.address2}}</span> <br>
                        <span class="bold"> City/State/Zip: {{bolData.tenderFormValue.deliveryCity || ''}}/{{bolData.tenderFormValue.deliveryState || ''}}/{{bolData.tenderPayload.dropoff.location.zip}}</span> <br>
                        <span class="bold"> Contact Email: {{bolData.tenderFormValue.deliveryEmail || ''}}</span> <br>
                        <span class="bold"> Phone: {{bolData.tenderFormValue.deliveryPhone || ''}}</span> <br>
                        <span class="bold"> Consignee Ref: {{bolData.tenderFormValue.consigneeReference || ''}} </span>
                    </div>
                </div>
            </div>


            <div class="col-md-6 col-xs-12">
                <div class="address-box">
                    <div class="header-text">
                        Bill Freight Charges To
                    </div>
                    <div class="content-text">
                        <span class="bold"> Name: {{bolData.tenderPayload.billTo.name || ''}} </span> <br>
                        <span class="bold"> Address: {{bolData.tenderPayload.billTo.address1 || ''}} {{bolData.tenderPayload.billTo.address2 || ''}}</span> <br>
                        <span class="bold"> City/State/Zip: {{bolData.tenderPayload.billTo.city}} {{bolData.tenderPayload.billTo.state
                            }} {{bolData.tenderPayload.billTo.zip}} </span> <br>
                        <span class="bold"> Billing Ref: {{bolData.tenderFormValue.billingReference || ''}} </span>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-xs-12">
                <div class="address-box">
                    <div class="header-text">
                        References
                    </div>
                    <div class="content-text">
                        <span class="bold"> Accessorials: </span> <br>
                        <span class="bold"> Carrier Name: </span> <br>
                        <span class="bold"> Trailer Number: </span> <br>
                        <span class="bold"> Seal Number(s): </span> <br>
                        <span class="bold"> Pickup Date: {{bolData.tenderFormValue.pickupDate || ''}}</span> <br>
                        <span class="bold"> Delivery Date: {{bolData.tenderFormValue.deliveryDate || ''}} </span> <br>
                        <span class="bold"> Miles: {{distance}} </span>
                    </div>
                </div>
            </div>
        </div>

        <br>

        <div class="row">
            <div class="col-md-12 col-xs-12 ">
                <h4 class="header-text text-blue text-center">Inspect before delivery. Call LinkEx for disposition if damage is identified and do not attempt delivery, 972-481-9900</h4>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6 col-xs-12">
                <div class="address-box min-height">
                    <div class="content-text p-0">
                        <span class="bold"> Special Instructions: {{bolData.tenderFormValue.specialInstruction || ''}} </span> <br>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-xs-12">
                <div class="address-box min-height">
                    <div class="content-text bol p-0">
                        <span class="bold"> Freight Charge Terms: (freight charges are prepaid unless marked otherwise)</span> <br>

                        <div class="form-group mt-1">
                            <mat-radio-group aria-label="Select Hazmat mt-1" class="m-t" ngDefaultControl formControlName="frightChargeItem">
                                <mat-radio-button value="prepaid" disabled>Prepaid</mat-radio-button>
                                <mat-radio-button value="collect" disabled>Collect</mat-radio-button>
                                <mat-radio-button value="third-party" disabled>3rd Party</mat-radio-button>
                            </mat-radio-group>
                        </div>


                        <div class="form-group mt-2">
                            <mat-checkbox ngDefaultControl formControlName="masterBillofLading" [disabled]="true">Master Bill of Lading: with attached <br> Underlying Bills of Lading</mat-checkbox>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <br><br>

        <div class="row">
            <div class="col-md-12 col-xs-12">

                <div class="header-text">
                    Customer Order Information
                </div>

                <div class="responsive">
                    <table class="mat-table mat-elevation-z0 no-more-tables">

                        <thead>
                            <tr>
                                <th>
                                    Item ID
                                </th>
                                <th>
                                    #PCS
                                </th>
                                <th>
                                    Piece Type
                                </th>
                                <th>
                                    Weight
                                </th>
                                <th>
                                    DIMs
                                </th>
                                <th>
                                    Description
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of bolData.tenderPayload.items">
                                <td>
                                    {{item.name}}
                                </td>
                                <td>
                                    {{item.quantity}}
                                </td>
                                <td>
                                    {{item.quantityType}}
                                </td>
                                <td>
                                    {{item.weight}} lbs
                                </td>
                                <td>
                                    {{ (item.dimension.length && item.dimension.width && item.dimension.height) ? item.dimension.length +' X '+item.dimension.width+' X ' +item.dimension.height+' In': ''}}
                                </td>
                                <td>
                                    {{item.name}}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>Grand Total</span>
                                </td>
                                <td>
                                    <span> {{totalItems}} </span>
                                </td>
                                <td>

                                </td>
                                <td>
                                    <span>{{totalWeight}} lbs</span>
                                </td>
                                <td>

                                </td>
                                <td>

                                </td>

                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <br><br>

        <div class="row">
            <div class="col-md-12">
                <div class="responsive">
                    <table class="mat-table mat-elevation-z0 no-more-tables border-table">

                        <tr>
                            <td colspan="2">
                                NOTE Liability Limitation for loss or damage is this shipment may be applicable. See 49 U.S.C. 14706(c)(1)(A) and (B).
                            </td>
                        </tr>

                        <tr>
                            <td class="col-md-6">
                                RECEIVED, subject to individually determined rates or contracts that have been agreed upon in writing between the carrier and shipper, if applicable, otherwise to the rates, classifications and rules that have been established by the carrier and are available to the shipper, on request. The shipper hereby certifies that he/she is familiar with all the terms and conditions of the NMFC Uniform Straight Bill of Lading, including those on the back thereof, and the said terms and conditions are hereby agreed to by the shipper and accepted for him/herself and his/her assigns.
                            </td>

                            <td class="col-md-6 border-left-none">
                                The carrier shall not make delivery of this shipment without payment of freight and all other lawful charges.
                            </td>
                        </tr>
                    </table>

                    <br>

                    <table class="mat-table mat-elevation-z0 no-more-tables border-table">
                        <thead>
                            <tr>
                                <th>
                                    SHIPPER SIGNATURE / DATE
                                </th>
                                <th class="border-left-none">
                                    Trailer Loaded:
                                </th>
                                <th class="border-left-none">
                                    Freight Counted:
                                </th>
                                <th class="border-left-none">
                                    CARRIER SIGNATURE / PICKUP DATE
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <td class="col-md-3">
                                This is to certify that the above named materials areproperly classified, packaged, marked and labeled, and are in proper condition for transportation according to the applicable regulations of the DOT.
                            </td>
                            <td class="col-md-3 border-left-none">
                                <div class="form-group tandc mt-1">
                                    <mat-radio-group aria-label="Select Hazmat mt-1" class="m-t column" ngDefaultControl formControlName="trailerLoaded">
                                        <mat-radio-button value="shipper" disabled>By Shipper</mat-radio-button>
                                        <mat-radio-button value="driver" disabled>By Driver</mat-radio-button>
                                    </mat-radio-group>
                                </div>
                            </td>
                            <td class="col-md-3 border-left-none">
                                <div class="form-group tandc mt-1">
                                    <mat-radio-group aria-label="Select Hazmat mt-1" class="m-t column" ngDefaultControl formControlName="freightCounted">
                                        <mat-radio-button value="shipper" disabled>By Shipper</mat-radio-button>
                                        <mat-radio-button value="driver" disabled>By Driver / Pallet Said to Contain</mat-radio-button>
                                        <mat-radio-button value="pieces" disabled>By Driver / Pieces</mat-radio-button>
                                    </mat-radio-group>
                                </div>
                            </td>
                            <td class="col-md-3 border-left-none">
                                Carrier acknowledges receipt of packages and required placards. Carrier certifies emergency response information was made available and/or carrier has the DOT emergency response guidebook or equivalent documentation in the vehicle.
                                Property described above is received in good order, except as noted.
                            </td>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <br>

        <!-- <div class="row">
            <div class="col-md-12 col-xs-12 ">
                <h4 class="header-text text-blue text-center">Inspect before delivery. Call LinkEx for disposition if damage is identified and do not attempt delivery, 972-481-9900</h4>
            </div>
        </div> -->

    </div>
</div>
<div class="content-section m-section pt-0">
    <div class="col-md-10 col-xs-12 col-md-offset-1 p-0">
        <div class="row">
            <div class="col-md-12 col-xs-12">
                <mat-button-toggle-group name="fontStyle" class="mat-elevation-z0" aria-label="Font Style">
                    <button mat-flat-button class="primary-btn btn-auto" (click)="downloadBOL()" value="Download">Download</button>
                    <button mat-flat-button class="primary-btn btn-auto" (click)="printBolDocument()" value="Print BOL">Print BOL</button>
                </mat-button-toggle-group>
            </div>
        </div>
    </div>
</div>