<div class="spinner-wrapper" *ngIf="loader">
    <div class="spinner">
        <div></div>
    </div>
</div>
<!-- Breadcrumb Wrapper -->
<div class="breadcrumb-wrapper">
    <div class="row">
        <div class="col-md-10 col-xs-12 col-md-offset-1">
            <ol>
                <li routerLink="/home">
                    <span class="material-icons">home</span>
                </li>
                <li class="active">
                    Track
                </li>
            </ol>
        </div>
    </div>
</div>

<!-- Section -->
<div class="content-section">
    <div class="row">
        <div class="col-md-8 col-xs-10 col-md-offset-2 col-xs-offset-1">

            <form [formGroup]="track" class="Tracking-FormGroup">

                <div class="row">
                    <div class="form-group">
                        <label for="">Search By</label>
                        <mat-form-field appearance="outline" class="col-md-12 p-0">
                            <mat-select placeholder="Search by" ngDefaultControl formControlName="searchBy">
                                <mat-option *ngFor="let search of search" [value]="search.value">
                                    {{search.viewValue}}
                                </mat-option>
                            </mat-select>
                            <mat-error>
                                Please select option
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row">
                    <div class="form-group">
                        <label for="">Enter up to 30 SO Numbers</label>
                        <mat-form-field appearance="outline" class="col-md-12 p-0 textarea">
                            <textarea matInput placeholder="Ex. SO000000, SO000001" rows="3" formControlName="ids"></textarea>
                            <mat-error>
                                Enter Valid SO Number
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row center-xs">
                    <div class="col-md-4"> </div>
                    <div class="col-md-4">
                        <button mat-flat-button (click)="onSubmit()" class="primary-btn" [disabled]="submitDisabled">
                            Track
                        </button>
                    </div>
                    <div class="col-md-4 end-xs">
                        <button mat-button class="primary-btn link" (click)="clear()">
                            Clear
                        </button>
                    </div>
                </div>

            </form>

            <br><br>
            <div class="responsive" *ngIf="dataSource.data.length>0">
                <table mat-table [dataSource]="dataSource" class="mat-elevation-z0">

                    <!-- So Number -->
                    <ng-container matColumnDef="sonumber">
                        <th mat-header-cell *matHeaderCellDef>SO Number</th>
                        <td mat-cell *matCellDef="let element"> <a href="" *ngIf="element.isFound" routerLink="/details" [queryParams]="{shipOrder: element.sonumber}">{{element.sonumber}}</a>
                            <span *ngIf="!element.isFound">{{element.sonumber}}</span>
                        </td>
                    </ng-container>

                    <!-- Status -->
                    <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef>Status</th>
                        <td mat-cell *matCellDef="let element" [title]="element.status === 'Rated' ? 'Pending Pickup' : element.status">
                            <ng-container *ngIf="element.status === 'Rated'; else notRated">{{'Pending Pickup'}}
                            </ng-container>
                            <ng-template #notRated>{{element.status}}</ng-template>
                        </td>
                    </ng-container>

                    <!-- Pickup Date -->
                    <ng-container matColumnDef="pickupdate">
                        <th mat-header-cell *matHeaderCellDef>Pickup Date</th>
                        <!-- <td mat-cell *matCellDef="let element"> {{element.pickupdate | date: 'MM/dd/yyyy hh:mm a'}} </td> -->
                        <td mat-cell *matCellDef="let element"> {{element.pickupdate}} </td>
                    </ng-container>

                    <!-- Estimated Delivery -->
                    <ng-container matColumnDef="deliverydate">
                        <th mat-header-cell *matHeaderCellDef> Estimated Delivery Date</th>
                        <!-- <td mat-cell *matCellDef="let element"> {{element.deliverydate | date: 'MM/dd/yyyy'}} </td> -->
                        <td mat-cell *matCellDef="let element"> {{element.deliverydate}} </td>
                    </ng-container>

                    <!-- Date Delivered -->
                    <ng-container matColumnDef="datedelivered">
                        <th mat-header-cell *matHeaderCellDef> Date Delivered </th>
                        <!-- <td mat-cell *matCellDef="let element"> {{element.datedelivered | date: 'MM/dd/yyyy hh:mm a'}} </td> -->
                        <td mat-cell *matCellDef="let element"> {{element.datedelivered}} </td>
                    </ng-container>

                    <!-- Delivery Window -->
                    <ng-container matColumnDef="deliverywindow">
                        <th mat-header-cell *matHeaderCellDef>Delivery Window</th>
                        <td mat-cell *matCellDef="let element"> {{ formatTime(element.deliverywindow) }} </td>
                    </ng-container>

                    <!-- Consignee -->
                    <ng-container matColumnDef="consignee">
                        <th mat-header-cell *matHeaderCellDef>Consignee</th>
                        <td mat-cell *matCellDef="let element"> {{element.consignee}} </td>
                    </ng-container>

                    <!-- Receive Notification -->
                    <!-- <ng-container matColumnDef="receivenotification">
                        <th mat-header-cell *matHeaderCellDef>Receive Notification</th>
                        <td mat-cell *matCellDef="let element"> <span class="material-icons">mail</span> </td>
                    </ng-container> -->

                    <tr mat-header-row *matHeaderRowDef="TrackingColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: TrackingColumns;"></tr>

                </table>
            </div>

        </div>
    </div>
</div>