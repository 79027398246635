<!-- Banner -->
<div class="banner documents" *ngIf="pageHeader" [heroImage]="pageHeader.heroImageURL">
    <h1 class="banner-text">{{pageHeader.heroImageTitle}}</h1>
</div>

<!-- Breadcrumb Wrapper -->
<div class="breadcrumb-wrapper" *ngIf="pageHeader">
    <div class="row">
        <div class="col-md-10 col-xs-12 col-md-offset-1">
            <ol>
                <li routerLink="/home">
                    <span class="material-icons">home</span>
                </li>
                <ng-container *ngFor="let breadcrumb of getBreadcrumbs(); let last = last">
                    <li [ngClass]="{'child': !last, 'active': last}">
                        {{ breadcrumb }}
                    </li>
                </ng-container>
            </ol>
        </div>
    </div>
</div>

<!-- Section -->
<div class="content-section resources-content">
    <div class="row">
        <div class="col-md-10 col-md-offset-1" *ngIf="pageContent">

            <h4 class="header-text text-blue py-2">
                Documents
            </h4>
            <div class="content-text" [innerHTML]="sanitizedDescription">
            </div>
        </div>
    </div>
</div>