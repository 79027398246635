<!-- Banner -->
<div class="banner about-us" *ngIf="pageHeader" [heroImage]="pageHeader.heroImageURL">
    <h1 class="banner-text">{{pageHeader.heroImageTitle}}</h1>
</div>

<!-- Breadcrumb Wrapper -->
<div class="breadcrumb-wrapper" *ngIf="pageHeader">
    <div class="row">
        <div class="col-md-10 col-xs-12 col-md-offset-1">
            <ol>
                <li routerLink="/home">
                    <span class="material-icons">home</span>
                </li>
                <ng-container *ngFor="let breadcrumb of getBreadcrumbs(); let last = last">
                    <li [ngClass]="{'child': !last, 'active': last}">
                        {{ breadcrumb }}
                    </li>
                </ng-container>
            </ol>
        </div>
    </div>
</div>


<!-- Section -->
<div class="content-section">
    <div class="row">
        <div class="col-md-10 col-md-offset-1" *ngIf="pageContent"> <!-- (focusout)="toggleState()" -->

            <h4 class="header-text text-blue py-2">
                {{ pageContent.header }}
            </h4>

            <div class="content-text p-0">
                <div [innerHTML]="sanitizedDescription"></div>
            </div>

            <h4 class="header-text text-blue py-2">
                {{ pageContent.accordion.accordionHeader }}
            </h4>

            <mat-accordion *ngIf="pageContent && pageContent.accordion && pageContent.accordion.accordionItems">
                <mat-expansion-panel *ngFor="let accordionItem of pageContent.accordion.accordionItems; let i = index" class="mat-elevation-z0 accordion-custom" hideToggle="true">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            {{ accordionItem.header }}
                        </mat-panel-title>
                    </mat-expansion-panel-header>

                    <div class="content-text" [innerHTML]="sanitizedAccordionItems[i]"></div>
                </mat-expansion-panel>
                <!-- <mat-expansion-panel [(expanded)]="linkex" class="mat-elevation-z0 accordion-custom" hideToggle="true">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            2002 - LinkEx
                        </mat-panel-title>
                    </mat-expansion-panel-header>

                    <div class="content-text">
                        <ul>
                            <li>Dallas, TX based company founded </li>
                            <li>Created as a response for Relocation systems (creating and packaging)</li>
                            <li>International and Air Freight forwarder</li>
                        </ul>

                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel [(expanded)]="saia" class="mat-elevation-z0 accordion-custom" hideToggle="true">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            2012 - Saia
                        </mat-panel-title>
                    </mat-expansion-panel-header>

                    <div class="content-text">
                        Robart Co.'s acquired and re-branded as Saia TL Plus and Saia Logistics Services
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel [(expanded)]="saiaEx" class="mat-elevation-z0 accordion-custom" hideToggle="true">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            2015 - Saia
                        </mat-panel-title>
                    </mat-expansion-panel-header>

                    <div class="content-text">
                        Acquired LinkEx and merged with Saia TL Plus and SaiaLogistics Services.
                    </div>
                </mat-expansion-panel> -->

            </mat-accordion>
        </div>
    </div>
</div>