import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { PageContentModel } from 'src/app/core/model/page-content.model';
import { PageHeaderContentModel } from 'src/app/core/model/page-header.model';
import { ContentfulService } from 'src/app/core/service/contentful.service';

@Component({
  selector: 'app-holiday-schedule',
  templateUrl: './holiday-schedule.component.html',
  styleUrls: ['./holiday-schedule.component.scss']
})
export class HolidayScheduleComponent implements OnInit {
  pageContent!: PageContentModel;
  pageHeader!: PageHeaderContentModel;
  constructor(private contentfulService: ContentfulService, private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.loadBodyEntries();
    this.loadHeaderEntries();
  }
  loadHeaderEntries() {
    this.contentfulService.getEntry('pageHeader', 'Holiday Schedule Header').subscribe(
      (entry) => {
        this.pageHeader = new PageHeaderContentModel(entry);
       console.log( this.pageHeader)
      }, error => {
        console.error('Error fetching entries:', error);
      });
  }

  loadBodyEntries() {
    this.contentfulService.getEntry('pageInsights', 'Holiday Schedule Content').subscribe(
      (entry) => {
        this.pageContent = new PageContentModel(entry);
      }, error => {
        console.error('Error fetching entries:', error);
      });
  }

  getBreadcrumbs(): string[] {
    return this.pageHeader.breadCrumbs.split(',').map(breadcrumb => breadcrumb.trim());
  }
}
