import { Entry, EntryCollection } from 'contentful';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { richTextOptions } from './rich-text-options.model';
import { LinkItemContentModel } from './link-content.model';

export class NotificationContentModel {

    pageUrl!: string;
    notificationFlag!: boolean;
    description!: string; 
    icons!: string;

    constructor(entries?: EntryCollection<any>) {

        if (entries && entries.items.length > 0 && entries.items[0].fields) {

            const contentDetail: any = entries.items[0].fields; 
            this.pageUrl = contentDetail.pageLinks;
            this.notificationFlag = contentDetail.displayNotification; 
            this.icons = contentDetail.iconEntry;
            this.description = documentToHtmlString(contentDetail.shortDescription, richTextOptions);

        }

    }

}