import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { PageContentModel } from 'src/app/core/model/page-content.model';
import { PageHeaderContentModel } from 'src/app/core/model/page-header.model';
import { ContentfulService } from 'src/app/core/service/contentful.service';

@Component({
  selector: 'app-tms',
  templateUrl: './tms.component.html',
  styleUrls: ['./tms.component.scss']
})
export class TmsComponent implements OnInit {

  recipientEmail = 'info@linkex.us';
  pageContent!: PageContentModel;
  pageHeader!: PageHeaderContentModel;

  sendEmail() {
    const emailUrl = `mailto:${this.recipientEmail}`;
    window.location.href = emailUrl;
  }

  constructor(private contentfulService: ContentfulService, private sanitizer: DomSanitizer,) { }

  ngOnInit(): void {
    this.loadBodyEntries();
    this.loadHeaderEntries();
  }
  loadHeaderEntries() {
    this.contentfulService.getEntry('pageHeader', 'Transportation Management System Header').subscribe(
      (entry) => {
        this.pageHeader = new PageHeaderContentModel(entry);
      }, error => {
        console.error('Error fetching entries:', error);
      });
  }

  getBreadcrumbs(): string[] {
    return this.pageHeader.breadCrumbs.split(',').map(breadcrumb => breadcrumb.trim());
  }
  loadBodyEntries() {
    this.contentfulService.getEntry('pageInsights', 'Transportation Management System Content').subscribe(
      (entry) => {
        this.pageContent = new PageContentModel(entry);
      }, error => {
        console.error('Error fetching entries:', error);
      });
  }

  get leftSanitizedSection1Description(): SafeHtml {
    const htmlContent = this.pageContent.links[0].leftPanelLinks;
    const modifiedHtmlContent = htmlContent.replace(/(<a[^>]*>.*?<\/a>)/g, '$1');
    return this.sanitizer.bypassSecurityTrustHtml(modifiedHtmlContent);
  }

  get rightSanitizedSection1Description(): SafeHtml {
    const htmlContent = this.pageContent.links[0].rightPanelLinks;
    const sanitizedContent = htmlContent.replace(/&lt;|&gt;|&quot;|&nbsp;/g, (match) => {
      const replacements = {
        '&lt;': '<',
        '&gt;': '>',
        '&quot;': '"',
        '&nbsp;': '',
      } as Record<string, string>; // Type assertion here

      return replacements[match as keyof typeof replacements];
    }).replace(/<p[^>]*>(.*?)<\/p>/g, '$1').replace(/\s*<iframe/g, '<iframe');
    // console.log("ssss", modifiedHtmlContent)
    return this.sanitizer.bypassSecurityTrustHtml(sanitizedContent);
    // return withoutPTags;
  }

  get leftSanitizedSection2Description(): SafeHtml {
    const htmlContent = this.pageContent.links[1].leftPanelLinks;
    const modifiedHtmlContent = htmlContent.replace(/(<a[^>]*>.*?<\/a>)/g, '$1');
    return this.sanitizer.bypassSecurityTrustHtml(modifiedHtmlContent);
  }

  get rightSanitizedSection2Description(): SafeHtml {
    const htmlContent = this.pageContent.links[1].rightPanelLinks;
    const modifiedHtmlContent = htmlContent //.replace(/(<p[^>]*>.*?<\/p>)/g, '$1'); 
    return this.sanitizer.bypassSecurityTrustHtml(modifiedHtmlContent);
  }

}
