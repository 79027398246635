<!-- Banner -->
<div class="banner careers" *ngIf="pageHeader" [heroImage]="pageHeader.heroImageURL">
    <h1 class="banner-text"> {{pageHeader.heroImageTitle}}</h1>
</div>

<!-- Breadcrumb Wrapper -->
<div class="breadcrumb-wrapper" *ngIf="pageHeader">
    <div class="row">
        <div class="col-md-10 col-xs-12 col-md-offset-1">
            <ol>
                <li routerLink="/home">
                    <span class="material-icons">home</span>
                </li>
                <ng-container *ngFor="let breadcrumb of getBreadcrumbs(); let last = last">
                    <li [ngClass]="{'child': !last, 'active': last}">
                        {{ breadcrumb }}
                    </li>
                </ng-container>
            </ol>
        </div>
    </div>
</div>

<!-- Section -->
<div class="content-section">
    <div class="row">
        <div class="col-md-10 col-md-offset-1" *ngIf="pageContent">

            <h4 class="header-text text-blue py-2">
                {{pageContent.header}}
            </h4>

            <div class="content-text holidays" [innerHTML]="pageContent.description">
                <!-- <h3 class="header">Observed Holidays</h3>
                LinkEx operations will be closed in observance of the following holidays. <br>
                <b>**Limited Operation Days will not be calculated as a service day; please adjust your shipping schedule accordingly.</b> <br> <br>


                <table class="mat-table mat-elevation-z0">

                    <thead role="rowgroup">
                        <tr class="mat-header-row">
                            <th class="mat-header-cell">
                                DATE
                            </th>
                            <th class="mat-header-cell">
                                WEEKDAY
                            </th>
                            <th class="mat-header-cell">
                                HOLIDAY
                            </th>
                        </tr>
                    </thead>
                    <tbody role="rowgroup">
                        <tr class="mat-header-row">
                            <td class="mat-cell">
                                1/1/24
                            </td>
                            <td class="mat-cell">
                                Monday
                            </td>
                            <td class="mat-cell">
                                New Year's Day (observed)
                            </td>
                        </tr>
                        <tr>
                            <td class="mat-cell">
                                3/29/24
                            </td>
                            <td class="mat-cell">
                                Friday
                            </td>
                            <td class="mat-cell">
                                Good Friday - LIMITED OPERATIONS DAY**
                            </td>
                        </tr>
                        <tr>
                            <td class="mat-cell">
                                5/27/24
                            </td>
                            <td class="mat-cell">
                                Monday
                            </td>
                            <td class="mat-cell">
                                Memorial Day
                            </td>
                        </tr>
                        <tr>
                            <td class="mat-cell">
                                7/4/24
                            </td>
                            <td class="mat-cell">
                                Thursday
                            </td>
                            <td class="mat-cell">
                                Independence Day
                            </td>
                        </tr>
                        <tr>
                            <td class="mat-cell">
                                9/2/24
                            </td>
                            <td class="mat-cell">
                                Monday
                            </td>
                            <td class="mat-cell">
                                Labor Day
                            </td>
                        </tr>
                        <tr>
                            <td class="mat-cell">
                                11/28/24
                            </td>
                            <td class="mat-cell">
                                Thursday
                            </td>
                            <td class="mat-cell">
                                Thanksgiving Day
                            </td>
                        </tr>
                        <tr>
                            <td class="mat-cell">
                                11/29/24
                            </td>
                            <td class="mat-cell">
                                Friday
                            </td>
                            <td class="mat-cell">
                                Thanksgiving Day After
                            </td>
                        </tr>
                        <tr>
                            <td class="mat-cell">
                                12/24/24
                            </td>
                            <td class="mat-cell">
                                Tuesday
                            </td>
                            <td class="mat-cell">
                                Christmas Eve
                            </td>
                        </tr>
                        <tr>
                            <td class="mat-cell">
                                12/25/24
                            </td>
                            <td class="mat-cell">
                                Wednesday
                            </td>
                            <td class="mat-cell">
                                Christmas Day
                            </td>
                        </tr>
                        <tr>
                            <td class="mat-cell">
                                1/1/25
                            </td>
                            <td class="mat-cell">
                                Wednesday
                            </td>
                            <td class="mat-cell">
                                New Year's Day (observed)
                            </td>
                        </tr>

                    </tbody>
                </table>

 -->

            </div>

        </div>
    </div>
</div>