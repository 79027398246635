import { Entry, EntryCollection } from 'contentful';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { richTextOptions } from './rich-text-options.model';
import { AccordionContentModel, AccordionItemContentModel } from './accordion-content.model';
import { LinkItemContentModel } from './link-content.model';

export class BlogContentModel {
   
    blogJsonData:any = []

    constructor(entries?: EntryCollection<any>) {
        if (entries && entries.items.length > 0 && entries.items[0].fields) {
            const contentDetail: any = entries.items[0].fields;
            this.blogJsonData = contentDetail.blogJson;
        }
    }
}