<mat-toolbar color="primary" class="row between-xs m-0">
    <img [src]="'/assets/LinkExLogo.svg'" routerLink="/home" class="navbar-brand" alt="">
    <div class="navbar-nav desktop">
        <ng-container *ngFor="let item of menuItems; let i = index">

            <button #menuTrigger="matMenuTrigger" (click)="openMenuToggle(i)" [matMenuTriggerFor]="item.items?.length ? iconResponsive : null" mat-button [routerLink]="item.link === undefined ? [] : [item.link]">
                {{item.label}}
            </button>

            <mat-menu #iconResponsive="matMenu" [hasBackdrop]="true">
                <ng-container>
                    <span *ngFor="let subMenu of item.items">
                        <button *ngIf="subMenu.isAsset == false" mat-menu-item routerLink="{{subMenu.link}}">
                            {{subMenu.label}}
                        </button>

                        <a *ngIf="subMenu.isAsset == true" href="{{subMenu.link}}" target="_blank">
                            <button mat-menu-item>
                                {{subMenu.label}}
                            </button>
                        </a>
                    </span>
                </ng-container>
            </mat-menu>

        </ng-container>
    </div>

    <button class="mobile p-1" mat-button (click)="toggleMenu()"><span class="material-icons">menu</span></button>

</mat-toolbar>

<div class="mobile">
    <!-- <button mat-button [matMenuTriggerFor]="dropdown"> 
        <span class="material-icons-outlined">more_vert</span>
    </button>

    <mat-menu #dropdown="matMenu">
      
        <ng-container *ngFor="let item of menuItems">    
            <button [matMenuTriggerFor]="item.items ? iconResponsive : null"  mat-menu-item [routerLink]="item.link === undefined ? [] : [item.link]">
                {{item.label}}
            </button>
            
            <mat-menu #iconResponsive="matMenu"> 
                <button mat-menu-item *ngFor="let subMenu of item.items" routerLink="{{subMenu.link}}">
                    {{subMenu.label}}
                </button> 
            </mat-menu>
        </ng-container> 

    </mat-menu> -->

    <ng-material-multilevel-menu *ngIf="menubackdrop" [items]='menuItems' (click)="closeMobileMenu($event)" [configuration]='config'> </ng-material-multilevel-menu>
</div>