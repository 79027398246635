import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { PageContentModel } from '../core/model/page-content.model';
import { PageHeaderContentModel } from '../core/model/page-header.model';
import { ContentfulService } from '../core/service/contentful.service';

@Component({
  selector: 'app-careers',
  templateUrl: './careers.component.html',
  styleUrls: ['./careers.component.scss']
})
export class CareersComponent implements OnInit {
  pageContent!: PageContentModel;
  pageHeader!: PageHeaderContentModel;
  constructor(private contentfulService: ContentfulService, private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.loadBodyEntries();
    this.loadHeaderEntries();
  }
  loadHeaderEntries() {
    this.contentfulService.getEntry('pageHeader', 'Careers Header').subscribe(
      (entry) => {
        this.pageHeader = new PageHeaderContentModel(entry);
      }, error => {
        console.error('Error fetching entries:', error);
      });
  }

  loadBodyEntries() {
    this.contentfulService.getEntry('pageInsights', 'Careers Content').subscribe(
      (entry) => {
        this.pageContent = new PageContentModel(entry);
        this.pageContent.description = this.pageContent.description.replace(/<a\s+(?:[^>]*?\s+)?href="([^"]*)"([^>]*)>/g, '<a href="$1" target="_blank"$2>');
      }, error => {
        console.error('Error fetching entries:', error);
      });
  }

  getBreadcrumbs(): string[] {
    return this.pageHeader.breadCrumbs.split(',').map(breadcrumb => breadcrumb.trim());
  }
}
