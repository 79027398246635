export const environment = {
  production: true,
  apiUrl: 'https://api.linkex.us',
  GA_TRACKING_ID: 'G-EKJP6B6XW4',
  GTM_CONTAINER_ID: 'GTM-P7Q8C8N',
  space: 'ah9y293bwjbs',
  accessToken: 'A2UbAh3voWH5hR55jrIHcaapYI1Kfa-Bv4oY3OoHp4Q',
  environment: 'master',
  recaptcha: {
    siteKey: '6Lfj4eopAAAAABZfw3DVQKrJEULDq8Gwc1JXa2_4'
  }
};
