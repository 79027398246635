import { Directive, Input, ElementRef, Renderer2, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
    selector: '[heroImage]'
})
export class ImageDirective implements OnChanges {
    @Input() heroImage: string | undefined;

    constructor(private el: ElementRef, private renderer: Renderer2) { }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['heroImage'] && this.heroImage) {
            this.renderer.setStyle(this.el.nativeElement, 'background-image', `url('${this.heroImage}')`);
        }
    }
}
