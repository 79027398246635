<div class="spinner-wrapper" *ngIf="loader">
    <div class="spinner">
        <div></div>
    </div>
</div>
<!-- Breadcrumb Wrapper -->
<div class="breadcrumb-wrapper">
    <div class="row">
        <div class="col-md-10 col-xs-12 col-md-offset-1">
            <ol>
                <li routerLink="/home">
                    <span class="material-icons">home</span>
                </li>
                <li class="child" routerLink="/track">
                    Track
                </li>
                <li class="active">
                    Details
                </li>
            </ol>
        </div>
    </div>
</div>

<!-- Section -->
<div class="content-section">
    <div *ngIf="isInfoAvailable">
        <div class="row center-xs">
            <div class="col-md-10">
                <h4 class="header-text text-blue py-2">
                    SO NUMBER | <span>{{shipOrder}}</span>
                </h4>
                <div class="content-text p-0">
                    <!-- PICKUP DATE | <span>{{trackingDetails.PickUpDateTime | date: 'MM/dd/yyyy hh:mm a z'}}</span> -->
                    PICKUP DATE | <span>{{trackingDetails.PickUpDateTime}}</span>
                </div>
                <div class="badge">
                    {{ getStatusText() }}
                </div>


                <div class="content-text p-0" *ngIf="trackingDetails.Status === 'Delivered'; else pickUpInfo">
                    ON <span><!-- {{trackingDetails.DeliveryDateTime | date: 'MM/dd/yyyy hh:mm a z'}} --> {{trackingDetails.DeliveryDateTime}}</span>
                </div>
                <ng-template #pickUpInfo>
                    <div class="content-text p-0">
                        Estimated Delivery Date <span> {{trackingDetails.EarliestEstimatedDelivery|
                            slice:0:10}}{{trackingDetails.EarliestEstimatedDelivery|
                            slice:10}} - {{trackingDetails.LatestEstimatedDelivery|
                            slice:10}}
                        </span>
                    </div>
                </ng-template>
            </div>
        </div>

        <br><br>

        <div class="row center-xs">
            <div class="col-md-12 col-xs-12" *ngIf="trackingDetails.Status === 'Delivered'; else pickProgress">
                <div class="tracking-status">
                    <span class="icons-status-text">In Transit</span>
                    <!-- <span class="status-divider-before"></span> -->
                    <span class="material-icons completed">lens</span>
                    <span class="status-divider-after"></span>
                </div>
                <div class="tracking-status">
                    <span class="icons-status-text">Delivered</span>
                    <span class="status-divider-before"></span>
                    <img [src]="'/assets/images/iconset/in-transit-complete.png'" class="in-transit" alt="">
                </div>
            </div>
            <ng-template #pickProgress>
                <div class="col-md-12 col-xs-12">
                    <!-- <div class="tracking-status">
                <span class="icons-status-text"> Picked Up </span>
                <span class="material-icons completed">lens</span>
                <span class="status-divider-after"></span>
            </div> -->
                    <div class="tracking-status">
                        <span class="icons-status-text">In Transit</span>
                        <!-- <span class="status-divider-before"></span> -->
                        <span class="material-icons completed">lens</span>
                        <!-- <span class="status-divider-after"></span> -->
                    </div>
                    <!-- <div class="tracking-status">
                <span class="icons-status-text">At Destination Terminal</span>
                <span class="status-divider-before"></span>
                <span class="material-icons completed">lens</span>
                <span class="status-divider-after"></span>
            </div>
            <div class="tracking-status">
                <span class="icons-status-text">Out for Delivery</span>
                <span class="status-divider-before"></span>
                <span class="material-icons completed">lens</span>
                <span class="status-divider-after"></span>
            </div> -->
                    <!-- <div class="tracking-status">
                <span class="icons-status-text">Delivered</span>
                <span class="status-divider-before"></span> 
                <img [src]="'/assets/images/iconset/in-transit-complete.png'" class="in-transit" alt="">      
            </div> -->
                    <!-- <div class="receive-notification">
                <span class="icons-status-text">Receive Notification</span>
                <span class="material-icons">email</span>
            </div> -->
                </div>
            </ng-template>
            <div class="col-md-8 col-xs-12 p-0">
                <div class="row start-xs">
                    <div class="col-md-6 col-xs-12">
                        <div class="address-box">
                            <div class="header-text">
                                SHIPPER
                            </div>
                            <div class="content-text">
                                {{trackingDetails.ShipperAddress.Name}}<br>
                                {{trackingDetails.ShipperAddress.Address1}} <br>
                                {{trackingDetails.ShipperAddress.Address2}} <br>
                                {{trackingDetails.ShipperAddress.City}},
                                {{trackingDetails.ShipperAddress.State}}
                                {{trackingDetails.ShipperAddress.PostalCode}}
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-xs-12">
                        <div class="address-box">
                            <div class="header-text">
                                CONSIGNEE
                            </div>
                            <div class="content-text">
                                {{trackingDetails.ConsigneeAddress.Name}}<br>
                                {{trackingDetails.ConsigneeAddress.Address1}} <br>
                                {{trackingDetails.ConsigneeAddress.Address2}}<br>
                                {{trackingDetails.ConsigneeAddress.City}},
                                {{trackingDetails.ConsigneeAddress.State}}
                                {{trackingDetails.ConsigneeAddress.PostalCode}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-8 col-xs-12 p-0">
                <div class="row start-xs">
                    <div class="col-md-6 col-xs-12">
                        <div class="address-box">
                            <div class="header-text">
                                Bill To
                            </div>
                            <div class="content-text">
                                <span>{{trackingDetails.BillAddress.Name}}</span> <br>
                                <span>{{trackingDetails.BillAddress.Address1}}</span> <br> <span> {{trackingDetails.BillAddress.Address2}}</span> <br>
                                <span>{{trackingDetails.BillAddress.City}}</span>, <span>{{trackingDetails.BillAddress.State
                                    }}</span>, <span>{{trackingDetails.BillAddress.PostalCode}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-xs-12">
                        <div class="address-box">
                            <div class="content-text">
                                <span class="bold"> Status: </span> {{ getStatusText() }}<br>
                                <span class="bold">SO Number: </span> {{trackingDetails.ShippingOrder}}<br>
                                <span class="bold">Shipper Ref Number: </span> {{trackingDetails.ShipperRefNumber}} <br>
                                <span class="bold">Consignee Ref Number: </span> {{trackingDetails.ConsigneeRefNumber}} <br>
                                <span class="bold">Billing Ref Number: </span> {{trackingDetails.BillingRefNumber}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <br><br>

        <div class="row center-xs">
            <div class="col-md-8 col-xs-12 start-xs">

                <mat-accordion>
                    <mat-expansion-panel class="mat-elevation-z0 accordion-custom quote" hideToggle="false">
                        <mat-expansion-panel-header class="border-0">
                            <mat-panel-title>
                                <div class="header-text py-2">Shipment Information</div>
                            </mat-panel-title>
                        </mat-expansion-panel-header>

                        <br>

                        <div class="responsive">
                            <table class="mat-table mat-elevation-z0" id="shipment-information">
                                <thead role="rowgroup">
                                    <tr>
                                        <th class="mat-header-cell-shipment-info">
                                            Item ID
                                        </th>
                                        <th class="mat-header-cell-shipment-info">
                                            Dimensions
                                        </th>
                                        <th class="mat-header-cell-shipment-info">
                                            Description
                                        </th>
                                        <th class="mat-header-cell-shipment-info">
                                            Qty
                                        </th>
                                        <th class="mat-header-cell-shipment-info">
                                            Qty UOM
                                        </th>
                                    </tr>
                                </thead>
                                <tbody role="rowgroup">
                                    <tr class="mat-row" *ngFor="let lineItem of trackingDetails.LineItems">
                                        <td class="mat-cell-shipment-info">
                                            {{lineItem.Type}}
                                        </td>
                                        <td class="mat-cell-shipment-info">
                                            {{lineItem.Length}} x {{lineItem.Width}} x {{lineItem.Height}}
                                        </td>
                                        <td class="mat-cell-shipment-info">
                                            {{lineItem.Contents}}
                                        </td>
                                        <td class="mat-cell-shipment-info">
                                            {{lineItem.Quantity}}
                                        </td>
                                        <td class="mat-cell-shipment-info">
                                            {{lineItem.QuantityUom}}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <br>

                        <div class="responsive">
                            <table class="mat-table mat-elevation-z0" id="total-shipment-information">
                                <thead role="rowgroup">
                                    <tr>
                                        <th class="mat-header-cell-shipment-info">
                                            Total Pieces
                                        </th>
                                        <th class="mat-header-cell-shipment-info">
                                            Total Actual Weight
                                        </th>
                                        <th class="mat-header-cell-shipment-info">
                                            Total Chargeable Weight
                                        </th>
                                    </tr>
                                </thead>
                                <tbody role="rowgroup">
                                    <tr class="mat-row">
                                        <td class="mat-cell-shipment-info">
                                            {{trackingDetails.TotalPieces}}
                                        </td>
                                        <td class="mat-cell-shipment-info">
                                            {{trackingDetails.TotalWeight}} lb
                                        </td>
                                        <td class="mat-cell-shipment-info">
                                            <!-- trackingDetails.TotalChargedWeight ? (trackingDetails.TotalWeight -
                                            trackingDetails.TotalChargedWeight) + ' lb' : trackingDetails.TotalWeight +
                                            ' lb' -->
                                            {{trackingDetails.TotalChargedWeight}} lb
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <br><br>

                    </mat-expansion-panel>

                    <mat-expansion-panel class="mat-elevation-z0 accordion-custom quote" hideToggle="false">
                        <mat-expansion-panel-header class="border-0">
                            <mat-panel-title>
                                <div class="header-text py-2">History</div>
                            </mat-panel-title>
                        </mat-expansion-panel-header>

                        <br>

                        <div class="responsive">
                            <table class="mat-table mat-elevation-z0" id="shipment-history">

                                <thead role="rowgroup">
                                    <tr class="mat-header-row">
                                        <th class="mat-header-cell">
                                            Date
                                        </th>
                                        <th class="mat-header-cell">
                                            Time
                                        </th>
                                        <th class="mat-header-cell">
                                            Location
                                        </th>
                                        <th class="mat-header-cell">
                                            Activity
                                        </th>
                                    </tr>
                                </thead>
                                <tbody role="rowgroup">
                                    <tr class="mat-row" *ngFor="let history of trackingDetails.history">
                                        <td class="mat-cell">
                                            {{history.date}}
                                        </td>
                                        <td class="mat-cell">
                                            <!-- {{ formatTime(history.time) }} -->
                                            {{history.time}}
                                        </td>
                                        <td class="mat-cell">
                                            {{history.location}}
                                        </td>
                                        <td class="mat-cell">
                                            {{history.activity}}
                                        </td>
                                    </tr>
                                </tbody>

                            </table>
                        </div>

                        <br><br>

                    </mat-expansion-panel>

                    <mat-expansion-panel class="mat-elevation-z0 accordion-custom quote" hideToggle="false">
                        <mat-expansion-panel-header class="border-0">
                            <mat-panel-title>
                                <div class="header-text py-2">Proof of Delivery</div>
                            </mat-panel-title>
                        </mat-expansion-panel-header>


                        <br>

                        <div class="responsive">
                            <table class="mat-table mat-elevation-z0" id="proof-delivery">

                                <thead role="rowgroup">
                                    <tr class="mat-header-row">
                                        <th class="mat-header-cell">
                                            Date
                                        </th>
                                        <th class="mat-header-cell">
                                            Signed By
                                        </th>
                                    </tr>
                                </thead>
                                <tbody role="rowgroup">
                                    <tr class="mat-row">
                                        <td class="mat-cell">
                                            <!-- trackingDetails.ProofOfDeliveryDate -->
                                            <!-- {{trackingDetails.DeliveryDateTime | date: 'MM/dd/yyyy hh:mm a z'}} -->
                                            {{trackingDetails.DeliveryDateTime}}
                                        </td>
                                        <td class="mat-cell">
                                            {{trackingDetails.ProofOfDeliverySignature}}
                                        </td>
                                    </tr>
                                </tbody>

                            </table>
                        </div>

                        <br><br>



                    </mat-expansion-panel>


                    <mat-expansion-panel class="mat-elevation-z0 accordion-custom quote" hideToggle="false" style="visibility: hidden;
                    ">
                        <mat-expansion-panel-header class="border-0">
                            <mat-panel-title>
                                <div class="header-text py-2">Documents</div>
                            </mat-panel-title>
                        </mat-expansion-panel-header>

                        <br>

                        <ng-template #noDocument>
                            <div class="content-text">
                                No documents found
                            </div>
                        </ng-template>


                        <div class="responsive">
                            <table class="mat-table mat-elevation-z0">

                                <thead role="rowgroup">
                                    <tr class="mat-header-row">
                                        <th class="mat-header-cell">
                                            Name
                                        </th>
                                        <th class="mat-header-cell">
                                            Link
                                        </th>
                                    </tr>
                                </thead>
                                <tbody role="rowgroup" *ngIf="trackingDetails.documents.length > 0; else noDocument">
                                    <tr class="mat-row" *ngFor="let document of trackingDetails.documents">
                                        <td class="mat-cell">
                                            {{document.documentName.split('.')[0]}}
                                        </td>
                                        <td class="mat-cell">
                                            <a href="{{document.documentLink}}" target="_blank"> <span class="material-icons">link</span> </a>
                                        </td>
                                    </tr>
                                </tbody>

                            </table>

                        </div>


                    </mat-expansion-panel>


                </mat-accordion>

            </div>
        </div>



        <div class="row center-xs">
            <div class="col-md-8 col-xs-12 start-xs">

                <mat-accordion>

                </mat-accordion>

            </div>
        </div>


        <br><br>

        <div class="row center-xs">
            <div class="col-md-8 col-xs-12 p-0">

                <div class="row">
                    <div class="col-md-4">

                    </div>
                    <div class="col-md-4">

                    </div>
                    <div class="col-md-4 col-xs-12 around-xs btn-grp">
                        <button mat-flat-button class="primary-btn" (click)="downLoadFiles()">Download</button>

                        <button mat-flat-button class="primary-btn" (click)="printTrackingDocument()">Print</button>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>