import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Mat-UI Modules 
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatMenuModule } from '@angular/material/menu';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';

import { NgMaterialMultilevelMenuModule, MultilevelMenuService } from 'ng-material-multilevel-menu';

//Components
import { AppComponent } from './app.component';
import { NavbarComponent } from './navbar/navbar.component';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
import { AboutusComponent } from './company/aboutus/aboutus.component';
import { TruckloadBrokerageComponent } from './services/truckload-brokerage/truckload-brokerage.component';
import { ManagementteamComponent } from './company/managementteam/managementteam.component';
import { CorevalueComponent } from './company/corevalue/corevalue.component';
import { CredentialsComponent } from './company/credentials/credentials.component';
import { ServiceOfferingComponent } from './services/service-offering/service-offering.component';
import { InternationalComponent } from './services/international/international.component';
import { FinalMileComponent } from './services/final-mile/final-mile.component';
import { TrackComponent } from './trackship/track/track.component';
import { DetailsComponent } from './trackship/details/details.component';
import { ExpeditedComponent } from './services/expedited/expedited.component';
import { ContractLogisticsComponent } from './services/contract-logistics/contract-logistics.component';
import { WarehousingComponent } from './services/warehousing/warehousing.component';
import { DocumentsComponent } from './resources/documents/documents.component';
import { TmsComponent } from './resources/tms/tms.component';
import { ResourcesComponent } from './resources/resources/resources.component';
import { PoolDistributionComponent } from './services/pool-distribution/pool-distribution.component';
import { TransloadingComponent } from './services/transloading/transloading.component';
import { VideosComponent } from './news/videos/videos.component';
import { CareersComponent } from './careers/careers.component';
import { BlogComponent } from './news/blog/blog.component';
import { HttpClientModule } from '@angular/common/http';
import { BlogPostComponent } from './news/blog/blog-post/blog-post.component';
import { ContactComponent } from './contact/contact.component';
import { QuoteComponent } from './quote/quote.component';
import { finalmileQuestionnaireComponent } from './services/finalmile-questionnaire/finalmile-questionnaire.component';
import { CommonModule, DatePipe } from '@angular/common';
import { HolidayScheduleComponent } from './resources/holiday-schedule/holiday-schedule.component';
import { SitemapComponent } from './sitemap/sitemap.component';
import { LiveQuoteComponent } from './quote/live-quote/live-quote.component';
import { TlTenderQuoteComponent } from './quote/live-quote/tl-tender-quote/tl-tender-quote.component';
import { TimepickerComponent } from './shared/timepicker/timepicker.component';
import { BillOfLadingComponent } from './bill-of-lading/bill-of-lading.component';
import { TimePickerComponent } from './shared/time-picker/time-picker.component';
import { ImageDirective } from './shared/directives/image-directive';
import { TestimonialsComponent } from './company/testimonials/testimonials.component';
import { RECAPTCHA_SETTINGS, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings } from 'ng-recaptcha';
import { environment } from 'src/environments/environment';
import { ServicesComponent } from './resources/service-updates/services.component';
import { CustomsClearanceComponent } from './services/customs-clearance/customs-clearance.component';



@NgModule({
  declarations: [
      AppComponent,
      NavbarComponent,
      FooterComponent,
      HomeComponent,
      AboutusComponent,
      TruckloadBrokerageComponent,
      ManagementteamComponent,
      CorevalueComponent,
      CredentialsComponent,
      TestimonialsComponent,
      ServiceOfferingComponent,
      InternationalComponent,
      FinalMileComponent,
      TrackComponent,
      DetailsComponent,
      ExpeditedComponent,
      ContractLogisticsComponent,
      WarehousingComponent,
      DocumentsComponent,
      TmsComponent,
      ResourcesComponent,
      PoolDistributionComponent,
      TransloadingComponent,
      VideosComponent,
      CareersComponent,
      BlogComponent,
      BlogPostComponent,
      CareersComponent,
      ContactComponent,
      QuoteComponent,
      finalmileQuestionnaireComponent,
      HolidayScheduleComponent,
      SitemapComponent,
      LiveQuoteComponent,
      TlTenderQuoteComponent,
      TimepickerComponent,
      BillOfLadingComponent,
      TimePickerComponent,
      ImageDirective,
      ServicesComponent,
      CustomsClearanceComponent
    ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    CommonModule,
    RecaptchaModule,
    RecaptchaFormsModule,

    // Mat-UI 
    MatCheckboxModule,
    MatButtonModule,
    MatInputModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatRadioModule,
    MatSelectModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatMenuModule,
    MatNativeDateModule,
    MatRippleModule,
    MatSidenavModule,
    MatToolbarModule,
    MatListModule,
    MatGridListModule,
    MatCardModule,
    MatStepperModule,
    MatTabsModule,
    MatExpansionModule,
    MatButtonToggleModule,
    MatChipsModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatDialogModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,

    //Third party
    NgMaterialMultilevelMenuModule
  ],
  providers: [MultilevelMenuService, NavbarComponent, DatePipe, {
    provide: RECAPTCHA_SETTINGS,
    useValue: { 
      siteKey: environment.recaptcha.siteKey,
    } as RecaptchaSettings,
  },
],
  bootstrap: [AppComponent],
})
export class AppModule { }
