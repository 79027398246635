import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { PageContentModel } from 'src/app/core/model/page-content.model';
import { PageHeaderContentModel } from 'src/app/core/model/page-header.model';
import { ContentfulService } from 'src/app/core/service/contentful.service';

@Component({
  selector: 'app-videos',
  templateUrl: './videos.component.html',
  styleUrls: ['./videos.component.scss']
})
export class VideosComponent implements OnInit {
  @Input()
  url1: string = 'https://www.youtube.com/embed/EYGA-hOKW5M';
  urlSafe1: SafeResourceUrl | undefined;  

  url2: string = 'https://www.youtube.com/embed/ynAKZCWI2f4';
  urlSafe2: SafeResourceUrl | undefined;   
  pageContent!: PageContentModel;
  pageHeader!: PageHeaderContentModel;

  constructor(private _sanitizer: DomSanitizer, private contentfulService: ContentfulService, private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.urlSafe1 = this._sanitizer.bypassSecurityTrustResourceUrl(this.url1); 
    this.urlSafe2 = this._sanitizer.bypassSecurityTrustResourceUrl(this.url2); 
    this.loadBodyEntries();
    this.loadHeaderEntries();
  }

  loadHeaderEntries() {
    this.contentfulService.getEntry('pageHeader', 'Videos Header').subscribe(
      (entry) => {
        this.pageHeader = new PageHeaderContentModel(entry);
      }, error => {
        console.error('Error fetching entries:', error);
      });
  }

  getBreadcrumbs(): string[] {
    return this.pageHeader.breadCrumbs.split(',').map(breadcrumb => breadcrumb.trim());
  }
  loadBodyEntries() {
    this.contentfulService.getEntry('pageInsights', 'Videos Content').subscribe(
      (entry) => {
        this.pageContent = new PageContentModel(entry);
      }, error => {
        console.error('Error fetching entries:', error);
      });
  }
  
  get leftSanitizedDescription(): SafeHtml {
    const htmlContent = this.pageContent.links[0].leftPanelLinks;
    const sanitizedContent = htmlContent.replace(/&lt;|&gt;|&quot;|&nbsp;/g, (match) => {
      const replacements = {
        '&lt;': '<',
        '&gt;': '>',
        '&quot;': '"',
        '&nbsp;': '',
      } as Record<string, string>; // Type assertion here

      return replacements[match as keyof typeof replacements];
    }).replace(/<p[^>]*>(.*?)<\/p>/g, '$1').replace(/\s*<iframe/g, '<iframe');
    // console.log("ssss", modifiedHtmlContent)
    return this.sanitizer.bypassSecurityTrustHtml(sanitizedContent);
    // return withoutPTags;
  }

  get rightSanitizedDescription(): SafeHtml {
    const htmlContent = this.pageContent.links[0].rightPanelLinks;
    const sanitizedContent = htmlContent.replace(/&lt;|&gt;|&quot;|&nbsp;/g, (match) => {
      const replacements = {
        '&lt;': '<',
        '&gt;': '>',
        '&quot;': '"',
        '&nbsp;': '',
      } as Record<string, string>; // Type assertion here

      return replacements[match as keyof typeof replacements];
    }).replace(/<p[^>]*>(.*?)<\/p>/g, '$1').replace(/\s*<iframe/g, '<iframe');
    // console.log("ssss", modifiedHtmlContent)
    return this.sanitizer.bypassSecurityTrustHtml(sanitizedContent);
    // return withoutPTags;
  }
}
