<div class="spinner-wrapper" *ngIf="loader">
    <div class="spinner">
        <div></div>
    </div>
</div>
<!-- Breadcrumb Wrapper -->
<div class="breadcrumb-wrapper">
    <div class="row">
        <div class="col-md-10 col-xs-12 col-md-offset-1">
            <ol>
                <li routerLink="/home">
                    <span class="material-icons">home</span>
                </li>
                <li class="child" routerLink="/service-offering">
                    Services
                </li>
                <li class="active">
                    Final Mile Questionnaire
                </li>
            </ol>
        </div>
    </div>
</div>

<!-- Section -->
<div class="content-section m-section pt-0">
    <div class="col-md-10 col-xs-12 col-md-offset-1">

        <div class="content-section py-0">
            <div class="row">
                <div class="col-md-12">
                    <h4 class="header-text text-blue py-2">Final Mile QUESTIONNAIRE</h4>
                    <div class="error" *ngIf="finalmileStatusError">
                        <span>Error</span> | Please fill out all highlighted form fields correctly and then try
                        submitting again
                    </div>

                    <div class="success" *ngIf="finalmileStatus">
                        Thank you for submitting a quote request. Someone will get back to you shortly.
                    </div>
                    <div *ngIf="isFormSubmittedFinalMileApiError" class="error">
                        {{apiResponse.message}}
                    </div>
                    <form [formGroup]="finalMile" #ngFinalMile="ngForm" (keydown)="onKeyDown($event)" class=" ">
                        <div class="row row-form">
                            <div class="form-group col-md-6 col-xs-12">
                                <label for="">Customer Name<sup> &#42;</sup></label>
                                <mat-form-field appearance="outline" class="col-md-12 p-0">
                                    <input matInput type="input" placeholder="Enter Customer Name"
                                        formControlName="custName" />
                                    <mat-error> Enter Valid Customer Name </mat-error>
                                </mat-form-field>
                            </div>

                            <div class="form-group col-md-6 col-xs-12">
                                <label for="">Customer Phone Number<sup> &#42;</sup></label>
                                <mat-form-field appearance="outline" class="col-md-12 p-0">
                                    <input matInput type="input" maxlength="12" (input)="numberOnly($event)"
                                        inputmode="numeric" placeholder="Enter Phone Number" autocomplete="off"
                                        formControlName="yourPhone" />
                                    <mat-error> Enter Valid Phone Number </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row row-form">
                            <div class="form-group col-md-6 col-xs-12">
                                <label for="">Customer Email<sup> &#42;</sup></label>
                                <mat-form-field appearance="outline" class="col-md-12 p-0">
                                    <input matInput type="input" placeholder="Enter Email ID"
                                        formControlName="custEmail" />
                                    <mat-error> Enter Valid Email </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row row-form">
                            <div class="form-group col-md-6 col-xs-12">
                                <label for="">Saia Salesperson Name</label>
                                <mat-form-field appearance="outline" class="col-md-12 p-0">
                                    <input matInput type="input" placeholder="Enter Saia Salesperson Name"
                                        formControlName="saiaSalesPerson" />
                                </mat-form-field>
                            </div>

                            <div class="form-group col-md-6 col-xs-12">
                                <label for="">Saia Salesperson Email</label>
                                <mat-form-field appearance="outline" class="col-md-12 p-0">
                                    <input matInput type="input" placeholder="Enter Email ID"
                                        formControlName="saiaSalesPersonEmail" />
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="row row-form">
                            <div class="col-md-12 col-xs-12">
                                <h4 class="header-text header-sub-size py-2 remove-underline">
                                    General Questions
                                </h4>
                            </div>
                        </div>

                        <div class="row row-form">
                            <div class="form-group col-md-6 col-xs-12">
                                <label for="" class="m-d">Is this opportunity a Fixed-term Project or Recurring Business
                                    need?<sup>
                                        &#42;</sup></label>
                                <mat-radio-group ngDefaultControl formControlName="opportunityType"
                                    (change)="opportunity($event)">
                                    <mat-radio-button value="fixedTermProject">Fixed-term Project
                                    </mat-radio-button>
                                    <mat-radio-button value="recurringBusiness">Recurring Business
                                    </mat-radio-button>
                                    <mat-error class="custom-error"
                                        *ngIf="isFormSubmittedFinalMile && finalMile.controls['opportunityType'].errors?.['required']">
                                        Select
                                        Opportunity Type</mat-error>
                                </mat-radio-group>
                            </div>
                        </div>

                        <br>

                        <div class="row row-form" *ngIf="expectedProject">
                            <div class="form-group col-md-6 col-xs-12">
                                <label for="">Expected project start date<sup>
                                        &#42;</sup></label>
                                <mat-form-field appearance="outline" class="col-md-12 p-0">
                                    <input matInput type="input" readonly [matDatepicker]="startDate" [min]="minDate"
                                        (dateChange)="dateChange($event)" placeholder="Select Start Date"
                                        autocomplete="off" formControlName="projectStartDate" />
                                    <mat-datepicker-toggle matIconSuffix [for]="startDate"></mat-datepicker-toggle>
                                    <mat-datepicker #startDate></mat-datepicker>
                                    <mat-error>Select Expected start date</mat-error>
                                </mat-form-field>
                            </div>

                            <div class="form-group col-md-6 col-xs-12">
                                <label for="">Expected project end date<sup>
                                        &#42;</sup></label>
                                <mat-form-field appearance="outline" class="col-md-12 p-0">
                                    <input matInput type="input" readonly [matDatepicker]="endDate" [min]="shipDate"
                                        (dateChange)="dateChange($event)" placeholder="Select End Date"
                                        autocomplete="off" formControlName="projectEndDate" />
                                    <mat-datepicker-toggle matIconSuffix [for]="endDate"></mat-datepicker-toggle>
                                    <mat-datepicker #endDate></mat-datepicker>
                                    <mat-error>Select Expected end date</mat-error>
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="row row-form" *ngIf="recurringProject">
                            <div class="form-group col-md-6 col-xs-12">
                                <label for="">Expected start date for Recurring Business<sup>
                                        &#42;</sup></label>
                                <mat-form-field appearance="outline" class="col-md-12 p-0">
                                    <input matInput type="input" readonly [matDatepicker]="startDateRecurring"
                                        [min]="minDate" (dateChange)="dateChange($event)"
                                        placeholder="Select Expected Date for Recurring Business" autocomplete="off"
                                        formControlName="expectedStartDateRecurring" />
                                    <mat-datepicker-toggle matIconSuffix [for]="startDateRecurring">
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #startDateRecurring></mat-datepicker>
                                    <mat-error>Select Expected start date</mat-error>
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="row row-form">
                            <div class="form-group col-md-12 col-xs-12">
                                <label for="">Project Scope<sup> &#42;</sup></label>
                                <mat-form-field appearance="outline" class="col-md-12 p-0">
                                    <input matInput type="input" placeholder="Enter Brief Description of Need"
                                        formControlName="projectScope" />
                                    <mat-error> Enter Project Scope</mat-error>
                                </mat-form-field>
                            </div>
                        </div>


                        <div class="row row-form">
                            <div class="form-group col-md-6 col-xs-12">
                                <label for="" class="m-d">Is historical and/or sample data available?<sup>
                                        &#42;</sup></label>
                                <mat-radio-group ngDefaultControl formControlName="historicalData">
                                    <mat-radio-button value="Yes">Yes</mat-radio-button>
                                    <mat-radio-button value="No">No</mat-radio-button>
                                    <mat-error class="custom-error"
                                        *ngIf="isFormSubmittedFinalMile && finalMile.controls['historicalData'].errors?.['required']">
                                        Select Historical Data</mat-error>
                                </mat-radio-group>
                            </div>
                        </div>

                        <br>

                        <hr />


                        <div class="row row-form">
                            <div class="form-group col-md-6 col-xs-12">
                                <label for="" class="m-d">Are you a current Saia customer?</label>
                                <mat-radio-group ngDefaultControl formControlName="currentCustomer"
                                    (change)="currentSaia($event)">
                                    <mat-radio-button value="Yes">Yes</mat-radio-button>
                                    <mat-radio-button value="No">No</mat-radio-button>
                                </mat-radio-group>
                            </div>

                            <div class="form-group col-md-6 col-xs-12" *ngIf="saiaAccount">
                                <label for="">Saia Account Number</label>
                                <mat-form-field appearance="outline" class="col-md-12 p-0">
                                    <input matInput type="input" placeholder="Enter Saia Account Number"
                                        formControlName="saiaAccount" (input)="alphaOnly($event)" />
                                </mat-form-field>
                            </div>
                        </div>

                        <br>

                        <div class="row row-form">
                            <div class="form-group col-md-12 col-xs-12">
                                <label for="">Additional relevant considerations</label>
                                <mat-form-field appearance="outline" class="col-md-12 p-0">
                                    <input matInput type="input" placeholder="Enter Additional Details"
                                        formControlName="additionalRelevant" />
                                </mat-form-field>
                            </div>

                        </div>

                        <div class="row row-form">
                            <div class="col-md-12 col-xs-12">
                                <h4 class="header-text header-sub-size py-2 remove-underline">
                                    Service Description
                                </h4>
                            </div>
                        </div>

                        <div class="row row-form">
                            <div class="form-group col-md-6 col-xs-12" formGroupName="typeServiceFb">
                                <label for="">Type of Service<sup> &#42;</sup></label>
                                <mat-checkbox-group aria-label="Select Type of Service" class="m-t" ngDefaultControl>
                                    <mat-checkbox formControlName="delivery" name="delivery" value="delivery">Delivery
                                    </mat-checkbox>
                                    <mat-checkbox formControlName="pickup" name="pickup" value="pickup">Pickup
                                    </mat-checkbox>
                                </mat-checkbox-group>

                                <mat-error class="custom-error"
                                    *ngIf="isFormSubmittedFinalMile && finalMile.get('typeServiceFb')?.hasError('required')">
                                    Please select Type of Service
                                </mat-error>
                            </div>

                            <div class="form-group col-md-6 col-xs-12" formGroupName="typeSiteFb">
                                <label for="">Type of Site<sup> &#42;</sup></label>
                                <mat-checkbox-group aria-label="Select Type of Site" class="m-t" ngDefaultControl>
                                    <mat-checkbox formControlName="residential" name="residential" value="residential">
                                        Residential
                                    </mat-checkbox>
                                    <mat-checkbox formControlName="commercial" name="commercial" value="commercial">
                                        Commercial
                                    </mat-checkbox>
                                </mat-checkbox-group>

                                <mat-error class="custom-error"
                                    *ngIf="isFormSubmittedFinalMile && finalMile.get('typeSiteFb')?.hasError('required')">
                                    Please select Type of Site
                                </mat-error>
                            </div>
                        </div>

                        <br>

                        <div class="row row-form">
                            <div class="form-group col-md-12 col-xs-12" formGroupName="trasportationFb">
                                <label for="">Transportation to destination provided by</label>
                                <mat-checkbox-group aria-label="Select Type of Site" class="m-t" ngDefaultControl>
                                    <mat-checkbox formControlName="saiaLTL" name="saiaLTL" value="saiaLTL">Saia LTL
                                    </mat-checkbox>
                                    <mat-checkbox formControlName="linkExSourcedTL" name="linkExSourcedTL"
                                        value="linkExSourcedTL">
                                        LinkEx-sourced TL
                                    </mat-checkbox>
                                    <mat-checkbox formControlName="customerSourced" name="customerSourced"
                                        value="customerSourced">
                                        Customer Sourced
                                    </mat-checkbox>
                                </mat-checkbox-group>
                            </div>
                        </div>

                        <br>

                        <div class="row row-form">
                            <div class="col-md-12 col-xs-12">
                                <h4 class="header-text header-sub-size py-2 remove-underline">
                                    Commodity
                                </h4>
                            </div>
                        </div>



                        <div class="row row-form">
                            <div class="form-group col-md-12 col-xs-12">
                                <label for="">Commodity Description<sup> &#42;</sup></label>
                                <mat-form-field appearance="outline" class="col-md-12 p-0 textarea">
                                    <textarea matInput maxlength="255"
                                        placeholder="Enter Commodity description, packaging, dimensions, other characteristics"
                                        rows="3" autocomplete="off" formControlName="commodityDescription"> </textarea>
                                    <mat-error>Enter Commodity Description</mat-error>
                                </mat-form-field>
                            </div>


                            <div class="form-group col-md-6 col-xs-12">
                                <label for="" class="m-d">Any hazardous materials<sup>
                                        &#42;</sup></label>
                                <mat-radio-group ngDefaultControl formControlName="hazardousMaterial">
                                    <mat-radio-button value="Yes">Yes</mat-radio-button>
                                    <mat-radio-button value="No">No</mat-radio-button>
                                    <mat-error class="custom-error"
                                        *ngIf="isFormSubmittedFinalMile && finalMile.controls['hazardousMaterial'].errors?.['required']">
                                        Select Historical Data</mat-error>
                                </mat-radio-group>
                            </div>
                        </div>

                        <br />


                        <hr />

                        <div class="row row-form">
                            <div class="col-md-12 col-xs-12">
                                <h4 class="header-text header-sub-size py-2 remove-underline">
                                    Final Mile Service Interests
                                </h4>
                            </div>

                            <div class="col-md-6 col-xs-12 column-direction" formGroupName="finalMileFb">
                                <mat-checkbox ngDefaultControl formControlName="notificationPro">
                                    Notification Prior to Delivery <span class="material-icons icon-size"
                                        matTooltip="Communicate via call, text, or email regarding expected delivery date and time.">info
                                    </span>
                                </mat-checkbox>
                                <mat-checkbox ngDefaultControl formControlName="unattendedDelivery">Unattended Delivery
                                    <span class="material-icons icon-size"
                                        matTooltip="No signature required at time of delivery.">info
                                    </span>
                                </mat-checkbox>
                                <mat-checkbox ngDefaultControl formControlName="appointmentScheduling">Appointment
                                    Scheduling <span class="material-icons icon-size"
                                        matTooltip="Coordinate date and time for delivery with consignee.">info
                                    </span>
                                </mat-checkbox>
                                <mat-checkbox ngDefaultControl formControlName="liftGateDelivery">Lift Gate Delivery
                                    <span class="material-icons icon-size" matTooltip="Ability to lower shipment to ground from the rear of the pickup/delivery
                                vehicle.">info
                                    </span>
                                </mat-checkbox>
                                <mat-checkbox ngDefaultControl formControlName="insidePickupDelivery">Inside Pickup or
                                    Delivery
                                    <span class="material-icons icon-size"
                                        matTooltip="Pickup/Delivery of freight not immediately adjacent to the vehicle (beyond first room of customer site).">info
                                    </span>
                                </mat-checkbox>
                                <mat-checkbox ngDefaultControl formControlName="curbsideDelivery">Curbside Delivery
                                    <span class="material-icons icon-size"
                                        matTooltip="Shipment dropped at the curb.">info
                                    </span>
                                </mat-checkbox>
                                <mat-checkbox ngDefaultControl formControlName="thresholdGarageDelivery">Threshold /
                                    Garage delivery
                                    <span class="material-icons icon-size"
                                        matTooltip="Shipment delivered to / inside the first door or dry area.">info
                                    </span>
                                </mat-checkbox>
                                <mat-checkbox ngDefaultControl formControlName="roomOfChoice">Room of Choice <span
                                        class="material-icons icon-size"
                                        matTooltip="Shipment brought to entry-level room of customer's choice. Often called 'White Glove'.">info
                                    </span></mat-checkbox>
                                <mat-checkbox ngDefaultControl formControlName="flightOfStairs">Flight(s) of Stairs
                                    <span class="material-icons icon-size"
                                        matTooltip="Room of choice, but involving ascent / descent of stairs.">info
                                    </span>
                                </mat-checkbox>
                                <mat-checkbox ngDefaultControl formControlName="manDelivery">2-Man / 3-Man Delivery
                                    <span class="material-icons icon-size"
                                        matTooltip="Delivery requiring more help than just the driver.">info
                                    </span>
                                </mat-checkbox>
                                <mat-checkbox ngDefaultControl formControlName="unpackagingAssembly">Unpackaging or
                                    assembly <span class="material-icons icon-size"
                                        matTooltip="Removing the packaging once shipment is dropped.">info
                                    </span>
                                </mat-checkbox>
                                <mat-checkbox ngDefaultControl formControlName="debrisRemoval">Debris Removal <span
                                        class="material-icons icon-size"
                                        matTooltip="Departing with the shipment's packaging materials.">info
                                    </span>
                                </mat-checkbox>
                                <mat-checkbox ngDefaultControl formControlName="assembly">Assembly <span
                                        class="material-icons icon-size"
                                        matTooltip="Put item together (to varying degrees of complexity and skill).">info
                                    </span>
                                </mat-checkbox>
                                <mat-checkbox ngDefaultControl formControlName="inRoomPlacement">In-Room Placement <span
                                        class="material-icons icon-size"
                                        matTooltip="Staging item once unpackaged and assembled.">info
                                    </span>
                                </mat-checkbox>
                                <mat-checkbox ngDefaultControl formControlName="hookup">Hookup <span
                                        class="material-icons icon-size"
                                        matTooltip="Connecting utilities like water or power.">info
                                    </span>
                                </mat-checkbox>
                                <mat-checkbox ngDefaultControl formControlName="haulAway">Haul Away <span
                                        class="material-icons icon-size"
                                        matTooltip="Removal of a replaced item following delivery.">info
                                    </span>
                                </mat-checkbox>
                                <mat-checkbox ngDefaultControl formControlName="disposalReturns">Disposal / Returns
                                    <span class="material-icons icon-size"
                                        matTooltip="Disposal or return of replaced or undelivered items.">info
                                    </span>
                                </mat-checkbox>
                                <mat-checkbox ngDefaultControl formControlName="repairs">Repairs <span
                                        class="material-icons icon-size"
                                        matTooltip="Post-delivery repairs of damages or blemishes.">info
                                    </span>
                                </mat-checkbox>
                            </div>


                        </div>

                        <br>

                        <div class="row center-xs">
                            <div class="col-md-4"></div>
                            <div class="col-md-4">
                                <button mat-flat-button class="primary-btn" (click)="submitFinalMileQuestionnaire()">
                                    Submit
                                </button>
                            </div>
                            <div class="col-md-4 end-xs p-0">
                                <button mat-button class="primary-btn link" type="reset"
                                    (click)="clearFinalMileQuestionnaire()">Clear</button>
                            </div>
                        </div>

                        <br /><br />
                    </form>

                </div>
            </div>
        </div>


    </div>
</div>