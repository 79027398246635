import { Component, Input, Renderer2, ElementRef, OnInit, forwardRef, Output, EventEmitter, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TimePickerComponent),
      multi: true,
    },
  ]
})
export class TimePickerComponent implements OnInit, ControlValueAccessor {

  @Input() label: string = '';
  @Input() instance: string = '';
  @Input() minumumTime: string = '';
  @Input() maximumTime: string = '';

  @Input() isAM!: boolean;
  @Output() isAMChange = new EventEmitter<boolean>();

  @Input() inputTime: string = '';
  @Output() inputTimeChange = new EventEmitter<string>();
  @Output() onTimeChange = new EventEmitter<string>(); // Event to emit the selected time

  //For validation s
  @Output() validationMessageEmitter = new EventEmitter<string>();
  regExp: any = /^[0-9]{1,2}:[0-9]{1,2}$/;
  errorMessage: string = '';

  @Input() isError: boolean = false;
  @Output() isErrorChange = new EventEmitter<boolean>();

  onChange!: (inputTime: string) => void;


  onPeriodChange(isPeriodAM: boolean) {
    this.isAM = isPeriodAM;
    this.isAMChange.emit(this.isAM);

    // Emit the selected period

    this.validateTimeWithin();
    this.onTimeChange.emit(this.instance);
    this.onChange((this.inputTime.toString() + (this.isAM ? ' AM' : ' PM')));

    // Emit the selected time
    //this.timeSelected.emit(this.timeInput);
  }

  onSelectedTimeChange(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    let value = inputElement.value;

    // Remove non-numeric characters and limit the length to 5 characters
    value = value.replace(/[^\d]/g, '').slice(0, 5);

    // Ensure that the value contains at least one digit
    const containsDigits = /\d/.test(value);

    // If there are no digits, set the value to an empty string
    if (!containsDigits) {
      value = '';
    }

    // Format the value as "hh:mm" with colons in fixed positions
    if (value.length === 4) {
      value = value.slice(0, 2) + ':' + value.slice(2, 4);
    } else if (value.length === 3) {
      value = value.slice(0, 1) + ':' + value.slice(1, 3);
    }

    // Set the input value to the formatted value
    inputElement.value = value;

    // Store the formatted value in this.timeInput
    this.inputTime = value;
    this.inputTimeChange.emit(this.inputTime);

    // Emit the timeSelected event
    this.onTimeChange.emit(this.instance);
    this.validateTimeWithin();
    this.onChange((this.inputTime.toString() + (this.isAM ? ' AM' : ' PM')));
  }

  constructor(private renderer: Renderer2, private el: ElementRef, private cdr: ChangeDetectorRef) { }

  writeValue(value: any): void {
  }

  validateTimeWithin() {
    if (!this.inputTime) {
      this.errorMessage = 'Input is required. Please enter a time value (hh:mm)';
    } else {
      // Regular expression to match the desired format (hh:mm with numbers only)

      if (!this.regExp.test(this.inputTime)) {
        this.errorMessage = 'Invalid input. Please enter a valid time format (hh:mm)';
      } else {
        const [hours, minutes] = this.inputTime.split(':').map(Number);

        if (hours > 12 || minutes > 59) {
          this.errorMessage = 'Invalid time. Hours should be 1-12, and minutes should be 0-59.';
        } else {
          this.errorMessage = ''; // Clear the validation message for valid input
        }
      }
    }
    if (this.errorMessage === '') {
      if (this.minumumTime) {
        if (this.regExp.test(this.inputTime) && Date.parse('01/01/0001 ' + this.inputTime + (this.isAM ? ' AM' : ' PM')) < Date.parse('01/01/0001 ' + this.formatTime(this.minumumTime))) {
          this.errorMessage = 'Invalid time. The time must be after 08:00 AM';
        }
      }
      if (this.maximumTime) {
        if (this.regExp.test(this.inputTime) && Date.parse('01/01/0001 ' + this.inputTime + (this.isAM ? ' AM' : ' PM')) > Date.parse('01/01/0001 ' + this.formatTime(this.maximumTime))) {
          this.errorMessage = 'Invalid time. The time must not be after 5:00 PM.';
        }
      }
    }

    this.isError = this.errorMessage ? true : false;
    this.isErrorChange.emit(this.isError);
  }

  reset(time: string, isAM: boolean) {
    this.inputTime = time;
    this.isAM = isAM;
    this.errorMessage = '';
    this.onTimeChange.emit(this.instance);
    this.inputTimeChange.emit(this.inputTime);
    this.onChange((this.inputTime.toString() + (this.isAM ? ' AM' : ' PM')));
  }

  formatTime(timeString: string) {
    const [hourString, minute] = timeString.split(":");
    const hour = +hourString % 24;
    return (hour % 12 || 12) + ":" + minute + (hour < 12 ? " AM" : " PM");
  }

  registerOnChange(fn: (inputTime: string) => void) {
    this.onChange = fn;
    this.onChange((this.inputTime.toString() + (this.isAM ? ' AM' : ' PM')));
  }


  registerOnTouched(fn: any): void {
    // Save the function that should be called when the input is touched
  }

  setDisabledState(isDisabled: boolean): void {
    // Handle disabling your form control if needed
  }

  ngOnInit(): void {

  }

  ngOnChanges() {
    // Update toggleAM based on input properties.  
  }

}
