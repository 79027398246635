<div class="spinner-wrapper" *ngIf="loader">
    <div class="spinner">
        <div></div>
    </div>
</div>
<!-- Breadcrumb Wrapper -->
<div class="breadcrumb-wrapper">
    <div class="row">
        <div class="col-md-10 col-xs-12 col-md-offset-1">
            <ol>
                <li routerLink="/">
                    <span class="material-icons">home</span>
                </li>
                <li class="child" routerLink="/quote">Get Quote</li>
                <li class="child rm-underline">Quote Details</li>
            </ol>
        </div>
    </div>
</div>

<!-- Section -->
<div class="content-section m-section pt-0">
    <div class="col-md-10 col-xs-12 col-md-offset-1 p-0" *ngIf="liveRateQuoteData?.statusMessage === 'SUCCESS'">
        <div class="success mt-3" *ngIf="emailQuoteResponse?.status==='mail_sent'">
            <strong>SUCCESS |</strong> {{emailQuoteResponse?.message}}
        </div>
        <div class="error mt-3" *ngIf="emailQuoteResponse?.status==='mail_failed'">
            <strong>FAIL |</strong> {{emailQuoteResponse?.message}}
        </div>
        <div class="row">
            <div class="col-md-12 blue-strip mt-3">
                Truckload Quote
            </div>
            <div class="col-md-6 col-xs-12 border-l p-1 text-area text-yellow">

                This quote is for Standard Truckload Van service. If you require expedited service or other equipment type, please email <a href="mailto:TLQuotes@linkex.us" class="link-share">TLQuotes@linkex.us</a> for quotes requiring special handling. Please read the following carefully and verify the information is correct. If you have questions or concerns please contact any member of the Freight Department. We thank you for the opportunity and look forward to serving you in the future.

            </div>
            <div class="col-md-6 col-xs-12 border-r p-1">

                <div class="value">{{liveRateQuoteData?.quote?.total | currency:'USD':'symbol':'1.2-2'}}</div> <br>
                <div class="terms">
                    Terms: Net 15 Days <br>
                    Payments received after 30 days are subject to list price.<br>
                  <span  *ngIf="distance > 0">  Miles: {{distance}} </span>
                </div>

            </div>
            <div class="col-md-12 quote-details p-1">
                <div class="header-text">
                    Quote Reference # <span>{{liveRateQuoteData?.quote?.id}}</span>
                </div>

                <br>

                <div class="responsive">
                    <table class="mat-table mat-elevation-z0 no-more-tables" id="quote-detail-information">

                        <thead role="rowgroup">
                            <tr class="mat-header-row">
                                <th class="mat-header-cell">
                                    Quote Details
                                </th>
                                <th class="mat-header-cell">

                                </th>
                                <th class="mat-header-cell">

                                </th>
                                <th class="mat-header-cell">

                                </th>
                            </tr>
                        </thead>
                        <tbody role="rowgroup">
                            <tr class="mat-row">
                                <td class="mat-cell col-md-3">
                                    Customer Name:
                                </td>
                                <td class="mat-cell col-md-9" colspan="3">
                                    <span>{{liveRateQuoteData?.saiaAccountData?.CMNAME}}</span>
                                </td>
                            </tr>
                            <tr class="mat-row">
                                <td class="mat-cell col-md-3">
                                    Phone:
                                </td>
                                <td class="mat-cell col-md-9" colspan="3">
                                    <span>{{liveRateQuoteData?.userData?.phone}}</span>
                                </td>
                            </tr>
                            <tr class="mat-row">
                                <td class="mat-cell col-md-3">
                                    Email:
                                </td>
                                <td class="mat-cell col-md-9" colspan="3">
                                    <span>{{liveRateQuoteData?.userData?.email}}</span>
                                </td>
                            </tr>
                            <tr class="mat-row">
                                <td class="mat-cell col-md-3">
                                    Quote Reference #:
                                </td>
                                <td class="mat-cell col-md-9" colspan="3">
                                    <span>{{liveRateQuoteData?.quote?.id}}</span>
                                </td>
                            </tr>
                            <tr class="mat-row">
                                <td class="mat-cell col-md-3">
                                    Quote Date:
                                </td>
                                <td class="mat-cell col-md-9" colspan="3">
                                    <span>{{liveRateQuoteData?.quoteDate | date: 'MM/dd/yyyy hh:mm a z'}}</span>
                                </td>
                            </tr>
                            <tr class="mat-row">
                                <td class="mat-cell col-md-3">
                                    Quote Expiration Date:
                                </td>
                                <td class="mat-cell col-md-9" colspan="3">
                                    <span>{{liveRateQuoteData?.quoteExpirationDate | date: 'MM/dd/yyyy hh:mm a z'}}</span>
                                </td>
                            </tr>
                            <tr class="mat-row">
                                <td class="mat-cell col-md-3">
                                    Saia Account #:
                                </td>
                                <td class="mat-cell col-md-9" colspan="3">
                                    <span>{{liveRateQuoteData?.saiaAccountNumber}}</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!-- Ship from table -->
                <div class="responsive">
                    <table class="mat-table mat-elevation-z0 no-more-tables" id="ship-from-information">
                        <thead role="rowgroup">
                            <tr class="mat-header-row">
                                <th class="mat-header-cell">
                                    Ship From
                                </th>
                                <th class="mat-header-cell">

                                </th>
                                <th class="mat-header-cell">

                                </th>
                                <th class="mat-header-cell">

                                </th>
                            </tr>
                        </thead>
                        <tbody role="rowgroup">
                            <tr class="mat-row">
                                <td class="mat-cell col-md-3">
                                    Origin City:
                                </td>
                                <td class="mat-cell col-md-3">
                                    <span>{{liveRateQuoteData?.requestData.pickup.location.city}}</span>
                                </td>
                                <td class="mat-cell col-md-3">
                                    Origin State:
                                </td>
                                <td class="mat-cell col-md-3">
                                    <span>{{liveRateQuoteData?.requestData.pickup.location.state}}</span>
                                </td>
                            </tr>
                            <tr class="mat-row">
                                <td class="mat-cell col-md-3">
                                    Origin Zip:
                                </td>
                                <td class="mat-cell col-md-3">
                                    <span>{{liveRateQuoteData?.requestData.pickup.location.zip}}</span>
                                </td>
                                <td class="mat-cell col-md-3">
                                    Pickup Date:
                                </td>
                                <td class="mat-cell col-md-3">
                                    <span>{{liveRateQuoteData?.requestData.pickup.date | date: 'MM/dd/yyyy'}}</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!-- Ship to table -->
                <div class="responsive">
                    <table class="mat-table mat-elevation-z0 no-more-tables" id="ship-to-information">
                        <thead role="rowgroup">
                            <tr class="mat-header-row">
                                <th class="mat-header-cell">
                                    Ship To
                                </th>
                                <th class="mat-header-cell">

                                </th>
                                <th class="mat-header-cell">

                                </th>
                                <th class="mat-header-cell">

                                </th>
                            </tr>
                        </thead>
                        <tbody role="rowgroup">
                            <tr class="mat-row">
                                <td class="mat-cell col-md-3">
                                    Destination City:
                                </td>
                                <td class="mat-cell col-md-3">
                                    <span>{{liveRateQuoteData?.requestData.dropoff.location.city}}</span>
                                </td>
                                <td class="mat-cell col-md-3">
                                    Destination State:
                                </td>
                                <td class="mat-cell col-md-3">
                                    <span>{{liveRateQuoteData?.requestData.dropoff.location.state}}</span>
                                </td>
                            </tr>
                            <tr class="mat-row">
                                <td class="mat-cell col-md-3">
                                    Destination Zip:
                                </td>
                                <td class="mat-cell col-md-3">
                                    <span>{{liveRateQuoteData?.requestData.dropoff.location.zip}}</span>
                                </td>
                                <td class="mat-cell col-md-3">
                                    Expected Delivery Date:
                                </td>
                                <td class="mat-cell col-md-3">
                                    <span>{{liveRateQuoteData?.quote.expectedDeliveryDate | date: 'MM/dd/yyyy'}}</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!-- Freight information table -->
                <div class="responsive">
                    <table class="mat-table mat-elevation-z0 no-more-tables" id="freight-information-information">
                        <thead role="rowgroup">
                            <tr class="mat-header-row">
                                <th class="mat-header-cell">
                                    Freight Information
                                </th>
                                <th class="mat-header-cell">

                                </th>
                                <th class="mat-header-cell">

                                </th>
                                <th class="mat-header-cell">

                                </th>
                            </tr>
                        </thead>
                        <tbody role="rowgroup">
                            <tr class="mat-row">
                                <td class="mat-cell col-md-3">
                                    Weight:
                                </td>
                                <td class="mat-cell col-md-3">
                                    <span>{{liveRateQuoteData?.requestData.items[0]?.weight | number:'1.0-0'}} <span class="text-small">lbs</span></span>
                                </td>
                                <td class="mat-cell col-md-3">
                                    Hazmat:
                                </td>
                                <td class="mat-cell col-md-3">
                                    <span>{{liveRateQuoteData?.requestData.items[0]?.isHazmat ? 'Yes': 'No'}}</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <br>
            </div>
            <div class="col-md-12 quote-details p-1 text-area">
                By accepting this quote, both the customer and LinkEx Inc. acknowledge that the information listed above is in agreement. This Rate Agreement is subject to LinkEx terms and conditions (<a href="linkex.us" class="link-share">www.linkex.us</a>). Actual and Dimensional (DIM) weight or class information is subject to correction by the freight carrier. Customer agrees to take responsibility for any additional charges by the carrier if any of the above information provided to LinkEx Inc. is inaccurate. Customer will be charged for additional accessorial charges if necessary (i.e Waiting time, Appointments). Customer also agrees to be responsible for any charges not paid to LinkEx Inc. if charged to a 3rd party at the customer’s request.
            </div>

            <mat-button-toggle-group name="fontStyle" aria-label="Font Style">
                <button mat-flat-button class="primary-btn btn-auto" routerLink="/quote" (click)="clearLocalStore()" value="New Quote">New Quote</button>
                <button mat-flat-button class="primary-btn btn-auto" value="Email Quote" (click)="emailQuoteDocument()">Email Quote</button>
                <button mat-flat-button class="primary-btn btn-auto" value="Print Quote" (click)="printQuoteDocument()">Print Quote</button>
                <button mat-flat-button class="primary-btn btn-auto" value="Accept Quote" (click)="acceptQuote()">Accept Quote</button>
            </mat-button-toggle-group>

        </div>



    </div>
</div>