import { DatePipe } from '@angular/common';
import { Component, OnInit, AfterViewChecked, OnChanges, ChangeDetectorRef, Output, EventEmitter, Input, SimpleChanges, ViewChild, QueryList, ViewChildren } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LinkexService } from 'src/app/services/linkex.service.service';
import moment from 'moment';
import { TimePickerComponent } from 'src/app/shared/time-picker/time-picker.component';


interface packageType {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-tl-tender-quote',
  templateUrl: './tl-tender-quote.component.html',
  styleUrls: ['./tl-tender-quote.component.scss']
})

export class TlTenderQuoteComponent implements OnInit, OnChanges {

  loader: boolean = false;
  tlTenderQuote: FormGroup;
  tenderResponse: any = null;
  isFormSubmittedTruckloadApiError = '';
  tenderFormStatusError: boolean = false;
  isChangedEarlistTime: boolean = false;
  liveRateQuoteData: any = null;
  itemArray: number[] = [];
  totalItem: number = 0;
  totalWeight: number = 0;
  originalTotalWeight: number = 0;
  weightArray: number[] = [];
  timeDiff: number = 0;

  // TimePicker Variable Declarations 
  eptAM: boolean = true;
  lptAM: boolean = false;
  edtAM: boolean = true;
  ldtAM: boolean = false;
  eptValue: string = '08:00';
  lptValue: string = '05:00';
  edtValue: string = '08:00';
  ldtValue: string = '05:00';

  eptError: boolean = false;
  lptError: boolean = false;
  edtError: boolean = false;
  ldtError: boolean = false;
  billTo: any = {};

  // Added to prevent form from submitting when there are time errors
  timeError: boolean = false;
  timeErrorMessage: boolean = false;

  @ViewChildren(TimePickerComponent) timeComponentRef!: QueryList<TimePickerComponent>;


  earlistPickupTimeSelected: string = '';
  earlistPickupTimeofDay: string = '';
  latestPickupTimeSelected: string = '';
  latestPickupTimeofDay: string = '';

  earlistDeliveryTimeSelected: string = '';
  earlistDeliveryTimeofDay: string = '';
  latestDeliveryTimeSelected: string = '';
  latestDeliveryTimeofDay: string = '';

  validationMessages: { [key: string]: string } = {};

  constructor(private formBuilder: FormBuilder, private router: Router, private service: LinkexService, private datePipe: DatePipe, private cdr: ChangeDetectorRef) {

    //Session Storage
    const storedData = localStorage.getItem('liveRateQuoteTenderData');
    if (storedData) {
      this.liveRateQuoteData = JSON.parse(storedData);
      this.originalTotalWeight = Number(this.liveRateQuoteData.requestData.items[0].weight)
    } else {
      if (this.router.getCurrentNavigation()?.extras.state) {
        this.liveRateQuoteData = this.router.getCurrentNavigation()?.extras.state;
        if (this.liveRateQuoteData) {
          this.liveRateQuoteData = this.liveRateQuoteData.liveRateQuoteData ? JSON.parse(this.liveRateQuoteData.liveRateQuoteData) : '';
          // Store the data in localStorage for future use
          localStorage.setItem('liveRateQuoteTenderData', JSON.stringify(this.liveRateQuoteData));
          this.originalTotalWeight = Number(this.liveRateQuoteData.requestData.items[0].weight)
        }

      }
    }
    const cmbtpSaiaAccountData = this.liveRateQuoteData.cmbtpSaiaAccountData || {};
    const saiaAccountData = this.liveRateQuoteData.saiaAccountData;
    Object.keys(saiaAccountData).forEach((key) => {
      saiaAccountData[key] = saiaAccountData[key].trim()
    })
    Object.keys(cmbtpSaiaAccountData).forEach((key) => {
      cmbtpSaiaAccountData[key] = cmbtpSaiaAccountData[key].trim()
    })
    if (cmbtpSaiaAccountData?.CMNAME && cmbtpSaiaAccountData?.CMMAD1 && cmbtpSaiaAccountData?.CMMCIT && cmbtpSaiaAccountData?.CMMST && cmbtpSaiaAccountData?.CMMZIP) {
      this.billTo = {
        name: cmbtpSaiaAccountData?.CMNAME,
        address1: cmbtpSaiaAccountData?.CMMAD1,
        address2: cmbtpSaiaAccountData?.CMMAD2,
        city: cmbtpSaiaAccountData?.CMMCIT,
        state: cmbtpSaiaAccountData?.CMMST,
        country: 'US',
        zip: cmbtpSaiaAccountData?.CMMZIP,
        zp4: cmbtpSaiaAccountData?.CMMZP4
      }
    } else if (cmbtpSaiaAccountData?.CMNAME && cmbtpSaiaAccountData?.CMADR1 && cmbtpSaiaAccountData?.CMCITY && cmbtpSaiaAccountData?.CMST && cmbtpSaiaAccountData?.CMZIP) {
      this.billTo = {
        name: cmbtpSaiaAccountData?.CMNAME,
        address1: cmbtpSaiaAccountData?.CMADR1,
        address2: cmbtpSaiaAccountData?.CMADR2,
        city: cmbtpSaiaAccountData?.CMCITY,
        state: cmbtpSaiaAccountData?.CMST,
        country: 'US',
        zip: cmbtpSaiaAccountData?.CMZIP,
        zp4: cmbtpSaiaAccountData?.CMZIP4
      }
    } else if (saiaAccountData?.CMNAME && saiaAccountData?.CMMAD1 && saiaAccountData?.CMMCIT && saiaAccountData?.CMMST && saiaAccountData?.CMMZIP) {
      this.billTo = {
        name: saiaAccountData?.CMNAME,
        address1: saiaAccountData?.CMMAD1,
        address2: saiaAccountData?.CMMAD2,
        city: saiaAccountData?.CMMCIT,
        state: saiaAccountData?.CMMST,
        country: 'US',
        zip: saiaAccountData?.CMMZIP,
        zp4: saiaAccountData?.CMMZP4
      }
    } else {
      this.billTo = {
        name: saiaAccountData?.CMNAME,
        address1: saiaAccountData?.CMADR1,
        address2: saiaAccountData?.CMADR2,
        city: saiaAccountData?.CMCITY,
        state: saiaAccountData?.CMST,
        country: 'US',
        zip: saiaAccountData?.CMZIP,
        zp4: saiaAccountData?.CMZIP4
      }
    }
    this.billTo.locationCode = saiaAccountData?.CMBTP ? saiaAccountData?.CMBTP : this.liveRateQuoteData.saiaAccountNumber;
    this.totalWeight = Number(this.liveRateQuoteData.requestData.items[0].weight);

    //Pickup Date formatting
    const pickupDateValue = this.liveRateQuoteData.requestData.pickup.date;
    const dateParts = pickupDateValue.split(' '); // Split by space to separate date and time
    const formattedPickupDate = dateParts[0]; // Get the date part

    //Delivery Date formatting
    const deliveryDateValue = this.liveRateQuoteData.quote.expectedDeliveryDate;
    const datePartsDelivery = deliveryDateValue.split(' '); // Split by space to separate date and time
    const formattedDeliveryDate = datePartsDelivery[0]; // Get the date part

    const today = new Date()
    let earliestPickupDate = moment(`${formattedPickupDate} ${today.getHours()}:${today.getMinutes()}`, 'MM/DD/YYYY HH:mm').toDate();
    this.timeDiff = (((earliestPickupDate.getTime() - today.getTime()) / 1000) / 60) / 60;

    // Form Builder Group
    this.tlTenderQuote = this.formBuilder.group({
      pickupDate: [formattedPickupDate],
      earliestPickupSI: [this.eptValue, [Validators.required]],
      latestPickupSI: [this.lptValue, [Validators.required]],
      pickupCompanyName: ['', [Validators.required]],
      pickupAddressline1: ['', [Validators.required]],
      pickupAddressline2: [''],
      pickupShipperCity: [this.liveRateQuoteData.requestData.pickup.location.city],
      pickupShipperState: [this.liveRateQuoteData.requestData.pickup.location.state],
      pickupShipperZip: [this.liveRateQuoteData.requestData.pickup.location.zip],
      pickupContactName: [''],
      pickupPhone: [''],
      pickupEmail: ['', [Validators.email]],
      deliveryDate: [formattedDeliveryDate],
      earliestDeliveryCI: [this.edtValue, [Validators.required]],
      latestDeliveryCI: [this.ldtValue, [Validators.required]],
      deliveryCompanyName: ['', [Validators.required]],
      deliveryAddress1: ['', [Validators.required]],
      deliveryAddress2: [''],
      deliveryCity: [this.liveRateQuoteData.requestData.dropoff.location.city],
      deliveryState: [this.liveRateQuoteData.requestData.dropoff.location.state],
      deliveryZip: [this.liveRateQuoteData.requestData.dropoff.location.zip],
      deliveryContact: [''],
      deliveryPhone: [''],
      deliveryEmail: ['', [Validators.email]],
      specialInstruction: [''],
      freightDetails: this.formBuilder.array([this.createFreightFormGroup(true)]),
      billingReference: ['', [Validators.required]],
      shipperReference: [''],
      consigneeReference: ['']
    });

    const freightDetailsArray = this.tlTenderQuote.get('freightDetails') as FormArray;
    freightDetailsArray.controls.forEach((control, index) => {
      this.characterCount[index] = 0;
    });

  }

  //Freight Details Form Array
  get freightDetailsAsFormArray(): any {
    return this.tlTenderQuote.get('freightDetails') as FormArray;
  }


  createFreightFormGroup(isInitials?: boolean): FormGroup {
    this.weightArray.push(isInitials ? Number(this.liveRateQuoteData.requestData.items[0].weight) : 0);
    this.itemArray.push(0);

    return this.formBuilder.group({
      freightDescription: ['', [Validators.required]],
      packageType: ['', [Validators.required]],
      weight: [isInitials ? this.liveRateQuoteData.requestData.items[0].weight : '', [Validators.required, Validators.max(48000), this.weightLimitValidator.bind(this)]],
      pieces: ['', [Validators.required]],
      dimensions: ['', [this.inputValidator]],
      hazmat: [this.liveRateQuoteData.requestData.items[0].isHazmat ? 'YES' : 'NO']
    });
  }

  //Add Form Array 
  addControl(): void {
    const freightDetailsArray = this.tlTenderQuote.get('freightDetails') as FormArray;
    freightDetailsArray.push(this.createFreightFormGroup());

    const newIndex = freightDetailsArray.length - 1;
    this.characterCount[newIndex] = 0;

    // Commenting out because adding an item stops validation of this entire section
    for (let i = 0; i < freightDetailsArray.length; i++) {
      const freightDescriptionControl = freightDetailsArray.at(i).get('freightDescription');
      const packageTypeControl = freightDetailsArray.at(i).get('packageType');
      const weightControl = freightDetailsArray.at(i).get('weight');
      const piecesControl = freightDetailsArray.at(i).get('pieces');
      // const dimensionsControl = freightDetailsArray.at(i).get('dimensions');

      freightDescriptionControl?.clearValidators();
      packageTypeControl?.clearValidators();
      weightControl?.clearValidators();
      piecesControl?.clearValidators();
      // dimensionsControl?.clearValidators();

      freightDescriptionControl?.markAsUntouched({ onlySelf: true })
      packageTypeControl?.markAsUntouched({ onlySelf: true })
      weightControl?.markAsUntouched({ onlySelf: true })
      piecesControl?.markAsUntouched({ onlySelf: true })
      // dimensionsControl?.markAsUntouched({ onlySelf: true })
    }
  }

  //Remove Form Array
  removeControl(): void {
    if (this.freightDetailsAsFormArray.length > 1) {
      this.weightArray.pop();
      this.totalWeight = this.weightArray.reduce((accu, i) => accu + i);
      this.itemArray.pop();
      this.totalItem = this.itemArray.reduce((accu, i) => accu + i);
      this.freightDetailsAsFormArray.removeAt(this.freightDetailsAsFormArray.length - 1);
    } else {
      console.log('At least one shipping detail is required.');
    }
  }

  //Time-Picker 12hours validation message
  handleValidationMessage(instanceId: string, message: string): void {
    this.validationMessages[instanceId] = message;
  }

  onTimeChange(instanceId: string) {
    this.isFormSubmittedTruckloadApiError = '';
    this.timeError = false;

    // this.tlTenderQuote.controls['earliestPickupSI'].setValue(this.eptValue + this.eptAM ? ' AM' : ' PM')
    console.log(`InstanceId: ${instanceId}`)
    if ((instanceId === 'ept' || instanceId === 'lpt') && (!this.eptError && !this.lptError)) {
      //logic for comparing time format
      const startTime = '01/01/0001 ' + this.eptValue + (this.eptAM ? ' AM' : ' PM');
      const endTime = '01/01/0001 ' + this.lptValue + (this.lptAM ? ' AM' : ' PM');

      let hourDiff = (((Date.parse(endTime) - Date.parse(startTime)) / 1000) / 60) / 60;
      if (Date.parse(startTime) > Date.parse(endTime)) {
        this.isFormSubmittedTruckloadApiError = 'error';
        this.timeError = true
        window.scrollTo(0, 0);
        this.tenderResponse = {
          message: 'Latest pickup may not occur before earliest pickup time'
        }
        return;
      }
      // Separated from the above to throw specific message when both times are equal
      if (Date.parse(startTime) === Date.parse(endTime)) {
        this.isFormSubmittedTruckloadApiError = 'error';
        this.timeError = true
        window.scrollTo(0, 0);
        this.tenderResponse = {
          message: 'Latest pickup time may not be the same time as the earliest pickup time'
        }
        return;
      }
      if ((hourDiff < 4)) {
        this.isFormSubmittedTruckloadApiError = 'error';
        this.timeError = true
        window.scrollTo(0, 0);
        this.tenderResponse = {
          message: 'A 4 hour minimum window is required between the earliest pickup time and latest pickup time'
        }
        return;
      }
    }

    // Thien added to validate when lpt has an error but does not revalidate since it does not meet the primary conditionals
    if ((instanceId === 'ept' || instanceId === 'lpt') && (!this.eptError && this.lptError)) {
      const startTime = '01/01/0001 ' + this.eptValue + (this.eptAM ? ' AM' : ' PM');
      const endTime = '01/01/0001 ' + this.lptValue + (this.lptAM ? ' AM' : ' PM');

      let hourDiff = (((Date.parse(endTime) - Date.parse(startTime)) / 1000) / 60) / 60;
      if ((hourDiff < 4) && (hourDiff > 0)) {
        this.isFormSubmittedTruckloadApiError = 'error';
        this.timeError = true
        window.scrollTo(0, 0);
        this.tenderResponse = {
          message: 'A 4 hour minimum window is required between the earliest pickup time and latest pickup time'
        }
        return;
      }
      if ((hourDiff < 0)) {
        this.isFormSubmittedTruckloadApiError = 'error';
        this.timeError = true
        window.scrollTo(0, 0);
        this.tenderResponse = {
          message: 'Latest pickup may not occur before earliest pickup time'
        }
        return;
      }
      if ((hourDiff === 0)) {
        this.isFormSubmittedTruckloadApiError = 'error';
        this.timeError = true
        window.scrollTo(0, 0);
        this.tenderResponse = {
          message: 'Latest pickup time may not be the same time as the earliest pickup time'
        }
        return;
      }
    }

    // Thien added to validate when ept has an error but does not revalidate since it does not meet the primary conditionals
    if ((instanceId === 'ept' || instanceId === 'lpt') && (this.eptError && !this.lptError)) {
      const startTime = '01/01/0001 ' + this.eptValue + (this.eptAM ? ' AM' : ' PM');
      const endTime = '01/01/0001 ' + this.lptValue + (this.lptAM ? ' AM' : ' PM');

      let hourDiff = (((Date.parse(endTime) - Date.parse(startTime)) / 1000) / 60) / 60;
      if ((hourDiff < 4) && (hourDiff > 0)) {
        this.isFormSubmittedTruckloadApiError = 'error';
        this.timeError = true
        window.scrollTo(0, 0);
        this.tenderResponse = {
          message: 'A 4 hour minimum window is required between the earliest pickup time and latest pickup time'
        }
        return;
      }
      if ((hourDiff < 0)) {
        this.isFormSubmittedTruckloadApiError = 'error';
        this.timeError = true
        window.scrollTo(0, 0);
        this.tenderResponse = {
          message: 'Latest pickup may not occur before earliest pickup time'
        }
        return;
      }
      if ((hourDiff === 0)) {
        this.isFormSubmittedTruckloadApiError = 'error';
        this.timeError = true
        window.scrollTo(0, 0);
        this.tenderResponse = {
          message: 'Latest pickup time may not be the same time as the earliest pickup time'
        }
        return;
      }
    }

    if ((instanceId === 'edt' || instanceId === 'ldt') && (!this.edtError && !this.ldtError)) {
      //logic for comparing time format
      const startTime = '01/01/0001 ' + this.edtValue + (this.edtAM ? ' AM' : ' PM');
      const endTime = '01/01/0001 ' + this.ldtValue + (this.ldtAM ? ' AM' : ' PM');

      let hourDiff = (((Date.parse(endTime) - Date.parse(startTime)) / 1000) / 60) / 60;
      if (Date.parse(startTime) > Date.parse(endTime)) {
        this.isFormSubmittedTruckloadApiError = 'error';
        this.timeError = true
        window.scrollTo(0, 0);
        this.tenderResponse = {
          message: 'Latest delivery may not occur before earliest delivery time'
        }
        return;
      }
      if (Date.parse(startTime) === Date.parse(endTime)) {
        this.isFormSubmittedTruckloadApiError = 'error';
        this.timeError = true
        window.scrollTo(0, 0);
        this.tenderResponse = {
          message: 'Latest delivery time may not be the same time as the earliest delivery time'
        }
        return;
      }
      if ((hourDiff < 4)) {
        this.isFormSubmittedTruckloadApiError = 'error';
        this.timeError = true
        window.scrollTo(0, 0);
        this.tenderResponse = {
          message: 'A 4 hour minimum window is required between the earliest delivery time and the latest delivery time'
        }
        return;
      }
    }

    // Thien added to validate when ldt has an error but does not revalidate since it does not meet the primary conditionals
    if ((instanceId === 'edt' || instanceId === 'ldt') && (!this.edtError && this.ldtError)) {
      const startTime = '01/01/0001 ' + this.edtValue + (this.edtAM ? ' AM' : ' PM');
      const endTime = '01/01/0001 ' + this.ldtValue + (this.ldtAM ? ' AM' : ' PM');

      let hourDiff = (((Date.parse(endTime) - Date.parse(startTime)) / 1000) / 60) / 60;
      if ((hourDiff < 4) && (hourDiff > 0)) {
        this.isFormSubmittedTruckloadApiError = 'error';
        this.timeError = true
        window.scrollTo(0, 0);
        this.tenderResponse = {
          message: 'A 4 hour minimum window is required between the earliest delivery time and the latest delivery time'
        }
        return;
      }
      if ((hourDiff < 0)) {
        this.isFormSubmittedTruckloadApiError = 'error';
        this.timeError = true
        window.scrollTo(0, 0);
        this.tenderResponse = {
          message: 'Latest delivery may not occur before earliest delivery time'
        }
        return;
      }
      if ((hourDiff === 0)) {
        this.isFormSubmittedTruckloadApiError = 'error';
        this.timeError = true
        window.scrollTo(0, 0);
        this.tenderResponse = {
          message: 'Latest delivery time may not be the same time as the earliest delivery time'
        }
        return;
      }
    }

    // Thien added to validate when edt has an error but does not revalidate since it does not meet the primary conditionals
    if ((instanceId === 'edt' || instanceId === 'ldt') && (this.edtError && !this.ldtError)) {
      const startTime = '01/01/0001 ' + this.edtValue + (this.edtAM ? ' AM' : ' PM');
      const endTime = '01/01/0001 ' + this.ldtValue + (this.ldtAM ? ' AM' : ' PM');

      let hourDiff = (((Date.parse(endTime) - Date.parse(startTime)) / 1000) / 60) / 60;
      if ((hourDiff < 4) && (hourDiff > 0)) {
        this.isFormSubmittedTruckloadApiError = 'error';
        this.timeError = true
        window.scrollTo(0, 0);
        this.tenderResponse = {
          message: 'A 4 hour minimum window is required between the earliest delivery time and the latest delivery time'
        }
        return;
      }
      if ((hourDiff < 0)) {
        this.isFormSubmittedTruckloadApiError = 'error';
        this.timeError = true
        window.scrollTo(0, 0);
        this.tenderResponse = {
          message: 'Latest delivery may not occur before earliest delivery time'
        }
        return;
      }
      if ((hourDiff === 0)) {
        this.isFormSubmittedTruckloadApiError = 'error';
        this.timeError = true
        window.scrollTo(0, 0);
        this.tenderResponse = {
          message: 'Latest delivery time may not be the same time as the earliest delivery time'
        }
        return;
      }
    }


  }



  submitTenderRequest() {
    const formValues = this.tlTenderQuote.value;
    this.cdr.detectChanges();
    this.isFormSubmittedTruckloadApiError = '';
    let datePipe = new DatePipe('en-US');
    let pickupDate = datePipe.transform(formValues.pickupDate, 'MM/dd/yyyy');
    this.tenderFormStatusError = false

    // Check time errors again independently. Primarily for when an error occurs and resubmission is attempted without fixing errors.
    if (!this.timeError) {
      this.onTimeChange('edt')
    }
    if (!this.timeError) {
      this.onTimeChange('ept')
    }

    let deliveryDate = datePipe.transform(formValues.deliveryDate, 'MM/dd/yyyy');
    const formArray = this.tlTenderQuote.get('freightDetails') as FormArray;
    if (this.hasEmptyFieldsInFormArray(formArray)) {
      // Handle the case where there are empty fields in the form array

      const freightDescriptionArray = this.tlTenderQuote.get('freightDetails') as FormArray;

      for (let i = 0; i < freightDescriptionArray.length; i++) {
        const freightDescriptionControl = freightDescriptionArray.at(i).get('freightDescription');
        const packageTypeControl = freightDescriptionArray.at(i).get('packageType');
        const weightControl = freightDescriptionArray.at(i).get('weight');
        const piecesControl = freightDescriptionArray.at(i).get('pieces');
        // const dimensionsControl = freightDescriptionArray.at(i).get('dimensions');

        freightDescriptionControl?.markAsUntouched({ onlySelf: true })
        packageTypeControl?.markAsUntouched({ onlySelf: true })
        weightControl?.markAsUntouched({ onlySelf: true })
        piecesControl?.markAsUntouched({ onlySelf: true })
        // dimensionsControl?.markAsUntouched({ onlySelf: true })

        freightDescriptionControl?.setValidators([Validators.required]);
        freightDescriptionControl?.updateValueAndValidity();

        packageTypeControl?.setValidators([Validators.required]);
        packageTypeControl?.updateValueAndValidity();

        weightControl?.setValidators([Validators.required]);
        weightControl?.updateValueAndValidity();

        piecesControl?.setValidators([Validators.required]);
        piecesControl?.updateValueAndValidity();

        // dimensionsControl?.setValidators([Validators.required, this.inputValidator]);
        // dimensionsControl?.updateValueAndValidity();
      }
    }

    if (this.totalWeight >= 44000) return;
    if (this.tlTenderQuote.invalid || this.hasEmptyFields() || this.eptError || this.lptError || this.edtError || this.ldtError || this.timeError) {
      // Check for time error to throw appropriate message
      // Prevents user from submitting if a timeErrorMessage has not been cleared
      if (this.timeError || (this.timeError && this.timeErrorMessage)) {
        this.timeErrorMessage = true
        window.scrollTo(0, 0);
        return
      }
      this.timeErrorMessage = false;
      this.tenderFormStatusError = true;
      window.scrollTo(0, 0);
      this.tlTenderQuote.controls['pickupCompanyName'].updateValueAndValidity();
      this.tlTenderQuote.controls['pickupAddressline1'].updateValueAndValidity();
      this.tlTenderQuote.controls['pickupAddressline2'].updateValueAndValidity();
      this.tlTenderQuote.controls['pickupContactName'].updateValueAndValidity();
      this.tlTenderQuote.controls['pickupPhone'].updateValueAndValidity();
      this.tlTenderQuote.controls['pickupEmail'].updateValueAndValidity();
      this.tlTenderQuote.controls['deliveryCompanyName'].updateValueAndValidity();
      this.tlTenderQuote.controls['deliveryAddress1'].updateValueAndValidity();
      this.tlTenderQuote.controls['deliveryAddress2'].updateValueAndValidity();
      this.tlTenderQuote.controls['deliveryContact'].updateValueAndValidity();
      this.tlTenderQuote.controls['deliveryPhone'].updateValueAndValidity();
      this.tlTenderQuote.controls['deliveryEmail'].updateValueAndValidity();
      this.tlTenderQuote.controls['specialInstruction'].updateValueAndValidity();
      this.tlTenderQuote.controls['billingReference'].updateValueAndValidity();
      this.tlTenderQuote.controls['shipperReference'].updateValueAndValidity();
      this.tlTenderQuote.controls['consigneeReference'].updateValueAndValidity();

      return;

    } else {
      this.tenderFormStatusError = false;
      this.timeErrorMessage = false;
      window.scrollTo(0, 0);
    }

    if (this.tlTenderQuote.status != 'VALID') return;
    let references = [];
    if (formValues.billingReference) {
      references.push({
        type: 'Billing Ref Num',
        refNum: formValues.billingReference,
        isPrimary: false
      })
    }
    if (formValues.shipperReference) {
      references.push({
        type: 'Shipper Ref Num',
        refNum: formValues.shipperReference,
        isPrimary: false
      })
    }
    if (formValues.consigneeReference) {
      references.push({
        type: 'Consignee Ref Num',
        refNum: formValues.consigneeReference,
        isPrimary: false
      })
    }
    references.push({
      type: 'Saia Customer ACCT Number',
      refNum: this.liveRateQuoteData.saiaAccountNumber,
      isPrimary: false
    });
    references.push({
      type: 'Quote Number',
      refNum: this.liveRateQuoteData.quote.id,
      isPrimary: false
    });
    const items: any = [];
    formValues.freightDetails.forEach((item: any, index: number) => {
      let dimension = item.dimensions.toLowerCase().split('x');
      let [length, width, height] = dimension
      items.push({
        sequence: index + 1,
        freightClass: 0,
        name: item.freightDescription,
        weight: item.weight?.replace(/,/g, ''),
        weightUnits: "lb",
        quantityType: this.mgPackageTypes[item.packageType] || 'PLT',
        quantity: item.pieces,
        isHazmat: false,
        isStackable: false,
        dimension: {
          length: length ? length.trim() : '',
          width: width ? width.trim() : '',
          height: height ? height.trim() : '',
          units: "in"
        }
      });
    });

    let earliestPickup = datePipe.transform(`${pickupDate} ${this.tlTenderQuote.controls['earliestPickupSI']?.value}`, 'MM/dd/yyyy HH:mm');
    let latestPickup = datePipe.transform(`${pickupDate} ${this.tlTenderQuote.controls['latestPickupSI']?.value}`, 'MM/dd/yyyy HH:mm');
    let earliestDelivery = datePipe.transform(`${deliveryDate} ${this.tlTenderQuote.controls['earliestDeliveryCI']?.value}`, 'MM/dd/yyyy HH:mm');
    let latestDelivery = datePipe.transform(`${deliveryDate} ${this.tlTenderQuote.controls['latestDeliveryCI']?.value}`, 'MM/dd/yyyy HH:mm');
    this.loader = true;
    let payload = {
      companyAccountNumber: this.liveRateQuoteData.requestData.companyAccountNumber,
      source: this.liveRateQuoteData.requestData.source,
      mode: "Truckload",
      quoteID: this.liveRateQuoteData.dbDocumentId,
      saiaAccountNumber: this.liveRateQuoteData.saiaAccountNumber,
      references: references,
      payment: {
        method: "Third Party",
        billTo: {
          address: {
            customerAcctNum: "LXCO001",
            locationCode: this.billTo.locationCode,
            name: this.billTo.name,
            addrLine1: this.billTo.address1,
            addrLine2: this.billTo.address2,
            city: this.billTo.city,
            stateProvince: this.billTo.state,
            postalCode: this.billTo.zip,
            countryCode: "US"
          },
          contacts: [
            {
              name: this.liveRateQuoteData.requestData.yourName,
              type: "Billing Contact",
              contactMethods: [
                {
                  sequenceNum: 1,
                  type: "email",
                  text: this.liveRateQuoteData.requestData.yourEmail
                },
                {
                  sequenceNum: 2,
                  type: "phone",
                  text: this.liveRateQuoteData.requestData.yourPhone
                }
              ]
            }
          ]
        }
      },
      pickup: {
        earliest: earliestPickup,
        latest: latestPickup,
        location: {
          name: formValues.pickupCompanyName,
          address1: formValues.pickupAddressline1,
          address2: formValues.pickupAddressline2,
          city: formValues.pickupShipperCity,
          state: formValues.pickupShipperState,
          zip: formValues.pickupShipperZip,
          country: "US"
        },
        contacts: [
          {
            name: formValues.pickupContactName,
            contactMethods: [
              {
                "sequenceNum": 1,
                "type": "phone",
                "text": formValues.pickupPhone
              },
              {
                "sequenceNum": 2,
                "type": "email",
                "text": formValues.pickupEmail
              }
            ]
          }
        ],
        comment: formValues.specialInstruction
      },
      dropoff: {
        earliest: earliestDelivery,
        latest: latestDelivery,
        location: {
          name: formValues.deliveryCompanyName,
          address1: formValues.deliveryAddress1,
          address2: formValues.deliveryAddress2,
          city: formValues.deliveryCity,
          state: formValues.deliveryState,
          zip: formValues.deliveryZip,
          country: "US"
        },
        contacts: [
          {
            name: formValues.deliveryContact,
            contactMethods: [
              {
                sequenceNum: 1,
                type: "phone",
                text: formValues.deliveryPhone
              },
              {
                sequenceNum: 2,
                type: "email",
                text: formValues.deliveryEmail
              }
            ]
          }
        ],
        comment: formValues.specialInstruction
      },
      items: items,
      quoteDbItem: this.liveRateQuoteData.quoteDbItem,
      billTo: this.billTo
    }
    this.service.submitTenderRequest(payload).subscribe((result: any) => {
      this.loader = false;
      if (result.error || result?.resp?.statusCode === 'Fail') {
        this.isFormSubmittedTruckloadApiError = 'error';
        this.addGA4Event({ 'event': 'submit-billoflading-error', errorMessage: JSON.stringify(result) })
        this.tenderResponse = {
          message: 'Error in submitting your request, Please Call LinkEx 972-481-9900.'
        }
      } else if (result.resp.statusCode === 'SUCCESS') {
        this.isFormSubmittedTruckloadApiError = 'success'
        this.addGA4Event({ 'event': 'submit-billoflading-success' })
        let bolData = {
          liveRateQuoteData: this.liveRateQuoteData,
          bolInfo: result,
          tenderPayload: payload,
          tenderFormValue: formValues
        };
        this.router.navigate(['/bol'], {
          state: {
            bolData: JSON.stringify(bolData),
          },
        });
      }
    }, (error: any) => {
      this.loader = false;
      this.addGA4Event({ 'event': 'submit-billoflading-error', errorMessage: JSON.stringify(error) })
      this.isFormSubmittedTruckloadApiError = 'error'
      this.tenderResponse = {
        message: 'Error in submitting your request, Please Call LinkEx 972-481-9900.'
      }
    })
  }
  addGA4Event(event: any): void {
    (window as { [key: string]: any })['dataLayer'].push(event);
  }
  hasEmptyFields() {
    for (const controlName in this.tlTenderQuote.controls) {
      if (this.tlTenderQuote.controls.hasOwnProperty(controlName)) {
        const control = this.tlTenderQuote.get(controlName);
        if (control && control.value === '' && this.tlTenderQuote.get(controlName)?.hasValidator(Validators.required)) {
          return true; // Return true if any control has an empty value
        }
      }
    }
    return false; // Return false if no control has an empty value
  }

  async skipWeekendHoliday(date: string): Promise<string> {
    return new Promise((resolve, reject) => {
      this.service.skipWeekendHoliday({ date: date }).subscribe((result: any) => {
        if (result.nextDate) {
          resolve(result.nextDate)
        } else {
          resolve(date)
        }
      }, (error) => {
        resolve(date)
      })
    })
  }

  validateTime(time: string): boolean {
    const pattern = /^(0?[1-9]|1[0-2]):[0-5][0-9]$/;
    return !pattern.test(time);
  }

  resetForm(): void {
    window.scrollTo(0, 0);
    this.totalItem = 0;
    this.isFormSubmittedTruckloadApiError = '';
    this.weightArray = [this.originalTotalWeight];
    this.totalWeight = this.weightArray[0];
    this.tenderFormStatusError = false;
    this.timeError = false;
    this.timeErrorMessage = false;
    // Needing to explicitly set booleans
    this.timeComponentRef.forEach(element => {
      if (element.instance == 'ept') {
        this.eptAM = true
        element.reset('08:00', true);
      } else if (element.instance == 'lpt') {
        this.lptAM = false
        element.reset('05:00', false);
      } else if (element.instance == 'edt') {
        // Having to force reset this. Otherwise, it is setting the boolean value to false. Bhushan check.
        this.edtAM = true
        element.reset('08:00', true);
      } else if (element.instance == 'ldt') {
        this.ldtAM = false
        element.reset('05:00', false);
      }
    });

    this.tlTenderQuote.controls['pickupCompanyName'].setValue('');
    this.tlTenderQuote.controls['pickupCompanyName'].setErrors(null);

    this.tlTenderQuote.get('pickupAddressline1')?.setValue('');
    this.tlTenderQuote.get('pickupAddressline1')?.setErrors(null);

    this.tlTenderQuote.get('pickupAddressline2')?.setValue('');
    this.tlTenderQuote.get('pickupAddressline2')?.setErrors(null);

    this.tlTenderQuote.get('pickupContactName')?.setValue('');
    this.tlTenderQuote.get('pickupContactName')?.setErrors(null);

    this.tlTenderQuote.get('pickupPhone')?.setValue('');
    this.tlTenderQuote.get('pickupPhone')?.setErrors(null);

    this.tlTenderQuote.get('pickupEmail')?.setValue('');
    this.tlTenderQuote.get('pickupEmail')?.setErrors(null);

    this.tlTenderQuote.get('deliveryCompanyName')?.setValue('');
    this.tlTenderQuote.get('deliveryCompanyName')?.setErrors(null);

    this.tlTenderQuote.get('deliveryAddress1')?.setValue('');
    this.tlTenderQuote.get('deliveryAddress1')?.setErrors(null);

    this.tlTenderQuote.get('deliveryAddress2')?.setValue('');
    this.tlTenderQuote.get('deliveryAddress2')?.setErrors(null);

    this.tlTenderQuote.get('deliveryContact')?.setValue('');
    this.tlTenderQuote.get('deliveryContact')?.setErrors(null);

    this.tlTenderQuote.get('deliveryPhone')?.setValue('');
    this.tlTenderQuote.get('deliveryPhone')?.setErrors(null);

    this.tlTenderQuote.get('deliveryEmail')?.setValue('');
    this.tlTenderQuote.get('deliveryEmail')?.setErrors(null);

    this.tlTenderQuote.get('specialInstruction')?.setValue('');
    this.tlTenderQuote.get('specialInstruction')?.setErrors(null);

    this.tlTenderQuote.get('earliestPickupSI')?.setValue('08:00');
    this.tlTenderQuote.get('earliestPickupSI')?.setErrors(null);

    this.tlTenderQuote.get('latestPickupSI')?.setValue('05:00');
    this.tlTenderQuote.get('latestPickupSI')?.setErrors(null);

    this.tlTenderQuote.get('earliestDeliveryCI')?.setValue('08:00');
    this.tlTenderQuote.get('earliestDeliveryCI')?.setErrors(null);

    this.tlTenderQuote.get('latestDeliveryCI')?.setValue('05:00');
    this.tlTenderQuote.get('latestDeliveryCI')?.setErrors(null);

    const freightDetailsArray = this.tlTenderQuote.get('freightDetails') as FormArray;

    for (let i = 0; i < freightDetailsArray.length; i++) {
      const freightDescriptionControl = freightDetailsArray.at(i).get('freightDescription');
      const packageTypeControl = freightDetailsArray.at(i).get('packageType');
      const weightControl = freightDetailsArray.at(i).get('weight');
      const piecesControl = freightDetailsArray.at(i).get('pieces');
      const dimensionsControl = freightDetailsArray.at(i).get('dimensions');

      freightDescriptionControl?.clearValidators();
      packageTypeControl?.clearValidators();
      weightControl?.clearValidators();
      piecesControl?.clearValidators();
      // dimensionsControl?.clearValidators();

      freightDescriptionControl?.markAsUntouched({ onlySelf: true })
      packageTypeControl?.markAsUntouched({ onlySelf: true })
      weightControl?.markAsUntouched({ onlySelf: true })
      piecesControl?.markAsUntouched({ onlySelf: true })
      // dimensionsControl?.markAsUntouched({ onlySelf: true })

      weightControl?.setValidators([Validators.max(48000), this.weightLimitValidator.bind(this)])
      dimensionsControl?.setValidators([this.inputValidator])

    }

    this.tlTenderQuote.get('billingReference')?.setValue('');
    this.tlTenderQuote.get('billingReference')?.setErrors(null);

    this.tlTenderQuote.get('shipperReference')?.setValue('');
    this.tlTenderQuote.get('shipperReference')?.setErrors(null);

    this.tlTenderQuote.get('consigneeReference')?.setValue('');
    this.tlTenderQuote.get('consigneeReference')?.setErrors(null);

    const formArray = [{
      freightDescription: '',
      packageType: '',
      weight: this.liveRateQuoteData.requestData.items[0].weight,
      pieces: '',
      dimensions: '',
      hazmat: this.liveRateQuoteData.requestData.items[0].isHazmat ? 'YES' : 'NO'
    }];
    this.itemArray[0] = 0;
    for (let i = 1; i < this.itemArray.length; i++) {
      this.itemArray[i] = 0;
      this.weightArray[i] = 0;
      formArray.push({
        freightDescription: '',
        packageType: '',
        weight: '',
        dimensions: '',
        pieces: '',
        hazmat: ''
      })
    }
    this.tlTenderQuote.controls['freightDetails'].setValue(formArray);

  }

  hasEmptyFieldsInFormArray(formArray: FormArray): boolean {
    for (let i = 0; i < formArray.length; i++) {
      const group = formArray.at(i) as FormGroup;

      // Check if any control value in the form group is empty
      if (Object.values(group.value).some(value => value === '')) {
        return true; // Return true if any control in the form group has an empty value
      }
    }

    return false; // Return false if no control in any form group has an empty value
  }

  inputValidator(control: AbstractControl) {
    // Check if the input is empty or null, in which case it's considered valid
    if (!control.value) {
      return null;
    }
    // const dimensionsPattern = /^(\d+(\.\d+)?\s?x\s?\d+(\.\d+)?\s?x\s?\d+(\.\d+)?)?$/;
    const dimensionsPattern = /^(\d+(\.\d+)?\s?[xX]\s?)\d+(\.\d+)?\s?[xX]\s?\d+(\.\d+)?$/;

    if (dimensionsPattern.test(control.value)) {
      return null; // Input is valid
    } else {
      return { invalidDimensions: true }; // Input is invalid
    }
    // Split the input by 'x' and remove spaces to get individual dimensions
    // const dimensionsArray = control.value.split('x').map((dimension: string) => dimension.trim());
    // // Check if there are exactly three dimensions (L x W x H)
    // if (dimensionsArray.length === 3) {
    //   // Check if all three dimensions are valid numbers or valid decimals
    //   const isValid = dimensionsArray.every((dimension: string) => {
    //     const parsedDimension = parseFloat(dimension);
    //     return !isNaN(parsedDimension) && parsedDimension >= 0; // Check if it's a non-negative number
    //   });
    //   return isValid ? null : { invalidDimensions: true };
    // }
    // return { invalidDimensions: true };
  }



  weightLimitValidator(control: AbstractControl): { [key: string]: any } | null {
    const maxWeight = 44000;
    const enteredValue = control.value ? parseInt(control.value.replace(/,/g, '')) : null;
    if (enteredValue !== null && (isNaN(enteredValue) || enteredValue > maxWeight)) {
      return { maxWeight: true };
    }
    return null;
  }

  addWeight(event: any, index: any): void {
    if (index === 0) {
      this.weightArray[0] = Number(event.target.value.replace(/,/g, ''));
      this.totalWeight = this.weightArray.reduce((accu, i) => accu + i);
    } else {
      this.weightArray[index] = Number(event.target.value.replace(/,/g, ''));
      this.totalWeight = this.weightArray.reduce((accu, i) => accu + i);
    }
  }

  addPieces(event: any, index: any): void {
    this.itemArray[index] = Number(event.target.value.replace(/,/g, ''));
    this.totalItem = this.itemArray.reduce((accu, i) => accu + i);
  }

  alphaOnly(event: any) {
    const input = event.target as HTMLInputElement;
    const inputValue = input.value;
    input.value = inputValue.replace(/[^0-9a-zA-Z ]/g, '');
  }

  numberOnly(event: any) {
    const input = event.target as HTMLInputElement;
    const inputValue = input.value;
    input.value = inputValue.replace(/[^0-9 ]/g, '');
  }

  commaNumberOnly(event: any) {
    const input = event.target as HTMLInputElement;
    let inputValue = input.value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
    if (inputValue !== '') {
      const parsedValue = parseInt(inputValue);
      if (!isNaN(parsedValue)) {
        inputValue = parsedValue.toLocaleString(); // Format with commas
      }
    }
    input.value = inputValue;
  }

  packagetype: packageType[] = [
    { value: 'Boxes', viewValue: 'Boxes' },
    { value: 'Bundle', viewValue: 'Bundle' },
    { value: 'Carton', viewValue: 'Carton' },
    { value: 'Cases', viewValue: 'Cases' },
    { value: 'Crates', viewValue: 'Crates' },
    { value: 'Drums', viewValue: 'Drums' },
    { value: 'Packages', viewValue: 'Packages' },
    { value: 'Pallets', viewValue: 'Pallets' },
    { value: 'Reels', viewValue: 'Reels' },
    { value: 'Skids', viewValue: 'Skids' },
    { value: 'Tote Bins', viewValue: 'Tote Bins' },
  ]

  mgPackageTypes: any = {
    Bundle: 'BDL',
    Boxes: 'BOX',
    Cases: 'CAS',
    Crates: 'CRT',
    Carton: 'CTN',
    Drums: 'DRM',
    Pieces: 'PCS',
    Pallets: 'PLT',
    'Tote Bins': 'TOT'
    // TOT = Totes
  }

  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges) {
    // create header using child_id 
  }

  characterCount: { [key: string]: number } = {
    pickupCompanyName: 0,
    pickupAddressline1: 0,
    pickupAddressline2: 0,
    pickupContactName: 0,
    pickupPhone: 0,
    pickupEmail: 0,
    deliveryCompanyName: 0,
    deliveryAddress1: 0,
    deliveryAddress2: 0,
    deliveryContact: 0,
    deliveryPhone: 0,
    deliveryEmail: 0,
    specialInstruction: 0,
    billingReference: 0,

  };

  updateCharacterCount(event: Event, controlName: string): void {
    const value = (event.target as HTMLInputElement)?.value || '';
    this.characterCount[controlName] = value.length;
  }

  updateCharacterCountDescription(event: Event, controlIndex: number): void {
    const value = (event.target as HTMLInputElement)?.value || '';
    this.characterCount[controlIndex] = value.length;
  }

}
