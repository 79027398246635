<!-- Banner -->
<div class="banner video" *ngIf="pageHeader" [heroImage]="pageHeader.heroImageURL">
    <h1 class="banner-text">{{pageHeader.heroImageTitle}}</h1>
</div>

<!-- Breadcrumb Wrapper -->
<div class="breadcrumb-wrapper" *ngIf="pageHeader">
    <div class="row">
        <div class="col-md-10 col-md-offset-1">
            <ol>
                <li routerLink="/home">
                    <span class="material-icons">home</span>
                </li>
                <ng-container *ngFor="let breadcrumb of getBreadcrumbs(); let last = last">
                    <li [ngClass]="{'child': !last, 'active': last}">
                        {{ breadcrumb }}
                    </li>
                </ng-container>
            </ol>
        </div>
    </div>
</div>

<!-- Section -->
<div class="content-section">
    <div class="row">
        <div class="col-md-10 col-md-offset-1 " *ngIf="pageContent">
            <h4 class="header-text text-blue py-2">
                {{pageContent.header}}
            </h4>
            <div class="content-text cust-flex">
                <div class="row">
                    <div class="col-md-6 col-xs-12 cust-height" [innerHTML]="leftSanitizedDescription">
                        <!-- <iframe frameborder="0" allowfullscreen width="100%"
                            src="https://player.vimeo.com/video/827755569"></iframe> -->
                    </div>
                    <div class="col-md-6 col-xs-12 cust-height" [innerHTML]="rightSanitizedDescription">
                        <!-- <iframe frameborder="0" allowfullscreen width="100%"
                            src="https://player.vimeo.com/video/808039595"></iframe> -->
                    </div>
                </div>
            </div>


        </div>
    </div>
</div>