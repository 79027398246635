import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { PageContentModel } from 'src/app/core/model/page-content.model';
import { PageHeaderContentModel } from 'src/app/core/model/page-header.model';
import { ContentfulService } from 'src/app/core/service/contentful.service';


@Component({
  selector: 'app-customs-clearance',
  templateUrl: './customs-clearance.component.html',
  styleUrls: ['./customs-clearance.component.scss']
})
export class CustomsClearanceComponent implements OnInit {
  pageContent!: PageContentModel;
  pageHeader!: PageHeaderContentModel;
  constructor(private contentfulService: ContentfulService, private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.loadBodyEntries();
    this.loadHeaderEntries();
  }

  loadHeaderEntries() {
    this.contentfulService.getEntry('pageHeader', 'Customs Clearance Header').subscribe(
      (entry) => {
        this.pageHeader = new PageHeaderContentModel(entry);
      }, error => {
        console.error('Error fetching entries:', error);
      });
  }

  loadBodyEntries() {
    this.contentfulService.getEntry('pageInsights', 'Customs Clearance Content').subscribe(
      (entry) => {
        this.pageContent = new PageContentModel(entry);
        this.pageContent.description = this.pageContent.description.replace(/<a\s+(?:[^>]*?\s+)?href="([^"]*)"([^>]*)>/g, '<a href="$1" target="_blank"$2>');
        this.pageContent.description = this.pageContent.description.replace(/&lt;/g, '<');
        this.pageContent.description = this.pageContent.description.replace(/&gt;/g, '>');
      }, error => {
        console.error('Error fetching entries:', error);
      });
  }

  getBreadcrumbs(): string[] {
    return this.pageHeader.breadCrumbs.split(',').map(breadcrumb => breadcrumb.trim());
  }
  get sanitizedDescription(): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(this.pageContent.description);
  }

}
