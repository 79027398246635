import { Component, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { AboutusComponent } from './company/aboutus/aboutus.component';
import { TruckloadBrokerageComponent } from './services/truckload-brokerage/truckload-brokerage.component';
import { ManagementteamComponent } from './company/managementteam/managementteam.component';
import { CorevalueComponent } from './company/corevalue/corevalue.component';
import { TestimonialsComponent } from './company/testimonials/testimonials.component';
import { CredentialsComponent } from './company/credentials/credentials.component';
import { ServiceOfferingComponent } from './services/service-offering/service-offering.component';
import { InternationalComponent } from './services/international/international.component';
import { FinalMileComponent } from './services/final-mile/final-mile.component';
import { TrackComponent } from './trackship/track/track.component';
import { DetailsComponent } from './trackship/details/details.component';
import { ContractLogisticsComponent } from './services/contract-logistics/contract-logistics.component';
import { ExpeditedComponent } from './services/expedited/expedited.component';
import { WarehousingComponent } from "./services/warehousing/warehousing.component";
import { DocumentsComponent } from './resources/documents/documents.component';
import { TmsComponent } from './resources/tms/tms.component';
import { PoolDistributionComponent } from './services/pool-distribution/pool-distribution.component';
import { TransloadingComponent } from './services/transloading/transloading.component';
import { VideosComponent } from './news/videos/videos.component';
import { ResourcesComponent } from './resources/resources/resources.component';
import { CareersComponent } from './careers/careers.component';
import { BlogComponent } from './news/blog/blog.component';
import { BlogPostComponent } from './news/blog/blog-post/blog-post.component';
import { ContactComponent } from './contact/contact.component';
import { QuoteComponent } from './quote/quote.component';
import { config } from 'rxjs';
import { finalmileQuestionnaireComponent } from './services/finalmile-questionnaire/finalmile-questionnaire.component';
import { HolidayScheduleComponent } from './resources/holiday-schedule/holiday-schedule.component';
import { SitemapComponent } from './sitemap/sitemap.component';
import { LiveQuoteComponent } from './quote/live-quote/live-quote.component';
import { TlTenderQuoteComponent } from './quote/live-quote/tl-tender-quote/tl-tender-quote.component';
import { BillOfLadingComponent } from './bill-of-lading/bill-of-lading.component';
import { ServicesComponent } from './resources/service-updates/services.component';
import { CustomsClearanceComponent } from './services/customs-clearance/customs-clearance.component';


const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: '/home' },
  { path: 'home', component: HomeComponent },
  { path: 'company', component: AboutusComponent },
  { path: 'truckload-brokerage', component: TruckloadBrokerageComponent },
  { path: 'management-team', component: ManagementteamComponent },
  { path: 'core-values', component: CorevalueComponent },
  { path: 'linkex-testimonials', component: TestimonialsComponent },
  { path: 'credentials-affiliations', component: CredentialsComponent },
  { path: 'service-offering', component: ServiceOfferingComponent },
  { path: 'international-shipping', component: InternationalComponent },
  { path: 'final-mile', component: FinalMileComponent },
  { path: 'track', component: TrackComponent },
  { path: 'details', component: DetailsComponent },
  { path: 'contract-logistics', component: ContractLogisticsComponent },
  { path: 'expedited-delivery', component: ExpeditedComponent },
  { path: 'warehousing', component: WarehousingComponent },
  { path: 'documents', component: DocumentsComponent },
  { path: 'linkex-tms', component: TmsComponent },
  { path: 'pool-distribution', component: PoolDistributionComponent },
  { path: 'drayage-transloading', component: TransloadingComponent },
  { path: 'videos', component: VideosComponent },
  { path: 'resources', component: ResourcesComponent },
  { path: 'careers', component: CareersComponent },
  { path: 'news', component: BlogComponent },
  { path: 'news-post', component: BlogPostComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'quote', component: QuoteComponent },
  { path: 'finalmile-questionnaire', component: finalmileQuestionnaireComponent },
  { path: 'holiday-schedule', component: HolidayScheduleComponent },
  { path: 'sitemap', component: SitemapComponent },
  { path: 'live-quote', component: LiveQuoteComponent },
  { path: 'tl-tender-quote', component: TlTenderQuoteComponent },
  { path: 'bol', component: BillOfLadingComponent },
  { path: 'services', component: ServicesComponent },
  { path: 'customs-clearance', component: CustomsClearanceComponent }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
