import { Component, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { PageContentModel } from 'src/app/core/model/page-content.model';
import { PageHeaderContentModel } from 'src/app/core/model/page-header.model';
import { ContentfulService } from 'src/app/core/service/contentful.service';

@Component({
  selector: 'app-managementteam',
  templateUrl: './managementteam.component.html',
  styleUrls: ['./managementteam.component.scss']
})
export class ManagementteamComponent implements OnInit {

  managementAccordionFlags: boolean[]=[]

  entry: any;
  contentfulSysFields: any;
  pageContent!: PageContentModel;
  pageHeader!: PageHeaderContentModel; 
  
  toggleState() {
    if(this.managementAccordionFlags.indexOf(true)>-1) {
      console.log(this.managementAccordionFlags)
      for(let index=0; index < this.managementAccordionFlags.length;index++ ) {
        this.managementAccordionFlags[index] = false;
      }
    }
  }

  constructor(private contentfulService: ContentfulService, private sanitizer: DomSanitizer,) { 
    this.contentfulSysFields = this.contentfulService.getContentfulSysFields();
  }

  ngOnInit(): void {
    this.loadBodyEntries();
    this.loadHeaderEntries();
  }

  loadBodyEntries() {
    this.contentfulService.getEntry('pageInsights', 'Management Team Content').subscribe(
      (entry) => {
        
        this.pageContent = new PageContentModel(entry);
        if (this.pageContent && this.pageContent.accordion && this.pageContent.accordion.accordionItems) {
          this.pageContent.accordion.accordionItems.forEach((ele) => {
            this.managementAccordionFlags.push(false)
          });
        }

      }, error => {
        console.error('Error fetching entries:', error);
      });
  }

  loadHeaderEntries() {
    this.contentfulService.getEntry('pageHeader', 'Management Team Header').subscribe(
      (entry) => {
        this.pageHeader = new PageHeaderContentModel(entry);
        console.log(this.pageHeader);

      }, error => {
        console.error('Error fetching entries:', error);
      });
  }

  getBreadcrumbs(): string[] {
    return this.pageHeader.breadCrumbs.split(',').map(breadcrumb => breadcrumb.trim());
  }

  get sanitizedDescription(): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(this.pageContent.description);
  }

  get sanitizedAccordionItems(): SafeHtml[] {
    if (this.pageContent && this.pageContent.accordion && this.pageContent.accordion.accordionItems) {
      return this.pageContent.accordion.accordionItems.map(item => this.sanitizer.bypassSecurityTrustHtml(item.description));
    }
    return [];
  }


}
