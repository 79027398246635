import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { AppComponent } from 'src/app/app.component';

@Component({
  selector: 'app-blog-post',
  templateUrl: './blog-post.component.html',
  styleUrls: ['./blog-post.component.scss']
})
export class BlogPostComponent implements OnInit {

  data: any = [];
  id: any;
  filterData: any;

  constructor(private HttpClient: HttpClient, private router: Router, private activateRouter: ActivatedRoute) {
    this.getJsonData();
  }

  getJsonData() {
    this.HttpClient.get('assets/blog.json').subscribe((res) => {
      this.data = res;
    });
  }

  ngOnInit(): void {      
    this.activateRouter.queryParams.subscribe( params => { 
      if(params['Id'] == null || params['Id'] == undefined) {  
          const id = sessionStorage.getItem('newsId');
          this.id = id;
      }else {
        this.id = params['Id'];
        sessionStorage.setItem('newsId', this.id); 
      }
    }); 
  }
}
