import { EntryCollection } from 'contentful';

export class MenuContentModel {

    label!: string;
    link!: string;
    items: MenuContentModel[] = [];
    isAsset!: boolean;

    constructor(entries?: EntryCollection<any>) {
        if (entries && entries?.items?.length > 0 && entries?.items[0].fields) {
            const contentDetail: any = entries.items[0].fields;
            this.label = contentDetail.name;
            this.link = contentDetail.url;
            this.isAsset = contentDetail?.fields?.url?.startsWith('/assets') ? true: false;
            if(contentDetail?.submenu?.length > 0) {
                contentDetail.submenu.forEach((menu:any) => {
                    this.items.push(new MenuContentModel(menu));
                });
            }
        } else {
            const contentDetail: any = entries;
            this.label = contentDetail.fields.name;
            this.link = contentDetail.fields.url;
            this.isAsset = contentDetail?.fields?.url?.startsWith('/assets') ? true: false;
            if(contentDetail?.fields?.submenu) {
                contentDetail?.fields?.submenu.forEach((menu:any) => {
                    this.items.push(new MenuContentModel(menu));
                });
            }
        }
    }
}