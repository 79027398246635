import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { PageContentModel } from 'src/app/core/model/page-content.model';
import { PageHeaderContentModel } from 'src/app/core/model/page-header.model';
import { ContentfulService } from 'src/app/core/service/contentful.service';

@Component({
  selector: 'app-testimonials',
  templateUrl: './testimonials.component.html',
  styleUrls: ['./testimonials.component.scss']
})
export class TestimonialsComponent implements OnInit {
  
  entry: any;
  contentfulSysFields: any;
  pageContent!: PageContentModel;
  pageHeader!: PageHeaderContentModel;

  constructor(private contentfulService: ContentfulService, private sanitizer: DomSanitizer) { 
    this.contentfulSysFields = this.contentfulService.getContentfulSysFields();
  }

  ngOnInit(): void {
    this.loadBodyEntries();
    this.loadHeaderEntries();
  }

  loadBodyEntries() {
    this.contentfulService.getEntry('pageInsights', 'Testimonials Content').subscribe(
      (entry) => {
        this.pageContent = new PageContentModel(entry);
      }, error => {
        console.error('Error fetching entries:', error);
      });
  }

  loadHeaderEntries() {
    this.contentfulService.getEntry('pageHeader', 'Testimonials Header').subscribe(
      (entry) => {
        this.pageHeader = new PageHeaderContentModel(entry);
      }, error => {
        console.error('Error fetching entries:', error);
      });
  }

  getBreadcrumbs(): string[] {
    return this.pageHeader?.breadCrumbs?.split(',').map(breadcrumb => breadcrumb.trim());
  }
}
