import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { LinkexService } from '../linkex.service.service';
import { Subject } from 'rxjs';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-finalmile-questionnaire',
  templateUrl: './finalmile-questionnaire.component.html',
  styleUrls: ['./finalmile-questionnaire.component.scss']
})
export class finalmileQuestionnaireComponent implements OnInit {

  @ViewChild('ngFinalMile') ngFinalMile: any;
  finalmileStatusError: boolean = false;
  finalmileStatus: boolean = false;
  loader: boolean = false;
  isFormSubmittedFinalMile: boolean = false;
  isFormSubmittedFinalMileApiError: boolean = false;
  apiResponse: any = {};
  minDate = new Date();
  minDateToFinish = new Subject<string>();
  shipDate = new Date();

  today = new Date();
  constructor(private fb: FormBuilder, private service: LinkexService,) {
    this.minDateToFinish.subscribe(r => {
      this.shipDate = new Date(r);
    })
  }

  finalMile = this.fb.group({

    custName: ['', [Validators.required]],
    yourPhone: ['', [Validators.required]],
    custEmail: ['', [Validators.required, Validators.pattern('^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,4}$')]],
    saiaSalesPerson: [''],
    saiaSalesPersonEmail: [''],
    projectStartDate: [''],
    projectEndDate: [''],
    expectedStartDateRecurring: [''],
    opportunityType: ['', [Validators.required]],
    projectScope: ['', [Validators.required]],
    historicalData: ['', [Validators.required]],
    currentCustomer: [''],
    saiaAccount: [''],
    additionalRelevant: [''],

    typeServiceFb: this.fb.group({
      delivery: false,
      pickup: false,
    }, { validators: this.typeOfService }),

    typeSiteFb: this.fb.group({
      residential: false,
      commercial: false,
    }, { validators: this.typeOfSite }),

    trasportationFb: this.fb.group({
      saiaLTL: false,
      linkExSourcedTL: false,
      customerSourced: false,
    }),

    commodityDescription: ['', [Validators.required]],
    hazardousMaterial: ['', [Validators.required]],

    finalMileFb: this.fb.group({
      notificationPro: false,
      unattendedDelivery: false,
      appointmentScheduling: false,
      liftGateDelivery: false,
      insidePickupDelivery: false,
      curbsideDelivery: false,
      thresholdGarageDelivery: false,
      roomOfChoice: false,
      flightOfStairs: false,
      manDelivery: false,
      unpackagingAssembly: false,
      debrisRemoval: false,
      assembly: false,
      inRoomPlacement: false,
      hookup: false,
      haulAway: false,
      disposalReturns: false,
      repairs: false
    }),

  })


  dateChange(e: { value: { toString: () => string } | null }) {
    const datePipe = new DatePipe('en-US');
    const formattedDate = e.value ? datePipe.transform(e.value.toString()) : null;
    if (formattedDate !== null) {
      this.minDateToFinish.next(formattedDate);
    }
  }

  typeOfService(group: FormGroup) {
    const checkboxes = [
      'delivery',
      'pickup',
    ];
    const checkedBoxes = checkboxes.map(controlName => group.controls[controlName].value);
    const atLeastOneChecked = checkedBoxes.some(Boolean);
    return atLeastOneChecked ? null : { required: true };
  }

  typeOfSite(group: FormGroup) {
    const checkboxes = [
      'residential',
      'commercial',
    ];
    const checkedBoxes = checkboxes.map(controlName => group.controls[controlName].value);
    const atLeastOneChecked = checkedBoxes.some(Boolean);
    return atLeastOneChecked ? null : { required: true };
  }

  expectedProject = false;
  recurringProject = false;
  opportunity(event: any) {
    if (event.value == 'fixedTermProject') {
      this.expectedProject = true;
      this.finalMile.controls['projectStartDate'].setValidators(Validators.required);
      this.finalMile.controls['projectEndDate'].setValidators(Validators.required);

      this.recurringProject = false;
      this.finalMile.controls['expectedStartDateRecurring'].clearValidators();
      this.finalMile.controls['expectedStartDateRecurring'].updateValueAndValidity();
    } else {
      if (event.value == 'recurringBusiness') {
        this.recurringProject = true;
        this.finalMile.controls['expectedStartDateRecurring'].setValidators(Validators.required);

        this.expectedProject = false;
        this.finalMile.controls['projectStartDate'].clearValidators();
        this.finalMile.controls['projectStartDate'].updateValueAndValidity();
        this.finalMile.controls['projectEndDate'].clearValidators();
        this.finalMile.controls['projectEndDate'].updateValueAndValidity();
      }
    }
  }


  saiaAccount = false;
  currentSaia(event: any) {
    if (event.value == 'Yes') {
      this.saiaAccount = true;
    } else {
      this.saiaAccount = false;
    }
  }

  onKeyDown(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  }

  numberOnly(event: any) {
    const input = event.target as HTMLInputElement;
    const inputValue = input.value;
    input.value = inputValue.replace(/[^0-9 ]/g, '');
  }

  alphaOnly(event: any) {
    const input = event.target as HTMLInputElement;
    const inputValue = input.value;
    input.value = inputValue.replace(/[^0-9a-zA-Z ]/g, '');
  }

  clearFinalMileQuestionnaire(): any {
    this.finalmileStatusError = false;
    this.isFormSubmittedFinalMile = false;
    this.isFormSubmittedFinalMileApiError = false;
    this.finalMile.reset();
  }

  submitFinalMileQuestionnaire(): any {
    this.isFormSubmittedFinalMile = true;
    if (this.finalMile.invalid) {
      window.scrollTo(0, 0);
      this.finalmileStatusError = true;
    } else {
      window.scrollTo(0, 0);
      this.finalmileStatusError = false;
    }
    if (this.finalMile.status != 'VALID') return;
    const formValues = this.finalMile.value;
    const { typeServiceFb, typeSiteFb, trasportationFb, finalMileFb } = formValues;
    const typeService = [], typeSite = [], trasportation = [], finalMile = [];
    if (typeServiceFb.delivery) typeService.push('Delivery');
    if (typeServiceFb.pickup) typeService.push('Pickup');

    if (typeSiteFb.residential) typeSite.push('Residential');
    if (typeSiteFb.commercial) typeSite.push('Commercial');

    if (trasportationFb?.saiaLTL) trasportation.push('Saia LTL');
    if (trasportationFb?.linkExSourcedTL) trasportation.push('LinkEx-Sourced TL');
    if (trasportationFb?.customerSourced) trasportation.push('Customer Sourced');


    if (finalMileFb?.notificationPro) finalMile.push('Notification Prior to Delivery');
    if (finalMileFb?.unattendedDelivery) finalMile.push('Unattended Delivery');
    if (finalMileFb?.appointmentScheduling) finalMile.push('Appointment Scheduling');
    if (finalMileFb?.liftGateDelivery) finalMile.push('Lift Gate Delivery');
    if (finalMileFb?.insidePickupDelivery) finalMile.push('Inside Pickup Delivery');
    if (finalMileFb?.curbsideDelivery) finalMile.push('Curbside Delivery');
    if (finalMileFb?.thresholdGarageDelivery) finalMile.push('Threshold/Garage Delivery');
    if (finalMileFb?.roomOfChoice) finalMile.push('Room Of Choice');
    if (finalMileFb?.flightOfStairs) finalMile.push('Flight(s) Of Stairs');
    if (finalMileFb?.manDelivery) finalMile.push('2-Man/3-Man Delivery');
    if (finalMileFb?.unpackagingAssembly) finalMile.push('Unpackaging Assembly');
    if (finalMileFb?.debrisRemoval) finalMile.push('Debris Removal');
    if (finalMileFb?.assembly) finalMile.push('Assembly');
    if (finalMileFb?.inRoomPlacement) finalMile.push('In-Room Placement');
    if (finalMileFb?.hookup) finalMile.push('Hookup');
    if (finalMileFb?.haulAway) finalMile.push('Haul Away');
    if (finalMileFb?.disposalReturns) finalMile.push('Disposal/Returns');
    if (finalMileFb?.repairs) finalMile.push('Repairs');
    const opportunityType = formValues.opportunityType === 'fixedTermProject' ? 'Fixed-Term Project' : formValues.opportunityType === 'recurringBusiness' ? 'Recurring Business' : formValues.opportunityType;

    let payload = {
      customerName: formValues.custName,
      customerPhone: formValues.yourPhone,
      customerEmail: formValues.custEmail,
      saiaSalesPersonName: formValues.saiaSalesPerson,
      saiaSalesPersonEmail: formValues.saiaSalesPersonEmail,
      general: {
        opportunityToFixedOrRecurringBusiness: opportunityType,
        expectedProjectStartDate: formValues.projectStartDate,
        expectedProjectEndDate: formValues.projectEndDate,
        expectedStartDateForRecurringBusiness: formValues.expectedStartDateRecurring,
        projectScope: formValues.projectScope,
        isHistoricalSampleDataAvailable: formValues.historicalData,
        isCurrentSaiaCustomer: formValues.currentCustomer,
        saiaAccountNumber: formValues.saiaAccount,
        additionalConsiderations: formValues.additionalRelevant,
      },
      serviceDescription: {
        typeOfService: typeService,
        typeOfSite: typeSite,
        transportationToDestinationProvidedBy: trasportation
      },
      commodity: {
        commodityDescription: formValues.commodityDescription,
        anyHazardousMaterials: formValues.hazardousMaterial
      },
      finalMileServiceInteresets: finalMile
    }
    this.loader = true;
    this.finalmileStatus = false;
    this.isFormSubmittedFinalMileApiError = false;
    this.service.submitFinalMileQuestionnaire(payload).subscribe((result: any) => {
      this.loader = false;
      this.apiResponse = result;
      if (result.status === 'mail_sent') {
        this.finalmileStatus = true;
        this.isFormSubmittedFinalMile = false;
        this.finalMile.reset();
        this.ngFinalMile.resetForm();
      } else {
        this.isFormSubmittedFinalMileApiError = true;
      }
    }, error => {
      this.loader = false;
      this.isFormSubmittedFinalMileApiError = true;
    })
  }

  ngOnInit(): void {
  }

}
