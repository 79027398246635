import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { PageContentModel } from 'src/app/core/model/page-content.model';
import { PageHeaderContentModel } from 'src/app/core/model/page-header.model';
import { ContentfulService } from 'src/app/core/service/contentful.service';

@Component({
  selector: 'app-service-offering',
  templateUrl: './service-offering.component.html',
  styleUrls: ['./service-offering.component.scss']
})
export class ServiceOfferingComponent implements OnInit {

  entry: any;
  contentfulSysFields: any;
  pageContent!: PageContentModel;
  pageHeader!: PageHeaderContentModel;
  accordionSubArray: any = []
  constructor(private contentfulService: ContentfulService, private sanitizer: DomSanitizer,) {
    this.contentfulSysFields = this.contentfulService.getContentfulSysFields();
  }
  ngOnInit(): void {
    this.loadBodyEntries();
    this.loadHeaderEntries();
  }

  // loadBodyEntries() {
  //   this.contentfulService.getEntry('pageInsights', 'Service Offering Content').subscribe(
  //     (entry) => {

  //       this.pageContent = new PageContentModel(entry);
  //       console.log(this.pageContent);

  //       if (this.pageContent && this.pageContent.accordion && this.pageContent.accordion.accordionItems) {
  //         let chunkSize = 4;
  //         this.accordionSubArray = Array.from({ length: Math.ceil(this.pageContent.accordion.accordionItems.length / chunkSize) }, (_, i) =>
  //           this.pageContent.accordion.accordionItems.slice(i * chunkSize, i * chunkSize + chunkSize)
  //         );
  //       }
  //     }, error => {
  //       console.error('Error fetching entries:', error);
  //     });
  // }

  loadHeaderEntries() {
    this.contentfulService.getEntry('pageHeader', 'Service Offering Header').subscribe(
      (entry) => {
        this.pageHeader = new PageHeaderContentModel(entry);
      }, error => {
        console.error('Error fetching entries:', error);
      });
  }

  loadBodyEntries() {
    this.contentfulService.getEntry('pageInsights', 'Service Offering Content').subscribe(
      (entry) => {
        this.pageContent = new PageContentModel(entry);
        // console.log(this.pageContent);
      }, error => {
        console.error('Error fetching entries:', error);
      });
  }

  getBreadcrumbs(): string[] {
    return this.pageHeader.breadCrumbs.split(',').map(breadcrumb => breadcrumb.trim());
  }

  get leftSanitizedDescription(): SafeHtml {
    const htmlContent = this.pageContent.links[0].leftPanelLinks;
    const modifiedHtmlContent = htmlContent.replace(/(<a[^>]*>.*?<\/a>)/g, '$1 <span class="material-icons">arrow_forward</span>');
    return this.sanitizer.bypassSecurityTrustHtml(modifiedHtmlContent);
  }

  get rightSanitizedDescription(): SafeHtml {
    const htmlContent = this.pageContent.links[0].rightPanelLinks;
    const modifiedHtmlContent = htmlContent.replace(/(<a[^>]*>.*?<\/a>)/g, '$1 <span class="material-icons">arrow_forward</span>');
    return this.sanitizer.bypassSecurityTrustHtml(modifiedHtmlContent);
  }

}
