import { Component, OnInit, QueryList, ViewChild, ViewChildren, HostListener } from '@angular/core';
import { MenuItem } from '../menu-item';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { Router } from '@angular/router';
import { MenuContentModel } from '../core/model/menu-content.model';
import { ContentfulService } from '../core/service/contentful.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  menubackdrop: boolean | undefined;
  events: Array<string>;

  @ViewChildren(MatMenuTrigger) trigger!: QueryList<MatMenuTrigger>;

  openMenuToggle(index: number) {
    this.trigger.toArray().forEach((item: MatMenuTrigger, i: number) => {
      if (i !== index && item.menuOpen) {
        item.closeMenu()
      }
    });
  }

  toggleMenu() {
    this.menubackdrop = !this.menubackdrop;
  }

  closeMobileMenu(event: any) {
    if (this.events.includes(event.target.textContent)) {
      this.menubackdrop = true;
    } else {
      this.menubackdrop = false;
    }
  }
  menuContent!: MenuContentModel;
  constructor(private router: Router, private contentfulService: ContentfulService) {
    this.events =
      [
        'menu',
        'Services',
        'Resources',
        'News',
        'Company',
        ' keyboard_arrow_down ',
        'Services keyboard_arrow_down ',
        'Resources keyboard_arrow_down ',
        'News keyboard_arrow_down ',
        'Company keyboard_arrow_down '
      ]
  }

  @HostListener('document:click', ['$event'])
  clickout(event: any) {
    if (!(this.events.includes(event.target.textContent))) {
      this.menubackdrop = false;
    }
  }

  menuItems: MenuItem[] = [];
  //  [
  //   { label: 'Quote', link: '/quote', isAsset: false },
  //   { label: 'Track', link: '/track', isAsset: false },
  //   {
  //     label: 'Services', isAsset: false,
  //     items: [
  //       { label: 'Service Offering', link: '/service-offering', isAsset: false },
  //       { label: 'Contract Logistics', link: '/contract-logistics', isAsset: false },
  //       { label: 'Truckload Brokerage', link: '/truckload-brokerage', isAsset: false },
  //       { label: 'Expedited', link: '/expedited-delivery', isAsset: false },
  //       { label: 'Final Mile', link: '/final-mile', isAsset: false },
  //       { label: 'International', link: '/international-shipping', isAsset: false },
  //       { label: 'Warehousing', link: '/warehousing', isAsset: false },
  //       { label: 'Pool Distribution', link: '/pool-distribution', isAsset: false },
  //       { label: 'Drayage / Transloading', link: '/drayage-transloading', isAsset: false }
  //     ]
  //   },
  //   {
  //     label: 'Resources', isAsset: false,
  //     items: [
  //       { label: 'Tools and Resources', link: '/resources', isAsset: false },
  //       { label: 'Documents', link: '/documents', isAsset: false },
  //       { label: 'Transportation Management System (TMS)', link: '/linkex-tms', isAsset: false },
  //       { label: 'Careers', link: '/careers', isAsset: false },
  //       { label: 'Shipment Tracking', link: '/track', isAsset: false },
  //       { label: 'Holiday Schedule', link: '/holiday-schedule', isAsset: false },
  //       { label: 'Privacy Policy', link: '/assets/docs/Saia-Privacy-Policy.pdf', isAsset: true },

  //     ]
  //   },
  //   {
  //     label: 'News', isAsset: false,
  //     items: [
  //       { label: 'Videos', link: '/videos', isAsset: false },
  //       { label: 'Blog', link: '/news', isAsset: false },
  //     ]
  //   },
  //   {
  //     label: 'Company', isAsset: false,
  //     items: [
  //       { label: 'About Us', link: '/company', isAsset: false },
  //       { label: 'Management Team', link: '/management-team', isAsset: false },
  //       { label: 'Core Values', link: '/core-values', isAsset: false },
  //       { label: 'Testimonials', link: '/linkex-testimonials', isAsset: false },
  //       { label: 'Credentials And Affiliations', link: '/credentials-affiliations', isAsset: false },
  //     ]
  //   },
  //   { label: 'Contact', link: '/contact', isAsset: false },
  //   // { label: 'Login' },
  // ];

  config = {
    interfaceWithRoute: true,
    listBackgroundColor: `#CC3029`,
    fontColor: `#cfcfcf`,
    backgroundColor: `#CC3029`,
    selectedListFontColor: `#fff`,
    collapseOnSelect: true
  };

  ngOnInit(): void {
    this.loadBodyEntries();
   }

   loadBodyEntries() {
    this.contentfulService.getMenuEntry('menu', 'Menu Content').subscribe(
    (entry) => {
        // console.log(entry)
        this.menuContent = new MenuContentModel(entry);
        this.menuItems = this.menuContent.items;
      }, error => {
        console.error('Error fetching entries:', error);
      });
  }
}