import { Entry, EntryCollection } from 'contentful';

export class PageHeaderContentModel {

    heroImageTitle!: string;
    heroImageURL!: string;
    breadCrumbs!: string;


    constructor(entries?: EntryCollection<any>) {
        if (entries && entries.items.length > 0 && entries.items[0].fields) {
            const contentDetail: any = entries.items[0].fields;
            this.heroImageTitle = contentDetail?.title;
            this.heroImageURL = contentDetail.image?.fields?.file?.url;
            this.breadCrumbs = contentDetail.breadcrumbs;
        }
    }
}
