import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { PageContentModel } from 'src/app/core/model/page-content.model';
import { PageHeaderContentModel } from 'src/app/core/model/page-header.model';
import { ContentfulService } from 'src/app/core/service/contentful.service';

@Component({
    selector: 'app-services',
    templateUrl: './services.component.html',
    styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {
    pageContent!: PageContentModel;
    pageHeader!: PageHeaderContentModel;

    constructor(private contentfulService: ContentfulService, private sanitizer: DomSanitizer) { }

    loadHeaderEntries() {
        this.contentfulService.getEntry('pageHeader', 'Service Header').subscribe(
            (entry) => {
                this.pageHeader = new PageHeaderContentModel(entry);
            }, error => {
                console.error('Error fetching entries:', error);
            });
    }

    getBreadcrumbs(): string[] {
        return this.pageHeader.breadCrumbs.split(',').map(breadcrumb => breadcrumb.trim());
    }

    loadBodyEntries() {
        this.contentfulService.getEntry('pageInsights', 'Service Content').subscribe(
          (entry) => {
            this.pageContent = new PageContentModel(entry);
            this.pageContent.description = this.pageContent.description.replace(/<a\s+(?:[^>]*?\s+)?href="([^"]*)"([^>]*)>/g, '<a href="$1" target="_blank"$2>');
          }, error => {
            console.error('Error fetching entries:', error);
          });
      }
    

    ngOnInit(): void {
        this.loadHeaderEntries();
        this.loadBodyEntries();
    }

}