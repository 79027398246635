import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
// import { environmentProd } from '../environments/environment.prod';
// import { environmentPilot } from '../environments/environment.pilot';

// type GTagFunction = (command: string, eventName: string, options?: any) => void;
// declare var gtag: GTagFunction;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'website';
  loader = false;

  constructor(private router: Router) {
    // const containerIdProd = environmentProd.GTM_CONTAINER_ID;
    // const containerIdPilot = environmentPilot.GTM_CONTAINER_ID;
    // this.addGAScriptProd();
    // this.addGAScriptPilot();
    // this.addGTMScriptProd(containerIdProd);
    // this.addGTMScriptPilot(containerIdPilot);

    // this.router.events.pipe(
    //   filter((event: any) => event instanceof NavigationEnd)
    // ).subscribe((event: NavigationEnd) => {
    //   gtag('event', 'page_view', {
    //     page_path: event.urlAfterRedirects
    //   });
    // });
  }

  // addGAScriptProd() {
  //   let gtagScript: HTMLScriptElement = document.createElement('script');
  //   gtagScript.async = true;
  //   gtagScript.src = 'https://www.googletagmanager.com/gtag/js?id=' + environmentProd.GA_TRACKING_ID;
  //   document.head.prepend(gtagScript);
  //   gtag('config', environmentProd.GA_TRACKING_ID, { send_page_view: false });
  // }

  // addGAScriptPilot() {
  //   let gtagScript: HTMLScriptElement = document.createElement('script');
  //   gtagScript.async = true;
  //   gtagScript.src = 'https://www.googletagmanager.com/gtag/js?id=' + environmentPilot.GA_TRACKING_ID;
  //   document.head.prepend(gtagScript);
  //   gtag('config', environmentPilot.GA_TRACKING_ID, { send_page_view: false });
  // }


  // addGTMScriptProd(containerIdProd: string) {
  //   let gtmScript: HTMLScriptElement = document.createElement('script');
  //   gtmScript.innerHTML = `(function(w,d,s,l,i){
  //     w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});
  //     var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
  //     j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  //   })(window,document,'script','dataLayer','${containerIdProd}');`;
  //   document.head.prepend(gtmScript);
  // }

  // addGTMScriptPilot(containerIdPilot: string) {
  //   let gtmScript: HTMLScriptElement = document.createElement('script');
  //   gtmScript.innerHTML = `(function(w,d,s,l,i){
  //     w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});
  //     var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
  //     j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  //   })(window,document,'script','dataLayer','${containerIdPilot}');`;
  //   document.head.prepend(gtmScript);
  // }

  ngOnInit() { }

}
