<!-- Notification -->
<div *ngIf="pageNotification?.notificationFlag == true">
    <div *ngIf="pageNotification" [routerLink]="pageNotification.pageUrl" class="scroller-bg"> 
        <span class="animate" >
            <span class="material-icons font-12">{{pageNotification.icons}}</span> 
            <span [innerHTML]="sanitizedNotification"></span>
        </span> 
    </div>   
</div>
  
<!-- Banner -->
<div class="home-banner" *ngIf="pageHeader" [heroImage]="pageHeader.heroImageURL">
    <div class="row center-xs">

        <div class="col-md-12 col-xs-12">
            <h4 class="header-text text-blue py-2 custom">
                {{pageHeader.heroImageTitle}}
            </h4>
        </div>
        <form [formGroup]="trackingForm" class="Tracking-FormGroup">

            <div class="row">
                <div class="form-group form-button-group">

                    <mat-form-field appearance="outline" class="col-md-12 p-0">

                        <input matInput type="input" formControlName="trackRequest" placeholder="Ex. SO000000, SO000001" (keydown)="onKeyDown($event)">
                        <button matSuffix mat-icon-button aria-label="Clear" (click)="clear()">
                            <mat-icon>close</mat-icon>
                        </button>
                        <mat-error>
                            Enter Valid SO Number
                        </mat-error>
                    </mat-form-field>

                </div>

                <button mat-flat-button class="primary-btn" (click)="submit()">
                    Track
                </button>
            </div>

        </form>

    </div>    
</div>

  
<!-- Who we are -->
<section class="who-we-are" *ngIf="pageContent">
    <div class="row">
        <div class="col-md-10 col-md-offset-1">
            <h4 class="header-text text-blue py-2">
                {{ pageContent.header }}
            </h4>
            <div class="content-text p-0">
                <div [innerHTML]="sanitizedDescription"></div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-10 col-md-offset-1" *ngIf="pageContent && pageContent.accordion && pageContent.accordion.accordionItems">
            <div class="row box-margin">

                <ng-container *ngFor="let accordionItem of pageContent.accordion.accordionItems; let i = index">
                    <div class="col-md-2 col-xs-12">
                        <div class="box" routerLink="{{ accordionItem.link }}">
                            <div class="header">
                                {{ accordionItem.header }}
                            </div>
                            <div class="content">
                                <div class="content-text-p" [innerHTML]="sanitizedAccordionItems[i]"></div>
                            </div>
                            <span class="material-icons-outlined">{{accordionItem.icons}}</span>
                        </div>
                    </div> 
                </ng-container> 

            </div>
        </div>
    </div>
 
</section>

<!-- AllCert -->
<section class="section-sprite center-xs" *ngIf="pageContent">
    <img [src]="pageContent.thumbnailURL" height=" " alt="">
</section>