import { Entry, EntryCollection } from 'contentful';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { richTextOptions } from './rich-text-options.model';
import { AccordionContentModel, AccordionItemContentModel } from './accordion-content.model';
import { LinkItemContentModel } from './link-content.model';

export class PageContentModel {

    header!: string;
    description!: string;
    thumbnailURL!: string;
    accordion!: AccordionContentModel;
    links: LinkItemContentModel[] = [];


    constructor(entries?: EntryCollection<any>) {
        if (entries && entries.items.length > 0 && entries.items[0].fields) {
            const contentDetail: any = entries.items[0].fields;

            this.header = contentDetail.header;
            this.description = documentToHtmlString(contentDetail.pageContent, richTextOptions);
            this.thumbnailURL = contentDetail.thumbnail?.fields?.file?.url;

            if (contentDetail?.accordion?.fields) {
                this.accordion = new AccordionContentModel(contentDetail.accordion.fields);
            }

            if (contentDetail?.pageLinks && contentDetail.pageLinks.length > 0) {
                contentDetail.pageLinks.forEach((link: any) => {
                    this.links.push(new LinkItemContentModel(link.fields));
                });
            }

        }
    }
}