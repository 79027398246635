import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { Subject } from 'rxjs';
import { LinkexService } from '../services/linkex.service.service';
import { DatePipe } from '@angular/common';
import { DateFilterFn } from '@angular/material/datepicker';
import moment from 'moment';

interface Each {
  value: string;
  viewValue: string;
}

interface Class {
  value: string;
  viewValue: string;
}

interface Incoterms {
  value: string;
  viewValue: string;
}

interface HuType {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-quote',
  templateUrl: './quote.component.html',
  styleUrls: ['./quote.component.scss']
})
export class QuoteComponent implements OnInit {
  [x: string]: any;
  @ViewChild(MatTabGroup) tabGroup!: MatTabGroup;
  @ViewChild('ngTruckload') ngTruckload: any;
  @ViewChild('ngExpedited') ngExpedited: any;
  @ViewChild('ngFinalCartage') ngFinalCartage: any;
  @ViewChild('ngWarehousing') ngWarehousing: any;
  @ViewChild('ngInternational') ngInternational: any;
  @ViewChild('ngTransloading') ngTransloading: any;

  loader: boolean = false;
  isFormSubmittedTruckload = false;
  isFormSubmittedExpeditedload = false;
  isFormSubmittedLtlcartage = false;
  isFormSubmittedWarehousing = false;
  isFormSubmittedInternational = false;
  isFormSubmittedTransloading = false;
  isFormSubmittedTruckloadApiError = false;
  isFormSubmittedLtlcartageApiError = false;
  isFormSubmittedInternationalApiError = false;
  isFormSubmittedTransloadingApiError = false;
  isFormSubmittedWarehouseApiError = false;
  isFormSubmittedExpeditedApiError = false;
  isValidSaiaAccount: string | null = null;
  isTruckloadAccessorialChecked: string | null = null;
  dateValue: any;
  apiResponse: any = {};
  saiaAccountData: any = null;
  cmbtpSaiaAccountData: any = null;
  minDate = new Date();
  minDateToFinish = new Subject<string>();
  shipDate = new Date();
  rDate = new Date();

  email = 'tlquotes@linkex.us';
  subject = 'Request for Quote';

  warehouseStatusError: boolean = false;
  warehouseStatus: boolean = false;
  today = new Date();
  activeTabIndex: number | undefined;
  previousQuoteStatus: string = "";
  previousQuoteMsg: string = "";
  inputText: string = '';
  showButton: boolean = false;
  isLoading: boolean = false;
  isLoadingPreviousQuote: boolean = false;
  isValidSaiaInput: boolean = false;
  showForm = false;

  captcha: string = '';
  showCaptchaError: boolean = false;

  // dateChange(e: { value: { toString: () => string; }; }) {
  //   this.minDateToFinish.next(e.value.toString());
  // }

  blockedDates: Date[] = [
    new Date(2023, 0, 2),
    new Date(2023, 3, 7),
    new Date(2023, 4, 29),
    new Date(2023, 6, 4),
    new Date(2023, 8, 4),
    new Date(2023, 10, 23),
    new Date(2023, 10, 24),
    new Date(2023, 11, 25),
    new Date(2023, 11, 26),
    new Date(2024, 0, 1)
  ];

  dateFilter: DateFilterFn<Date | null> = (date: Date | null) => {
    // Block weekends (Saturday and Sunday)
    if (!date) {
      return true; // Allow null date values
    }

    // Block weekends (Saturday and Sunday)
    const day = date.getDay();
    if (day === 0 || day === 6) {
      return false;
    }

    // Block specific dates
    return !this.blockedDates.some(blockedDate => this.isSameDay(date, blockedDate));
  };

  isSameDay(date1: Date, date2: Date): boolean {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  }

  dateChange(e: { value: { toString: () => string } | null }) {
    const datePipe = new DatePipe('en-US');
    const formattedDate = e.value ? datePipe.transform(e.value.toString()) : null;
    if (formattedDate !== null) {
      this.minDateToFinish.next(formattedDate);
    }
  }

  constructor(private fb: FormBuilder, private service: LinkexService, private route: ActivatedRoute, private router: Router, private datePipe: DatePipe) {
    this.minDateToFinish.subscribe(r => {
      this.shipDate = new Date(r);
      this.rDate = new Date(r);
    })

    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    this.minDate = tomorrow;
  }

  each: Each[] = [
    { value: 'Each', viewValue: 'Each' },
    { value: 'Total', viewValue: 'Total' },
  ];

  class: Class[] = [
    { value: '50', viewValue: '50' },
    { value: '55', viewValue: '55' },
    { value: '60', viewValue: '60' },
    { value: '65', viewValue: '65' },
    { value: '70', viewValue: '70' },
    { value: '77.5', viewValue: '77.5' },
    { value: '85', viewValue: '85' },
    { value: '92.5', viewValue: '92.5' },
    { value: '100', viewValue: '100' },
    { value: '110', viewValue: '110' },
    { value: '125', viewValue: '125' },
    { value: '150', viewValue: '150' },
    { value: '175', viewValue: '175' },
    { value: '200', viewValue: '200' },
    { value: '250', viewValue: '250' },
    { value: '300', viewValue: '300' },
    { value: '400', viewValue: '400' },
    { value: '500', viewValue: '500' },
  ];
  truckLoadStatusError: boolean = false;
  expeditedLoadStatusError: boolean = false;
  ltlCatageStatusError: boolean = false;
  warehousingStatusError: boolean = false;
  internationalStatusError: boolean = false;
  transloadingError: boolean = false;
  truckLoadStatus: boolean = false;
  expeditedLoadStatus: boolean = false;
  ltlCatageStatus: boolean = false;
  warehousingStatus: boolean = false;
  internationalStatus: boolean = false;
  transloadingStatus: boolean = false;
  incoterms: Incoterms[] = [
    { value: 'EXW', viewValue: 'EXW' },
    { value: 'FCA', viewValue: 'FCA' },
    { value: 'CPT', viewValue: 'CPT' },
    { value: 'CIP', viewValue: 'CIP' },
    { value: 'DAT', viewValue: 'DAT' },
    { value: 'DAP', viewValue: 'DAP' },
    { value: 'DDP', viewValue: 'DDP' },
    { value: 'FAS', viewValue: 'FAS' },
    { value: 'FOB', viewValue: 'FOB' },
    { value: 'CFR', viewValue: 'CFR' },
    { value: 'CIF', viewValue: 'CIF' },
    { value: 'DPU', viewValue: 'DPU' },
  ];

  ngOnInit(): void {
    const tab = this.route.snapshot.queryParamMap.get('tab');
    this.activeTabIndex = this.getTabIndex(tab);
  }

  getTabIndex(tabLabel: string | null): number {
    if (tabLabel) {
      const decodedTabLabel = this.decodeTabLabel(tabLabel);
      switch (decodedTabLabel) {
        case 'Truckload':
          return 0;
        case 'Expedited':
          return 1;
        case 'Final-Mile-and-Cartage':
          return 2;
        case 'Warehousing':
          return 3;
        case 'International':
          return 4;
        case 'Drayage / Transloading':
          return 5;
        default:
          return 0;
      }
    } else {
      return 0;
    }
  }

  decodeTabLabel(tabLabel: string): string {
    return tabLabel.replace('Drayage-and-Transloading', 'Drayage / Transloading');
  }

  encodeTabLabel(tabLabel: string): string {
    let encodedTabLabel = tabLabel.replace('Drayage / Transloading', 'Drayage-and-Transloading');
    encodedTabLabel = encodedTabLabel.replace(/ /g, '-');
    return encodedTabLabel;
  }

  onTabChanged(event: MatTabChangeEvent) {
    const selectedTabLabel = event.tab.textLabel;
    const encodedTabLabel = this.encodeTabLabel(selectedTabLabel);

    this.router.navigate(['/quote'], {
      relativeTo: this.route,
      queryParams: { tab: encodedTabLabel },
      queryParamsHandling: 'merge'
    });
  }


  serviceOfferings: any = {
    serviceOffering1: 'Expedited/Ground, Air',
    serviceOffering2: 'Dry Van Truckload',
    serviceOffering3: 'Reefer Truckload',
    serviceOffering4: 'Step Deck',
    serviceOffering5: 'Power Only',
    serviceOffering6: 'Specialized',
    serviceOffering7: 'Rail/Intermodal',
    serviceOffering8: 'International/Ocean, Air, Import and Export',
    serviceOffering9: 'Contract Logistics',
    serviceOffering10: 'Trans Management',
    serviceOffering11: 'DFW Local Cartage',
    serviceOffering12: 'Other',
  };
  accessorial: any = {
    accessorial1: 'Will Pallets/Units Stack',
    accessorial2: 'Origin Driver Assistance Required',
    accessorial3: 'Destination Driver Assistance Required',
    accessorial4: 'Lift Gate Required at Origin',
    accessorial5: 'Lift Gate Required at Destination',
    accessorial6: 'Hazmat UN',
  };
  replyBy: any = {
    replyBy1: 'Phone',
    replyBy2: 'Fax',
    replyBy3: 'Email'
  };
  stdServices: any = {
    stdServices1: 'Appointment Pickup',
    stdServices2: 'Appointment Delivery',
    stdServices3: 'Lift Gate Pickup',
    stdServices4: 'Lift Gate Delivery',
    stdServices5: 'Pallet Jack Pickup',
    stdServices6: 'Pallet Jack Delivery',
    stdServices7: 'Tilt Dolly Pickup',
    stdServices8: 'Tilt Dolly Delivery',
    stdServices9: 'Inside Pickup',
    stdServices10: 'Inside Delivery',
    stdServices11: 'Debris Removal',
    stdServices12: 'Residential Pickup',
    stdServices13: 'Residential Delivery',
    stdServices14: 'Trade Show/Convention Hall/Hotel Pickup',
    stdServices15: 'Trade Show/Convention Hall/Hotel Delivery',
    stdServices16: 'Military Base/School Pickup',
    stdServices17: 'Military Base/School Delivery',
    stdServices18: 'Sorting and Segregating',
    stdServices19: 'Hazardous Materials'
  };
  typeOfServices: any = {
    typeOfService1: 'Short Term',
    typeOfService2: 'Pick and Pack',
    typeOfService3: 'Long Term',
    typeOfService4: 'Cross Dock',
  };
  loadTypes: any = {
    loadType1: 'Less that Container Load (LCL)',
    loadType2: 'Full Container Load (FCL)'
  };
  equipmentTypes: any = {
    ETStandard: 'Standard',
    ETHighCube: 'High Cube',
    ETRefrigerated: 'Refrigerated(Reefer)',
    ETOpenTop: 'Open Top',
    ETFlatRack: 'Flat Rack',
    ETOutofGauge: 'Out of Gauge',
    ETVanTrailer: 'Van Trailer',
    ETBonded: 'Bonded'
  }


  truckload = this.fb.group({
    previousQuoteNumber: [''],
    yourCompany: [''],
    yourName: ['', [Validators.required]],
    yourPhone: ['', [Validators.required]],
    yourEmail: ['', [Validators.required, Validators.pattern('^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,4}$')]],
    saiaAccountNumber: ['', [this.saiaAccountNumberValidator()]],
    accountNumber: [''],
    // additionalDetails: [''],
    pickDate: ['', [Validators.required]],
    originCity: ['', [Validators.required]],
    originState: ['', [Validators.required]],
    originZip: ['', [Validators.required]],
    // dockHours: ['', [Validators.required]],
    destinationCity: ['', [Validators.required]],
    destinationState: ['', [Validators.required]],
    destinationZip: ['', [Validators.required]],
    // destiDockHours: ['', [Validators.required]],

    // truckloadShippingDetails: this.fb.array([this.shippingDetailsArray()]),
    // freightDetails: this.fb.array([this.freightDetailsArray()]),
    weight: ['', [Validators.required, Validators.max(48000), this.weightLimitValidator]],
    hazmat: [],

    standarDryVan: [{ value: true, disabled: true }],
    // specialInstructions: [''],
    accessorialServicesFb: this.fb.group({
      insideDelivery: false,
      insidePickup: false,
      liftgateDelivery: false,
      liftgatePickup: false,
      limitedAccessOnDelivery: false,
      limitedAccessOnPickup: false,
      residentialDelivery: false,
      residentialPickup: false,
      sortAtDestination: false,
      sortAtOrigin: false,
      hazardousMaterials: false,
      tankerEndorsement: false
    }),

    replyBy1: [''],
    replyBy3: [''],
    // recaptchaReactive: ['', Validators.required],


    // saiaSalesRep: [''],
    // saiaSalesRepEmail: ['']

  });

  saiaAccountNumberValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;

      if (value && value.length !== 7) {
        return { invalidSaiaAccountNumber: true };
      }

      return null;
    };
  }

  sendEmail(): void {

    const mailtoLink = `mailto:${this.email}?subject=${encodeURIComponent(this.subject)}`;

    // Open the user's default browser email client
    window.location.href = mailtoLink;
  }

  weightLimitValidator(control: AbstractControl): { [key: string]: any } | null {
    const maxWeight = 44000;
    const enteredValue = control.value ? parseInt(control.value.replace(/,/g, '')) : null;
    if (enteredValue !== null && (isNaN(enteredValue) || enteredValue > maxWeight)) {
      return { maxWeight: true };
    }
    return null;
  }

  equipmentReq(group: FormGroup) {
    const checkboxes = [
      'dryVanTruckload',
      'reeferTruckload',
      'flatbedTruckload',
      'other'
    ];
    const checkedBoxes = checkboxes.map(controlName => group.controls[controlName].value);
    const atLeastOneChecked = checkedBoxes.some(Boolean);
    return atLeastOneChecked ? null : { required: true };
  }

  otherAdditionalDetails = false;
  otherAdditionalFn(event: any) {
    if (event.value === 'Other') {
      this.otherAdditionalDetails = true;
      // this.truckload.controls['additionalDetails'].setValidators(Validators.required);
    } else {
      this.otherAdditionalDetails = false;
      // this.truckload.controls['additionalDetails'].clearValidators();
      // this.truckload.controls['additionalDetails'].updateValueAndValidity();
    }
  }

  // get truckloadShippingDetailsAsFormArray(): any {
  //   return this.truckload.get('truckloadShippingDetails') as FormArray;
  // }

  // get truckloadFreightDetailsAsFormArray(): any {
  //   return this.truckload.get('freightDetails') as FormArray;
  // }

  shippingDetailsArray(): any {
    return this.fb.group({
      weight: ['', [Validators.required]],
      units: ['', [Validators.required]],
      descriptionCommodity: ['', [Validators.required]],
      declaredValue: ['', [Validators.required]],
    });
  }

  // freightDetailsArray(): any {
  //   return this.fb.group({
  //     length: [''],
  //     width: [''],
  //     height: [''],
  //     units: [''],
  //     ofUnits: [''],
  //   });
  // }

  // addShippingControl(): void {
  //   this.truckloadShippingDetailsAsFormArray.push(this.shippingDetailsArray());
  // }

  // addFreightControl(): void {
  //   this.truckloadFreightDetailsAsFormArray.push(this.freightDetailsArray());
  // }

  // removeShippingControl(): void {
  //   if (this.truckloadShippingDetailsAsFormArray.length > 1) {
  //     this.truckloadShippingDetailsAsFormArray.removeAt(this.truckloadShippingDetailsAsFormArray.length - 1);
  //   } else {
  //     console.log('At least one shipping detail is required.');
  //   }
  // }

  // removeFreightControl(): void {
  //   if (this.truckloadFreightDetailsAsFormArray.length > 1) {
  //     this.truckloadFreightDetailsAsFormArray.removeAt(this.truckloadFreightDetailsAsFormArray.length - 1);
  //   } else {
  //     console.log('At least one shipping detail is required.');
  //   }
  // }

  expedited = this.fb.group({
    yourCompany: [''],
    yourName: ['', [Validators.required]],
    yourPhone: ['', [Validators.required]],
    yourEmail: ['', [Validators.required, Validators.pattern('^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,4}$')]],
    shipDate: [''],
    originAddress: [''],
    originCity: ['', [Validators.required]],
    originState: ['', [Validators.required]],
    originZip: ['', [Validators.required]],
    deliveryDate: [''],
    destinationAddress: [''],
    destinationCity: ['', [Validators.required]],
    destinationState: ['', [Validators.required]],
    destinationZip: ['', [Validators.required]],
    pieceCount: ['', [Validators.required]],
    hutype: ['', [Validators.required]],
    weight: ['', [Validators.required]],
    dimensions: ['', [Validators.required]],
    commodity: [''],
    hazmatType: ['', [Validators.required]],
    hazmatUn: [''],
    billAccount: [''],
    saiaPro: [''],
    equipmentType: ['', [Validators.required]],
    addDetails: [''],

    accessorialServicesFb: this.fb.group({
      insideDelivery: false,
      insidePickup: false,
      liftgateDelivery: false,
      liftgatePickup: false,
      limitedAccessOnDelivery: false,
      limitedAccessOnPickup: false,
      residentialDelivery: false,
      residentialPickup: false,
      sortAtDestination: false,
      sortAtOrigin: false,
      hazardousMaterials: false,
      tankerEndorsement: false,
      whiteGloveService: false
    }),

    replyBy1: [''],
    replyBy3: [''],

    saiaAccountNumber: [''],
    // saiaSalesRep: [''],
    // saiaSalesRepEmail: ['']
  });

  additionalDetailsType = false;
  equipmentType(event: any) {
    if (event.value === 'Other') {
      this.additionalDetailsType = true;
      this.expedited.controls['addDetails'].setValidators(Validators.required);
    } else {
      this.additionalDetailsType = false;
      this.expedited.controls['addDetails'].clearValidators();
      this.expedited.controls['addDetails'].updateValueAndValidity();
    }
  }

  hazmatUn = false;
  hazmatUnFn(event: any) {
    if (event.value === 'Yes') {
      this.hazmatUn = true;
      this.expedited.controls['hazmatUn'].setValidators(Validators.required);
    } else {
      this.hazmatUn = false;
      this.expedited.controls['hazmatUn'].clearValidators();
      this.expedited.controls['hazmatUn'].updateValueAndValidity();
    }
  }

  hutype: HuType[] = [
    { value: 'Bags', viewValue: 'Bags' },
    { value: 'Bales', viewValue: 'Bales' },
    { value: 'Barrels', viewValue: 'Barrels' },
    { value: 'Baskets', viewValue: 'Baskets' },
    { value: 'Boxes', viewValue: 'Boxes' },
    { value: 'Buckets', viewValue: 'Buckets' },
    { value: 'Bundle', viewValue: 'Bundle' },
    { value: 'Cans', viewValue: 'Cans' },
    { value: 'Carton', viewValue: 'Carton' },
    { value: 'Cases', viewValue: 'Cases' },
    { value: 'Coils', viewValue: 'Coils' },
    { value: 'Crates', viewValue: 'Crates' },
    { value: 'Cylinders', viewValue: 'Cylinders' },
    { value: 'Drums', viewValue: 'Drums' },
    { value: 'Each', viewValue: 'Each' },
    { value: 'Envelopes', viewValue: 'Envelopes' },
    { value: 'Joints', viewValue: 'Joints' },
    { value: 'Kits', viewValue: 'Kits' },
    { value: 'Loose', viewValue: 'Loose' },
    { value: 'No Package Code', viewValue: 'No Package Code' },
    { value: 'Packages', viewValue: 'Packages' },
    { value: 'Pails', viewValue: 'Pails' },
    { value: 'Pallets', viewValue: 'Pallets' },
    { value: 'Pieces', viewValue: 'Pieces' },
    { value: 'Reels', viewValue: 'Reels' },
    { value: 'Rolls', viewValue: 'Rolls' },
    { value: 'Shrink Wrapped Pallet', viewValue: 'Shrink Wrapped Pallet' },
    { value: 'Skids', viewValue: 'Skids' },
    { value: 'Spools', viewValue: 'Spools' },
    { value: 'Tanks', viewValue: 'Tanks' },
    { value: 'Tote Bins', viewValue: 'Tote Bins' },
    { value: 'Tubes', viewValue: 'Tubes' },
    { value: 'Units', viewValue: 'Units' },
  ];

  finalCartage = this.fb.group({

    companyName: ['', [Validators.required]],
    contactName: ['', [Validators.required]],
    yourPhone: ['', [Validators.required]],
    custEmail: ['', [Validators.required, Validators.pattern('^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,4}$')]],
    orginZip: ['', [Validators.required]],
    destZip: ['', [Validators.required]],
    RequestPickupDate: ['', [Validators.required]],
    requestDeliveryDate: ['', [Validators.required]],

    shippingDetails: this.fb.array([this.shippingArray()]),

    pickupServiceFb: this.fb.group({
      appointmentPickupPS: false,
      callBeforePickupPS: false,
      liftgatePickupPS: false,
      limitedAccessPickupPS: false,
      residentialPickupPS: false
    }),

    deliveryServiceFb: this.fb.group({
      appointmentDeliveryDS: false,
      callBeforeDeliveryDS: false,
      liftgateDeliveryDS: false,
      limitedAccessDeliveryDS: false,
      residentialDeliveryDS: false,
      unattendedDeliveryDS: false,
      curbsideDeliveryDS: false,
      thresholdGarageDeliveryDS: false
    }),

    whiteGloveServiceFb: this.fb.group({
      additionalLaborWS: false,
      debrisRemovalWS: false,
      insidePickupDeliveryWS: false,
      lightAssemblyWS: false,
      roomOfChoiceWS: false,
      flightOfStairsWS: false,
      unpackagingWS: false,
      inRoomPlacementWS: false,
      hookupWS: false,
      haulAwayWS: false,
      disposalReturnsWS: false,
      repairsWS: false
    }),

    additionalSpecialInstructions: [''],
    warehouseQuesPhoneFM: false,
    warehouseQuesEmailFM: false,
    saiaAccountNumberFM: [''],
    // saiaSalesRepFM: [''],
    // saiaSalesRepEmail: ['']

  });

  get shippingDetailsAsFormArray(): any {
    return this.finalCartage.get('shippingDetails') as FormArray;
  }

  shippingArray(): any {
    return this.fb.group({
      shipCommodity: ['', [Validators.required]],
      shipQuantity: ['', [Validators.required]],
      hutype: ['', [Validators.required]],
      hazmatType: ['', [Validators.required]],
      weight: ['', [Validators.required]],
      dimensions: ['', [Validators.required]],
      per: ['', [Validators.required]],
    });
  }

  addControl(): void {
    this.shippingDetailsAsFormArray.push(this.shippingArray());
  }

  removeControl(): void {
    if (this.shippingDetailsAsFormArray.length > 1) {
      this.shippingDetailsAsFormArray.removeAt(this.shippingDetailsAsFormArray.length - 1);
    } else {
      console.log('At least one shipping detail is required.');
    }
  }

  warehouseQuestionnaire = this.fb.group({
    yourCompany: ['', [Validators.required]],
    yourName: ['', [Validators.required]],
    accountNumber: [''],
    yourEmail: ['', [Validators.required, Validators.pattern('^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,4}$')]],
    phoneNumber: [''],
    faxNumber: [''],
    city: ['', [Validators.required]],
    state: ['', [Validators.required]],
    zip: ['', [Validators.required]],
    commodity: ['', [Validators.required]],

    packagedFB: this.fb.group({
      packagedCase: false,
      packagedDrum: false,
      packagedTote: false,
      packagedCrate: false,
      packagedPallet: false,
      packagedOther: false,
    }, { validators: this.generalQuestions }),
    packagingType: [''],

    productDetails: ['', [Validators.required]],
    ongoingOpportunity: ['', [Validators.required]],
    otherDetails: [''],
    lengthRequired: ['', [Validators.required]],

    inboundProductFB: this.fb.group({
      receivingContainer: false,
      receivingFTL: false,
      receivingLTL: false,
      receivingParcel: false,
      receivingOther: false,
    }, { validators: this.inboundProduct }),
    inboundOtherDetails: [''],

    inbound: ['', [Validators.required]],
    peakSeason: ['', [Validators.required]],
    peakSeasonDetails: [''],

    productIs: this.fb.group({
      productPalletized: false,
      productFloorLoaded: false,
    }, { validators: this.inboundProductIs }),

    palletInbound: ['', [Validators.required]],
    caseInbound: ['', [Validators.required]],
    sku: ['', [Validators.required]],

    specialHandling: ['', [Validators.required]],
    sortingAdditionalDetails: [''],
    handleReturns: ['', [Validators.required]],
    handlesortingAdditionalDetails: [''],

    palletAvg: ['', [Validators.required]],
    maxPallets: ['', [Validators.required]],
    palletsStandard: ['', [Validators.required]],
    dimensions: [''],
    palletsStackable: ['', [Validators.required]],
    maxHeight: [''],
    avgWeight: ['', [Validators.required]],

    inventoryRequiredFB: this.fb.group({
      storageRequiredFloor: false,
      storageRequiredRacking: false,
      storageRequiredOther: false,
    }, { validators: this.inventoryRequiredvalidator }),
    storageAdditionalDetails: [''],

    inventoryPeak: ['', [Validators.required]],
    inventoryPeakSeason: [''],

    avgCase: ['', [Validators.required]],
    inventoryAvgSit: ['', [Validators.required]],
    turnsYear: ['', [Validators.required]],
    skuInventory: ['', [Validators.required]],

    requimentCertificateFB: this.fb.group({
      rcClimateControl: false,
      rcHazmat: false,
      rcFoodGrade: false,
      rcOther: false,
    }, { validators: this.certificateRequirement }),
    certificationAdditionalDetails: [''],

    obLeavingFacilityFB: this.fb.group({
      leavingFacilityContainer: false,
      leavingFacilityFTL: false,
      leavingFacilityLTL: false,
      leavingFacilityParcel: false,
      leavingFacilityOther: false,
    }, { validators: this.obLeavingFacility }),
    facilityAdditionalDetails: [''],

    orderPerMonth: ['', [Validators.required]],
    outboundPeakSeason: ['', [Validators.required]],
    outboundPeak: [''],

    pickingRequired: ['', [Validators.required]],
    perOrderAvg: [''],
    caseOrderAvg: [''],
    pieceOrderAvg: [''],

    linePerOrderAvg: ['', [Validators.required]],

    outboundPeakSeasonTransportInbound: [''],
    outboundPeakSeasonTransportInboundReason: [''],
    inboundZip: [''],
    legalOverweightInbound: [''],

    outboundPeakSeasonTransportOutbound: [''],
    outboundPeakSeasonTransportOutboundReason: [''],
    OutboundZip: [''],
    legalOverweightOutbound: [''],

    warehouseQuesPhone: [''],
    warehouseQuesFax: [''],
    warehouseQuesEmail: [''],
    saiaAccountNumber: [''],
    // saiaSalesRep: [''],
    // saiaSalesRepEmail: ['']

  });

  typeOfService(group: FormGroup) {
    const checkboxes = [
      'delivery',
      'pickup',
    ];
    const checkedBoxes = checkboxes.map(controlName => group.controls[controlName].value);
    const atLeastOneChecked = checkedBoxes.some(Boolean);
    return atLeastOneChecked ? null : { required: true };
  }

  typeOfSite(group: FormGroup) {
    const checkboxes = [
      'residential',
      'commercial',
    ];
    const checkedBoxes = checkboxes.map(controlName => group.controls[controlName].value);
    const atLeastOneChecked = checkedBoxes.some(Boolean);
    return atLeastOneChecked ? null : { required: true };
  }


  generalQuestions(group: FormGroup) {
    const checkboxes = [
      'packagedCase',
      'packagedDrum',
      'packagedTote',
      'packagedCrate',
      'packagedOther',
      'packagedPallet'
    ];
    const checkedBoxes = checkboxes.map(controlName => group.controls[controlName].value);
    const atLeastOneChecked = checkedBoxes.some(Boolean);
    return atLeastOneChecked ? null : { required: true };
  }

  inboundProduct(group: FormGroup) {
    const checkboxes = [
      'receivingContainer',
      'receivingFTL',
      'receivingLTL',
      'receivingParcel',
      'receivingOther'
    ];
    const checkedBoxes = checkboxes.map(controlName => group.controls[controlName].value);
    const atLeastOneChecked = checkedBoxes.some(Boolean);
    return atLeastOneChecked ? null : { required: true };
  }


  inboundProductIs(group: FormGroup) {
    const checkboxes = [
      'productPalletized',
      'productFloorLoaded',
    ];
    const checkedBoxes = checkboxes.map(controlName => group.controls[controlName].value);
    const atLeastOneChecked = checkedBoxes.some(Boolean);
    return atLeastOneChecked ? null : { required: true };
  }

  inventoryRequiredvalidator(group: FormGroup) {
    const checkboxes = [
      'storageRequiredFloor',
      'storageRequiredRacking',
      'storageRequiredOther',
    ];
    const checkedBoxes = checkboxes.map(controlName => group.controls[controlName].value);
    const atLeastOneChecked = checkedBoxes.some(Boolean);
    return atLeastOneChecked ? null : { required: true };
  }


  certificateRequirement(group: FormGroup) {
    const checkboxes = [
      'rcClimateControl',
      'rcHazmat',
      'rcFoodGrade',
      'rcOther'
    ];
    const checkedBoxes = checkboxes.map(controlName => group.controls[controlName].value);
    const atLeastOneChecked = checkedBoxes.some(Boolean);
    return atLeastOneChecked ? null : { required: true };
  }

  obLeavingFacility(group: FormGroup) {
    const checkboxes = [
      'leavingFacilityContainer',
      'leavingFacilityFTL',
      'leavingFacilityLTL',
      'leavingFacilityOther',
      'leavingFacilityParcel'
    ];
    const checkedBoxes = checkboxes.map(controlName => group.controls[controlName].value);
    const atLeastOneChecked = checkedBoxes.some(Boolean);
    return atLeastOneChecked ? null : { required: true };
  }

  saiaAccount = false;
  currentSaia(event: any) {
    if (event.value == 'Yes') {
      this.saiaAccount = true;
    } else {
      this.saiaAccount = false;
    }
  }

  otherPackaging = false;
  OtherPackage(event: any) {
    if (event.checked == true) {
      this.otherPackaging = true;
      this.warehouseQuestionnaire.controls['packagingType'].setValidators(Validators.required);
    } else {
      this.otherPackaging = false;
      this.warehouseQuestionnaire.controls['packagingType'].clearValidators();
      this.warehouseQuestionnaire.controls['packagingType'].updateValueAndValidity();

    }
  }

  otherDetails = false;
  additionalDetails(event: any) {
    if (event.value === 'Yes') {
      this.otherDetails = true;
      this.warehouseQuestionnaire.controls['otherDetails'].setValidators(Validators.required);
    } else {
      this.otherDetails = false;
      this.warehouseQuestionnaire.controls['otherDetails'].clearValidators();
      this.warehouseQuestionnaire.controls['otherDetails'].updateValueAndValidity();
    }
  }

  inboundOtherDetails = false;
  inboundOtherDetail(event: any) {
    if (event.checked == true) {
      this.inboundOtherDetails = true;
      this.warehouseQuestionnaire.controls['inboundOtherDetails'].setValidators(Validators.required);
    } else {
      this.inboundOtherDetails = false;
      this.warehouseQuestionnaire.controls['inboundOtherDetails'].clearValidators();
      this.warehouseQuestionnaire.controls['inboundOtherDetails'].updateValueAndValidity();
    }
  }

  peakSeasonDetails = false;
  peakSeason(event: any) {
    if (event.value === 'Yes') {
      this.peakSeasonDetails = true;
      this.warehouseQuestionnaire.controls['peakSeasonDetails'].setValidators(Validators.required);
    } else {
      this.peakSeasonDetails = false;
      this.warehouseQuestionnaire.controls['peakSeasonDetails'].clearValidators();
      this.warehouseQuestionnaire.controls['peakSeasonDetails'].updateValueAndValidity();
    }
  }

  handlingDetails = false;
  sortingDetails(event: any) {
    if (event.value === 'Yes') {
      this.handlingDetails = true;
      this.warehouseQuestionnaire.controls['sortingAdditionalDetails'].setValidators(Validators.required);
    } else {
      this.handlingDetails = false;
      this.warehouseQuestionnaire.controls['sortingAdditionalDetails'].clearValidators();
      this.warehouseQuestionnaire.controls['sortingAdditionalDetails'].updateValueAndValidity();
    }
  }

  returnHandlingDetails = false;
  kindHandlingDetails(event: any) {
    if (event.value === 'Yes') {
      this.returnHandlingDetails = true;
      this.warehouseQuestionnaire.controls['handlesortingAdditionalDetails'].setValidators(Validators.required);
    } else {
      this.returnHandlingDetails = false;
      this.warehouseQuestionnaire.controls['handlesortingAdditionalDetails'].clearValidators();
      this.warehouseQuestionnaire.controls['handlesortingAdditionalDetails'].updateValueAndValidity();
    }
  }

  dimensions = false;
  togglePallet(event: any) {
    if (event.value === 'No') {
      this.dimensions = true;
      this.warehouseQuestionnaire.controls['dimensions'].setValidators(Validators.required);
    } else {
      this.dimensions = false;
      this.warehouseQuestionnaire.controls['dimensions'].clearValidators();
      this.warehouseQuestionnaire.controls['dimensions'].updateValueAndValidity();
    }
  }

  palletHeight = false;
  stackablePallets(event: any) {
    if (event.value === 'Yes') {
      this.palletHeight = true;
      this.warehouseQuestionnaire.controls['maxHeight'].setValidators(Validators.required);
    } else {
      this.palletHeight = false;
      this.warehouseQuestionnaire.controls['maxHeight'].clearValidators();
      this.warehouseQuestionnaire.controls['maxHeight'].updateValueAndValidity();
    }
  }

  storageRequired = false;
  typeOfStorage(event: any) {
    if (event.checked == true) {
      this.storageRequired = true;
      this.warehouseQuestionnaire.controls['storageAdditionalDetails'].setValidators(Validators.required);
    } else {
      this.storageRequired = false;
      this.warehouseQuestionnaire.controls['storageAdditionalDetails'].clearValidators();
      this.warehouseQuestionnaire.controls['storageAdditionalDetails'].updateValueAndValidity();
    }
  }

  peakSeasonInventory = false;
  InventoryPeak(event: any) {
    if (event.value === 'Yes') {
      this.peakSeasonInventory = true;
      this.warehouseQuestionnaire.controls['inventoryPeakSeason'].setValidators(Validators.required);
    } else {
      this.peakSeasonInventory = false;
      this.warehouseQuestionnaire.controls['inventoryPeakSeason'].clearValidators();
      this.warehouseQuestionnaire.controls['inventoryPeakSeason'].updateValueAndValidity();
    }
  }

  certificationDetails = false;
  requirementCertification(event: any) {
    if (event.checked == true) {
      this.certificationDetails = true;
      this.warehouseQuestionnaire.controls['certificationAdditionalDetails'].setValidators(Validators.required);
    } else {
      this.certificationDetails = false;
      this.warehouseQuestionnaire.controls['certificationAdditionalDetails'].clearValidators();
      this.warehouseQuestionnaire.controls['certificationAdditionalDetails'].updateValueAndValidity();
    }
  }

  leavingFacility = false;
  otherFacility(event: any) {
    if (event.checked == true) {
      this.leavingFacility = true;
      this.warehouseQuestionnaire.controls['facilityAdditionalDetails'].setValidators(Validators.required);
    } else {
      this.leavingFacility = false;
      this.warehouseQuestionnaire.controls['facilityAdditionalDetails'].clearValidators();
      this.warehouseQuestionnaire.controls['facilityAdditionalDetails'].updateValueAndValidity();
    }
  }

  outboundPeak = false;
  outboundPeakSeason(event: any) {
    if (event.value === 'Yes') {
      this.outboundPeak = true;
      this.warehouseQuestionnaire.controls['outboundPeak'].setValidators(Validators.required);
    } else {
      this.outboundPeak = false;
      this.warehouseQuestionnaire.controls['outboundPeak'].clearValidators();
      this.warehouseQuestionnaire.controls['outboundPeak'].updateValueAndValidity();
    }
  }

  palletOrder = false;
  caseOrder = false;
  pieceOrder = false;

  pickingType(event: any) {
    if (event.value === 'Pallet') {
      this.palletOrder = true;
      this.caseOrder = false;
      this.pieceOrder = false;
      this.warehouseQuestionnaire.controls['perOrderAvg'].setValidators(Validators.required);
      this.warehouseQuestionnaire.controls['caseOrderAvg'].clearValidators();
      this.warehouseQuestionnaire.controls['caseOrderAvg'].updateValueAndValidity();
      this.warehouseQuestionnaire.controls['pieceOrderAvg'].clearValidators();
      this.warehouseQuestionnaire.controls['pieceOrderAvg'].updateValueAndValidity();
    } else if (event.value === 'Case/Carton') {
      this.palletOrder = false;
      this.caseOrder = true;
      this.pieceOrder = false;
      this.warehouseQuestionnaire.controls['caseOrderAvg'].setValidators(Validators.required);
      this.warehouseQuestionnaire.controls['perOrderAvg'].clearValidators();
      this.warehouseQuestionnaire.controls['perOrderAvg'].updateValueAndValidity();
      this.warehouseQuestionnaire.controls['pieceOrderAvg'].clearValidators();
      this.warehouseQuestionnaire.controls['pieceOrderAvg'].updateValueAndValidity();
    } else if (event.value === 'Each/Pieces') {
      this.palletOrder = false;
      this.caseOrder = false;
      this.pieceOrder = true;
      this.warehouseQuestionnaire.controls['pieceOrderAvg'].setValidators(Validators.required);
      this.warehouseQuestionnaire.controls['perOrderAvg'].clearValidators();
      this.warehouseQuestionnaire.controls['perOrderAvg'].updateValueAndValidity();
      this.warehouseQuestionnaire.controls['caseOrderAvg'].clearValidators();
      this.warehouseQuestionnaire.controls['caseOrderAvg'].updateValueAndValidity();
    }
  }

  inbound = false;
  inboundTransport(event: any) {
    if (event.value === 'Yes') {
      this.inbound = true;
      this.warehouseQuestionnaire.controls['outboundPeakSeasonTransportInboundReason'].setValidators(Validators.required);
      this.warehouseQuestionnaire.controls['inboundZip'].setValidators(Validators.required);
    } else {
      this.inbound = false;
      this.warehouseQuestionnaire.controls['outboundPeakSeasonTransportInboundReason'].clearValidators();
      this.warehouseQuestionnaire.controls['outboundPeakSeasonTransportInboundReason'].updateValueAndValidity();
      this.warehouseQuestionnaire.controls['inboundZip'].clearValidators();
      this.warehouseQuestionnaire.controls['inboundZip'].updateValueAndValidity();
    }
  }

  outbound = false;
  outboundTransport(event: any) {
    if (event.value === 'Yes') {
      this.outbound = true;
      this.warehouseQuestionnaire.controls['outboundPeakSeasonTransportOutboundReason'].setValidators(Validators.required);
      this.warehouseQuestionnaire.controls['OutboundZip'].setValidators(Validators.required);
    } else {
      this.outbound = false;
      this.warehouseQuestionnaire.controls['outboundPeakSeasonTransportOutboundReason'].clearValidators();
      this.warehouseQuestionnaire.controls['outboundPeakSeasonTransportOutboundReason'].updateValueAndValidity();
      this.warehouseQuestionnaire.controls['OutboundZip'].clearValidators();
      this.warehouseQuestionnaire.controls['OutboundZip'].updateValueAndValidity();
    }
  }


  international = this.fb.group({
    yourCompany: ['', [Validators.required]],
    yourName: ['', [Validators.required]],
    yourEmail: ['', [Validators.required, Validators.pattern('^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,4}$')]],
    yourPhone: ['', [Validators.required]],
    yourAddress: ['', [Validators.required]],
    yourCity: ['', [Validators.required]],
    yourState: ['', [Validators.required]],
    yourZip: ['', [Validators.required]],
    // cargoType: ['', [Validators.required]],
    cargoTypeFB: this.fb.group({
      air: false,
      ocean: false,
    }, { validators: this.cargoTypeCheck }),
    loadType: ['', [Validators.required]],
    containerSizeFB: this.fb.group({
      twentyContainer: false,
      fortyContainerStd: false,
      fortyContainerHigh: false,
    }),
    description: ['', [Validators.required]],
    originName: ['', [Validators.required]],
    destinationName: ['', [Validators.required]],
    pieceCount: ['', [Validators.required]],
    weight: ['', [Validators.required]],
    dimensions: ['', [Validators.required]],
    hazmatType: ['', [Validators.required]],
    value: ['', [Validators.required]],
    incoterms: ['', [Validators.required]],
    handling: ['', [Validators.required]],

    replyBy1: [''],
    replyBy3: [''],

    saiaAccountNumber: [''],
    // saiaSalesRep: [''],
    // saiaSalesRepEmail: ['']
  })

  cargoTypeCheck(group: FormGroup) {
    const checkboxes = [
      'air',
      'ocean',
    ];
    const checkedBoxes = checkboxes.map(controlName => group.controls[controlName].value);
    const atLeastOneChecked = checkedBoxes.some(Boolean);
    return atLeastOneChecked ? null : { required: true };
  }

  fclQuestion = false;
  fclQuestionFn(event: any) {
    if (event.value === 'FCL') {
      this.fclQuestion = true;
      this.international.controls['containerSizeFB'].setValidators(this.specifyContainerSizeCheck);
    } else {
      this.fclQuestion = false;
      this.international.controls['containerSizeFB'].clearValidators();
      this.international.controls['containerSizeFB'].updateValueAndValidity();
    }
  }

  specifyContainerSizeCheck(control: AbstractControl): ValidationErrors | null {
    const checkboxes = [
      'twentyContainer',
      'fortyContainerStd',
      'fortyContainerHigh'
    ];
    const checkedBoxes = checkboxes.map(controlName => control.get(controlName)?.value);
    const atLeastOneChecked = checkedBoxes.some(Boolean);
    return atLeastOneChecked ? null : { required: true };
  }

  transloading = this.fb.group({
    yourCompany: ['', [Validators.required]],
    yourName: ['', [Validators.required]],
    yourEmail: ['', [Validators.required, Validators.pattern('^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,4}$')]],
    yourPhone: ['', [Validators.required]],
    companyStreetAdd: ['', [Validators.required]],
    city: ['', [Validators.required]],
    state: ['', [Validators.required]],
    zip: ['', [Validators.required]],
    description: ['', [Validators.required]],
    originPort: ['', [Validators.required]],
    destinationCity: ['', [Validators.required]],
    destinationState: ['', [Validators.required]],
    destinationZip: ['', [Validators.required]],
    containerSizeFB: this.fb.group({
      twenty: false,
      forty: false,
      fortyfive: false,
      fortyeight: false,
      fiftythree: false,
      unsure: false,
    }, { validators: this.containerSizeCheck }),
    containerWeight: ['', [Validators.required]],
    hazmatLoad: ['', [Validators.required]],
    equipmentTypeFB: this.fb.group({
      standard: false,
      highCube: false,
      refrigerated: false,
      openTop: false,
      flatRack: false,
      outOfGauge: false,
      vanTrailer: false,
      bonded: false,
    }, { validators: this.equipmentTypeCheck }),
    dimension: [''],
    DTspecialInstruction: ['', [Validators.required]],
    cargoReadyDate: ['', [Validators.required]],
    replyBy1: [''],
    replyBy3: [''],
    saiaAccountNumber: [''],
    // saiaSalesRep: [''],
    // saiaSalesRepEmail: ['']

  });

  outOfGauge = false;
  outOfGaugeFn(event: any) {
    if (event.checked == true) {
      this.outOfGauge = true;
      this.transloading.controls['dimension'].setValidators(Validators.required);
    } else {
      this.outOfGauge = false;
      this.transloading.controls['dimension'].clearValidators();
      this.transloading.controls['dimension'].updateValueAndValidity();
    }
  }

  equipmentTypeCheck(group: FormGroup) {
    const checkboxes = [
      'standard',
      'highCube',
      'refrigerated',
      'openTop',
      'flatRack',
      'outOfGauge',
      'vanTrailer',
      'bonded',
    ];
    const checkedBoxes = checkboxes.map(controlName => group.controls[controlName].value);
    const atLeastOneChecked = checkedBoxes.some(Boolean);
    return atLeastOneChecked ? null : { required: true };
  }

  containerSizeCheck(group: FormGroup) {
    const checkboxes = [
      'twenty',
      'forty',
      'fortyfive',
      'fortyeight',
      'fiftythree',
      'unsure',
    ];
    const checkedBoxes = checkboxes.map(controlName => group.controls[controlName].value);
    const atLeastOneChecked = checkedBoxes.some(Boolean);
    return atLeastOneChecked ? null : { required: true };
  }

  submitTruckLoadQuotes(): any {
    this.isFormSubmittedTruckload = true;
    if (this.truckload.invalid) {
      window.scrollTo(0, 0);
      this.truckLoadStatusError = true;
    } else {
      window.scrollTo(0, 0);
      this.truckLoadStatusError = false;
      // this.truckLoadStatus = true;
    }
    if (this.truckload.status != 'VALID') return;
    if (this.isValidSaiaAccount == 'no') {
      this.truckload.controls['saiaAccountNumber'].setValue('');
    }
    this.isValidSaiaAccount = null;
    const formValues = this.truckload.value;
    const replyBy = [];
    if (formValues.replyBy1) replyBy.push('Phone');
    if (formValues.replyBy3) replyBy.push('Email');

    const accessorial = [];
    if (formValues.accessorialServicesFb?.insideDelivery) accessorial.push('Inside Delivery');
    if (formValues.accessorialServicesFb?.insidePickup) accessorial.push('Inside Pickup');
    if (formValues.accessorialServicesFb?.liftgateDelivery) accessorial.push('Liftgate Delivery');
    if (formValues.accessorialServicesFb?.liftgatePickup) accessorial.push('Liftgate Pickup');
    if (formValues.accessorialServicesFb?.limitedAccessOnDelivery) accessorial.push('Limited Access On Delivery');
    if (formValues.accessorialServicesFb?.limitedAccessOnPickup) accessorial.push('Limited Access On Pickup');
    if (formValues.accessorialServicesFb?.residentialDelivery) accessorial.push('Residential Delivery');
    if (formValues.accessorialServicesFb?.residentialPickup) accessorial.push('Residential Pickup');
    if (formValues.accessorialServicesFb?.sortAtDestination) accessorial.push('Sort/Segregate At Destination');
    if (formValues.accessorialServicesFb?.sortAtOrigin) accessorial.push('Sort/Segregate At Origin');
    if (formValues.accessorialServicesFb?.hazardousMaterials) accessorial.push('Hazardous Materials');
    if (formValues.accessorialServicesFb?.tankerEndorsement) accessorial.push('Tanker Endorsement');

    let selectedDate = new Date(formValues['pickDate'] ?? '');
    let currentTime = new Date();
    selectedDate.setHours(currentTime.getHours());
    selectedDate.setMinutes(currentTime.getMinutes());

    let currentOffset = currentTime.getTimezoneOffset();
    let targetOffset = selectedDate.getTimezoneOffset();
    let offsetDiff = currentOffset - targetOffset;
    selectedDate = new Date(selectedDate.getTime() + (offsetDiff * 60 * 1000));

    let datePipe = new DatePipe('en-US');
    let formattedDate = datePipe.transform(selectedDate, 'MM/dd/yyyy hh:mm a z');

    let payload = {
      yourCompany: formValues.yourCompany,
      yourName: formValues.yourName,
      yourPhone: formValues.yourPhone,
      yourEmail: formValues.yourEmail,
      shipmentDetails: {
        shippingDate: formattedDate,
        originCity: formValues.originCity,
        originState: formValues.originState,
        originZip: formValues.originZip,
        destinationCity: formValues.destinationCity,
        destinationState: formValues.destinationState,
        destinationZip: formValues.destinationZip,
      },
      freightInformation: {
        weight: `${this.numberWithCommas(formValues.weight?.replace(/,/g, '') as string)} lbs`,
        isHazmat: formValues.hazmat
      },
      accessorials: accessorial,
      replyBy: replyBy,
      saiaAccountNumber: formValues.saiaAccountNumber,
    }
    this.loader = true;
    this.truckLoadStatus = false;
    this.isFormSubmittedTruckloadApiError = false;
    this.service.submitTruckloadRateQuotes(payload).subscribe((result: any) => {
      this.loader = false;
      this.apiResponse = result;
      if (result.status === 'mail_sent') {
        this.truckLoadStatus = true;
        this.isFormSubmittedTruckload = false;
        this.truckload.reset();
        this.ngTruckload.resetForm();
        this.otherAdditionalDetails = false;
        this.addGA4Event({ 'event': 'submit-truckload-success', errorMessage: 'this is test' })
        // this.truckload.controls['paymentType'].patchValue('Third Party');
      } else {
        this.isFormSubmittedTruckloadApiError = true;
        this.addGA4Event({ 'event': 'submit-truckload-error', errorMessage: JSON.stringify(result) })
      }
    }, error => {
      this.loader = false;
      this.isFormSubmittedTruckloadApiError = true;
      this.addGA4Event({ 'event': 'submit-truckload-error', errorMessage: JSON.stringify(error) })
      this.apiResponse = {
        message: 'Error in submitting your request, Please Call LinkEx 972-481-9900.'
      }
    })
  }
  private numberWithCommas(x: string) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  submitExpeditedLoadQuotes(): any {
    this.isFormSubmittedExpeditedload = true;
    if (this.expedited.invalid) {
      window.scrollTo(0, 0);
      this.expeditedLoadStatusError = true;
    } else {
      window.scrollTo(0, 0);
      this.expeditedLoadStatusError = false;
      // this.truckLoadStatus = true;
    }
    if (this.expedited.status != 'VALID') return;
    const formValues = this.expedited.value;
    const accessorialService = [];
    const accessorialServicesFb = formValues.accessorialServicesFb;
    if (accessorialServicesFb?.insideDelivery) accessorialService.push('Inside Delivery');
    if (accessorialServicesFb?.insidePickup) accessorialService.push('Inside Pickup');
    if (accessorialServicesFb?.liftgateDelivery) accessorialService.push('Liftgate Delivery');
    if (accessorialServicesFb?.liftgatePickup) accessorialService.push('Liftgate Pickup');
    if (accessorialServicesFb?.limitedAccessOnDelivery) accessorialService.push('Limited Access On Delivery');
    if (accessorialServicesFb?.limitedAccessOnPickup) accessorialService.push('Limited Access On Pickup');
    if (accessorialServicesFb?.residentialDelivery) accessorialService.push('Residential Delivery');
    if (accessorialServicesFb?.residentialPickup) accessorialService.push('Residential Pickup');
    if (accessorialServicesFb?.sortAtDestination) accessorialService.push('Sort/Segregate At Destination');
    if (accessorialServicesFb?.sortAtOrigin) accessorialService.push('Sort/Segregate At Origin');
    if (accessorialServicesFb?.hazardousMaterials) accessorialService.push('Hazardous Materials');
    if (accessorialServicesFb?.tankerEndorsement) accessorialService.push('Tanker Endorsement');
    if (accessorialServicesFb?.whiteGloveService) accessorialService.push('White Glove Service');
    const replyBy = [];
    if (formValues.replyBy1) replyBy.push('Phone');
    if (formValues.replyBy3) replyBy.push('Email');

    //shippingDate
    // let selectedDate = new Date(formValues['shipDate'] ?? '');

    let selectedDate: Date | null = formValues['shipDate'] ? new Date(formValues['shipDate']) : null;
    let formattedDate: string | null = null;

    if (selectedDate) {
      let currentTime = new Date();
      selectedDate.setHours(currentTime.getHours());
      selectedDate.setMinutes(currentTime.getMinutes());

      let currentOffset = currentTime.getTimezoneOffset();
      let targetOffset = selectedDate.getTimezoneOffset();
      let offsetDiff = currentOffset - targetOffset;
      selectedDate = new Date(selectedDate.getTime() + (offsetDiff * 60 * 1000));

      // Now you can safely use the modified selectedDate
      let formattedDate = this.datePipe.transform(selectedDate, 'MM/dd/yyyy hh:mm a z');
      console.log('Formatted Date:', formattedDate);

      // Continue with other logic using formattedDate
    } else {
      // Handle the case where selectedDate is null
      console.error('No date selected.');
    }


    //expectedDeliveryDate
    // let expectedDate = new Date(formValues['deliveryDate'] ?? '');

    let expectedDate: Date | null = formValues['deliveryDate'] ? new Date(formValues['deliveryDate']) : null;
    let exFormattedDate: string | null = null;

    if (expectedDate) {
      let expectedTime = new Date();
      expectedDate.setHours(expectedTime.getHours());
      expectedDate.setMinutes(expectedTime.getMinutes());

      let expectedOffset = expectedTime.getTimezoneOffset();
      let exTargetOffset = expectedDate.getTimezoneOffset();
      let exOffsetDiff = expectedOffset - exTargetOffset;
      expectedDate = new Date(expectedDate.getTime() + (exOffsetDiff * 60 * 1000));

      let exDatePipe = new DatePipe('en-US');
      let exFormattedDate = exDatePipe.transform(expectedDate, 'MM/dd/yyyy hh:mm a z');
      console.log('Formatted Date:', exFormattedDate);

      // Continue with other logic using formattedDate
    } else {
      // Handle the case where selectedDate is null
      console.error('No date selected.');
    }

    let payload = {
      yourCompany: formValues.yourCompany,
      yourName: formValues.yourName,
      yourPhone: formValues.yourPhone,
      yourEmail: formValues.yourEmail,
      shipmentDetails: {
        shippingDate: formattedDate,
        originAddress: formValues.originAddress,
        originCity: formValues.originCity,
        originState: formValues.originState,
        originZip: formValues.originZip,
        expectedDeliveryDate: exFormattedDate,
        destinationAddress: formValues.destinationAddress,
        destinationCity: formValues.destinationCity,
        destinationState: formValues.destinationState,
        destinationZip: formValues.destinationZip,
        pieceCount: formValues.pieceCount,
        huTypes: formValues.hutype,
        weight: formValues.weight,
        dimensions: formValues.dimensions,
      },
      commodityDesc: formValues.commodity,
      hazmat: formValues.hazmatType,
      hazmatUn: formValues.hazmatUn,
      billToAccount: formValues.billAccount,
      saiaPro: formValues.saiaPro,
      equipmentType: formValues.equipmentType,
      equipmentTypeText: formValues.addDetails,
      accessorialService: accessorialService,
      replyBy: replyBy,
      saiaAccountNumber: formValues.saiaAccountNumber,
      // saiaSalesRep: formValues.saiaSalesRep,
      // saiaSalesRepEmail: formValues.saiaSalesRepEmail
    }
    this.loader = true;
    this.expeditedLoadStatus = false;
    this.isFormSubmittedExpeditedApiError = false;
    this.service.submitExpeditedRateQuotes(payload).subscribe((result: any) => {
      this.loader = false;
      this.apiResponse = result;
      if (result.status === 'mail_sent') {
        this.expeditedLoadStatus = true;
        this.isFormSubmittedExpeditedload = false;
        this.expedited.reset();
        this.ngExpedited.resetForm();
        this.hazmatUn = false;
        this.additionalDetailsType = false;
        this.addGA4Event({ 'event': 'submit-expedited-success' })
      } else {
        this.isFormSubmittedExpeditedApiError = true;
        this.addGA4Event({ 'event': 'submit-expedited-error', errorMessage: JSON.stringify(result) })
      }
    }, error => {
      this.loader = false;
      this.isFormSubmittedExpeditedApiError = true;
      this.addGA4Event({ 'event': 'submit-expedited-error', errorMessage: JSON.stringify(error) })
      this.apiResponse = {
        message: 'Error in submitting your request, Please Call LinkEx 972-481-9900.'
      }
    })
  }
  submitLtlCartageQuotes(): any {
    this.isFormSubmittedLtlcartage = true;
    if (this.finalCartage.invalid) {
      window.scrollTo(0, 0);
      this.ltlCatageStatusError = true;
    } else {
      window.scrollTo(0, 0);
      this.ltlCatageStatusError = false;
      // this.ltlCatageStatus = true;
    }
    if (this.finalCartage.status != 'VALID') return;

    let formValues = this.finalCartage.value;
    const pickupServces = [], deliveryServices = [], whiteGloveService = [];
    const { pickupServiceFb, deliveryServiceFb, whiteGloveServiceFb } = formValues
    if (pickupServiceFb?.appointmentPickupPS) pickupServces.push('Appointment Pickup');
    if (pickupServiceFb?.callBeforePickupPS) pickupServces.push('Call Before Pickup');
    if (pickupServiceFb?.liftgatePickupPS) pickupServces.push('Liftgate Pickup');
    if (pickupServiceFb?.limitedAccessPickupPS) pickupServces.push('Limited Access Pickup');
    if (pickupServiceFb?.residentialPickupPS) pickupServces.push('Residential Pickup');

    if (deliveryServiceFb?.appointmentDeliveryDS) deliveryServices.push('Appointment Delivery');
    if (deliveryServiceFb?.callBeforeDeliveryDS) deliveryServices.push('Call Before Delivery');
    if (deliveryServiceFb?.liftgateDeliveryDS) deliveryServices.push('Liftgate Delivery');
    if (deliveryServiceFb?.limitedAccessDeliveryDS) deliveryServices.push('Limited Access Delivery');
    if (deliveryServiceFb?.residentialDeliveryDS) deliveryServices.push('Residential Delivery');
    if (deliveryServiceFb?.unattendedDeliveryDS) deliveryServices.push('Unattended Delivery');
    if (deliveryServiceFb?.curbsideDeliveryDS) deliveryServices.push('Curbside Delivery');
    if (deliveryServiceFb?.thresholdGarageDeliveryDS) deliveryServices.push('Threshold/Garage Delivery');

    if (whiteGloveServiceFb?.additionalLaborWS) whiteGloveService.push('Additional Labor');
    if (whiteGloveServiceFb?.debrisRemovalWS) whiteGloveService.push('Debris Removal');
    if (whiteGloveServiceFb?.insidePickupDeliveryWS) whiteGloveService.push('Inside Pickup Delivery');
    if (whiteGloveServiceFb?.lightAssemblyWS) whiteGloveService.push('Light Assembly');
    if (whiteGloveServiceFb?.roomOfChoiceWS) whiteGloveService.push('Room Of Choice');
    if (whiteGloveServiceFb?.flightOfStairsWS) whiteGloveService.push('Flight(s) Of Stairs');
    if (whiteGloveServiceFb?.unpackagingWS) whiteGloveService.push('Unpackaging');
    if (whiteGloveServiceFb?.inRoomPlacementWS) whiteGloveService.push('In Room Placement');
    if (whiteGloveServiceFb?.hookupWS) whiteGloveService.push('Hookup');
    if (whiteGloveServiceFb?.haulAwayWS) whiteGloveService.push('Haul Away');
    if (whiteGloveServiceFb?.disposalReturnsWS) whiteGloveService.push('Disposal/Returns');
    if (whiteGloveServiceFb?.repairsWS) whiteGloveService.push('Repairs');
    const replyBy = [];
    if (formValues.warehouseQuesPhoneFM) replyBy.push("Phone");
    if (formValues.warehouseQuesEmailFM) replyBy.push("Email");

    //requestedPickupDate
    let selectedDate = new Date(formValues['RequestPickupDate'] ?? '');
    let currentTime = new Date();
    selectedDate.setHours(currentTime.getHours());
    selectedDate.setMinutes(currentTime.getMinutes());

    let currentOffset = currentTime.getTimezoneOffset();
    let targetOffset = selectedDate.getTimezoneOffset();
    let offsetDiff = currentOffset - targetOffset;
    selectedDate = new Date(selectedDate.getTime() + (offsetDiff * 60 * 1000));

    let datePipe = new DatePipe('en-US');
    let formattedDate = datePipe.transform(selectedDate, 'MM/dd/yyyy hh:mm a z');

    //requestedDeliveryDate
    let expectedDate = new Date(formValues['requestDeliveryDate'] ?? '');
    let expectedTime = new Date();
    expectedDate.setHours(expectedTime.getHours());
    expectedDate.setMinutes(expectedTime.getMinutes());

    let expectedOffset = expectedTime.getTimezoneOffset();
    let exTargetOffset = expectedDate.getTimezoneOffset();
    let exOffsetDiff = expectedOffset - exTargetOffset;
    expectedDate = new Date(expectedDate.getTime() + (exOffsetDiff * 60 * 1000));

    let exDatePipe = new DatePipe('en-US');
    let exFormattedDate = exDatePipe.transform(expectedDate, 'MM/dd/yyyy hh:mm a z');

    let payload = {
      yourCompany: formValues.companyName,
      yourName: formValues.contactName,
      yourPhone: formValues.yourPhone,
      yourEmail: formValues.custEmail,
      shipmentDetails: {
        originZip: formValues.orginZip,
        destinationZip: formValues.destZip,
        requestedPickupDate: formattedDate,
        requestedDeliveryDate: exFormattedDate,
        shipmentItems: formValues.shippingDetails
      },
      finalMileService: {
        pickupServices: pickupServces,
        deliveryServices: deliveryServices,
        whiteGloveService: whiteGloveService
      },
      additionalComments: formValues.additionalSpecialInstructions,
      replyBy: replyBy,
      saiaAccountNumber: formValues.saiaAccountNumberFM,
      // saiaSalesRep: formValues.saiaSalesRepFM,
      // saiaSalesRepEmail: formValues.saiaSalesRepEmail
    };

    this.loader = true;
    this.ltlCatageStatus = false;
    this.isFormSubmittedLtlcartageApiError = false;
    this.service.submitFinalMileCartageRateQuotes(payload).subscribe((response: any) => {
      this.loader = false;
      this.apiResponse = response;
      if (response.status === 'mail_sent') {
        this.ltlCatageStatus = true;
        this.isFormSubmittedLtlcartage = false;
        this.finalCartage.reset();
        this.ngFinalCartage.resetForm();
        this.addGA4Event({ 'event': 'submit-finalmilecartage-success' })
      } else {
        this.isFormSubmittedLtlcartageApiError = true;
        this.addGA4Event({ 'event': 'submit-finalmilecartage-error', errorMessage: JSON.stringify(response) })
      }
    }, (error) => {
      this.loader = false;
      this.isFormSubmittedLtlcartageApiError = true;
      this.addGA4Event({ 'event': 'submit-finalmilecartage-error', errorMessage: JSON.stringify(error) })
      this.apiResponse = {
        message: 'Error in submitting your request, Please Call LinkEx 972-481-9900.'
      }
    });
  }
  submitWarehousingQuotes(): any {
    this.isFormSubmittedWarehousing = true;
    if (this.warehouseQuestionnaire.invalid) {
      window.scrollTo(0, 0);
      //console.log(this.warehouseQuestionnaire.controls['packaged'].value);
      this.warehousingStatusError = true;
    } else {
      window.scrollTo(0, 0);
      this.warehousingStatusError = false;
      // this.warehousingStatus = true;
    }
    if (this.warehouseQuestionnaire.status != 'VALID') return;

    let formValue = this.warehouseQuestionnaire.value
    const { inboundProductFB, productIs, inventoryRequiredFB, requimentCertificateFB, obLeavingFacilityFB, packagedFB } = formValue;
    let productIsArray: string[] = [];

    if (productIs.productPalletized) productIsArray.push("Palletized");
    if (productIs.productFloorLoaded) productIsArray.push("Floor-loaded");

    let storageRequiredArray: string[] = [];

    if (inventoryRequiredFB.storageRequiredFloor) storageRequiredArray.push("Floor");
    if (inventoryRequiredFB.storageRequiredRacking) storageRequiredArray.push("Racking");
    if (inventoryRequiredFB.storageRequiredOther) storageRequiredArray.push("Other");

    let requirementCertificationArray: string[] = [];

    if (requimentCertificateFB.rcClimateControl) requirementCertificationArray.push("Climate Control");
    if (requimentCertificateFB.rcHazmat) requirementCertificationArray.push("Hazmat");
    if (requimentCertificateFB.rcFoodGrade) requirementCertificationArray.push("Food Grade");
    if (requimentCertificateFB.rcOther) requirementCertificationArray.push("Other");

    let leavingFacilityArray: string[] = [];

    if (obLeavingFacilityFB.leavingFacilityContainer) leavingFacilityArray.push("Container");
    if (obLeavingFacilityFB.leavingFacilityFTL) leavingFacilityArray.push("FTL");
    if (obLeavingFacilityFB.leavingFacilityLTL) leavingFacilityArray.push("LTL");
    if (obLeavingFacilityFB.leavingFacilityParcel) leavingFacilityArray.push("Parcel");
    if (obLeavingFacilityFB.leavingFacilityOther) leavingFacilityArray.push("Other");

    let packagedTypesArray: string[] = [];

    if (packagedFB.packagedCase) packagedTypesArray.push("Case");
    if (packagedFB.packagedDrum) packagedTypesArray.push("Drum");
    if (packagedFB.packagedTote) packagedTypesArray.push("Tote");
    if (packagedFB.packagedCrate) packagedTypesArray.push("Crate");
    if (packagedFB.packagedPallet) packagedTypesArray.push("Pallet");
    if (packagedFB.packagedOther) packagedTypesArray.push("Other");

    let receivingProductArray: string[] = [];

    if (inboundProductFB.receivingContainer) receivingProductArray.push("Container");
    if (inboundProductFB.receivingFTL) receivingProductArray.push("FTL");
    if (inboundProductFB.receivingLTL) receivingProductArray.push("LTL");
    if (inboundProductFB.receivingParcel) receivingProductArray.push("Parcel");
    if (inboundProductFB.receivingOther) receivingProductArray.push("Other");

    let replyBy = [];
    if (formValue.warehouseQuesPhone) {
      replyBy.push('Phone')
    } else if (formValue.warehouseQuesEmail) {
      replyBy.push('Email')
    } else if (formValue.warehouseQuesFax) {
      replyBy.push('Fax')
    }

    //receiveProductDate
    let selectedDate = new Date(formValue['productDetails'] ?? '');
    let currentTime = new Date();
    selectedDate.setHours(currentTime.getHours());
    selectedDate.setMinutes(currentTime.getMinutes());

    let currentOffset = currentTime.getTimezoneOffset();
    let targetOffset = selectedDate.getTimezoneOffset();
    let offsetDiff = currentOffset - targetOffset;
    selectedDate = new Date(selectedDate.getTime() + (offsetDiff * 60 * 1000));

    let datePipe = new DatePipe('en-US');
    let formattedDate = datePipe.transform(selectedDate, 'MM/dd/yyyy hh:mm a z');

    let payload = {
      companyName: formValue.yourCompany,
      contactName: formValue.yourName,
      contactNumber: formValue.phoneNumber,
      email: formValue.yourEmail,
      fax: formValue.faxNumber,
      saiaAccountNumber: formValue.saiaAccountNumber,
      // saiaSalesRep: formValue.saiaSalesRep,
      // saiaSalesRepEmail: formValue.saiaSalesRepEmail,
      replyBy: replyBy,
      requestedWarehouseLoaction: {
        city: formValue.city,
        state: formValue.state,
        zip: formValue.zip,
      },
      general: {
        commodity: formValue.commodity,
        packagedTypes: packagedTypesArray,
        packagedTypesText: formValue.packagingType,
        receiveProductDate: formattedDate,
        isOngoingOpportunity: formValue.ongoingOpportunity,
        ongoingOpportunityText: formValue.otherDetails,
        lengthOfTermRequired: formValue.lengthRequired,
      },
      inbound: {
        receiveProductBy: receivingProductArray,
        receiveProductByText: formValue.inboundOtherDetails,
        inboundExpectedPerMonth: formValue.inbound,
        isPeakSeason: formValue.peakSeason,
        peakSeasonText: formValue.peakSeasonDetails,
        product: productIsArray,
        palletsPerInbound: formValue.palletInbound,
        casePerInbound: formValue.caseInbound,
        skuPerInbound: formValue.sku,
        isSortingSpecialHandling: formValue.specialHandling,
        sortingSpecialHandlingText: formValue.sortingAdditionalDetails,
        handleReturnsKind: formValue.handleReturns,
        handleReturnsText: formValue.handlesortingAdditionalDetails,
      },
      inventory: {
        palletsInventoryAverage: formValue.palletAvg,
        maxPalletsPerMonth: formValue.maxPallets,
        isPalletsStandard40x48: formValue.palletsStandard,
        palletsStandard40x48Text: formValue.dimensions,
        isPalletsStackable: formValue.palletsStackable,
        palletsStackableHeight: formValue.maxHeight,
        averageWeightPerPallet: formValue.avgWeight,
        typeOfStorage: storageRequiredArray,
        typeOfStorageText: formValue.storageAdditionalDetails,
        isPeakSeason: formValue.inventoryPeak,
        peakSeasonText: formValue.inventoryPeakSeason,
        averageCasePerPallet: formValue.avgCase,
        expectInventorySitOnAverage: formValue.inventoryAvgSit,
        numberOfTurnsPerYear: formValue.turnsYear,
        diffSkuInInventory: formValue.skuInventory,
        specialRequirementsCertification: requirementCertificationArray,
        specialRequirementsCertificationText:
          formValue.certificationAdditionalDetails,
      },
      outbound: {
        productLeaveBy: leavingFacilityArray,
        productLeaveByText: formValue.facilityAdditionalDetails,
        ordersExpectPerMonth: formValue.orderPerMonth,
        isPeakSeason: formValue.outboundPeakSeason,
        peakSeasonText: formValue.outboundPeak,
        typeOfPickingRequired: [formValue.pickingRequired],
        palletsAverage: formValue.perOrderAvg,
        casesAverage: formValue.caseOrderAvg,
        eachPieceAverage: formValue.pieceOrderAvg,
        linesPerOrderAverage: formValue.linePerOrderAvg,
      },
      transportation: {
        needInboundTransportation: formValue.outboundPeakSeasonTransportInbound,
        inboundTransport: [formValue.outboundPeakSeasonTransportInboundReason],
        inboundOriginZip: formValue.inboundZip,
        inboundLegalOverweight: formValue.legalOverweightInbound,
        needOutboundTransportation: formValue.outboundPeakSeasonTransportOutbound,
        outboundTransport: [formValue.outboundPeakSeasonTransportOutboundReason],
        outboundOriginZip: formValue.OutboundZip,
        outboundLegalOverweight: formValue.legalOverweightOutbound,
      }
    }
    this.loader = true;
    this.warehousingStatus = false;
    this.isFormSubmittedWarehouseApiError = false;
    this.service.submitWarehouseQuestionnaire(payload).subscribe((response: any) => {
      this.loader = false;
      this.apiResponse = response;
      if (response.status === 'mail_sent') {
        this.warehousingStatus = true;
        this.isFormSubmittedWarehousing = false;
        this.warehouseQuestionnaire.reset();
        this.ngWarehousing.resetForm();
        this.otherPackaging = false;
        this.otherDetails = false;
        this.inboundOtherDetails = false;
        this.peakSeasonDetails = false;
        this.returnHandlingDetails = false;
        this.dimensions = false;
        this.palletHeight = false;
        this.storageRequired = false;
        this.peakSeasonInventory = false;
        this.certificationDetails = false;
        this.leavingFacility = false;
        this.outboundPeak = false;
        this.palletOrder = false;
        this.caseOrder = false;
        this.pieceOrder = false;
        this.inbound = false;
        this.outbound = false;
        this.addGA4Event({ 'event': 'submit-warehousequestionnaire-success' })
      } else {
        this.addGA4Event({ 'event': 'submit-warehousequestionnaire-error', errorMessage: JSON.stringify(response) })
        this.isFormSubmittedWarehouseApiError = true;
      }
    }, (error) => {
      this.loader = false;
      this.isFormSubmittedWarehouseApiError = true;
      this.addGA4Event({ 'event': 'submit-warehousequestionnaire-error', errorMessage: JSON.stringify(error) });
      this.apiResponse = {
        message: 'Error in submitting your request, Please Call LinkEx 972-481-9900.'
      };
    });
  }
  submitInternationalQuotes(): any {
    this.isFormSubmittedInternational = true;
    if (this.international.invalid) {
      window.scrollTo(0, 0);
      this.internationalStatusError = true;
    } else {
      window.scrollTo(0, 0);
      this.internationalStatusError = false;
      // this.internationalStatus = true;
    }

    if (this.international.status != 'VALID') return;
    const formValues = this.international.value;
    const { cargoTypeFB, containerSizeFB } = formValues;
    const cargoType = [], containerSize = [];
    if (cargoTypeFB?.air) cargoType.push('Air');
    if (cargoTypeFB?.ocean) cargoType.push('Ocean');
    if (containerSizeFB?.twentyContainer) containerSize.push("20' Container");
    if (containerSizeFB?.fortyContainerStd) containerSize.push("40' Standard Container");
    if (containerSizeFB?.fortyContainerHigh) containerSize.push("40' High Cube Container");
    let replyBy = [];
    if (formValues.replyBy1) {
      replyBy.push('Phone')
    }
    if (formValues.replyBy3) {
      replyBy.push('Email')
    }
    let payload = {
      yourCompany: formValues.yourCompany,
      yourName: formValues.yourName,
      yourEmail: formValues.yourEmail,
      yourPhone: formValues.yourPhone,
      companyStreetAddress: formValues.yourAddress,
      companyCity: formValues.yourCity,
      companyState: formValues.yourState,
      companyZip: formValues.yourZip,
      cargoType: cargoType,
      loadType: formValues.loadType,
      specifyContainerSize: containerSize,
      commodityDescription: formValues.description,
      shipmentDetails: {
        originAddress: formValues.originName,
        destinationAddress: formValues.destinationName,
        shipmentItems: [{
          pieceCount: formValues.pieceCount,
          weight: formValues.weight,
          dimensions: formValues.dimensions,
          hazmat: formValues.hazmatType,
        }],
        cargoInsuranceValue: formValues.value,
      },
      incoterms: formValues.incoterms,
      specialHandlingRequirements: formValues.handling,
      replyBy: replyBy,
      saiaAccountNumber: formValues.saiaAccountNumber,
      // saiaSalesRep: formValues.saiaSalesRep,
      // saiaSalesRepEmail: formValues.saiaSalesRepEmail
    };
    this.loader = true;
    this.internationalStatus = false;
    this.isFormSubmittedInternationalApiError = false;
    this.service.submitInternationalRateQuotes(payload).subscribe((response: any) => {
      this.loader = false;
      this.apiResponse = response;
      if (response.status === 'mail_sent') {
        this.internationalStatus = true;
        this.isFormSubmittedInternational = false;
        this.international.reset();
        this.ngInternational.resetForm();
        this.fclQuestion = false;
        this.addGA4Event({ 'event': 'submit-international-success' });
      } else {
        this.addGA4Event({ 'event': 'submit-international-error', errorMessage: JSON.stringify(response) });
        this.isFormSubmittedInternationalApiError = true;
      }
    }, (error) => {
      this.loader = false;
      this.isFormSubmittedInternationalApiError = true;
      this.addGA4Event({ 'event': 'submit-international-error', errorMessage: JSON.stringify(error) });
      this.apiResponse = {
        message: 'Error in submitting your request, Please Call LinkEx 972-481-9900.'
      }
    });
  }
  submitTransloadingQuotes(event: Event): any {
    this.isFormSubmittedTransloading = true;
    if (this.transloading.invalid) {
      window.scrollTo(0, 0);
      this.transloadingError = true;
    } else {
      window.scrollTo(0, 0);
      this.transloadingError = false;
      // this.transloadingStatus = true;
    }
    if (this.transloading.status != 'VALID') return;

    const formValues = this.transloading.value;
    const { containerSizeFB, equipmentTypeFB } = formValues;
    const containerSize = [], equipmentType = [];
    if (containerSizeFB.twenty) containerSize.push("20'");
    if (containerSizeFB.forty) containerSize.push("40'");
    if (containerSizeFB.fortyfive) containerSize.push("45'");
    if (containerSizeFB.fortyeight) containerSize.push("48'");
    if (containerSizeFB.fiftythree) containerSize.push("53'");
    if (containerSizeFB.unsure) containerSize.push("Unsure");

    if (equipmentTypeFB.standard) equipmentType.push("Standard");
    if (equipmentTypeFB.highCube) equipmentType.push("High Cube");
    if (equipmentTypeFB.refrigerated) equipmentType.push("Refrigerated(Reefer)");
    if (equipmentTypeFB.openTop) equipmentType.push("Open Top");
    if (equipmentTypeFB.flatRack) equipmentType.push("Flat Rack");
    if (equipmentTypeFB.outOfGauge) equipmentType.push("Out Of Gauge");
    if (equipmentTypeFB.vanTrailer) equipmentType.push("Van Trailer");
    if (equipmentTypeFB.bonded) equipmentType.push("Bonded");

    let replyBy = [];
    if (formValues.replyBy1) {
      replyBy.push('Phone')
    }
    if (formValues.replyBy3) {
      replyBy.push('Email')
    }

    //cargoReadyDate
    let selectedDate = new Date(formValues['cargoReadyDate'] ?? '');
    let currentTime = new Date();
    selectedDate.setHours(currentTime.getHours());
    selectedDate.setMinutes(currentTime.getMinutes());

    let currentOffset = currentTime.getTimezoneOffset();
    let targetOffset = selectedDate.getTimezoneOffset();
    let offsetDiff = currentOffset - targetOffset;
    selectedDate = new Date(selectedDate.getTime() + (offsetDiff * 60 * 1000));

    let datePipe = new DatePipe('en-US');
    let formattedDate = datePipe.transform(selectedDate, 'MM/dd/yyyy hh:mm a z');

    let payload = {
      yourCompany: formValues.yourCompany,
      yourName: formValues.yourName,
      yourEmail: formValues.yourEmail,
      yourPhone: formValues.yourPhone,
      companyStreetAddress: formValues.companyStreetAdd,
      companyCity: formValues.city,
      companyState: formValues.state,
      companyZip: formValues.zip,
      commodityDescription: formValues.description,
      originPortRailYard: formValues.originPort,
      destinationCity: formValues.destinationCity,
      destinationState: formValues.destinationState,
      destinationZip: formValues.destinationZip,
      containerSize: containerSize,
      containerWeight: formValues.containerWeight,
      hazmatLoad: formValues.hazmatLoad,
      equipmentType: equipmentType,
      dimensions: formValues.dimension,
      specialInstructions: formValues.DTspecialInstruction,
      cargoReadyDate: formattedDate,
      replyBy: replyBy,
      saiaAccountNumber: formValues.saiaAccountNumber,
      // saiaSalesRep: formValues.saiaSalesRep,
      // saiaSalesRepEmail: formValues.saiaSalesRepEmail
    };
    this.loader = true;
    this.transloadingStatus = false;
    this.isFormSubmittedTransloadingApiError = false;
    this.service.submitDrayageTransloadingRateQuotes(payload).subscribe((response: any) => {
      this.loader = false;
      this.apiResponse = response
      if (response.status === 'mail_sent') {
        this.transloadingStatus = true;
        this.isFormSubmittedTransloading = false;
        this.transloading.reset();
        this.ngTransloading.resetForm();
        this.outOfGauge = false;
        this.addGA4Event({ 'event': 'submit-drayagetransloading-success' });
      } else {
        this.isFormSubmittedTransloadingApiError = true;
        this.addGA4Event({ 'event': 'submit-drayagetransloading-error', errorMessage: JSON.stringify(response) });
      }
    }, (error) => {
      this.loader = false;
      this.isFormSubmittedTransloadingApiError = true;
      this.addGA4Event({ 'event': 'submit-drayagetransloading-error', errorMessage: JSON.stringify(error) });
      this.apiResponse = {
        message: 'Error in submitting your request, Please Call LinkEx 972-481-9900.'
      }
    });
  }
  validateSaiaAccount(): void {
    if (this.truckload.value.saiaAccountNumber) {

      this.isLoading = true;
      this.service.validateSaiaAccount({ accountNumber: this.truckload.value.saiaAccountNumber }).subscribe((result: any) => {
        // isValidSaiaAccount
        if (result.isValidCustomer) {
          this.isValidSaiaAccount = 'yes';
          this.saiaAccountData = result.customerInfo;
          this.cmbtpSaiaAccountData = result.cmbtpCustomerInfo;
          this.showForm = true;
          this.isLoading = false;
          this.captcha = '';
        } else {
          this.isValidSaiaAccount = 'no';
          this.isLoading = false;
          this.showForm = false;
        }
      }, (error) => {
        this.isValidSaiaAccount = 'no';
        this.isLoading = false;
      })
    }
  }

  getLiveRateQuotes(): void {
    this.isFormSubmittedTruckload = true;
    if (this.truckload.invalid) {
      window.scrollTo(0, 0);
      this.truckLoadStatusError = true;
    } else {
      window.scrollTo(0, 0);
      this.truckLoadStatusError = false;
      // this.truckLoadStatus = true;
    }
    localStorage.clear()
    if (this.truckload.status != 'VALID') return;
    const formValues = this.truckload.value;
    const replyBy = [];
    if (formValues.replyBy1) replyBy.push('Phone');
    if (formValues.replyBy3) replyBy.push('Email');
    let selectedDate = new Date(formValues['pickDate'] ?? '');
    let currentTime = new Date();
    selectedDate.setHours(currentTime.getHours());
    selectedDate.setMinutes(currentTime.getMinutes());

    let currentOffset = currentTime.getTimezoneOffset();
    let targetOffset = selectedDate.getTimezoneOffset();
    let offsetDiff = currentOffset - targetOffset;
    selectedDate = new Date(selectedDate.getTime() + (offsetDiff * 60 * 1000));

    let datePipe = new DatePipe('en-US');
    let formattedDate = datePipe.transform(selectedDate, 'MM/dd/yyyy');
    let accessorialsCode = []
    if (formValues.hazmat && formValues.hazmat == 'Yes') {
      accessorialsCode.push({ "code": "HAZ:F" })
    }

    let payload = {
      yourCompany: formValues.yourCompany,
      yourName: formValues.yourName,
      yourPhone: formValues.yourPhone,
      yourEmail: formValues.yourEmail,
      saiaAccountNumber: formValues.saiaAccountNumber,
      saiaAccountData: this.saiaAccountData,
      cmbtpSaiaAccountData: this.cmbtpSaiaAccountData,
      equipment: [
        {
          code: "TV"
        }
      ],
      accessorials: accessorialsCode,
      items: [
        {
          sequence: 1,
          freightClass: 0,
          name: "",
          weight: formValues.weight?.replace(/,/g, ''),
          weightUnits: "lb",
          quantityType: "PLT",
          quantity: 1,
          isHazmat: (formValues.hazmat && formValues.hazmat == 'Yes') ? true : false,
          isStackable: false,
          dimension: {
            length: '0',
            width: '0',
            height: '0',
            units: "in"
          }
        }
      ],
      pickup: {
        date: `${formattedDate} 08:00`,
        location: {
          city: formValues.originCity,
          state: formValues.originState,
          zip: formValues.originZip,
          country: "US"
        }
      },
      dropoff: {
        date: `${formattedDate} 08:00`,
        location: {
          city: formValues.destinationCity,
          state: formValues.destinationState,
          zip: formValues.destinationZip,
          country: "US"
        }
      }
      // reference: [
      //     {
      //         "type": "V",
      //         "value": "12345"
      //     },
      //     {
      //         "type": "C",
      //         "value": "13455"
      //     }
      // ]
    }
    this.loader = true;
    this.isValidSaiaAccount = null;
    this.truckLoadStatus = false;
    this.isFormSubmittedTruckloadApiError = false;
    this.service.submitLiveRateQuotesRequest(payload).subscribe((result: any) => {
      console.log("Live Rate quotes Response", result)
      this.loader = false;
      this.apiResponse = result;
      if (result.statusMessage === 'SUCCESS') {
        if (formValues.hazmat && formValues.hazmat == 'Yes') {
          this.submitTruckLoadQuotes();
        }
        this.truckLoadStatus = true;
        this.isFormSubmittedTruckload = false;
        this.truckload.reset();
        this.ngTruckload.resetForm();
        this.otherAdditionalDetails = false;
        this.addGA4Event({ 'event': 'submit-liveratequotes-success' });
        this.router.navigate(['/live-quote'], {
          state: {
            liveRateQuoteData: JSON.stringify(result),
          },
        });
      } else {
        this.addGA4Event({ 'event': 'submit-liveratequotes-error', errorMessage: JSON.stringify(result) });
        this.submitTruckLoadQuotes();
      }
    }, error => {
      this.loader = false;
      this.isFormSubmittedTruckloadApiError = true;
      this.addGA4Event({ 'event': 'submit-liveratequotes-error', errorMessage: JSON.stringify(error) });
      this.apiResponse = {
        message: 'Error in submitting your request, Please Call LinkEx 972-481-9900.'
      }
    })
  }
  addGA4Event(event: any): void {
    (window as { [key: string]: any })['dataLayer'].push(event);
  }
  onInputChange(event: any): void {
    const input = event.target as HTMLInputElement;
    const inputValue = input.value;
    input.value = inputValue.replace(/[^0-9]/g, '');

    this.showButton = this.inputText.length === 7;

    if (this.isValidSaiaAccount === 'no') {
      this.isValidSaiaAccount = null;
    }
  }

  clearTruckLoad(): any {
    this.truckLoadStatusError = false;
    this.isFormSubmittedTruckload = false;
    this.truckload.reset();
    // this.truckload.controls['paymentType'].patchValue('Third Party');
  }
  clearExpeditedLoad(): any {
    this.expeditedLoadStatusError = false;
    this.isFormSubmittedExpeditedload = false;
    this.expedited.reset();
  }
  clearLTLCartage(): any {
    this.ltlCatageStatusError = false;
    this.isFormSubmittedLtlcartage = false;
    this.finalCartage.reset();
  }
  clearWarehousing(): any {
    this.warehousingStatusError = false;
    this.isFormSubmittedWarehousing = false;
    this.warehouseQuestionnaire.reset();
  }
  clearInternational(): any {
    this.internationalStatusError = false;
    this.isFormSubmittedInternational = false;
    this.international.reset();
  }
  clearDrayage(): any {
    this.transloadingError = false;
    this.isFormSubmittedTransloading = false;
    this.transloading.reset();
  }
  onKeyDown(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  }

  numberOnly(event: any) {
    const input = event.target as HTMLInputElement;
    const inputValue = input.value;
    input.value = inputValue.replace(/[^0-9 ]/g, '');
  }

  commaNumberOnly(event: any) {
    const input = event.target as HTMLInputElement;
    let inputValue = input.value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
    if (inputValue !== '') {
      const parsedValue = parseInt(inputValue);
      if (!isNaN(parsedValue)) {
        inputValue = parsedValue.toLocaleString(); // Format with commas
      }
    }
    input.value = inputValue;
  }

  alphaOnly(event: any) {
    const input = event.target as HTMLInputElement;
    const inputValue = input.value;
    input.value = inputValue.replace(/[^0-9a-zA-Z ]/g, '');
  }
  loadPreviousQuote() {
    if (this.truckload.value.previousQuoteNumber) {
      this.isLoadingPreviousQuote = true;
      this.previousQuoteStatus = "";
      this.previousQuoteMsg = "";
      this.service.getPreviousQuote({ quoteid: this.truckload.value.previousQuoteNumber }).subscribe((result: any) => {
        this.isLoadingPreviousQuote = false;
        if (result.status) {
          if (result.quoteData.soNumber && result.quoteData.tendered) {
            this.previousQuoteStatus = "success";
            this.previousQuoteMsg = `Quote ${this.truckload.value.previousQuoteNumber} has already been accepted and tendered. Please track your SO number by visiting our <a href="track?shipOrder=${result.quoteData.soNumber}">tracking page</a>.`;
          } else if (result.quoteData?.jsonResponse?.quote) {
            let quoteExpirationDate = moment(result.quoteData?.quoteExpirationDate).toDate().getTime()
            if (quoteExpirationDate < new Date().getTime()) {
              this.previousQuoteStatus = "success";
              this.previousQuoteMsg = "The requested quote has expired. Please generate a new quote request.";
            } else {
              let liveQuoteData = {
                ...result.quoteData,
                requestData: result.quoteData.inputJson,
                quote: result.quoteData?.jsonResponse?.quote,
                dbDocumentId: result.quoteData.id,
                quoteDbItem: result.quoteData,
                userData: {
                  companyName: result.quoteData.inputJson.yourCompany,
                  contactName: result.quoteData.inputJson.yourName,
                  phone: result.quoteData.inputJson.yourPhone,
                  email: result.quoteData.inputJson.yourEmail,
                },
                statusMessage: 'SUCCESS'
              }
              localStorage.clear()
              this.router.navigate(['/live-quote'], {
                state: {
                  liveRateQuoteData: JSON.stringify(liveQuoteData),
                },
              });
            }
          }
        } else {
          this.previousQuoteStatus = "fail";
          this.previousQuoteMsg = "Quote not found. Please try again or create a new quote below.";
        }
      }, (err) => {
        this.isLoadingPreviousQuote = false;
      })
    }
  };
  saiaAccountLengthValidate(event: any) {
    this.showForm = false;
    if (event.target.value.length < 7) {
      this.isValidSaiaAccount = null;
      this.showForm = false;
      // this.showCaptchaError = true;
    }
    // else if (!this.captcha) {
    //   // If the captcha has not been resolved, show the captcha error
    //   this.showCaptchaError = true;
    // }
  };
  onAccessorialChange(event: any) {
    if (this.isValidSaiaAccount == 'no') {
      this.truckload.controls['saiaAccountNumber'].setValue('');
    }
    this.isValidSaiaAccount = null;
    const formValues = this.truckload.value
    this.isTruckloadAccessorialChecked = 'no'
    if (formValues.accessorialServicesFb?.insideDelivery) this.isTruckloadAccessorialChecked = 'yes';
    if (formValues.accessorialServicesFb?.insidePickup) this.isTruckloadAccessorialChecked = 'yes';
    if (formValues.accessorialServicesFb?.liftgateDelivery) this.isTruckloadAccessorialChecked = 'yes';
    if (formValues.accessorialServicesFb?.liftgatePickup) this.isTruckloadAccessorialChecked = 'yes';
    if (formValues.accessorialServicesFb?.limitedAccessOnDelivery) this.isTruckloadAccessorialChecked = 'yes';
    if (formValues.accessorialServicesFb?.limitedAccessOnPickup) this.isTruckloadAccessorialChecked = 'yes';
    if (formValues.accessorialServicesFb?.residentialDelivery) this.isTruckloadAccessorialChecked = 'yes';
    if (formValues.accessorialServicesFb?.residentialPickup) this.isTruckloadAccessorialChecked = 'yes';
    if (formValues.accessorialServicesFb?.sortAtDestination) this.isTruckloadAccessorialChecked = 'yes';
    if (formValues.accessorialServicesFb?.sortAtOrigin) this.isTruckloadAccessorialChecked = 'yes';
    if (formValues.accessorialServicesFb?.hazardousMaterials) this.isTruckloadAccessorialChecked = 'yes';
    if (formValues.accessorialServicesFb?.tankerEndorsement) this.isTruckloadAccessorialChecked = 'yes';
  }

  characterCount: { [key: string]: number } = {
    yourCompany: 0,
    yourName: 0,
    yourPhone: 0,
    yourEmail: 0,
    saiaAccountNumber: 0,
    originCity: 0,
    originState: 0,
    originZip: 0,
    destinationCity: 0,
    destinationState: 0,
    destinationZip: 0
  };

  updateCharacterCount(event: Event, controlName: string): void {
    const value = (event.target as HTMLInputElement)?.value || '';
    this.characterCount[controlName] = value.length;
  }
  captchaResolved(captchaResponse: string) {
    this.captcha = captchaResponse;
    if (this.captcha) {
      this.showCaptchaError = false;
    }
  }
  captchaCheck() {
    if (this.captcha || this.showForm) {
      this.validateSaiaAccount();
    } else {
      this.showCaptchaError = true;
    }
  }
}
