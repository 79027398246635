import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { PageContentModel } from 'src/app/core/model/page-content.model';
import { PageHeaderContentModel } from 'src/app/core/model/page-header.model';
import { ContentfulService } from 'src/app/core/service/contentful.service';

@Component({
  selector: 'app-resources',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.scss']
})
export class ResourcesComponent implements OnInit {
  
  services: boolean = false;
  pageContent!: PageContentModel;
  pageHeader!: PageHeaderContentModel;
  toggleState() {  
    if(this.services === true) {
      this.services = false; 
    }
  }

  constructor(private contentfulService: ContentfulService, private sanitizer: DomSanitizer,) { }

  ngOnInit(): void {
    this.loadBodyEntries();
    this.loadHeaderEntries();
  }

  loadHeaderEntries() {
    this.contentfulService.getEntry('pageHeader', 'Tools And Resources Header').subscribe(
      (entry) => {
        this.pageHeader = new PageHeaderContentModel(entry);
      }, error => {
        console.error('Error fetching entries:', error);
      });
  }

  loadBodyEntries() {
    this.contentfulService.getEntry('pageInsights', 'Tools And Resources Content').subscribe(
      (entry) => {
        this.pageContent = new PageContentModel(entry);
      }, error => {
        console.error('Error fetching entries:', error);
      });
  }

  getBreadcrumbs(): string[] {
    return this.pageHeader.breadCrumbs.split(',').map(breadcrumb => breadcrumb.trim());
  }

  get leftSanitizedDescription(): SafeHtml {
    const htmlContent = this.pageContent.links[0].leftPanelLinks;
    const modifiedHtmlContent = htmlContent.replace(/(<a[^>]*>.*?<\/a>)/g, '$1 <span class="material-icons">arrow_forward</span>');
    return this.sanitizer.bypassSecurityTrustHtml(modifiedHtmlContent);
  }

  get rightSanitizedDescription(): SafeHtml {
    const htmlContent = this.pageContent.links[0].rightPanelLinks;
    const modifiedHtmlContent = htmlContent.replace(/(<a[^>]*>.*?<\/a>)/g, '$1 <span class="material-icons">arrow_forward</span>');
    return this.sanitizer.bypassSecurityTrustHtml(modifiedHtmlContent);
  }
}
