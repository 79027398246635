<div class="spinner-wrapper" *ngIf="loader">
  <div class="spinner">
    <div></div>
  </div>
</div>
<!-- Breadcrumb Wrapper -->
<div class="breadcrumb-wrapper">
  <div class="row">
    <div class="col-md-10 col-xs-12 col-md-offset-1">
      <ol>
        <li routerLink="/">
          <span class="material-icons">home</span>
        </li>
        <li class="child">Quote</li>
      </ol>
    </div>
  </div>
</div>

<!-- Section -->
<div class="content-section m-section pt-0">
  <div class="col-md-10 col-xs-12 col-md-offset-1 p-0">
    <mat-tab-group mat-stretch-tabs mat-align-tabs [selectedIndex]="activeTabIndex" (selectedTabChange)="onTabChanged($event)">
      <mat-tab label="Truckload">
        <div class="content-section py-0">
          <div class="row">
            <div class="col-md-12 col-xs-12">
              <h4 class="header-text text-blue py-2">Request a Quote!</h4>
              <div class="error" *ngIf="truckLoadStatusError">
                <span>Error</span> | Please fill out all highlighted form fields correctly and then try submitting again
              </div>

              <div class="error" *ngIf="showCaptchaError==true">
                <span>Error</span> | Please fill out all highlighted form fields correctly and then try submitting again
              </div>

              <div class="success" *ngIf="truckLoadStatus">
                Thank you for submitting a quote request. Someone will get back to you shortly.
              </div>
              <div *ngIf="isFormSubmittedTruckloadApiError" class="error">
                {{apiResponse.message}}
              </div>
              <form [formGroup]="truckload" #ngTruckload="ngForm" (keydown)="onKeyDown($event)" class=" " autocomplete="off">


                <div class="row row-form">
                  <div class="col-md-12 col-xs-12">
                    <h4 class="header-text header-sub-size py-2 remove-underline">
                      Load Previous Quote
                    </h4>
                  </div>
                </div>


                <div class="row row-form">
                  <div class="form-group col-md-6 col-xs-12">
                    <label for="">Previous Quote Number</label>
                    <mat-form-field appearance="outline" class="col-md-12 p-0">
                      <input matInput type="input" placeholder="Enter Previous Quote Number" autocomplete="off" formControlName="previousQuoteNumber" />
                      <mat-error> Enter Valid Previous Quote Number</mat-error>
                    </mat-form-field>
                  </div>

                  <div class="form-group col-md-6 col-xs-12">
                    <label for="">&nbsp;</label>
                    <button mat-flat-button class="primary-btn" type="button" (click)="loadPreviousQuote()">
                      Submit <mat-icon *ngIf="isLoadingPreviousQuote" class="material-symbols-outlined rotate">loop</mat-icon>
                    </button>
                  </div>

                </div>

                <div class="error" *ngIf="previousQuoteStatus=='fail'" [innerHTML]="previousQuoteMsg">
                </div>
                <div class="success" *ngIf="previousQuoteStatus=='success'" [innerHTML]="previousQuoteMsg">
                </div>
                <hr />


                <div class="row row-form">
                  <div class="form-group col-md-6 col-xs-12 counter">
                    <label for="">Company Name</label>
                    <mat-form-field appearance="outline" class="col-md-12 p-0">
                      <input matInput type="input" placeholder="Enter Company Name" autocomplete="off" formControlName="yourCompany" maxlength="50" (input)="updateCharacterCount($event, 'yourCompany')" />
                    </mat-form-field>
                    <mat-hint>{{ characterCount['yourCompany'] }}/50</mat-hint>
                  </div>

                  <div class="form-group col-md-6 col-xs-12 counter counter-astrik">
                    <label for="">Contact Name<sup> &#42;</sup></label>
                    <mat-form-field appearance="outline" class="col-md-12 p-0">
                      <input matInput type="input" placeholder="Enter Contact Name" formControlName="yourName" (input)="alphaOnly($event)" maxlength="50" (input)="updateCharacterCount($event, 'yourName')" />
                      <mat-error> Enter
                        Valid Contact Name </mat-error>
                    </mat-form-field>
                    <mat-hint>{{ characterCount['yourName'] }}/50</mat-hint>
                  </div>
                </div>
                <div class="row row-form">
                  <div class="form-group col-md-6 col-xs-12 counter counter-astrik">
                    <label for="">Phone<sup> &#42;</sup></label>
                    <mat-form-field appearance="outline" class="col-md-12 p-0">
                      <input matInput type="phone" (input)="numberOnly($event)" inputmode="numeric" placeholder="Enter Phone Number" autocomplete="off" formControlName="yourPhone" maxlength="10" (input)="updateCharacterCount($event, 'yourPhone')" />
                      <mat-error> Enter Valid Phone </mat-error>
                    </mat-form-field>
                    <mat-hint>{{ characterCount['yourPhone'] }}/10</mat-hint>
                  </div>

                  <div class="form-group col-md-6 col-xs-12 counter counter-astrik">
                    <label for="">Email<sup> &#42;</sup></label>
                    <mat-form-field appearance="outline" class="col-md-12 p-0">
                      <input matInput type="input" placeholder="Enter Email ID" formControlName="yourEmail" maxlength="50" (input)="updateCharacterCount($event, 'yourEmail')" />
                      <mat-error> Enter Valid Email </mat-error>
                    </mat-form-field>
                    <mat-hint>{{ characterCount['yourEmail'] }}/50</mat-hint>
                  </div>
                </div>

                <div class="row row-form align-center">
                  <div class="form-group col-md-6 col-xs-12 counter counter-astrik">
                    <label for="">Saia Account Number<sup> &#42;</sup></label>
                    <mat-form-field appearance="outline" class="col-md-12 p-0">
                      <input matInput type="input" maxlength="7" inputmode="numeric" placeholder="Enter Saia Account Number" autocomplete="off" formControlName="saiaAccountNumber" [(ngModel)]="inputText" (input)="onInputChange($event)" (blur)="saiaAccountLengthValidate($event)" (input)="updateCharacterCount($event, 'saiaAccountNumber')" />
                      <!-- <mat-error> Enter Valid Saia Account Number </mat-error> -->
                      <mat-error *ngIf="truckload.get('saiaAccountNumber')?.hasError('invalidSaiaAccountNumber')">
                        Account Number Must Be 7 Digits
                      </mat-error>
                    </mat-form-field>
                    <mat-hint>{{ characterCount['saiaAccountNumber'] }}/7</mat-hint>
                  </div>
                  <!-- <div class="form-group col-md-6 col-xs-12" *ngIf="showForm==false">
                    <re-captcha (resolved)="captchaResolved($event)" formControlName="recaptchaReactive"></re-captcha>
                    <mat-error *ngIf="showCaptchaError" class="font-size">
                      Required
                    </mat-error> 
                  </div> -->
                </div>

                <div class="row center-xs">
                  <div class="col-md-3"></div>
                  <div class="form-group col-md-6 col-xs-12" *ngIf="showButton">
                    <label for="">&nbsp;</label>
                    <button mat-flat-button class="primary-btn btn-auto" type="button" (click)="validateSaiaAccount()">
                      Validate Saia Account Number <mat-icon *ngIf="isLoading" class="material-symbols-outlined rotate">loop</mat-icon>
                    </button>
                  </div>
                </div>

                <br/>
                <!-- <div class="error" *ngIf="isValidSaiaInput">
                  Account number must be 7 digits.
                </div> -->
                <!-- <div class="error" *ngIf="">
                  Please fill out all highlighted form fields correctly and then try submitting again.
                </div> -->
                <div class="success" *ngIf="isValidSaiaAccount=='yes'">
                  The account has been validated. Please continue with your quote request.
                </div>
                <div class="error" *ngIf="isValidSaiaAccount=='no'">
                  Account number not found. Please try again.
                </div>
                <div class="row row-form">

                  <div class="form-group col-md-6 col-xs-12" *ngIf="otherAdditionalDetails">
                    <label for="">Additional Details<sup> &#42;</sup></label>
                    <mat-form-field appearance="outline" class="col-md-12 p-0">
                      <input matInput type="phone" maxlength="255" (input)="alphaOnly($event)" placeholder="Enter Additional Details" autocomplete="off" formControlName="additionalDetails" />
                      <mat-error> Enter Valid Additional Details </mat-error>
                    </mat-form-field>
                  </div>

                </div>
                <ng-container *ngIf="showForm">




                  <div class="row row-form">
                    <div class="col-md-12 col-xs-12">
                      <h4 class="header-text header-sub-size py-2 remove-underline">
                        Shipment Details
                      </h4>
                      <i>FOR DOMESTIC US SHIPPING ONLY</i>
                    </div>
                  </div>

                  <br>

                  <div class="row row-form">

                    <div class="form-group col-md-3 col-xs-12">
                      <label for="">Pickup Date<sup> &#42;</sup></label>
                      <mat-form-field appearance="outline" class="col-md-12 p-0">
                        <input matInput [matDatepicker]="tshipdate" [min]="minDate" (dateChange)="dateChange($event)" [matDatepickerFilter]="dateFilter" readonly placeholder="Select Pickup Date" autocomplete="off" formControlName="pickDate" />
                        <mat-datepicker-toggle matIconSuffix [for]="tshipdate"></mat-datepicker-toggle>
                        <mat-datepicker #tshipdate></mat-datepicker>
                        <mat-error>Select Valid Shipping Date </mat-error>
                      </mat-form-field>
                    </div>

                  </div>

                  <div class="row row-form">

                    <div class="form-group col-md-3 col-xs-12 counter counter-astrik">
                      <label for="">Origin City<sup> &#42;</sup></label>
                      <mat-form-field appearance="outline" class="col-md-12 p-0">
                        <input matInput type="input" placeholder="Enter Origin City" formControlName="originCity" (input)="alphaOnly($event)" maxlength="50" (input)="updateCharacterCount($event, 'originCity')" />
                        <mat-error> Enter Valid Origin City </mat-error>
                      </mat-form-field>
                      <mat-hint>{{ characterCount['originCity'] }}/50</mat-hint>
                    </div>

                    <div class="form-group col-md-3 col-xs-12 counter counter-astrik">
                      <label for="">Origin State<sup> &#42;</sup></label>
                      <mat-form-field appearance="outline" class="col-md-12 p-0">
                        <input matInput type="input" placeholder="Enter Origin State" autocomplete="off" formControlName="originState" (input)="alphaOnly($event)" maxlength="50" (input)="updateCharacterCount($event, 'originState')" />
                        <mat-error> Enter Valid Origin State </mat-error>
                      </mat-form-field>
                      <mat-hint>{{ characterCount['originState'] }}/50</mat-hint>
                    </div>

                    <div class="form-group col-md-3 col-xs-12 counter counter-astrik">
                      <label for="">Origin Zip Code<sup> &#42;</sup></label>
                      <mat-form-field appearance="outline" class="col-md-12 p-0">
                        <input matInput type="input" placeholder="Enter Origin Zip Code" formControlName="originZip" (input)="alphaOnly($event)" maxlength="7" (input)="updateCharacterCount($event, 'originZip')" />
                        <mat-error> Enter Valid Zip Code </mat-error>
                      </mat-form-field>
                      <mat-hint>{{ characterCount['originZip'] }}/7</mat-hint>
                    </div>

                  </div>

                  <div class="row row-form">

                    <div class="form-group col-md-3 col-xs-12 counter counter-astrik">
                      <label for="">Destination City<sup> &#42;</sup></label>
                      <mat-form-field appearance="outline" class="col-md-12 p-0">
                        <input matInput type="input" placeholder="Enter Destination City" formControlName="destinationCity" (input)="alphaOnly($event)" maxlength="50" (input)="updateCharacterCount($event, 'originCity')" />
                        <mat-error> Enter Valid Destination City </mat-error>
                      </mat-form-field>
                      <mat-hint>{{ characterCount['destinationCity'] }}/50</mat-hint>
                    </div>

                    <div class="form-group col-md-3 col-xs-12 counter counter-astrik">
                      <label for="">Destination State<sup> &#42;</sup></label>
                      <mat-form-field appearance="outline" class="col-md-12 p-0">
                        <input matInput type="input" placeholder="Enter Destination State" autocomplete="off" formControlName="destinationState" (input)="alphaOnly($event)" maxlength="50" (input)="updateCharacterCount($event, 'destinationState')" />
                        <mat-error> Enter Valid Destination State </mat-error>
                      </mat-form-field>
                      <mat-hint>{{ characterCount['destinationState'] }}/50</mat-hint>
                    </div>

                    <div class="form-group col-md-3 col-xs-12 counter counter-astrik">
                      <label for="">Destination Zip Code<sup> &#42;</sup></label>
                      <mat-form-field appearance="outline" class="col-md-12 p-0">
                        <input matInput type="input" placeholder="Enter Destination Zip Code" formControlName="destinationZip" (input)="alphaOnly($event)" maxlength="7" (input)="updateCharacterCount($event, 'destinationZip')" />
                        <mat-error> Enter Valid Destination Zip Code </mat-error>
                      </mat-form-field>
                      <mat-hint>{{ characterCount['destinationZip'] }}/7</mat-hint>
                    </div>

                  </div>

                  <br>

                  <div class="row row-form">
                    <div class="col-md-12 col-xs-12">
                      <h4 class="header-text header-sub-size py-2 remove-underline">
                        Freight Information
                      </h4>
                    </div>
                  </div>

                  <div class="row row-form">
                    <div class="form-group col-md-3 col-xs-12">
                      <label for="">Weight<sup> &#42;</sup></label>
                      <mat-form-field appearance="outline" class="col-md-12 p-0">
                        <input matInput type="input" placeholder="Enter Weight" formControlName="weight" maxlength="6" (input)="commaNumberOnly($event)" />
                        <mat-error *ngIf="truckload.controls['weight'].hasError('required')">Enter Valid Weight</mat-error>
                        <mat-error *ngIf="truckload.controls['weight'].hasError('maxWeight')">Maximum weight is 44,000 lbs</mat-error>
                      </mat-form-field>
                    </div>

                    <div class="form-group col-md-6 col-xs-12">
                      <label for="">Hazmat</label>
                      <mat-radio-group aria-label="Select Hazmat" class="m-t" ngDefaultControl formControlName="hazmat">
                        <mat-radio-button value="Yes">Yes</mat-radio-button>
                        <mat-radio-button value="No">No</mat-radio-button>
                      </mat-radio-group>
                    </div>

                  </div>

                  <div class="row row-form">
                    <div class="col-md-12 col-xs-12">
                      <h4 class="header-text header-sub-size py-2 remove-underline">
                        Select Equipment
                      </h4>
                    </div>

                    <div class="col-md-6 col-xs-12 column-direction">
                      <mat-checkbox ngDefaultControl formControlName="standarDryVan">Standard Truckload Dry Van</mat-checkbox>
                    </div>

                  </div>

                  <br>

                  <div class="row row-form">
                    <div class="col-md-12 col-xs-12">
                      <h4 class="header-text header-sub-size py-2 remove-underline">
                        Accessorial Services
                      </h4>
                    </div>

                    <div class="form-group col-md-4 col-xs-12" formGroupName="accessorialServicesFb">
                      <mat-checkbox-group aria-label="Select Accessorial Services" class="m-t checkbox-vertical" ngDefaultControl>
                        <mat-checkbox formControlName="insideDelivery" (change)="onAccessorialChange($event)" name="insideDelivery" value="insideDelivery">Inside
                          Delivery</mat-checkbox>
                        <mat-checkbox formControlName="insidePickup" (change)="onAccessorialChange($event)" name="insidePickup" value="insidePickup">Inside
                          Pickup</mat-checkbox>
                        <mat-checkbox formControlName="liftgateDelivery" (change)="onAccessorialChange($event)" name="liftgateDelivery" value="liftgateDelivery">
                          Liftgate Delivery</mat-checkbox>
                        <mat-checkbox formControlName="liftgatePickup" (change)="onAccessorialChange($event)" name="liftgatePickup" value="liftgatePickup">
                          Liftgate Pickup</mat-checkbox>
                        <mat-checkbox formControlName="limitedAccessOnDelivery" (change)="onAccessorialChange($event)" name="limitedAccessOnDelivery" value="limitedAccessOnDelivery"> Limited Access On Delivery</mat-checkbox>
                        <mat-checkbox formControlName="limitedAccessOnPickup" (change)="onAccessorialChange($event)" name="limitedAccessOnPickup" value="limitedAccessOnPickup">Limited Access On Pickup</mat-checkbox>
                        <mat-checkbox formControlName="residentialDelivery" (change)="onAccessorialChange($event)" name="residentialDelivery" value="residentialDelivery">Residential Delivery</mat-checkbox>
                        <mat-checkbox formControlName="residentialPickup" (change)="onAccessorialChange($event)" name="residentialPickup" value="residentialPickup">Residential Pickup</mat-checkbox>
                        <mat-checkbox formControlName="sortAtDestination" (change)="onAccessorialChange($event)" name="sortAtDestination" value="sortAtDestination">Sort/Segregate At Destination</mat-checkbox>
                        <mat-checkbox formControlName="sortAtOrigin" (change)="onAccessorialChange($event)" name="sortAtOrigin" value="sortAtOrigin">
                          Sort/Segregate At Origin</mat-checkbox>
                        <mat-checkbox formControlName="hazardousMaterials" (change)="onAccessorialChange($event)" name="hazardousMaterials" value="hazardousMaterials">
                          Hazardous Materials</mat-checkbox>
                        <mat-checkbox formControlName="tankerEndorsement" (change)="onAccessorialChange($event)" name="tankerEndorsement" value="tankerEndorsement">
                          Tanker Endorsement</mat-checkbox>
                      </mat-checkbox-group>
                    </div>


                  </div>

                  <br>

                  <div class="row row-form">
                    <div class="col-md-12 col-xs-12">
                      <h4 class="header-text header-sub-size py-2 remove-underline">
                        Reply to Quote by
                      </h4>
                    </div>

                    <div class="col-md-6 col-xs-12 column-direction">
                      <mat-checkbox ngDefaultControl formControlName="replyBy1">Phone</mat-checkbox>
                      <mat-checkbox ngDefaultControl formControlName="replyBy3">Email</mat-checkbox>
                    </div>
                  </div>

                  <br><br>

                  <div class="row">
                    <div class="col-md-12 col-xs-12 ">
                      <h4 class="header-text text-yellow text-center">This quote is for Standard Truckload Dry Van equipment. For Reefer, Flatbed, or Other equipment needs, please contact LinkEx 866.289.9838 or <a (click)="sendEmail()" class="link-share"> tlquotes@linkex.us</a>.</h4>
                    </div>
                  </div>

                  <br><br>

                  <div class="row center-xs">
                    <div class="col-md-4"></div>
                    <div class="col-md-4">
                      <button mat-flat-button class="primary-btn" *ngIf="isValidSaiaAccount === 'yes' && isTruckloadAccessorialChecked !== 'yes' && ( !truckload.value.hazmat || truckload.value.hazmat !== 'Yes' )" (click)="getLiveRateQuotes()">
                        Get Quote
                      </button>
                      <button mat-flat-button class="primary-btn" *ngIf="(!isValidSaiaAccount || isValidSaiaAccount === 'no') || isTruckloadAccessorialChecked==='yes' || (truckload.value.hazmat && truckload.value.hazmat === 'Yes')" (click)="submitTruckLoadQuotes()">
                        Submit
                      </button>
                    </div>
                    <div class="col-md-4 end-xs p-0">
                      <button mat-button class="primary-btn link" type="reset" (click)="clearTruckLoad()">Clear</button>
                    </div>
                  </div>

                  <br /><br />


                </ng-container>
              </form>
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Expedited">
        <div class="content-section py-0">
          <div class="row">
            <div class="col-md-12 col-xs-12">
              <h4 class="header-text text-blue py-2">Request a Quote!</h4>
              <div class="error" *ngIf="expeditedLoadStatusError">
                <span>Error</span> | Please fill out all highlighted form fields correctly and then try submitting again
              </div>

              <div class="success" *ngIf="expeditedLoadStatus">
                Thank you for submitting a quote request. Someone will get back to you shortly.
              </div>
              <div *ngIf="isFormSubmittedExpeditedApiError" class="error">
                {{apiResponse.message}}
              </div>
              <form [formGroup]="expedited" #ngExpedited="ngForm" (keydown)="onKeyDown($event)" class=" " autocomplete="off">
                <div class="row row-form">
                  <div class="form-group col-md-6 col-xs-12">
                    <label for="">Company Name</label>
                    <mat-form-field appearance="outline" class="col-md-12 p-0">
                      <input matInput type="input" placeholder="Enter Company Name" autocomplete="off" formControlName="yourCompany" />
                      <mat-error> Enter Valid Company Name </mat-error>
                    </mat-form-field>
                  </div>

                  <div class="form-group col-md-6 col-xs-12">
                    <label for="">Contact Name<sup> &#42;</sup></label>
                    <mat-form-field appearance="outline" class="col-md-12 p-0">
                      <input matInput type="input" placeholder="Enter Contact Name" formControlName="yourName" (input)="alphaOnly($event)" />
                      <mat-error> Enter Valid Contact Name </mat-error>
                    </mat-form-field>
                  </div>
                </div>
                <div class="row row-form">
                  <div class="form-group col-md-6 col-xs-12">
                    <label for="">Phone<sup> &#42;</sup></label>
                    <mat-form-field appearance="outline" class="col-md-12 p-0">
                      <input matInput type="phone" maxlength="12" (input)="numberOnly($event)" inputmode="numeric" placeholder="Enter Phone Number" autocomplete="off" formControlName="yourPhone" />
                      <mat-error> Enter Valid Phone </mat-error>
                    </mat-form-field>
                  </div>

                  <div class="form-group col-md-6 col-xs-12">
                    <label for="">Email<sup> &#42;</sup></label>
                    <mat-form-field appearance="outline" class="col-md-12 p-0">
                      <input matInput type="input" placeholder="Enter Email ID" formControlName="yourEmail" />
                      <mat-error> Enter Valid Email </mat-error>
                    </mat-form-field>
                  </div>
                </div>

                <div class="row row-form">
                  <div class="col-md-12 col-xs-12">
                    <h4 class="header-text header-sub-size py-2 remove-underline">
                      Shipment Details
                    </h4>
                  </div>
                </div>

                <div class="row row-form">

                  <div class="form-group col-md-3 col-xs-12">
                    <label for="">Ship Date</label>
                    <mat-form-field appearance="outline" class="col-md-12 p-0">
                      <input matInput [matDatepicker]="shipdate" [min]="minDate" (dateChange)="dateChange($event)" readonly placeholder="Select Ship Date" autocomplete="off" formControlName="shipDate" />
                      <mat-datepicker-toggle matIconSuffix [for]="shipdate"></mat-datepicker-toggle>
                      <mat-datepicker #shipdate></mat-datepicker>
                    </mat-form-field>
                  </div>

                  <div class="form-group col-md-9 col-xs-12">
                    <label for="">Origin Street Address</label>
                    <mat-form-field appearance="outline" class="col-md-12 p-0">
                      <input matInput type="input" maxlength="255" placeholder="Enter Origin Street Address" autocomplete="off" formControlName="originAddress" />
                    </mat-form-field>
                  </div>

                </div>

                <div class="row row-form">

                  <div class="form-group col-md-3 col-xs-12">
                    <label for="">Origin City<sup> &#42;</sup></label>
                    <mat-form-field appearance="outline" class="col-md-12 p-0">
                      <input matInput type="input" placeholder="Enter Origin City" formControlName="originCity" (input)="alphaOnly($event)" />
                      <mat-error> Enter Valid Origin City </mat-error>
                    </mat-form-field>
                  </div>

                  <div class="form-group col-md-3 col-xs-12">
                    <label for="">Origin State<sup> &#42;</sup></label>
                    <mat-form-field appearance="outline" class="col-md-12 p-0">
                      <input matInput type="input" placeholder="Enter Origin State" autocomplete="off" formControlName="originState" (input)="alphaOnly($event)" />
                      <mat-error> Enter Valid Origin State </mat-error>
                    </mat-form-field>
                  </div>

                  <div class="form-group col-md-3 col-xs-12">
                    <label for="">Origin Zip Code<sup> &#42;</sup></label>
                    <mat-form-field appearance="outline" class="col-md-12 p-0">
                      <input matInput type="input" placeholder="Enter Origin Zip Code" formControlName="originZip" (input)="alphaOnly($event)" />
                      <mat-error> Enter Valid Zip Code </mat-error>
                    </mat-form-field>
                  </div>

                </div>

                <hr />

                <div class="row row-form">

                  <div class="form-group col-md-3 col-xs-12">
                    <label for="">Expected Delivery Date</label>
                    <mat-form-field appearance="outline" class="col-md-12 p-0">
                      <input matInput type="input" readonly [min]="shipDate" [matDatepicker]="deliverydate" placeholder="Select Expected Delivery Date" autocomplete="off" formControlName="deliveryDate" />
                      <mat-datepicker-toggle matIconSuffix [for]="deliverydate"></mat-datepicker-toggle>
                      <mat-datepicker #deliverydate></mat-datepicker>
                    </mat-form-field>
                  </div>

                  <div class="form-group col-md-9 col-xs-12">
                    <label for="">Destination Street Address</label>
                    <mat-form-field appearance="outline" class="col-md-12 p-0">
                      <input matInput type="input" maxlength="255" placeholder="Enter Destination Street Address" autocomplete="off" formControlName="destinationAddress" (input)="alphaOnly($event)" />
                    </mat-form-field>
                  </div>

                </div>

                <div class="row row-form">

                  <div class="form-group col-md-3 col-xs-12">
                    <label for="">Destination City<sup> &#42;</sup></label>
                    <mat-form-field appearance="outline" class="col-md-12 p-0">
                      <input matInput type="input" placeholder="Enter Destination City" formControlName="destinationCity" (input)="alphaOnly($event)" />
                      <mat-error> Enter Valid Destination City </mat-error>
                    </mat-form-field>
                  </div>

                  <div class="form-group col-md-3 col-xs-12">
                    <label for="">Destination State<sup> &#42;</sup></label>
                    <mat-form-field appearance="outline" class="col-md-12 p-0">
                      <input matInput type="input" placeholder="Enter Destination State" autocomplete="off" formControlName="destinationState" (input)="alphaOnly($event)" />
                      <mat-error> Enter Valid Destination State </mat-error>
                    </mat-form-field>
                  </div>

                  <div class="form-group col-md-3 col-xs-12">
                    <label for="">Destination Zip Code<sup> &#42;</sup></label>
                    <mat-form-field appearance="outline" class="col-md-12 p-0">
                      <input matInput type="input" placeholder="Enter Destination Zip Code" formControlName="destinationZip" (input)="alphaOnly($event)" />
                      <mat-error> Enter Valid Destination Zip Code </mat-error>
                    </mat-form-field>
                  </div>

                </div>

                <hr />

                <div class="row row-form">

                  <div class="form-group col-md-3 col-xs-12">
                    <label for="">Piece Count<sup> &#42;</sup></label>
                    <mat-form-field appearance="outline" class="col-md-12 p-0">
                      <input matInput type="input" maxlength="8" (input)="alphaOnly($event)" inputmode="numeric" placeholder="Enter Piece Count" autocomplete="off" formControlName="pieceCount" />
                      <mat-error>Enter valid Piece Count</mat-error>
                    </mat-form-field>
                  </div>

                  <div class="form-group col-md-3 col-xs-12">
                    <label for="">HU Type<sup> &#42;</sup></label>
                    <mat-form-field appearance="outline" class="col-md-12 p-0">
                      <mat-select placeholder="Select Hu Type" ngDefaultControl formControlName="hutype">
                        <mat-option *ngFor="let hutype of hutype" [value]="hutype.value">
                          {{ hutype.viewValue }}
                        </mat-option>
                      </mat-select>
                      <mat-error> Please Select Hu Type</mat-error>
                    </mat-form-field>
                  </div>

                  <div class="form-group col-md-3 col-xs-12">
                    <label for="">Weight (lbs.)<sup> &#42;</sup></label>
                    <mat-form-field appearance="outline" class="col-md-12 p-0">
                      <input matInput type="input" maxlength="8" (input)="numberOnly($event)" inputmode="numeric" placeholder="Enter Total Weight" formControlName="weight" />
                      <mat-error>Enter Valid Weight</mat-error>
                    </mat-form-field>
                  </div>

                  <div class="form-group col-md-3 col-xs-12">
                    <label for="">Dimensions (L x W x H in.)<sup> &#42;</sup></label>
                    <mat-form-field appearance="outline" class="col-md-12 p-0">
                      <input matInput type="input" maxlength="25" (input)="alphaOnly($event)" inputmode="numeric" placeholder="Enter Dimensions" autocomplete="off" formControlName="dimensions" />
                      <mat-error>Enter Valid Dimensions</mat-error>
                    </mat-form-field>
                  </div>

                </div>

                <div class="row row-form">

                  <div class="form-group col-md-12 col-xs-12">
                    <label for="">Commodity Description</label>
                    <mat-form-field appearance="outline" class="col-md-12 p-0">
                      <input matInput type="input" maxlength="20" placeholder="Enter Commodity Description" formControlName="commodity" />
                    </mat-form-field>
                  </div>

                </div>

                <div class="row row-form">

                  <div class="form-group col-md-3 col-xs-12">
                    <label for="">Hazmat<sup> &#42;</sup></label>
                    <mat-radio-group aria-label="Select Equipment Type" ngDefaultControl formControlName="hazmatType" (change)="hazmatUnFn($event)">
                      <mat-radio-button value="Yes">Yes</mat-radio-button>
                      <mat-radio-button value="No">No</mat-radio-button>
                      <mat-error class="custom-error" *ngIf="isFormSubmittedExpeditedload && expedited.controls['hazmatType'].errors?.['required']">
                        Select Equipment Type</mat-error>
                    </mat-radio-group>

                  </div>

                  <div class="form-group col-md-3 col-xs-12" *ngIf="hazmatUn">
                    <label for="">Hazmat UN#<sup> &#42;</sup></label>
                    <mat-form-field appearance="outline" class="col-md-12 p-0">
                      <input matInput type="input" maxlength="20" placeholder="Enter Hazmat UN#" formControlName="hazmatUn" />
                      <mat-error>Enter Valid Hazmat UN#</mat-error>
                    </mat-form-field>
                  </div>

                  <div class="form-group col-md-3 col-xs-12">
                    <label for="">Bill To Account<sup>&nbsp;</sup></label>
                    <mat-form-field appearance="outline" class="col-md-12 p-0">
                      <input matInput type="input" maxlength="20" placeholder="Enter Bill To Account" formControlName="billAccount" />
                      <mat-error>Enter Valid Bill To Account</mat-error>
                    </mat-form-field>
                  </div>

                  <div class="form-group col-md-3 col-xs-12">
                    <label for="">Saia Pro #<sup>&nbsp;</sup></label>
                    <mat-form-field appearance="outline" class="col-md-12 p-0">
                      <input matInput type="input" maxlength="20" placeholder="Enter Saia Pro #" formControlName="saiaPro" />
                      <mat-error>Enter Valid Saia Pro #</mat-error>
                    </mat-form-field>
                  </div>

                </div>

                <div class="row row-form">
                  <div class="col-md-9 col-xs-12">
                    <h4 class="header-text header-sub-size py-2 remove-underline">
                      Equipment Type <sup> &#42;</sup>
                    </h4>

                    <mat-radio-group aria-label="Select Equipment Type" ngDefaultControl formControlName="equipmentType" (change)="equipmentType($event)">
                      <mat-radio-button value="Sprinter Van">Sprinter Van</mat-radio-button>
                      <mat-radio-button value="Straight Truck">Straight Truck</mat-radio-button>
                      <mat-radio-button value="53' Van">53' Van</mat-radio-button>
                      <mat-radio-button value="Reefer">Reefer</mat-radio-button>
                      <mat-radio-button value="Flatbed">Flatbed</mat-radio-button>
                      <mat-radio-button value="Air-Freight">Air Freight</mat-radio-button>
                      <mat-radio-button value="Other">Other</mat-radio-button>
                      <mat-error class="custom-error" *ngIf="isFormSubmittedExpeditedload && expedited.controls['equipmentType'].errors?.['required']">
                        Select Equipment Type</mat-error>
                    </mat-radio-group>
                  </div>

                </div>

                <br>

                <div class="row row-form" *ngIf="additionalDetailsType">

                  <div class="form-group col-md-12 col-xs-12">
                    <label for="">Additional Details<sup> &#42;</sup></label>
                    <mat-form-field appearance="outline" class="col-md-12 p-0">
                      <input matInput type="input" maxlength="20" placeholder="Enter Additional Details" formControlName="addDetails" (input)="alphaOnly($event)" />
                      <mat-error>Enter Additional Details</mat-error>
                    </mat-form-field>
                  </div>

                </div>

                <hr>

                <div class="row row-form">
                  <div class="col-md-12 col-xs-12">
                    <h4 class="header-text header-sub-size py-2 remove-underline">
                      Accessorial Services
                    </h4>
                  </div>

                  <div class="form-group col-md-4 col-xs-12" formGroupName="accessorialServicesFb">
                    <mat-checkbox-group aria-label="Select Accessorial Services" class="m-t checkbox-vertical" ngDefaultControl>
                      <mat-checkbox formControlName="insideDelivery" name="insideDelivery" value="insideDelivery">Inside
                        Delivery</mat-checkbox>
                      <mat-checkbox formControlName="insidePickup" name="insidePickup" value="insidePickup">Inside
                        Pickup</mat-checkbox>
                      <mat-checkbox formControlName="liftgateDelivery" name="liftgateDelivery" value="liftgateDelivery">
                        Liftgate Delivery</mat-checkbox>
                      <mat-checkbox formControlName="liftgatePickup" name="liftgatePickup" value="liftgatePickup">
                        Liftgate Pickup</mat-checkbox>
                      <mat-checkbox formControlName="limitedAccessOnDelivery" name="limitedAccessOnDelivery" value="limitedAccessOnDelivery"> Limited Access On Delivery</mat-checkbox>
                      <mat-checkbox formControlName="limitedAccessOnPickup" name="limitedAccessOnPickup" value="limitedAccessOnPickup">Limited Access On Pickup</mat-checkbox>
                      <mat-checkbox formControlName="residentialDelivery" name="residentialDelivery" value="residentialDelivery">Residential Delivery</mat-checkbox>
                      <mat-checkbox formControlName="residentialPickup" name="residentialPickup" value="residentialPickup">Residential Pickup</mat-checkbox>
                      <mat-checkbox formControlName="sortAtDestination" name="sortAtDestination" value="sortAtDestination">Sort/Segregate At Destination</mat-checkbox>
                      <mat-checkbox formControlName="sortAtOrigin" name="sortAtOrigin" value="sortAtOrigin">
                        Sort/Segregate At Origin</mat-checkbox>
                      <mat-checkbox formControlName="hazardousMaterials" name="hazardousMaterials" value="hazardousMaterials">
                        Hazardous Materials</mat-checkbox>
                      <mat-checkbox formControlName="tankerEndorsement" name="tankerEndorsement" value="tankerEndorsement">
                        Tanker Endorsement</mat-checkbox>
                      <mat-checkbox formControlName="whiteGloveService" name="whiteGloveService" value="whiteGloveService">
                        White Glove Service</mat-checkbox>
                    </mat-checkbox-group>
                  </div>


                </div>

                <br>

                <div class="row row-form">
                  <div class="col-md-12 col-xs-12">
                    <h4 class="header-text header-sub-size py-2 remove-underline">
                      Reply to Quote by
                    </h4>
                  </div>

                  <div class="col-md-6 col-xs-12 column-direction">
                    <mat-checkbox ngDefaultControl formControlName="replyBy1">Phone</mat-checkbox>
                    <mat-checkbox ngDefaultControl formControlName="replyBy3">Email</mat-checkbox>
                  </div>
                </div>

                <br /><br>

                <div class="row">
                  <div class="col-md-12 p-0">
                    <p class="m-0">
                      If you were referred by your Saia Account Executive /
                      Saia Account Exec - the following information is
                      required:
                    </p>
                  </div>
                </div>

                <br />

                <div class="row row-form">
                  <div class="form-group col-md-4 col-xs-12">
                    <label for="">Saia Account Number</label>
                    <mat-form-field appearance="outline" class="col-md-12 p-0">
                      <input matInput type="input" placeholder="Enter Saia Account Number" autocomplete="off" formControlName="saiaAccountNumber" />
                    </mat-form-field>
                  </div>

                  <!-- <div class="form-group col-md-4 col-xs-12">
                    <label for="">Saia Sales Rep</label>
                    <mat-form-field appearance="outline" class="col-md-12 p-0">
                      <input matInput type="input" placeholder="Enter Saia Sales Rep" formControlName="saiaSalesRep" />
                    </mat-form-field>
                  </div>

                  <div class="form-group col-md-4 col-xs-12">
                    <label for="">Saia Sales Rep Email</label>
                    <mat-form-field appearance="outline" class="col-md-12 p-0">
                      <input matInput type="input" placeholder="Enter Email ID" autocomplete="off" formControlName="saiaSalesRepEmail" />
                    </mat-form-field>
                  </div> -->
                </div>

                <br>

                <div class="row center-xs">
                  <div class="col-md-4"></div>
                  <div class="col-md-4">
                    <button mat-flat-button class="primary-btn" (click)="submitExpeditedLoadQuotes()">
                      Submit
                    </button>
                  </div>
                  <div class="col-md-4 end-xs p-0">
                    <button mat-button class="primary-btn link" type="reset" (click)="clearExpeditedLoad()">Clear</button>
                  </div>
                </div>

                <br /><br />
              </form>

            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Final Mile and Cartage">
        <div class="content-section py-0">
          <div class="row">
            <div class="col-md-12 col-xs-12">
              <h4 class="header-text text-blue py-2">Request a Quote!</h4>
              <div class="error" *ngIf="ltlCatageStatusError">
                <span>Error</span> | Please fill out all highlighted form fields correctly and then try submitting again
              </div>

              <div class="success" *ngIf="ltlCatageStatus">
                Thank you for submitting a quote request. Someone will get back to you shortly.
              </div>
              <div *ngIf="isFormSubmittedLtlcartageApiError" class="error">
                {{apiResponse.message}}
              </div>
              <form [formGroup]="finalCartage" #ngFinalCartage="ngForm" (keydown)="onKeyDown($event)" class=" " autocomplete="off">
                <div class="row row-form">
                  <div class="form-group col-md-6 col-xs-12">
                    <label for="">Company Name<sup> &#42;</sup></label>
                    <mat-form-field appearance="outline" class="col-md-12 p-0">
                      <input matInput type="input" placeholder="Enter Company Name" formControlName="companyName" />
                      <mat-error> Enter Valid Company Name </mat-error>
                    </mat-form-field>
                  </div>

                  <div class="form-group col-md-6 col-xs-12">
                    <label for="">Contact Name<sup> &#42;</sup></label>
                    <mat-form-field appearance="outline" class="col-md-12 p-0">
                      <input matInput type="input" placeholder="Enter Contact Name" (input)="alphaOnly($event)" formControlName="contactName" />
                      <mat-error> Enter Valid Contact Name </mat-error>
                    </mat-form-field>
                  </div>
                </div>
                <div class="row row-form">

                  <div class="form-group col-md-6 col-xs-12">
                    <label for="">Phone<sup> &#42;</sup></label>
                    <mat-form-field appearance="outline" class="col-md-12 p-0">
                      <input matInput type="input" maxlength="12" (input)="numberOnly($event)" inputmode="numeric" placeholder="Enter Phone Number" autocomplete="off" formControlName="yourPhone" />
                      <mat-error> Enter Valid Phone Name </mat-error>
                    </mat-form-field>
                  </div>

                  <div class="form-group col-md-6 col-xs-12">
                    <label for="">Email<sup> &#42;</sup></label>
                    <mat-form-field appearance="outline" class="col-md-12 p-0">
                      <input matInput type="input" placeholder="Enter Email ID" formControlName="custEmail" />
                      <mat-error> Enter Valid Email </mat-error>
                    </mat-form-field>
                  </div>
                </div>


                <div class="row row-form">
                  <div class="col-md-12 col-xs-12">
                    <h4 class="header-text header-sub-size py-2 remove-underline">
                      Shipment Details
                    </h4>
                  </div>
                </div>

                <div class="row row-form">
                  <div class="form-group col-md-3 col-xs-12">
                    <label for="">Origin Zip Code<sup> &#42;</sup></label>
                    <mat-form-field appearance="outline" class="col-md-12 p-0">
                      <input matInput type="input" placeholder="Enter Origin Zip Code" formControlName="orginZip" (input)="alphaOnly($event)" />
                      <mat-error> Enter Origin Zip Code </mat-error>
                    </mat-form-field>
                  </div>

                  <div class="form-group col-md-3 col-xs-12">
                    <label for="">Destination Zip Code<sup> &#42;</sup></label>
                    <mat-form-field appearance="outline" class="col-md-12 p-0">
                      <input matInput type="input" placeholder="Enter Destination Zip Code" formControlName="destZip" (input)="alphaOnly($event)" />
                      <mat-error> Enter Destination Zip Code </mat-error>
                    </mat-form-field>
                  </div>

                  <div class="form-group col-md-3 col-xs-12">
                    <label for="">Requested Pickup date<sup>
                        &#42;</sup></label>
                    <mat-form-field appearance="outline" class="col-md-12 p-0">
                      <input matInput type="input" readonly [matDatepicker]="RequestPickupDate" [min]="minDate" (dateChange)="dateChange($event)" placeholder="Select Date" autocomplete="off" formControlName="RequestPickupDate" />
                      <mat-datepicker-toggle matIconSuffix [for]="RequestPickupDate"></mat-datepicker-toggle>
                      <mat-datepicker #RequestPickupDate></mat-datepicker>
                      <mat-error>Select Requested Pickup date</mat-error>
                    </mat-form-field>
                  </div>

                  <div class="form-group col-md-3 col-xs-12">
                    <label for="">Requested Delivery date<sup>
                        &#42;</sup></label>
                    <mat-form-field appearance="outline" class="col-md-12 p-0">
                      <input matInput type="input" readonly [matDatepicker]="requestDeliveryDate" [min]="rDate" (dateChange)="dateChange($event)" placeholder="Select Date" autocomplete="off" formControlName="requestDeliveryDate" />
                      <mat-datepicker-toggle matIconSuffix [for]="requestDeliveryDate"></mat-datepicker-toggle>
                      <mat-datepicker #requestDeliveryDate></mat-datepicker>
                      <mat-error>Select Requested Delivery date</mat-error>
                    </mat-form-field>
                  </div>

                </div>

                <ng-container *ngFor="let control of  shippingDetailsAsFormArray.controls ; let i = index;" formArrayName='shippingDetails'>
                  <div [formGroupName]="i">

                    <hr />

                    <div class="row row-form">

                      <div class="form-group col-md-6 col-xs-12">
                        <label for="">Commodity<sup> &#42;</sup></label>
                        <mat-form-field appearance="outline" class="col-md-12 p-0">
                          <input matInput type="input" placeholder="Enter Commodity" formControlName="shipCommodity" />
                          <mat-error> Enter Commodity</mat-error>
                        </mat-form-field>
                      </div>

                      <div class="form-group col-md-3 col-xs-12">
                        <label for="">Quantity<sup> &#42;</sup></label>
                        <mat-form-field appearance="outline" class="col-md-12 p-0">
                          <input matInput type="input" placeholder="Enter Quantity" formControlName="shipQuantity" (input)="numberOnly($event)" />
                          <mat-error> Enter Quantity</mat-error>
                        </mat-form-field>
                      </div>

                      <div class="form-group col-md-3 col-xs-12">
                        <label for="">HU Type<sup> &#42;</sup></label>
                        <mat-form-field appearance="outline" class="col-md-12 p-0">
                          <mat-select placeholder="Select Hu Type" ngDefaultControl formControlName="hutype">
                            <mat-option *ngFor="let hutype of hutype" [value]="hutype.value">
                              {{ hutype.viewValue }}
                            </mat-option>
                          </mat-select>
                          <mat-error> Please Select Hu Type</mat-error>
                        </mat-form-field>
                      </div>

                    </div>

                    <div class="row row-form">

                      <div class="form-group col-md-3 col-xs-12">
                        <label for="">Hazmat<sup> &#42;</sup></label>
                        <mat-form-field appearance="outline" class="col-md-12 p-0">
                          <mat-select placeholder="Select Hazmat" ngDefaultControl formControlName="hazmatType">
                            <mat-option value="Yes"> Yes </mat-option>
                            <mat-option value="No"> No </mat-option>
                          </mat-select>
                          <mat-error> Please select Hazmat</mat-error>
                        </mat-form-field>
                      </div>

                      <div class="form-group col-md-3 col-xs-12">
                        <label for="">Weight (lbs)<sup> &#42;</sup></label>
                        <mat-form-field appearance="outline" class="col-md-12 p-0">
                          <input matInput type="input" placeholder="Enter Weight" formControlName="weight" (input)="numberOnly($event)" />
                          <mat-error> Enter Weight</mat-error>
                        </mat-form-field>
                      </div>

                      <div class="form-group col-md-3 col-xs-12">
                        <label for="">Dimensions (L x W x H in.)<sup> &#42;</sup></label>
                        <mat-form-field appearance="outline" class="col-md-12 p-0">
                          <input matInput type="input" placeholder="Enter Dimensions" formControlName="dimensions" (input)="alphaOnly($event)" />
                          <mat-error> Enter Dimensions</mat-error>
                        </mat-form-field>
                      </div>

                      <div class="form-group col-md-3 col-xs-12">
                        <label for="">Per<sup> &#42;</sup></label>
                        <mat-form-field appearance="outline" class="col-md-12 p-0">
                          <mat-select placeholder="Select each or total" ngDefaultControl formControlName="per">
                            <mat-option value="Each"> Each </mat-option>
                            <mat-option value="Total"> Total </mat-option>
                          </mat-select>
                          <mat-error>Please select each or total</mat-error>
                        </mat-form-field>
                      </div>

                    </div>

                  </div>

                </ng-container>


                <div class="row row-form">
                  <div class="col-md-12 col-xs-12 floating-right">
                    <button mat-flat-button class="primary-btn" type="button" (click)="addControl()">Add <span class="material-icons icon-size">add</span></button> &nbsp;&nbsp;

                    <button mat-flat-button class="primary-btn" type="button" (click)="removeControl()" *ngIf="shippingDetailsAsFormArray.controls.length > 1">Remove <span class="material-icons icon-size">remove</span></button>
                  </div>
                </div>

                <br>

                <div class="row row-form">
                  <div class="col-md-12 col-xs-12">
                    <h4 class="header-text header-sub-size py-2 remove-underline">
                      Final Mile Services
                    </h4>
                  </div>
                </div>

                <br>

                <div class="row row-form">
                  <div class="form-group col-md-4 col-xs-12" formGroupName="pickupServiceFb">
                    <label for="">Pickup Services</label>
                    <mat-checkbox-group aria-label="Select Pickup Services" class="m-t checkbox-vertical" ngDefaultControl>
                      <mat-checkbox formControlName="appointmentPickupPS" name="appointmentPickupPS" value="appointmentPickupPS">Appointment Pickup</mat-checkbox>
                      <mat-checkbox formControlName="callBeforePickupPS" name="callBeforePickupPS" value="callBeforePickupPS">
                        Call Before Pickup</mat-checkbox>
                      <mat-checkbox formControlName="liftgatePickupPS" name="liftgatePickupPS" value="liftgatePickupPS">
                        Liftgate Pickup</mat-checkbox>
                      <mat-checkbox formControlName="limitedAccessPickupPS" name="limitedAccessPickupPS" value="limitedAccessPickupPS">
                        Limited Access Pickup</mat-checkbox>
                      <mat-checkbox formControlName="residentialPickupPS" name="residentialPickupPS" value="residentialPickupPS">
                        Residential Pickup</mat-checkbox>
                    </mat-checkbox-group>
                  </div>


                  <div class="form-group col-md-4 col-xs-12" formGroupName="deliveryServiceFb">
                    <label for="">Delivery Services</label>
                    <mat-checkbox-group aria-label="Select Delivery Services" class="m-t checkbox-vertical" ngDefaultControl>
                      <mat-checkbox formControlName="appointmentDeliveryDS" name="appointmentDeliveryDS" value="appointmentDeliveryDS">Appointment Delivery</mat-checkbox>
                      <mat-checkbox formControlName="callBeforeDeliveryDS" name="callBeforeDeliveryDS" value="callBeforeDeliveryDS">
                        Call Before Delivery</mat-checkbox>
                      <mat-checkbox formControlName="liftgateDeliveryDS" name="liftgateDeliveryDS" value="liftgateDeliveryDS">
                        Liftgate Delivery</mat-checkbox>
                      <mat-checkbox formControlName="limitedAccessDeliveryDS" name="limitedAccessDeliveryDS" value="limitedAccessDeliveryDS">
                        Limited Access Delivery</mat-checkbox>
                      <mat-checkbox formControlName="residentialDeliveryDS" name="residentialDeliveryDS" value="residentialDeliveryDS">
                        Residential Delivery</mat-checkbox>
                      <mat-checkbox formControlName="unattendedDeliveryDS" name="unattendedDeliveryDS" value="unattendedDeliveryDS">
                        Unattended Delivery </mat-checkbox>
                      <mat-checkbox formControlName="curbsideDeliveryDS" name="curbsideDeliveryDS" value="curbsideDeliveryDS">
                        Curbside Delivery</mat-checkbox>
                      <mat-checkbox formControlName="thresholdGarageDeliveryDS" name="thresholdGarageDeliveryDS" value="thresholdGarageDeliveryDS">
                        Threshold / GarageDelivery</mat-checkbox>
                    </mat-checkbox-group>
                  </div>

                  <div class="form-group col-md-4 col-xs-12" formGroupName="whiteGloveServiceFb">
                    <label for="">White Glove Services</label>
                    <mat-checkbox-group aria-label="Select Delivery Services" class="m-t checkbox-vertical" ngDefaultControl>
                      <mat-checkbox formControlName="additionalLaborWS" name="additionalLaborWS" value="additionalLaborWS">Additional Labor</mat-checkbox>
                      <mat-checkbox formControlName="debrisRemovalWS" name="debrisRemovalWS" value="debrisRemovalWS">
                        Debris Removal</mat-checkbox>
                      <mat-checkbox formControlName="insidePickupDeliveryWS" name="insidePickupDeliveryWS" value="insidePickupDeliveryWS">
                        Inside Pickup / Delivery</mat-checkbox>
                      <mat-checkbox formControlName="lightAssemblyWS" name="lightAssemblyWS" value="lightAssemblyWS">
                        Light Assembly</mat-checkbox>
                      <mat-checkbox formControlName="roomOfChoiceWS" name="roomOfChoiceWS" value="roomOfChoiceWS">
                        Room of Choice</mat-checkbox>
                      <mat-checkbox formControlName="flightOfStairsWS" name="flightOfStairsWS" value="flightOfStairsWS">
                        Flight(s) of Stairs </mat-checkbox>
                      <mat-checkbox formControlName="unpackagingWS" name="unpackagingWS" value="unpackagingWS">
                        Unpackaging</mat-checkbox>
                      <mat-checkbox formControlName="inRoomPlacementWS" name="inRoomPlacementWS" value="inRoomPlacementWS">
                        In-Room Placement</mat-checkbox>
                      <mat-checkbox formControlName="hookupWS" name="hookupWS" value="hookupWS">
                        Hookup</mat-checkbox>
                      <mat-checkbox formControlName="haulAwayWS" name="haulAwayWS" value="haulAwayWS">
                        Haul Away</mat-checkbox>
                      <mat-checkbox formControlName="disposalReturnsWS" name="disposalReturnsWS" value="disposalReturnsWS">
                        Disposal / Returns</mat-checkbox>
                      <mat-checkbox formControlName="repairsWS" name="repairsWS" value="repairsWS">
                        Repairs</mat-checkbox>
                    </mat-checkbox-group>
                  </div>

                  <hr />

                  <div class="col-md-12">
                    <p class="m-0">
                      <i>The availability of any specific White Glove delivery service may be dictated by the locations,
                        commodities, and timelines involved in the requested movement.</i>
                    </p>
                  </div>

                </div>

                <br>

                <div class="row row-form">

                  <div class="form-group col-md-12 col-xs-12">
                    <label for="">Additional Comments or Special Instructions</label>
                    <mat-form-field appearance="outline" class="col-md-12 p-0">
                      <input matInput type="input" placeholder="Enter Additional Details" formControlName="additionalSpecialInstructions" />
                      <mat-error> Enter Weight</mat-error>
                    </mat-form-field>
                  </div>

                </div>

                <br>

                <div class="row row-form">
                  <div class="col-md-12 col-xs-12">
                    <h4 class="header-text header-sub-size py-2 remove-underline">
                      Reply to Quote by
                    </h4>
                  </div>

                  <div class="col-md-6 col-xs-12 column-direction">
                    <mat-checkbox ngDefaultControl formControlName="warehouseQuesPhoneFM">Phone</mat-checkbox>
                    <mat-checkbox ngDefaultControl formControlName="warehouseQuesEmailFM">Email</mat-checkbox>
                  </div>
                </div>

                <br />
                <br>

                <div class="row">
                  <div class="col-md-12 p-0">
                    <p class="m-0">
                      If you were referred by your Saia Account Executive /
                      Saia Account Exec - the following information is
                      required:
                    </p>
                  </div>
                </div>

                <br />

                <div class="row row-form">
                  <div class="form-group col-md-4 col-xs-12">
                    <label for="">Saia Account Number</label>
                    <mat-form-field appearance="outline" class="col-md-12 p-0">
                      <input matInput type="input" placeholder="Enter Saia Account Number" autocomplete="off" formControlName="saiaAccountNumberFM" />
                    </mat-form-field>
                  </div>

                  <!-- <div class="form-group col-md-4 col-xs-12">
                    <label for="">Saia Sales Rep</label>
                    <mat-form-field appearance="outline" class="col-md-12 p-0">
                      <input matInput type="input" placeholder="Enter Saia Sales Rep" autocomplete="off" formControlName="saiaSalesRepFM" />
                    </mat-form-field>
                  </div>

                  <div class="form-group col-md-4 col-xs-12">
                    <label for="">Saia Sales Rep Email</label>
                    <mat-form-field appearance="outline" class="col-md-12 p-0">
                      <input matInput type="input" placeholder="Enter Email ID" autocomplete="off" formControlName="saiaSalesRepEmail" />
                    </mat-form-field>
                  </div> -->
                </div>


                <br>

                <div class="row center-xs">
                  <div class="col-md-4"></div>
                  <div class="col-md-4">
                    <button mat-flat-button class="primary-btn" (click)="submitLtlCartageQuotes()">
                      Submit
                    </button>
                  </div>
                  <div class="col-md-4 end-xs p-0">
                    <button mat-button class="primary-btn link" type="reset" (click)="clearLTLCartage()">Clear</button>
                  </div>
                </div>

                <br /><br />
              </form>

            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Warehousing">
        <div class="content-section py-0">
          <div class="row">
            <div class="col-md-12 col-xs-12">
              <h4 class="header-text text-blue py-2">Request a Quote!</h4>
              <div class="error" *ngIf="warehousingStatusError">
                <span>Error</span> | Please fill out all highlighted form fields correctly and then try submitting again
              </div>

              <div class="success" *ngIf="warehousingStatus">
                Thank you for submitting a warehouse service request. A team member will be in touch within 24 hours.
              </div>
              <div *ngIf="isFormSubmittedWarehouseApiError" class="error">
                {{apiResponse.message}}
              </div>
              <form [formGroup]="warehouseQuestionnaire" #ngWarehousing="ngForm" (keydown)="onKeyDown($event)" class=" " autocomplete="off">

                <div class="row row-form">
                  <div class="form-group col-md-6 col-xs-12">
                    <label for="">Company Name<sup> &#42;</sup></label>
                    <mat-form-field appearance="outline" class="col-md-12 p-0">
                      <input matInput type="input" placeholder="Enter Company Name" autocomplete="off" formControlName="yourCompany" />
                      <mat-error>Company name is required</mat-error>
                    </mat-form-field>
                  </div>

                  <div class="form-group col-md-6 col-xs-12">
                    <label for="">Contact Name<sup> &#42;</sup></label>
                    <mat-form-field appearance="outline" class="col-md-12 p-0">
                      <input matInput type="input" placeholder="Enter Contact Name" (input)="alphaOnly($event)" autocomplete="off" formControlName="yourName" />
                      <mat-error>Contact name is required </mat-error>
                    </mat-form-field>
                  </div>
                </div>

                <div class="row row-form">


                  <div class="form-group col-md-6 col-xs-12">
                    <label for="">Email<sup> &#42;</sup></label>
                    <mat-form-field appearance="outline" class="col-md-12 p-0">
                      <input matInput type="input" placeholder="Enter Email ID" formControlName="yourEmail" autocomplete="off" />
                      <mat-error>Email is required</mat-error>
                    </mat-form-field>
                  </div>

                  <div class="form-group col-md-6 col-xs-12">
                    <label for="">Phone Number</label>
                    <mat-form-field appearance="outline" class="col-md-12 p-0">
                      <input matInput type="input" placeholder="Enter Phone Number" (input)="numberOnly($event)" inputmode="numeric" autocomplete="off" formControlName="phoneNumber" />
                      <mat-error>Phone number is required</mat-error>
                    </mat-form-field>
                  </div>
                </div>

                <br>

                <div class="row row-form">
                  <div class="col-md-12 col-xs-12">
                    <h4 class="header-text header-sub-size py-2 remove-underline">
                      Requested Warehouse Location
                    </h4>
                  </div>
                </div>

                <div class="row row-form">
                  <div class="form-group col-md-6 col-xs-12">
                    <label for="">City<sup> &#42;</sup></label>
                    <mat-form-field appearance="outline" class="col-md-12 p-0">
                      <input matInput type="input" placeholder="Enter City" (input)="alphaOnly($event)" autocomplete="off" formControlName="city" />
                      <mat-error>City is required</mat-error>
                    </mat-form-field>
                  </div>

                  <div class="form-group col-md-6 col-xs-12">
                    <label for="">State<sup> &#42;</sup></label>
                    <mat-form-field appearance="outline" class="col-md-12 p-0">
                      <input matInput type="input" placeholder="Enter State" (input)="alphaOnly($event)" autocomplete="off" formControlName="state" />
                      <mat-error>State is required</mat-error>
                    </mat-form-field>
                  </div>
                </div>


                <div class="row row-form">
                  <div class="form-group col-md-6 col-xs-12">
                    <label for="">Zip<sup> &#42;</sup></label>
                    <mat-form-field appearance="outline" class="col-md-12 p-0">
                      <input matInput type="input" placeholder="Enter Zip" (input)="alphaOnly($event)" autocomplete="off" formControlName="zip" />
                      <mat-error>
                        Zip code is required
                      </mat-error>
                    </mat-form-field>
                  </div>

                </div>

                <br>

                <div class="row row-form">
                  <div class="col-md-12 col-xs-12">
                    <h4 class="header-text header-sub-size py-2 remove-underline">
                      General Questions
                    </h4>
                  </div>
                </div>

                <div class="row row-form">

                  <div class="form-group col-md-6 col-xs-12">
                    <label for="">What is the Commodity? <sup> &#42;</sup></label>
                    <mat-form-field appearance="outline" class="col-md-12 p-0">
                      <input matInput type="input" placeholder="Enter Commodity" (input)="alphaOnly($event)" formControlName="commodity" />
                      <mat-error>Commodity is required</mat-error>
                    </mat-form-field>
                  </div>



                  <div class="form-group col-md-6 col-xs-12" formGroupName="packagedFB">
                    <label for="">How is it Packaged?<sup> &#42;</sup></label>
                    <mat-checkbox-group aria-label="Select General Questions" class="m-t" ngDefaultControl>
                      <mat-checkbox formControlName="packagedCase" value="Case">Case</mat-checkbox>
                      <mat-checkbox formControlName="packagedDrum" value="Drum">Drum</mat-checkbox>
                      <mat-checkbox formControlName="packagedTote" value="Tote">Tote</mat-checkbox>
                      <mat-checkbox formControlName="packagedCrate" value="Crate">Crate</mat-checkbox>
                      <mat-checkbox formControlName="packagedPallet" value="Pallet">Pallet
                      </mat-checkbox>
                      <mat-checkbox formControlName="packagedOther" value="Other" (change)="OtherPackage($event)">Other
                      </mat-checkbox>
                    </mat-checkbox-group>

                    <mat-error class="custom-error" *ngIf="isFormSubmittedWarehousing && warehouseQuestionnaire.get('packagedFB')?.hasError('required')">
                      Please select at least one option
                    </mat-error>

                  </div>

                  <div class=" form-group col-md-6 col-xs-12" *ngIf="otherPackaging">
                    <label for="">Packaging Type <sup> &#42;</sup></label>
                    <mat-form-field appearance="outline" class="col-md-12 p-0">
                      <input matInput type="input" placeholder="Enter Packaging Type" (input)="alphaOnly($event)" formControlName="packagingType" />
                      <mat-error>Packaging type is required</mat-error>
                    </mat-form-field>
                  </div>

                </div>

                <div class="row row-form">

                  <div class="form-group col-md-6 col-xs-12">
                    <label for="">When will we begin to receive product?<sup> &#42;</sup></label>
                    <mat-form-field appearance="outline" class="col-md-12 p-0">
                      <input matInput [matDatepicker]="receiveDate" [min]="today" readonly placeholder="Select Date" autocomplete="off" formControlName="productDetails" />
                      <mat-datepicker-toggle matIconSuffix [for]="receiveDate"></mat-datepicker-toggle>
                      <mat-datepicker #receiveDate></mat-datepicker>
                      <mat-error>Select receiving product date</mat-error>
                    </mat-form-field>
                  </div>


                  <div class="form-group col-md-6 col-xs-12">
                    <label for="">Is this an ongoing opportunity?<sup> &#42;</sup></label>
                    <mat-radio-group aria-label="Select General Questions" (change)="additionalDetails($event)" class="m-t" ngDefaultControl formControlName="ongoingOpportunity">
                      <mat-radio-button value="No">No</mat-radio-button>
                      <mat-radio-button value="Yes">Yes</mat-radio-button>
                    </mat-radio-group>
                    <mat-error class="custom-error" *ngIf="isFormSubmittedWarehousing && warehouseQuestionnaire.controls['ongoingOpportunity'].errors?.['required']">
                      Select
                      an Ongoing Opportunity</mat-error>
                  </div>

                  <div class="form-group col-md-6 col-xs-12" *ngIf="otherDetails">
                    <label for="">Enter Additional Details <sup> &#42;</sup></label>
                    <mat-form-field appearance="outline" class="col-md-12 p-0">
                      <input matInput type="input" placeholder="Enter Additional Details" (input)="alphaOnly($event)" formControlName="otherDetails" />
                      <mat-error *ngIf="otherDetails">Additional details is required</mat-error>
                    </mat-form-field>
                  </div>

                </div>

                <div class="row row-form">
                  <div class="form-group col-md-6 col-xs-12">
                    <label for="">Length of Term Required<sup> &#42;</sup></label>
                    <mat-form-field appearance="outline" class="col-md-12 p-0">
                      <input matInput type="input" placeholder="Enter Length of Term" (input)="alphaOnly($event)" formControlName="lengthRequired" />
                      <mat-error>Length of term is required</mat-error>
                    </mat-form-field>
                  </div>
                </div>

                <br>

                <mat-accordion>
                  <mat-expansion-panel class="mat-elevation-z0 accordion-custom warehouse-quenstionnaire" hideToggle="false">
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        Inbound
                      </mat-panel-title>
                    </mat-expansion-panel-header>

                    <div class="content-text">

                      <br>

                      <div class="row row-form">

                        <div class="form-group col-md-6 col-xs-12" formGroupName="inboundProductFB">
                          <label for="">How will we be receiving product?<sup> &#42;</sup></label>
                          <mat-checkbox-group aria-label="Select Receiving Product" class="m-t" ngDefaultControl>
                            <mat-checkbox formControlName="receivingContainer" value="container">Container
                            </mat-checkbox>
                            <mat-checkbox formControlName="receivingFTL" value="FTL">FTL
                            </mat-checkbox>
                            <mat-checkbox formControlName="receivingLTL" value="LTL">LTL
                            </mat-checkbox>
                            <mat-checkbox formControlName="receivingParcel" value="Parcel">
                              Parcel</mat-checkbox>
                            <mat-checkbox formControlName="receivingOther" value="Other" (change)="inboundOtherDetail($event)">
                              Other</mat-checkbox>
                          </mat-checkbox-group>

                          <mat-error class="custom-error" *ngIf="isFormSubmittedWarehousing && warehouseQuestionnaire.get('inboundProductFB')?.hasError('required')">
                            Please select at least one option</mat-error>

                        </div>

                        <div class="form-group col-md-6 col-xs-12" *ngIf="inboundOtherDetails">
                          <label for="">Enter Additional Details <sup> &#42;</sup></label>
                          <mat-form-field appearance="outline" class="col-md-12 p-0">
                            <input matInput type="input" placeholder="Enter Additional Details" (input)="alphaOnly($event)" formControlName="inboundOtherDetails" />
                            <mat-error>Additional details is required</mat-error>
                          </mat-form-field>
                        </div>

                        <div class="form-group col-md-6 col-xs-12">
                          <label for="">How many inbounds can we expect per month?<sup>
                              &#42;</sup></label>
                          <mat-form-field appearance="outline" class="col-md-12 p-0">
                            <input matInput type="input" placeholder="Enter Quantity" (input)="alphaOnly($event)" formControlName="inbound" />
                            <mat-error>Inbounds per month details are required</mat-error>
                          </mat-form-field>
                        </div>

                      </div>

                      <div class="row row-form">

                        <div class="form-group col-md-6 col-xs-12">
                          <label for="">Is there a peak season?<sup> &#42;</sup></label>
                          <mat-radio-group aria-label="Select peak season" class="m-t" (change)="peakSeason($event)" ngDefaultControl formControlName="peakSeason">
                            <mat-radio-button value="No">No</mat-radio-button>
                            <mat-radio-button value="Yes">Yes</mat-radio-button>
                          </mat-radio-group>
                          <mat-error class="custom-error" *ngIf="isFormSubmittedWarehousing && warehouseQuestionnaire.controls['peakSeason'].errors?.['required']">
                            Select Peak Season</mat-error>
                        </div>

                        <div class="form-group col-md-6 col-xs-12" *ngIf="peakSeasonDetails">
                          <label for="">Enter Peak Season<sup> &#42;</sup></label>
                          <mat-form-field appearance="outline" class="col-md-12 p-0">
                            <input matInput type="input" placeholder="Enter Peak Season" (input)="alphaOnly($event)" formControlName="peakSeasonDetails" />
                            <mat-error>Peak Season is required</mat-error>
                          </mat-form-field>
                        </div>

                        <div class="form-group col-md-6 col-xs-12" formGroupName="productIs">
                          <label for="">The Product is:<sup> &#42;</sup></label>
                          <mat-checkbox-group aria-label="Select product is" class="m-t" ngDefaultControl>
                            <mat-checkbox formControlName="productPalletized" value="Palletized">Palletized
                            </mat-checkbox>
                            <mat-checkbox formControlName="productFloorLoaded" value="Floor-loaded">Floor-loaded
                            </mat-checkbox>
                          </mat-checkbox-group>

                          <mat-error class="custom-error" *ngIf="isFormSubmittedWarehousing && warehouseQuestionnaire.get('productIs')?.hasError('required')">
                            Please select at least one option</mat-error>

                        </div>

                      </div>

                      <br>

                      <div class="row row-form">

                        <div class="form-group col-md-6 col-xs-12">
                          <label for="">How many pallets per inbound?<sup> &#42;</sup></label>
                          <mat-form-field appearance="outline" class="col-md-12 p-0">
                            <input matInput type="input" placeholder="Enter Quantity" (input)="alphaOnly($event)" formControlName="palletInbound" />
                            <mat-error>Pallets per inbound is required</mat-error>
                          </mat-form-field>
                        </div>

                        <div class="form-group col-md-6 col-xs-12">
                          <label for="">How many cases per inbound?<sup> &#42;</sup></label>
                          <mat-form-field appearance="outline" class="col-md-12 p-0">
                            <input matInput type="input" placeholder="Enter Quantity" (input)="alphaOnly($event)" formControlName="caseInbound" />
                            <mat-error>Cases per inbound is required</mat-error>
                          </mat-form-field>
                        </div>

                      </div>

                      <div class="row row-form">

                        <div class="form-group col-md-6 col-xs-12">
                          <label for="">How many different sku's per inbound?<sup> &#42;</sup></label>
                          <mat-form-field appearance="outline" class="col-md-12 p-0">
                            <input matInput type="input" placeholder="Enter Quantity" (input)="alphaOnly($event)" formControlName="sku" />
                            <mat-error>Sku's per inbound is required</mat-error>
                          </mat-form-field>
                        </div>

                        <div class="form-group col-md-6 col-xs-12">
                          <label for="">Is sorting or other special handling required on receipt?<sup>
                              &#42;</sup></label>
                          <mat-radio-group aria-label="Select Receiving Product" class="m-t" ngDefaultControl (change)="sortingDetails($event)" formControlName="specialHandling">
                            <mat-radio-button value="No">No</mat-radio-button>
                            <mat-radio-button value="Yes">Yes</mat-radio-button>
                            <mat-error class="custom-error" *ngIf="isFormSubmittedWarehousing && warehouseQuestionnaire.controls['specialHandling'].errors?.['required']">
                              Select special handling option</mat-error>
                          </mat-radio-group>
                        </div>

                        <div class="form-group col-md-6 col-xs-12" *ngIf="handlingDetails">
                          <label for="">Additional Details<sup> &#42;</sup></label>
                          <mat-form-field appearance="outline" class="col-md-12 p-0">
                            <input matInput type="input" placeholder="Enter Additional Details" (input)="alphaOnly($event)" formControlName="sortingAdditionalDetails" />
                            <mat-error>Additional Details is required</mat-error>
                          </mat-form-field>
                        </div>

                      </div>

                      <div class="row row-form">


                        <div class="form-group col-md-6 col-xs-12">
                          <label for="">Will we need to handle returns of any kind?<sup>
                              &#42;</sup></label>
                          <mat-radio-group aria-label="Select Receiving Product" class="m-t" ngDefaultControl (change)="kindHandlingDetails($event)" formControlName="handleReturns">
                            <mat-radio-button value="No">No</mat-radio-button>
                            <mat-radio-button value="Yes">Yes</mat-radio-button>
                            <mat-error class="custom-error" *ngIf="isFormSubmittedWarehousing && warehouseQuestionnaire.controls['handleReturns'].errors?.['required']">
                              Select handling option</mat-error>
                          </mat-radio-group>
                        </div>

                        <div class="form-group col-md-6 col-xs-12" *ngIf="returnHandlingDetails">
                          <label for="">Additional Details<sup> &#42;</sup></label>
                          <mat-form-field appearance="outline" class="col-md-12 p-0">
                            <input matInput type="input" placeholder="Enter Additional Details" (input)="alphaOnly($event)" formControlName="handlesortingAdditionalDetails" />
                            <mat-error>Additional Details is required</mat-error>
                          </mat-form-field>
                        </div>

                      </div>

                    </div>

                  </mat-expansion-panel>

                  <mat-expansion-panel class="mat-elevation-z0 accordion-custom warehouse-quenstionnaire" hideToggle="false">
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        Inventory
                      </mat-panel-title>
                    </mat-expansion-panel-header>

                    <div class="content-text">

                      <br>

                      <div class="row row-form">

                        <div class="form-group col-md-6 col-xs-12">
                          <label for="">How many pallets can we expect to have in inventory on
                            average?<sup> &#42;</sup></label>
                          <mat-form-field appearance="outline" class="col-md-12 p-0">
                            <input matInput type="input" placeholder="Enter Quantity" (input)="alphaOnly($event)" formControlName="palletAvg" />
                            <mat-error>Pallets of quantity is required</mat-error>
                          </mat-form-field>
                        </div>

                        <div class="form-group col-md-6 col-xs-12">
                          <label for="">What is the maximum number of pallets required per month?<sup>
                              &#42;</sup></label>
                          <mat-form-field appearance="outline" class="col-md-12 p-0">
                            <input matInput type="input" placeholder="Enter Quantity" (input)="alphaOnly($event)" formControlName="maxPallets" />
                            <mat-error>Pallets of quantity is required</mat-error>
                          </mat-form-field>
                        </div>

                      </div>

                      <div class="row row-form">

                        <div class="form-group col-md-6 col-xs-12">
                          <label for="">Are the pallets standard 40"x48"?<sup> &#42;</sup></label>
                          <mat-radio-group aria-label="Select Pallets Stackable" class="m-t" ngDefaultControl formControlName="palletsStandard" (change)="togglePallet($event)">
                            <mat-radio-button value="Yes">Yes</mat-radio-button>
                            <mat-radio-button value="No">No
                            </mat-radio-button>
                          </mat-radio-group>
                          <mat-error class="custom-error" *ngIf="isFormSubmittedWarehousing && warehouseQuestionnaire.controls['palletsStandard'].errors?.['required']">
                            Select pallets standards</mat-error>
                        </div>

                        <div *ngIf="dimensions" class="form-group col-md-6 col-xs-12">
                          <label for="">Dimensions (L x W x H in.)<sup> &#42;</sup></label>
                          <mat-form-field appearance="outline" class="col-md-12 p-0">
                            <input matInput type="input" maxlength="12" placeholder="Enter L X W X H" (input)="alphaOnly($event)" autocomplete="off" formControlName="dimensions" />
                            <mat-error>Dimensions are required</mat-error>
                          </mat-form-field>
                        </div>

                        <div class="form-group col-md-6 col-xs-12">
                          <label for="">Are the pallets stackable? How high?<sup> &#42;</sup></label>
                          <mat-radio-group aria-label="Select Pallets Stackable" (change)="stackablePallets($event)" class="m-t" ngDefaultControl formControlName="palletsStackable">
                            <mat-radio-button value="No">No</mat-radio-button>
                            <mat-radio-button value="Yes">Yes</mat-radio-button>
                          </mat-radio-group>
                          <mat-error class="custom-error" *ngIf="isFormSubmittedWarehousing && warehouseQuestionnaire.controls['palletsStackable'].errors?.['required']">
                            Select pallets stackable</mat-error>
                        </div>

                        <div *ngIf="palletHeight" class="form-group col-md-6 col-xs-12">
                          <label for="">Maximum Stack Height<sup> &#42;</sup></label>
                          <mat-form-field appearance="outline" class="col-md-12 p-0">
                            <input matInput type="input" maxlength="12" placeholder="Enter Maximum Stack Height" (input)="alphaOnly($event)" autocomplete="off" formControlName="maxHeight" />
                            <mat-error>Stack height is required</mat-error>
                          </mat-form-field>
                        </div>

                      </div>

                      <br>

                      <div class="row row-form">

                        <div class="form-group col-md-6 col-xs-12">
                          <label for="">What is the average weight per pallet ?<sup>
                              &#42;</sup></label>
                          <mat-form-field appearance="outline" class="col-md-12 p-0">
                            <input matInput type="input" maxlength="12" placeholder="Enter Average Weight" autocomplete="off" formControlName="avgWeight" />
                            <mat-error>Average weight per pallet is required</mat-error>
                          </mat-form-field>
                        </div>

                        <div class="form-group col-md-6 col-xs-12" formGroupName="inventoryRequiredFB">
                          <label for="">Type of storage required:<sup> &#42;</sup></label>
                          <mat-checkbox-group aria-label="Select storage" class="m-t" ngDefaultControl>
                            <mat-checkbox formControlName="storageRequiredFloor" value="Floor">
                              Floor</mat-checkbox>
                            <mat-checkbox formControlName="storageRequiredRacking" value="Racking">
                              Racking</mat-checkbox>
                            <mat-checkbox formControlName="storageRequiredOther" value="Other" (change)="typeOfStorage($event)">Other
                            </mat-checkbox>
                          </mat-checkbox-group>
                          <mat-error class="custom-error" *ngIf="isFormSubmittedWarehousing && warehouseQuestionnaire.get('inventoryRequiredFB')?.hasError('required')">
                            Please select at least one option</mat-error>

                        </div>

                        <div *ngIf="storageRequired" class="form-group col-md-6 col-xs-12">
                          <label for="">Additional Details<sup> &#42;</sup></label>
                          <mat-form-field appearance="outline" class="col-md-12 p-0">
                            <input matInput type="input" maxlength="12" placeholder="Enter Additional Details" (input)="alphaOnly($event)" autocomplete="off" formControlName="storageAdditionalDetails" />
                            <mat-error>Additional details is required</mat-error>
                          </mat-form-field>
                        </div>

                      </div>

                      <div class="row row-form">

                        <div class="form-group col-md-6 col-xs-12">
                          <label for="">Is there a peak season?<sup> &#42;</sup></label>
                          <mat-radio-group aria-label="Select Peak Season For Inventory" (change)="InventoryPeak($event)" class="m-t" ngDefaultControl formControlName="inventoryPeak">
                            <mat-radio-button value="No">No</mat-radio-button>
                            <mat-radio-button value="Yes">Yes</mat-radio-button>
                          </mat-radio-group>
                          <mat-error class="custom-error" *ngIf="isFormSubmittedWarehousing && warehouseQuestionnaire.controls['inventoryPeak'].errors?.['required']">
                            Select peak season</mat-error>
                        </div>

                        <div *ngIf="peakSeasonInventory" class="form-group col-md-6 col-xs-12">
                          <label for="">Peak Season<sup> &#42;</sup></label>
                          <mat-form-field appearance="outline" class="col-md-12 p-0">
                            <input matInput type="input" maxlength="12" placeholder="Enter Peak Season" (input)="alphaOnly($event)" autocomplete="off" formControlName="inventoryPeakSeason" />
                            <mat-error>Peak season details is required</mat-error>
                          </mat-form-field>
                        </div>

                        <div class="form-group col-md-6 col-xs-12">
                          <label for="">Average cases per pallet<sup> &#42;</sup></label>
                          <mat-form-field appearance="outline" class="col-md-12 p-0">
                            <input matInput type="input" placeholder="Enter Quantity" (input)="alphaOnly($event)" formControlName="avgCase" />
                            <mat-error>Average cases per pallet is required</mat-error>
                          </mat-form-field>
                        </div>

                      </div>

                      <div class="row row-form">

                        <div class="form-group col-md-6 col-xs-12">
                          <label for="">How long do you expect inventory to sit on average? <sup>
                              &#42;</sup></label>
                          <mat-form-field appearance="outline" class="col-md-12 p-0">
                            <input matInput type="input" placeholder="Enter Expected Time Frame" (input)="alphaOnly($event)" formControlName="inventoryAvgSit" />
                            <mat-error>Time frame is required</mat-error>
                          </mat-form-field>
                        </div>

                        <div class="form-group col-md-6 col-xs-12">
                          <label for="">Number of turns per year <sup>
                              &#42;</sup></label>
                          <mat-form-field appearance="outline" class="col-md-12 p-0">
                            <input matInput type="input" (input)="alphaOnly($event)" placeholder="Enter Number of Turns Per Year" formControlName="turnsYear" />
                            <mat-error>Number of turns per year is required</mat-error>
                          </mat-form-field>
                        </div>

                      </div>

                      <div class="row row-form">

                        <div class="form-group col-md-6 col-xs-12">
                          <label for="">How many different sku’s will we have in inventory?<sup>
                              &#42;</sup></label>
                          <mat-form-field appearance="outline" class="col-md-12 p-0">
                            <input matInput type="input" placeholder="Enter Quantity" (input)="alphaOnly($event)" formControlName="skuInventory" />
                            <mat-error>Sku's quantity is required</mat-error>
                          </mat-form-field>
                        </div>

                        <div class="form-group col-md-6 col-xs-12" formGroupName="requimentCertificateFB">
                          <label for="">Any Special requirement/certifications required?<sup>
                              &#42;</sup></label>
                          <mat-checkbox-group aria-label="Select Pallets Stackable" class="m-t" ngDefaultControl>
                            <mat-checkbox formControlName="rcClimateControl" value="Climate Control">Climate Control
                            </mat-checkbox>
                            <mat-checkbox formControlName="rcHazmat" value="Hazmat">
                              Hazmat</mat-checkbox>
                            <mat-checkbox formControlName="rcFoodGrade" value="Food Grade">
                              Food Grade</mat-checkbox>
                            <mat-checkbox formControlName="rcOther" value="Other" (change)="requirementCertification($event)">Other
                            </mat-checkbox>
                          </mat-checkbox-group>
                          <mat-error class="custom-error" *ngIf="isFormSubmittedWarehousing && warehouseQuestionnaire.get('requimentCertificateFB')?.hasError('required')">
                            Please select at least one option</mat-error>

                        </div>

                        <div *ngIf="certificationDetails" class="form-group col-md-6 col-xs-12">
                          <label for="">Additional Details<sup> &#42;</sup></label>
                          <mat-form-field appearance="outline" class="col-md-12 p-0">
                            <input matInput type="input" maxlength="12" placeholder="Enter Additional Details" (input)="alphaOnly($event)" autocomplete="off" formControlName="certificationAdditionalDetails" />
                            <mat-error>Additional details is required</mat-error>
                          </mat-form-field>
                        </div>

                      </div>

                    </div>

                  </mat-expansion-panel>

                  <mat-expansion-panel class="mat-elevation-z0 accordion-custom warehouse-quenstionnaire" hideToggle="false">
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        Outbound
                      </mat-panel-title>
                    </mat-expansion-panel-header>

                    <div class="content-text">

                      <br>

                      <div class="row row-form">

                        <div class="form-group col-md-6 col-xs-12" formGroupName="obLeavingFacilityFB">
                          <label for="">How will product be leaving our facility? <sup>
                              &#42;</sup></label>
                          <mat-checkbox-group aria-label="Select Product Leaving Facility" class="m-t" ngDefaultControl>
                            <mat-checkbox formControlName="leavingFacilityContainer" value="Container">Container
                            </mat-checkbox>
                            <mat-checkbox formControlName="leavingFacilityFTL" value="FTL">FTL
                            </mat-checkbox>
                            <mat-checkbox formControlName="leavingFacilityLTL" value="LTL">LTL
                            </mat-checkbox>
                            <mat-checkbox formControlName="leavingFacilityParcel" value="Parcel">
                              Parcel
                            </mat-checkbox>
                            <mat-checkbox formControlName="leavingFacilityOther" value="Other" (change)="otherFacility($event)">Other
                            </mat-checkbox>
                          </mat-checkbox-group>
                          <mat-error class="custom-error" *ngIf="isFormSubmittedWarehousing && warehouseQuestionnaire.get('obLeavingFacilityFB')?.hasError('required')">
                            Please select at least one option</mat-error>
                        </div>

                        <div *ngIf="leavingFacility" class="form-group col-md-6 col-xs-12">
                          <label for="">Additional Details<sup> &#42;</sup></label>
                          <mat-form-field appearance="outline" class="col-md-12 p-0">
                            <input matInput type="input" maxlength="12" placeholder="Enter Additional Details" (input)="alphaOnly($event)" autocomplete="off" formControlName="facilityAdditionalDetails" />
                            <mat-error>Additional details is required</mat-error>
                          </mat-form-field>
                        </div>

                        <div class="form-group col-md-6 col-xs-12">
                          <label for="">How many orders can we expect per month?<sup> &#42;</sup></label>
                          <mat-form-field appearance="outline" class="col-md-12 p-0">
                            <input matInput type="input" placeholder="Enter Quantity" (input)="alphaOnly($event)" formControlName="orderPerMonth" />
                            <mat-error>Additional details is required</mat-error>
                          </mat-form-field>
                        </div>

                      </div>

                      <div class="row row-form">

                        <div class="form-group col-md-6 col-xs-12">
                          <label for="">Is there a peak season?<sup> &#42;</sup></label>
                          <mat-radio-group aria-label="Select peak season" (change)="outboundPeakSeason($event)" class="m-t" ngDefaultControl formControlName="outboundPeakSeason">
                            <mat-radio-button value="No">No</mat-radio-button>
                            <mat-radio-button value="Yes">Yes</mat-radio-button>
                          </mat-radio-group>
                          <mat-error class="custom-error" *ngIf="isFormSubmittedWarehousing && warehouseQuestionnaire.controls['outboundPeakSeason'].errors?.['required']">
                            Select peak season</mat-error>
                        </div>

                        <div *ngIf="outboundPeak" class="form-group col-md-6 col-xs-12">
                          <label for="">Additional Details<sup> &#42;</sup></label>
                          <mat-form-field appearance="outline" class="col-md-12 p-0">
                            <input matInput type="input" maxlength="12" placeholder="Enter Peak Season" (input)="alphaOnly($event)" autocomplete="off" formControlName="outboundPeak" />
                            <mat-error>Additional details is required</mat-error>
                          </mat-form-field>
                        </div>

                        <div class="form-group col-md-6 col-xs-12">
                          <label for="">What type of picking is required?<sup> &#42;</sup></label>
                          <mat-radio-group aria-label="Select peak season" class="m-t" ngDefaultControl formControlName="pickingRequired" (change)="pickingType($event)">
                            <mat-radio-button value="Pallet">Pallet</mat-radio-button>
                            <mat-radio-button value="Case/Carton">Case/Carton</mat-radio-button>
                            <mat-radio-button value="Each/Pieces">Each/Pieces</mat-radio-button>
                          </mat-radio-group>
                          <mat-error class="custom-error" *ngIf="isFormSubmittedWarehousing && warehouseQuestionnaire.controls['pickingRequired'].errors?.['required']">
                            Select type of pick is required</mat-error>
                        </div>

                      </div>

                      <br>

                      <div class="row row-form">

                        <div class="form-group col-md-6 col-xs-12" *ngIf="palletOrder">
                          <label for="">Average Number of Pallet(s) per Order<sup>
                              &#42;</sup></label>
                          <mat-form-field appearance="outline" class="col-md-12 p-0">
                            <input matInput type="input" placeholder="Enter Quantity" (input)="alphaOnly($event)" formControlName="perOrderAvg" />
                          </mat-form-field>
                        </div>

                        <div class="form-group col-md-6 col-xs-12" *ngIf="caseOrder">
                          <label for="">Average Number of Case(s)/Carton(s) per Order<sup>
                              &#42;</sup></label>
                          <mat-form-field appearance="outline" class="col-md-12 p-0">
                            <input matInput type="input" placeholder="Enter Quantity" (input)="alphaOnly($event)" formControlName="caseOrderAvg" />
                          </mat-form-field>
                        </div>

                        <div class="form-group col-md-6 col-xs-12" *ngIf="pieceOrder">
                          <label for="">Average Number of Each/Pieces per Order<sup>
                              &#42;</sup></label>
                          <mat-form-field appearance="outline" class="col-md-12 p-0">
                            <input matInput type="input" placeholder="Enter Quantity" (input)="alphaOnly($event)" formControlName="pieceOrderAvg" />
                          </mat-form-field>
                        </div>

                      </div>

                      <div class="row row-form">

                        <div class="form-group col-md-6 col-xs-12">
                          <label for="">How many lines per order on average?<sup> &#42;</sup></label>
                          <mat-form-field appearance="outline" class="col-md-12 p-0">
                            <input matInput type="input" placeholder="Enter Quantity" (input)="alphaOnly($event)" formControlName="linePerOrderAvg" />
                            <mat-error>Enter lines per order on average</mat-error>
                          </mat-form-field>


                        </div>

                      </div>

                    </div>

                  </mat-expansion-panel>

                  <mat-expansion-panel class="mat-elevation-z0 accordion-custom warehouse-quenstionnaire" hideToggle="false">
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        Transportation
                      </mat-panel-title>
                    </mat-expansion-panel-header>

                    <div class="content-text">

                      <br>

                      <div class="row row-form">

                        <div class="form-group col-md-6 col-xs-12">
                          <label for="">Do you need a quote for the inbound transportation? </label>
                          <mat-radio-group aria-label="Select option" class="m-t" ngDefaultControl formControlName="outboundPeakSeasonTransportInbound" (change)="inboundTransport($event)">
                            <mat-radio-button value="No">No</mat-radio-button>
                            <mat-radio-button value="Yes">Yes</mat-radio-button>
                          </mat-radio-group>
                          <mat-error class="custom-error" *ngIf="isFormSubmittedWarehousing && warehouseQuestionnaire.controls['outboundPeakSeasonTransportInbound'].errors?.['required']">
                            Select quote for the inbound transportation</mat-error>
                        </div>

                      </div>

                      <br>

                      <div class="row row-form" *ngIf="inbound">

                        <div class="form-group col-md-6 col-xs-12">
                          <label for="">Inbound Transportation<sup>
                              &#42;</sup></label>
                          <mat-radio-group aria-label="Select option" class="m-t" ngDefaultControl formControlName="outboundPeakSeasonTransportInboundReason">
                            <mat-radio-button value="FTL">FTL
                            </mat-radio-button>
                            <mat-radio-button value="LTL">LTL
                            </mat-radio-button>
                            <mat-radio-button value="Container">
                              Container</mat-radio-button>
                            <mat-radio-button value="Flatbed">Flatbed
                            </mat-radio-button>
                          </mat-radio-group>
                          <mat-error class="custom-error" *ngIf="isFormSubmittedWarehousing && warehouseQuestionnaire.controls['outboundPeakSeasonTransportInboundReason'].errors?.['required']">
                            Select inbound transportation</mat-error>
                        </div>

                        <div class="form-group col-md-6 col-xs-12">
                          <label for="">Zip Code<sup>
                              &#42;</sup></label>
                          <mat-form-field appearance="outline" class="col-md-12 p-0">
                            <input matInput type="input" placeholder="Enter Zip" (input)="alphaOnly($event)" formControlName="inboundZip" />
                            <mat-error>Enter Zip Code</mat-error>
                          </mat-form-field>
                        </div>

                      </div>

                      <div class="row row-form">

                        <div class="form-group col-md-6 col-xs-12">
                          <label for="">Legal or overweight?</label>
                          <mat-form-field appearance="outline" class="col-md-12 p-0">
                            <input matInput type="input" placeholder="Enter Legal or Overweight Details" (input)="alphaOnly($event)" formControlName="legalOverweightInbound" />
                            <mat-error>Enter legal or overweight</mat-error>
                          </mat-form-field>
                        </div>

                      </div>

                      <div class="row row-form">

                        <div class="form-group col-md-6 col-xs-12">
                          <label for="">Do you need a quote for outbound transportation?</label>
                          <mat-radio-group aria-label="Select option" class="m-t" ngDefaultControl formControlName="outboundPeakSeasonTransportOutbound" (change)="outboundTransport($event)">
                            <mat-radio-button value="No">No</mat-radio-button>
                            <mat-radio-button value="Yes">Yes</mat-radio-button>
                          </mat-radio-group>
                          <mat-error class="custom-error" *ngIf="isFormSubmittedWarehousing && warehouseQuestionnaire.controls['outboundPeakSeasonTransportOutbound'].errors?.['required']">
                            Select quote for the outbound transportation</mat-error>
                        </div>

                      </div>

                      <br>

                      <div class="row row-form" *ngIf="outbound">

                        <div class="form-group col-md-6 col-xs-12">
                          <label for="">Outbound Transportation<sup>
                              &#42;</sup></label>
                          <mat-radio-group aria-label="Select option" class="m-t" ngDefaultControl formControlName="outboundPeakSeasonTransportOutboundReason">
                            <mat-radio-button value="FTL">FTL
                            </mat-radio-button>
                            <mat-radio-button value="LTL">LTL
                            </mat-radio-button>
                            <mat-radio-button value="Container">
                              Container</mat-radio-button>
                            <mat-radio-button value="Flatbed">Flatbed
                            </mat-radio-button>
                          </mat-radio-group>
                          <mat-error class="custom-error" *ngIf="isFormSubmittedWarehousing && warehouseQuestionnaire.controls['outboundPeakSeasonTransportOutboundReason'].errors?.['required']">
                            Select outbound transportation</mat-error>
                        </div>

                        <div class="form-group col-md-6 col-xs-12">
                          <label for="">Zip Code<sup>
                              &#42;</sup></label>
                          <mat-form-field appearance="outline" class="col-md-12 p-0">
                            <input matInput type="input" placeholder="Enter Zip" (input)="alphaOnly($event)" formControlName="OutboundZip" />
                            <mat-error>Enter Zip Code</mat-error>
                          </mat-form-field>
                        </div>

                      </div>

                      <div class="row row-form">

                        <div class="form-group col-md-6 col-xs-12">
                          <label for="">Legal or overweight?</label>
                          <mat-form-field appearance="outline" class="col-md-12 p-0">
                            <input matInput type="input" placeholder="Enter Legal or Overweight Details" (input)="alphaOnly($event)" formControlName="legalOverweightOutbound" />
                            <mat-error>Enter legal or overweight</mat-error>
                          </mat-form-field>
                        </div>
                      </div>

                    </div>


                  </mat-expansion-panel>

                </mat-accordion>

                <br>

                <br>

                <div class="row row-form">
                  <div class="col-md-12 col-xs-12">
                    <h4 class="header-text header-sub-size py-2 remove-underline">
                      Reply to Quote by
                    </h4>
                  </div>

                  <div class="col-md-6 col-xs-12 column-direction">
                    <mat-checkbox ngDefaultControl formControlName="warehouseQuesPhone">Phone</mat-checkbox>
                    <mat-checkbox ngDefaultControl formControlName="warehouseQuesEmail">Email</mat-checkbox>
                  </div>
                </div>

                <br />
                <br>

                <div class="row">
                  <div class="col-md-12 p-0">
                    <p class="m-0">
                      If you were referred by your Saia Account Executive /
                      Saia Account Exec - the following information is
                      required:
                    </p>
                  </div>
                </div>

                <br />

                <div class="row row-form">
                  <div class="form-group col-md-4 col-xs-12">
                    <label for="">Saia Account Number</label>
                    <mat-form-field appearance="outline" class="col-md-12 p-0">
                      <input matInput type="input" placeholder="Enter Saia Account Number" autocomplete="off" formControlName="saiaAccountNumber" />
                    </mat-form-field>
                  </div>

                  <!-- <div class="form-group col-md-4 col-xs-12">
                    <label for="">Saia Sales Rep</label>
                    <mat-form-field appearance="outline" class="col-md-12 p-0">
                      <input matInput type="input" placeholder="Enter Saia Sales Rep" autocomplete="off" formControlName="saiaSalesRep" />
                    </mat-form-field>
                  </div>

                  <div class="form-group col-md-4 col-xs-12">
                    <label for="">Saia Sales Rep Email</label>
                    <mat-form-field appearance="outline" class="col-md-12 p-0">
                      <input matInput type="input" placeholder="Enter Email ID" autocomplete="off" formControlName="saiaSalesRepEmail" />
                    </mat-form-field>
                  </div> -->
                </div>

                <div class="row row-form">
                  <div class="col-md-12 col-xs-12">
                    <h4 class="header-text header-sub-size py-2 remove-underline">
                      Notes
                    </h4>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12 col-xs-12 p-0">

                    <ul class="list-style">
                      <li> We use standard pallets (40”x48”) as our default unit of measure, please feel
                        free
                        to substitute your own unit of measure as needed </li>
                      <li> All additional paperwork is welcomed, i.e. item/sku lists, sample packing
                        lists,
                        sample orders, product lists with dimensions, velocity reports, etc </li>
                      <li> Pictures, if available, are always extremely helpful </li>
                      <li>Please send additional paperwork and/or pictures to warehousequotes@linkex.us</li>
                    </ul>

                  </div>
                </div>


                <div class="row center-xs">
                  <div class="col-md-4"></div>
                  <div class="col-md-4">
                    <button mat-flat-button class="primary-btn" (click)="submitWarehousingQuotes()">
                      Submit
                    </button>
                  </div>
                  <div class="col-md-4 end-xs p-0">
                    <button mat-button class="primary-btn link" type="reset" (click)="clearWarehousing()">Clear</button>
                  </div>
                </div>

                <br /><br />
              </form>

            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="International">
        <div class="content-section py-0">
          <div class="row">
            <div class="col-md-12 col-xs-12">
              <h4 class="header-text text-blue py-2">Request a Quote!</h4>
              <div class="error" *ngIf="internationalStatusError">
                <span>Error</span> | Please fill out all highlighted form fields correctly and then try submitting again
              </div>

              <div class="success" *ngIf="internationalStatus">
                Thank you for submitting a quote request. Someone will get back to you shortly.
              </div>
              <div *ngIf="isFormSubmittedInternationalApiError" class="error">
                {{apiResponse.message}}
              </div>
              <form [formGroup]="international" #ngInternational="ngForm" (keydown)="onKeyDown($event)" class=" " autocomplete="off">
                <div class="row row-form">
                  <div class="form-group col-md-6 col-xs-12">
                    <label for="">Company Name<sup> &#42;</sup></label>
                    <mat-form-field appearance="outline" class="col-md-12 p-0">
                      <input matInput type="input" placeholder="Enter Company Name" autocomplete="off" formControlName="yourCompany" />
                      <mat-error> Enter Valid Company Name </mat-error>
                    </mat-form-field>
                  </div>

                  <div class="form-group col-md-6 col-xs-12">
                    <label for="">Contact Name<sup> &#42;</sup></label>
                    <mat-form-field appearance="outline" class="col-md-12 p-0">
                      <input matInput type="input" placeholder="Enter Contact Name" formControlName="yourName" (input)="alphaOnly($event)" />
                      <mat-error> Enter Valid Contact Name </mat-error>
                    </mat-form-field>
                  </div>
                </div>
                <div class="row row-form">

                  <div class="form-group col-md-6 col-xs-12">
                    <label for="">Email<sup> &#42;</sup></label>
                    <mat-form-field appearance="outline" class="col-md-12 p-0">
                      <input matInput type="input" placeholder="Enter Email ID" formControlName="yourEmail" />
                      <mat-error> Enter Valid Email </mat-error>
                    </mat-form-field>
                  </div>

                  <div class="form-group col-md-6 col-xs-12">
                    <label for="">Phone<sup> &#42;</sup></label>
                    <mat-form-field appearance="outline" class="col-md-12 p-0">
                      <input matInput type="input" maxlength="12" (input)="numberOnly($event)" inputmode="numeric" placeholder="Enter Phone Number" autocomplete="off" formControlName="yourPhone" />
                      <mat-error> Enter Valid Phone Number </mat-error>
                    </mat-form-field>
                  </div>
                </div>

                <div class="row row-form">
                  <div class="form-group col-md-6 col-xs-12">
                    <label for="">Company Street Address<sup> &#42;</sup></label>
                    <mat-form-field appearance="outline" class="col-md-12 p-0">
                      <input matInput type="input" placeholder="Enter Street Address" autocomplete="off" formControlName="yourAddress" />
                      <mat-error> Enter Valid Company Street Address </mat-error>
                    </mat-form-field>
                  </div>

                  <div class="form-group col-md-6 col-xs-12">
                    <label for="">City<sup> &#42;</sup></label>
                    <mat-form-field appearance="outline" class="col-md-12 p-0">
                      <input matInput type="input" placeholder="Enter City" formControlName="yourCity" (input)="alphaOnly($event)" />
                      <mat-error> Enter Valid City </mat-error>
                    </mat-form-field>
                  </div>
                </div>

                <div class="row row-form">
                  <div class="form-group col-md-6 col-xs-12">
                    <label for="">State<sup> &#42;</sup></label>
                    <mat-form-field appearance="outline" class="col-md-12 p-0">
                      <input matInput type="input" placeholder="Enter State" autocomplete="off" formControlName="yourState" (input)="alphaOnly($event)" />
                      <mat-error> Enter Valid State </mat-error>
                    </mat-form-field>
                  </div>

                  <div class="form-group col-md-6 col-xs-12">
                    <label for="">Zip<sup> &#42;</sup></label>
                    <mat-form-field appearance="outline" class="col-md-12 p-0">
                      <input matInput type="input" placeholder="Enter Zip" formControlName="yourZip" (input)="alphaOnly($event)" />
                      <mat-error> Enter Valid Zip </mat-error>
                    </mat-form-field>
                  </div>
                </div>

                <hr>

                <div class="row row-form">
                  <div class="col-md-12 col-xs-12">
                    <h4 class="header-text header-sub-size py-2 remove-underline">
                      Cargo Type <sup> &#42;</sup>
                    </h4>
                  </div>

                  <div class="form-group col-md-6 col-xs-12" formGroupName="cargoTypeFB">
                    <mat-checkbox-group aria-label="Select Load Type" class="m-t" ngDefaultControl>
                      <mat-checkbox ngDefaultControl formControlName="air">Air</mat-checkbox>
                      <mat-checkbox ngDefaultControl formControlName="ocean">Ocean</mat-checkbox>
                    </mat-checkbox-group>
                    <mat-error class="custom-error" *ngIf="isFormSubmittedInternational && international.get('cargoTypeFB')?.hasError('required')">
                      Please select at least one option</mat-error>
                  </div>

                </div>

                <br />

                <div class="row row-form">
                  <div class="col-md-12 col-xs-12">
                    <h4 class="header-text header-sub-size py-2 remove-underline">
                      Load Type <sup> &#42;</sup>
                    </h4>
                  </div>

                  <div class="form-group col-md-6 col-xs-12">

                    <mat-radio-group aria-label="Select Load type" ngDefaultControl formControlName="loadType" (change)="fclQuestionFn($event)">
                      <mat-radio-button value="LCL">Less than Container Load (LCL)</mat-radio-button>
                      <mat-radio-button value="FCL">Full Container Load (FCL)</mat-radio-button>
                    </mat-radio-group>
                    <mat-error class="custom-error" *ngIf="isFormSubmittedInternational && international.controls['loadType'].errors?.['required']">
                      Please Select Load Type</mat-error>
                  </div>

                </div>

                <br><br>

                <div class="row row-form" *ngIf="fclQuestion">

                  <div class="form-group col-md-12 col-xs-12">
                    <label for="">Specify Container Size<sup>
                        &#42;</sup></label>

                    <mat-checkbox-group aria-label="Select Container Size" class="m-t" ngDefaultControl formGroupName="containerSizeFB">
                      <mat-checkbox ngDefaultControl formControlName="twentyContainer">20' Container</mat-checkbox>
                      <mat-checkbox ngDefaultControl formControlName="fortyContainerStd">40' Standard Container
                      </mat-checkbox>
                      <mat-checkbox ngDefaultControl formControlName="fortyContainerHigh">40' High Cube Container
                      </mat-checkbox>
                    </mat-checkbox-group>
                    <mat-error class="custom-error" *ngIf="isFormSubmittedInternational && international.get('containerSizeFB')?.hasError('required')">
                      Please select at least one option</mat-error>
                  </div>
                </div>

                <br>

                <hr />

                <div class="row">
                  <div class="form-group">
                    <label for="">Commodity Description<sup> &#42;</sup></label>
                    <mat-form-field appearance="outline" class="col-md-12 p-0 textarea">
                      <textarea matInput maxlength="255" placeholder="Enter Commodity Description" rows="3" autocomplete="off" formControlName="description"></textarea>
                      <mat-error>Please enter Commodity Description</mat-error>
                    </mat-form-field>
                  </div>
                </div>

                <hr />

                <div class="row row-form">
                  <div class="col-md-12 col-xs-12">
                    <h4 class="header-text header-sub-size py-2 remove-underline">
                      Shipment Details
                    </h4>
                  </div>
                </div>

                <div class="row row-form">
                  <div class="form-group col-md-6 col-xs-12">
                    <label for="">Origin Address<sup> &#42;</sup></label>
                    <mat-form-field appearance="outline" class="col-md-12 p-0">
                      <input matInput type="input" maxlength="255" placeholder="Enter Origin" formControlName="originName" />
                      <mat-error>Please enter origin address</mat-error>

                    </mat-form-field>
                  </div>

                  <div class="form-group col-md-6 col-xs-12">
                    <label for="">Destination Address<sup> &#42;</sup></label>
                    <mat-form-field appearance="outline" class="col-md-12 p-0">
                      <input matInput type="input" maxlength="255" placeholder="Enter Destination" autocomplete="off" formControlName="destinationName" />
                      <mat-error>Please enter destination address</mat-error>
                    </mat-form-field>
                  </div>
                </div>

                <div class="row row-form">
                  <div class="form-group col-md-6 col-xs-12">
                    <label for="">Piece Count<sup> &#42;</sup></label>
                    <mat-form-field appearance="outline" class="col-md-12 p-0">
                      <input matInput type="input" maxlength="8" (input)="numberOnly($event)" inputmode="numeric" placeholder="Enter Piece Count" formControlName="pieceCount" />
                      <mat-error>Please enter Piece Count</mat-error>
                    </mat-form-field>
                  </div>

                  <div class="form-group col-md-6 col-xs-12">
                    <label for="">Weight (lbs.)<sup> &#42;</sup></label>
                    <mat-form-field appearance="outline" class="col-md-12 p-0">
                      <input matInput type="input" maxlength="8" (input)="numberOnly($event)" inputmode="numeric" placeholder="Enter Weight" formControlName="weight" />
                      <mat-error>Enter valid weight</mat-error>
                    </mat-form-field>
                  </div>


                </div>

                <div class="row row-form">
                  <div class="form-group col-md-6 col-xs-12">
                    <label for="">Dimensions (L x W x H in.)<sup> &#42;</sup></label>
                    <mat-form-field appearance="outline" class="col-md-12 p-0">
                      <input matInput type="input" maxlength="12" (input)="alphaOnly($event)" placeholder="Enter Dimensions" autocomplete="off" formControlName="dimensions" />
                      <mat-error>Enter valid Dimensions</mat-error>
                    </mat-form-field>
                  </div>

                  <div class="form-group col-md-3 col-xs-12">
                    <label for="">Hazmat<sup> &#42;</sup></label>
                    <mat-form-field appearance="outline" class="col-md-12 p-0">
                      <mat-select placeholder="Select Hazmat" ngDefaultControl formControlName="hazmatType">
                        <mat-option value="Yes"> Yes </mat-option>
                        <mat-option value="No"> No </mat-option>
                      </mat-select>
                      <mat-error> Please select Hazmat</mat-error>
                    </mat-form-field>
                  </div>

                  <div class="form-group col-md-3 col-xs-12">
                    <label for="">Cargo Insurance Value<sup> &#42;</sup></label>
                    <mat-form-field appearance="outline" class="col-md-12 p-0">
                      <input matInput type="input" maxlength="15" inputmode="numeric" placeholder="Enter Cargo Insurance Value" autocomplete="off" formControlName="value" />
                      <mat-error> Enter Valid Insurance Value</mat-error>
                    </mat-form-field>
                  </div>

                </div>



                <hr />

                <div class="row row-form">
                  <div class="form-group col-md-6 col-xs-12">
                    <label for="">Incoterms<sup> &#42;</sup>
                      <a href="/assets/docs/incoterms-2020.pdf" target="_blank" class="link-share">(view
                        chart)</a></label>
                    <mat-form-field appearance="outline" class="col-md-12 p-0">
                      <mat-select placeholder="Select Term" ngDefaultControl formControlName="incoterms">
                        <mat-option *ngFor="let incoterms of incoterms" [value]="incoterms.value">
                          {{ incoterms.viewValue }}
                        </mat-option>
                      </mat-select>
                      <mat-error> Please Select Service </mat-error>
                    </mat-form-field>
                  </div>
                </div>

                <hr />

                <div class="row">
                  <div class="form-group">
                    <label for="">Special Handling Requirements<sup> &#42;</sup></label>
                    <mat-form-field appearance="outline" class="col-md-12 p-0 textarea">
                      <textarea matInput maxlength="255" placeholder="Enter Special Handling Requirements" rows="3" autocomplete="off" formControlName="handling"></textarea>
                      <mat-error> Enter Valid Special Handling Requirements </mat-error>
                    </mat-form-field>
                  </div>
                </div>

                <br>

                <div class="row row-form">
                  <div class="col-md-12 col-xs-12">
                    <h4 class="header-text header-sub-size py-2 remove-underline">
                      Reply to Quote by
                    </h4>
                  </div>

                  <div class="col-md-6 col-xs-12 column-direction">
                    <mat-checkbox ngDefaultControl formControlName="replyBy1">Phone</mat-checkbox>
                    <mat-checkbox ngDefaultControl formControlName="replyBy3">Email</mat-checkbox>
                  </div>
                </div>

                <br />
                <br>

                <div class="row">
                  <div class="col-md-12 p-0">
                    <p class="m-0">
                      If you were referred by your Saia Account Executive /
                      Saia Account Exec - the following information is
                      required:
                    </p>
                  </div>
                </div>

                <br />

                <div class="row row-form">
                  <div class="form-group col-md-4 col-xs-12">
                    <label for="">Saia Account Number</label>
                    <mat-form-field appearance="outline" class="col-md-12 p-0">
                      <input matInput type="input" placeholder="Enter Saia Account Number" autocomplete="off" formControlName="saiaAccountNumber" />
                    </mat-form-field>
                  </div>

                  <!-- <div class="form-group col-md-4 col-xs-12">
                    <label for="">Saia Sales Rep</label>
                    <mat-form-field appearance="outline" class="col-md-12 p-0">
                      <input matInput type="input" placeholder="Enter Saia Sales Rep" formControlName="saiaSalesRep" />
                    </mat-form-field>
                  </div>

                  <div class="form-group col-md-4 col-xs-12">
                    <label for="">Saia Sales Rep Email</label>
                    <mat-form-field appearance="outline" class="col-md-12 p-0">
                      <input matInput type="input" placeholder="Enter Email ID" autocomplete="off" formControlName="saiaSalesRepEmail" />
                    </mat-form-field>
                  </div> -->
                </div>

                <div class="row center-xs">
                  <div class="col-md-4"></div>
                  <div class="col-md-4">
                    <button mat-flat-button class="primary-btn" (click)="submitInternationalQuotes()">
                      Submit
                    </button>
                  </div>
                  <div class="col-md-4 end-xs p-0">
                    <button mat-button class="primary-btn link" type="reset" (click)="clearInternational()">Clear</button>
                  </div>
                </div>

                <br /><br />
              </form>

            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Drayage / Transloading">
        <div class="content-section py-0">
          <div class="row">
            <div class="col-md-12 col-xs-12">
              <h4 class="header-text text-blue py-2">Request a Quote!</h4>
              <div class="error" *ngIf="transloadingError">
                <span>Error</span> | Please fill out all highlighted form fields correctly and then try submitting again
              </div>

              <div class="success" *ngIf="transloadingStatus">
                Thank you for submitting a quote request. Someone will get back to you shortly.
              </div>
              <div *ngIf="isFormSubmittedTransloadingApiError" class="error">
                {{apiResponse.message}}
              </div>
              <form [formGroup]="transloading" #ngTransloading="ngForm" (keydown)="onKeyDown($event)" class=" " autocomplete="off">
                <div class="row row-form">
                  <div class="form-group col-md-6 col-xs-12">
                    <label for="">Company Name<sup> &#42;</sup></label>
                    <mat-form-field appearance="outline" class="col-md-12 p-0">
                      <input matInput type="input" placeholder="Enter Company Name" autocomplete="off" formControlName="yourCompany" />
                      <mat-error> Enter Valid Company Name </mat-error>
                    </mat-form-field>
                  </div>

                  <div class="form-group col-md-6 col-xs-12">
                    <label for="">Contact Name<sup> &#42;</sup></label>
                    <mat-form-field appearance="outline" class="col-md-12 p-0">
                      <input matInput type="input" placeholder="Enter Contact Name" formControlName="yourName" (input)="alphaOnly($event)" />
                      <mat-error> Enter Valid Contact Name </mat-error>
                    </mat-form-field>
                  </div>
                </div>
                <div class="row row-form">

                  <div class="form-group col-md-6 col-xs-12">
                    <label for="">Email<sup> &#42;</sup></label>
                    <mat-form-field appearance="outline" class="col-md-12 p-0">
                      <input matInput type="input" placeholder="Enter Email ID" formControlName="yourEmail" />
                      <mat-error> Enter Valid Email </mat-error>
                    </mat-form-field>
                  </div>

                  <div class="form-group col-md-6 col-xs-12">
                    <label for="">Phone<sup> &#42;</sup></label>
                    <mat-form-field appearance="outline" class="col-md-12 p-0">
                      <input matInput type="input" maxlength="12" (input)="numberOnly($event)" inputmode="numeric" placeholder="Enter Phone Number" autocomplete="off" formControlName="yourPhone" />
                      <mat-error> Enter Valid Phone </mat-error>
                    </mat-form-field>
                  </div>

                </div>

                <div class="row row-form">

                  <div class="form-group col-md-12 col-xs-12">
                    <label for="">Company Street Address<sup> &#42;</sup></label>
                    <mat-form-field appearance="outline" class="col-md-12 p-0">
                      <input matInput type="input" maxlength="255" placeholder="Enter Company Street Address" autocomplete="off" formControlName="companyStreetAdd" />
                      <mat-error> Enter Valid Company Street Address</mat-error>
                    </mat-form-field>
                  </div>

                  <div class="form-group col-md-3 col-xs-12">
                    <label for="">City<sup> &#42;</sup></label>
                    <mat-form-field appearance="outline" class="col-md-12 p-0">
                      <input matInput type="input" maxlength="255" placeholder="Enter City" autocomplete="off" formControlName="city" (input)="alphaOnly($event)" />
                      <mat-error> Enter Valid City</mat-error>
                    </mat-form-field>
                  </div>

                  <div class="form-group col-md-3 col-xs-12">
                    <label for="">State<sup> &#42;</sup></label>
                    <mat-form-field appearance="outline" class="col-md-12 p-0">
                      <input matInput type="input" maxlength="255" placeholder="Enter State" autocomplete="off" formControlName="state" (input)="alphaOnly($event)" />
                      <mat-error> Enter Valid State</mat-error>
                    </mat-form-field>
                  </div>

                  <div class="form-group col-md-3 col-xs-12">
                    <label for="">Zip<sup> &#42;</sup></label>
                    <mat-form-field appearance="outline" class="col-md-12 p-0">
                      <input matInput type="input" maxlength="255" placeholder="Enter Zip" autocomplete="off" formControlName="zip" (input)="alphaOnly($event)" />
                      <mat-error> Enter Valid Zip</mat-error>
                    </mat-form-field>
                  </div>

                </div>

                <hr />

                <div class="row row-form">
                  <div class="form-group col-md-9 col-xs-12">
                    <label for="">Commodity Description<sup> &#42;</sup></label>
                    <mat-form-field appearance="outline" class="col-md-12 p-0 textarea">
                      <textarea matInput maxlength="255" placeholder="Enter Commodity Description" rows="3" autocomplete="off" formControlName="description"></textarea>
                      <mat-error *ngIf="isFormSubmittedTransloading && transloading.controls['description'].errors?.['required']">
                        Enter Valid Commodity Description</mat-error>
                    </mat-form-field>
                  </div>

                  <div class="form-group col-md-3 col-xs-12">
                    <label for="">Origin Port/Rail Yard<sup> &#42;</sup></label>
                    <mat-form-field appearance="outline" class="col-md-12 p-0">
                      <input matInput type="input" maxlength="255" placeholder="Enter Origin Port/Rail Yard" autocomplete="off" formControlName="originPort" />
                      <mat-error> Enter Valid Origin Port/Rail Yard</mat-error>
                    </mat-form-field>
                  </div>
                </div>

                <div class="row row-form">

                  <div class="form-group col-md-3 col-xs-12">
                    <label for="">Destination City <sup> &#42;</sup></label>
                    <mat-form-field appearance="outline" class="col-md-12 p-0">
                      <input matInput type="input" placeholder="Enter Destination City" formControlName="destinationCity" (input)="alphaOnly($event)" />
                      <mat-error>Enter Valid Destination City</mat-error>
                    </mat-form-field>
                  </div>
                  <div class="form-group col-md-3 col-xs-12">
                    <label for="">Destination State / Province <sup> &#42;</sup></label>
                    <mat-form-field appearance="outline" class="col-md-12 p-0">
                      <input matInput type="input" placeholder="Enter Destination State / Province" autocomplete="off" formControlName="destinationState" (input)="alphaOnly($event)" />
                      <mat-error>Enter Valid Destination State</mat-error>
                    </mat-form-field>
                  </div>

                  <div class="form-group col-md-3 col-xs-12">
                    <label for="">Destination Zip <sup> &#42;</sup></label>
                    <mat-form-field appearance="outline" class="col-md-12 p-0">
                      <input matInput type="input" placeholder="Enter Destination Zip" formControlName="destinationZip" (input)="alphaOnly($event)" />
                      <mat-error>Enter Valid Destination Zip</mat-error>
                    </mat-form-field>
                  </div>

                </div>


                <div class="row row-form">
                  <div class="col-md-12 col-xs-12">
                    <h4 class="header-text header-sub-size py-2 remove-underline">
                      Container Size <sup> &#42;</sup>
                    </h4>

                    <div class="form-group col-md-12 col-xs-12 p-0" formGroupName="containerSizeFB">
                      <mat-checkbox-group aria-label="Select Container Size" class="m-t" ngDefaultControl>
                        <mat-checkbox formControlName="twenty" name="twenty" value="twenty">20'</mat-checkbox>
                        <mat-checkbox formControlName="forty" name="forty" value="forty">40'</mat-checkbox>
                        <mat-checkbox formControlName="fortyfive" name="fortyfive" value="fortyfive">45'</mat-checkbox>
                        <mat-checkbox formControlName="fortyeight" name="fortyeight" value="fortyeight">48'
                        </mat-checkbox>
                        <mat-checkbox formControlName="fiftythree" name="fiftythree" value="fiftythree">53'
                        </mat-checkbox>
                        <mat-checkbox formControlName="unsure" name="unsure" value="unsure">Unsure</mat-checkbox>
                      </mat-checkbox-group>

                      <mat-error class="custom-error" *ngIf="isFormSubmittedTransloading && transloading.get('containerSizeFB')?.hasError('required')">
                        Please select at least one option</mat-error>
                    </div>

                  </div>
                </div>

                <br />

                <div class="row row-form">
                  <div class="col-md-12 col-xs-12">
                    <h4 class="header-text header-sub-size py-2 remove-underline">
                      Container Weight<sup> &#42;</sup>
                    </h4>

                    <mat-radio-group aria-label="Select Container Weight" formControlName="containerWeight">
                      <mat-radio-button value="Legal">Legal</mat-radio-button>
                      <mat-radio-button value="Overweight">Overweight</mat-radio-button>
                      <mat-radio-button value="Unsure">Unsure</mat-radio-button>
                      <mat-error class="custom-error" *ngIf="isFormSubmittedTransloading && transloading.controls['containerWeight'].errors?.['required']">
                        Select Container Weight</mat-error>
                    </mat-radio-group>
                  </div>
                </div>

                <br />

                <div class="row row-form">
                  <div class="col-md-12 col-xs-12">
                    <h4 class="header-text header-sub-size py-2 remove-underline">
                      Hazmat Load<sup> &#42;</sup>
                    </h4>

                    <mat-radio-group aria-label="Select payment type" formControlName="hazmatLoad">
                      <mat-radio-button value="Yes">Yes</mat-radio-button>
                      <mat-radio-button value="No">No</mat-radio-button>
                      <mat-error class="custom-error" *ngIf="isFormSubmittedTransloading && transloading.controls['hazmatLoad'].errors?.['required']">
                        Select Hazmat Load</mat-error>
                    </mat-radio-group>
                  </div>
                </div>

                <br />

                <div class="row row-form">
                  <div class="col-md-12 col-xs-12">
                    <h4 class="header-text header-sub-size py-2 remove-underline">
                      Equipment Type <sup> &#42;</sup>
                    </h4>
                  </div>

                  <div class="form-group col-md-12 col-xs-12" formGroupName="equipmentTypeFB">
                    <mat-checkbox-group aria-label="Select Receiving Product" class="m-t" ngDefaultControl>
                      <mat-checkbox formControlName="standard" name="highCube" value="standard">Standard
                      </mat-checkbox>
                      <mat-checkbox formControlName="highCube" name="highCube" value="highCube">High Cube
                      </mat-checkbox>
                      <mat-checkbox formControlName="refrigerated" name="refrigerated" value="refrigerated">
                        Refrigerated(Reefer)
                      </mat-checkbox>
                      <mat-checkbox formControlName="openTop" name="openTop" value="openTop">
                        Open Top</mat-checkbox>
                      <mat-checkbox formControlName="flatRack" name="flatRack" value="flatRack">
                        Flat Rack</mat-checkbox>
                      <mat-checkbox formControlName="outOfGauge" name="outOfGauge" value="outOfGauge" (change)="outOfGaugeFn($event)">
                        Out Of Gauge</mat-checkbox>
                      <mat-checkbox formControlName="vanTrailer" name="vanTrailer" value="vanTrailer">
                        Van Trailer</mat-checkbox>
                      <mat-checkbox formControlName="bonded" name="bonded" value="bonded">
                        Bonded</mat-checkbox>
                    </mat-checkbox-group>

                    <mat-error class="custom-error" *ngIf="isFormSubmittedTransloading && transloading.get('equipmentTypeFB')?.hasError('required')">
                      Please select at least one option</mat-error>

                  </div>

                  <br />

                  <div class="form-group col-md-3 col-xs-12" *ngIf="outOfGauge">
                    <label for="">Dimensions (L x W x H in.)<sup> &#42;</sup></label>
                    <mat-form-field appearance="outline" class="col-md-12 p-0">
                      <input matInput type="input" placeholder="Enter Dimensions" formControlName="dimension" (input)="alphaOnly($event)" />
                      <mat-error>Please Enter Valid Dimensions</mat-error>
                    </mat-form-field>
                  </div>

                </div>

                <hr />

                <div class="row row-form">
                  <div class="form-group col-md-9 col-xs-12">
                    <label for="">Special Instructions<sup> &#42;</sup></label>
                    <mat-form-field appearance="outline" class="col-md-12 p-0 textarea">
                      <textarea matInput maxlength="255" placeholder="Enter Special Instructions" rows="3" autocomplete="off" formControlName="DTspecialInstruction"></textarea>
                      <mat-error *ngIf="transloading.controls.DTspecialInstruction.invalid && transloading.controls.DTspecialInstruction.touched">
                        Enter Valid Special Instruction</mat-error>
                    </mat-form-field>

                  </div>

                  <div class="form-group col-md-3 col-xs-12">
                    <label for="">Cargo Ready Date<sup> &#42;</sup></label>
                    <mat-form-field appearance="outline" class="col-md-12 p-0">
                      <input matInput [matDatepicker]="cargoReadyDate" [min]="minDate" readonly placeholder="Select Ready Date" autocomplete="off" formControlName="cargoReadyDate" />
                      <mat-datepicker-toggle matIconSuffix [for]="cargoReadyDate"></mat-datepicker-toggle>
                      <mat-datepicker #cargoReadyDate></mat-datepicker>
                      <mat-error>Select Cargo Ready Date</mat-error>
                    </mat-form-field>
                  </div>
                </div>

                <br />

                <div class="row row-form">
                  <div class="col-md-12 col-xs-12">
                    <h4 class="header-text header-sub-size py-2 remove-underline">
                      Reply to Quote By:
                    </h4>
                  </div>

                  <div class="col-md-6 col-xs-12 column-direction">
                    <mat-checkbox formControlName="replyBy1">Phone</mat-checkbox>
                    <mat-checkbox formControlName="replyBy3">Email</mat-checkbox>
                  </div>
                </div>

                <br>
                <hr>

                <div class="row">
                  <div class="col-md-12 p-0">
                    <p class="m-0">
                      If you were referred by your Saia Account Executive /
                      Saia Account Exec - the following information is
                      required:
                    </p>
                  </div>
                </div>

                <br />

                <div class="row row-form">
                  <div class="form-group col-md-4 col-xs-12">
                    <label for="">Saia Account Number</label>
                    <mat-form-field appearance="outline" class="col-md-12 p-0">
                      <input matInput type="input" placeholder="Enter Saia Account Number" autocomplete="off" formControlName="saiaAccountNumber" />
                    </mat-form-field>
                  </div>

                  <!-- <div class="form-group col-md-4 col-xs-12">
                    <label for="">Saia Sales Rep</label>
                    <mat-form-field appearance="outline" class="col-md-12 p-0">
                      <input matInput type="input" placeholder="Enter Saia Sales Rep" formControlName="saiaSalesRep" />
                    </mat-form-field>
                  </div>

                  <div class="form-group col-md-4 col-xs-12">
                    <label for="">Saia Sales Rep Email</label>
                    <mat-form-field appearance="outline" class="col-md-12 p-0">
                      <input matInput type="input" placeholder="Enter Email ID" autocomplete="off" formControlName="saiaSalesRepEmail" />
                    </mat-form-field>
                  </div> -->
                </div>



                <div class="row center-xs">
                  <div class="col-md-4"></div>
                  <div class="col-md-4">
                    <button mat-flat-button class="primary-btn" (click)="submitTransloadingQuotes($event)">
                      Submit
                    </button>
                  </div>
                  <div class="col-md-4 end-xs p-0">
                    <button mat-button class="primary-btn link" type="reset" (click)="clearDrayage()">Clear</button>
                  </div>
                </div>

                <br /><br />
              </form>

            </div>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>