<!-- Breadcrumb Wrapper -->
<div class="breadcrumb-wrapper">
    <div class="row">
        <div class="col-md-10 col-xs-12 col-md-offset-1"  *ngIf="pageHeader">
            <ol>
                <li routerLink="/home">
                    <span class="material-icons">home</span>
                </li>
                <ng-container *ngFor="let breadcrumb of getBreadcrumbs(); let last = last">
                    <li [ngClass]="{'child': !last, 'active': last}">
                        {{ breadcrumb }}
                    </li>
                </ng-container>
            </ol>
        </div>
    </div>
</div>

<!-- Section -->
<div class="content-section" *ngIf="pageContent">
    <div class="row">
        <div class="col-md-10 col-md-offset-1">

            <h4 class="header-text text-blue py-2">
                {{pageContent.header}} 
            </h4>

            <div class="row" *ngFor="let accordionItem of accordionSubArray; let i = index">
                <ng-container *ngIf="i%2==0; else OtherPart">
                    <div class="col-md-3 col-xs-12 img-box p-0">
                        <img [src]="accordionItem[0].imageURL" class=" " alt="">
                    </div>
                    <div class="col-md-3 col-xs-12 text-box"  [innerHTML]="accordionItem[0].header">
                    </div>
                    <div class="col-md-3 col-xs-12 img-box p-0">
                        <img [src]="accordionItem[1].imageURL" class=" " alt="">
                    </div>
                    <div class="col-md-3 col-xs-12 text-box" [innerHTML]="accordionItem[1].header"></div>
              </ng-container>
              <ng-template #OtherPart>
                    <div class="col-md-3 col-xs-12 text-box m-order-2" [innerHTML]="accordionItem[0].header">
                        
                    </div>
                    <div class="col-md-3 col-xs-12 img-box p-0 m-order-1">
                        <img [src]="accordionItem[0].imageURL" class=" " alt="">
                    </div>
                    <div class="col-md-3 col-xs-12 text-box m-order-4" [innerHTML]="accordionItem[1].header"></div>
                    <div class="col-md-3 col-xs-12 img-box p-0 m-order-3">
                        <img [src]="accordionItem[1].imageURL" class=" " alt="">
                    </div>
              </ng-template>
            </div>
        </div>
    </div>
</div>