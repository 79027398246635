<!-- Banner -->
<div class="banner tms" *ngIf="pageHeader" [heroImage]="pageHeader.heroImageURL">
    <h1 class="banner-text">{{pageHeader.heroImageTitle}}</h1>
</div>

<!-- Breadcrumb Wrapper -->
<div class="breadcrumb-wrapper" *ngIf="pageHeader">
    <div class="row">
        <div class="col-md-10 col-xs-12 col-md-offset-1">
            <ol>
                <li routerLink="/home">
                    <span class="material-icons">home</span>
                </li>
                <ng-container *ngFor="let breadcrumb of getBreadcrumbs(); let last = last">
                    <li [ngClass]="{'child': !last, 'active': last}">
                        {{ breadcrumb }}
                    </li>
                </ng-container>
            </ol>
        </div>
    </div>
</div>

<!-- Section -->
<div class="content-section">
    <div class="row">
        <div class="col-md-10 col-md-offset-1 cust-flex" *ngIf="pageContent">

            <div class="row">
                <div class="col-md-6 col-xs-12">
                    <div class="content-text" [innerHTML]="pageContent.description">
                    </div>
                </div>
            </div>

            <br>

            <div class="row aign-center">
                <div class="col-md-6 col-xs-12">
                    <div class="content-text" [innerHTML]="leftSanitizedSection1Description">

                    </div>

                </div>
                <div class="col-md-6 col-xs-12 cust-height" [innerHTML]="rightSanitizedSection1Description">
                    <!-- <img [src]="'/assets/images/resources/3laptops-OptiLinkTMS.png'" class="link" alt=""> -->

                    <!-- <iframe src="https://player.vimeo.com/video/873404982" frameborder="0" allowfullscreen width="100%" class=""></iframe> -->

                </div>
            </div>

            <br>

            <hr>

            <br>

            <div class="row aign-center">
                <div class="col-md-6 col-xs-12">
                    <div class="content-text" [innerHTML]="leftSanitizedSection2Description">
                    </div>
                </div>
                <div class="col-md-6 col-xs-12 h-align" [innerHTML]="rightSanitizedSection2Description">
                </div>
            </div>


        </div>
    </div>

    <br>
    <br>

    <div class="row center-xs">
        <div class="col-xs-12">
            <a mat-flat-button class="primary-btn auto-width" target="_blank" href="https://linkextms.mycarriertms.com/landing?campaign=a87f87f3-e0e2-4eda-a552-987d79e52d5e">
                Get Started Now
            </a>
        </div>
    </div>
</div>