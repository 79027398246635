import { Entry, EntryCollection } from 'contentful';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { richTextOptions } from './rich-text-options.model';

export class AccordionContentModel {
    static accordionItems(accordionItems: any) {
        throw new Error('Method not implemented.');
    }

    accordionHeader!: string;
    accordionItems: AccordionItemContentModel[] = [];

    constructor(accordionDetails: any) {
        if (accordionDetails) {
            this.accordionHeader = accordionDetails.header;
            accordionDetails.accordionListItems.forEach((item: any) => {
                this.accordionItems.push(new AccordionItemContentModel(item));
            });
        }
    }
}


export class AccordionItemContentModel {

    header!: string;
    description!: string;
    imageURL!: string;
    link!: string;
    icons!: string;

    constructor(accordianItem: any) {
        if (accordianItem) {
            const accordionContentDetail: any = accordianItem;
            this.header = accordionContentDetail.fields.header;
            this.description = documentToHtmlString(accordianItem.fields.content, richTextOptions);
            this.imageURL = accordionContentDetail.fields.image?.fields.file?.url;
            this.link = accordionContentDetail.fields?.link;
            this.icons = accordionContentDetail.fields?.iconEntry;
        }
    }
}
