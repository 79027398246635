<!-- Banner -->
<div class="banner transloading" *ngIf="pageHeader" [heroImage]="pageHeader.heroImageURL">
    <h1 class="banner-text">{{pageHeader.heroImageTitle}}</h1>
</div>

<!-- Breadcrumb Wrapper -->
<div class="breadcrumb-wrapper" *ngIf="pageHeader">
    <div class="row">
        <div class="col-md-10 col-xs-12 col-md-offset-1">
            <ol>
                <li routerLink="/home">
                    <span class="material-icons">home</span>
                </li>
                <ng-container *ngFor="let breadcrumb of getBreadcrumbs(); let last = last">
                    <li [ngClass]="{'child': !last, 'active': last}">
                        {{ breadcrumb }}
                    </li>
                </ng-container>
            </ol>
        </div>
    </div>
</div>

<!-- Section -->
<div class="content-section">
    <div class="row">
        <div class="col-md-5 col-md-offset-1" *ngIf="pageContent">
            <h4 class="header-text text-blue py-2">
                {{pageContent.header}}
            </h4>

            <div class="row">
                <div class="col-md-6 col-xs-12 p-0">
                    <div class="content-text">
                        <div class="content" [innerHTML]="sanitizedDescription"></div>
                    </div>
                </div>
            </div>

        </div>
        <div class="col-md-6 col-xs-12 relative" *ngIf="pageContent">

            <div class="circle-border-blue">
                <img [src]="pageContent.thumbnailURL" class="height" alt="">
            </div>

        </div>
    </div>
</div>