import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { LinkexService } from '../../services/linkex.service.service';
import { AppComponent } from 'src/app/app.component';
import { ActivatedRoute, Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
interface Search {
  value: string;
  viewValue: string;
}

export interface TrackingDetails {
  sonumber: string;
  status: string;
  pickupdate: string;
  deliverydate: string;
  datedelivered: string;
  deliverywindow: string;
  consignee: string;
  isFound: boolean;
}

@Component({
  selector: 'app-track',
  templateUrl: './track.component.html',
  styleUrls: ['./track.component.scss']
})
export class TrackComponent implements OnInit, OnDestroy {

  constructor(private fb: FormBuilder, private service: LinkexService, private appLoader: AppComponent, private route: ActivatedRoute, private router: Router) {
    this.previousUrl = this.router.getCurrentNavigation()?.previousNavigation?.finalUrl?.toString()
  }
  track = this.fb.group({
    ids: ['', [Validators.required]],
    searchBy: ['', [Validators.required]]
  })
  private trackingIds: any;
  private previousUrl: any;
  submitDisabled = false;
  loader: boolean = false;
  TrackingColumns: string[] = ['sonumber', 'status', 'pickupdate', 'deliverydate', 'datedelivered', 'deliverywindow', 'consignee'];
  dataSource = new MatTableDataSource<TrackingDetails>();
  search: Search[] = [
    { value: 'So-number', viewValue: 'SO Number' },
    // {value: 'Shipper-ref-number', viewValue: 'Shipper Ref Number'},
    // {value: 'Billing-ref-number', viewValue: 'Billing Ref Number'},
  ];
  ngOnDestroy(): void {
    this.service.trackingHistory = {
      trackingData: this.dataSource.data,
      trackingIds: this.trackingIds
    };
  }

  formatTime(time: string): string {
    const [startTime, endTime] = time.split(' - ');
    const formattedStartTime = this.formatSingleTime(startTime);
    const formattedEndTime = this.formatSingleTime(endTime);
    return `${formattedStartTime} - ${formattedEndTime}`;
  }

  formatSingleTime(time: string): string {
    const timeObj = new Date(`1970-01-01T${time}`);
    const formattedTime = timeObj.toLocaleString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });
    return formattedTime;
  }

  ngOnInit(): void {
    if (this.previousUrl?.startsWith('/details')) {
      let sourcedata: TrackingDetails[] = this.service.trackingHistory?.trackingData;
      this.dataSource.data = sourcedata;
      this.trackingIds = this.service.trackingHistory.trackingIds;
      this.track.controls['ids'].setValue(this.trackingIds);
      this.track.controls['searchBy'].setValue('So-number');
    } else {
      this.route.queryParams
        .subscribe(params => {
          this.track.controls['ids'].setValue(params['shipOrder']);
          this.track.controls['searchBy'].setValue('So-number');
          this.onSubmit();
        });
    }
  }
  onSubmit(): any {
    if (this.track.status === 'INVALID') return
    this.submitDisabled = true
    const formData = this.track.value;
    this.trackingIds = formData.ids;
    let trackingIds = formData.ids?.split(/[\n, ]/)
    trackingIds = trackingIds?.filter((so) => so != '');
    const payload: any = {
      ids: trackingIds,
      searchBy: formData.searchBy
    }
    this.dataSource.data = [];
    this.loader = true;
    this.service.trackShipment(payload).subscribe((response: any) => {
      this.submitDisabled = false;
      this.loader = false;
      let sourceData: TrackingDetails[] = [];
      if (!Array.isArray(response)) {
        if (response.success) {
          let data: TrackingDetails = {
            isFound: true,
            sonumber: response.information.ShippingOrder,
            status: response.information.Status,
            pickupdate: response.information.PickUpDateTime,
            deliverydate: response.information.EarliestEstimatedDelivery.split(" ")[0],
            datedelivered: response.information.DeliveryDateTime,
            deliverywindow: response.information.EarliestEstimatedDelivery.split(" ")[1] + ' - ' + response.information.LatestEstimatedDelivery.split(" ")[1],
            consignee: response.information.ConsigneeAddress.Name
          }
          sourceData.push(data)
        } else {
          let data: TrackingDetails = {
            isFound: false,
            sonumber: payload.ids[0],
            status: response.message,
            pickupdate: '',
            deliverydate: '',
            datedelivered: '',
            deliverywindow: '',
            consignee: ''
          }
          sourceData.push(data)
        }
        this.dataSource.data = sourceData;
      } else {
        response.forEach((info, index) => {
          if (info.success) {
            let data: TrackingDetails = {
              isFound: true,
              sonumber: info.information.ShippingOrder,
              status: info.information.Status,
              pickupdate: info.information.PickUpDateTime,
              deliverydate: info.information.EarliestEstimatedDelivery.split(" ")[0],
              datedelivered: info.information.DeliveryDateTime,
              deliverywindow: info.information.EarliestEstimatedDelivery.split(" ")[1] + ' - ' + info.information.LatestEstimatedDelivery.split(" ")[1],
              consignee: info.information.ConsigneeAddress.Name
            }
            sourceData.push(data)
          } else {
            let data: TrackingDetails = {
              isFound: false,
              sonumber: payload.ids[index],
              status: info.message,
              pickupdate: '',
              deliverydate: '',
              datedelivered: '',
              deliverywindow: '',
              consignee: ''
            }
            sourceData.push(data)
          }
        });
        this.dataSource.data = sourceData;
      }
    }, (error) => {
      this.submitDisabled = false
      this.loader = false;
    });
  }
  clear(): void {
    this.track.reset()
    this.dataSource.data = [];
  }
}
