<div class="row">
    <div class="col-md-6 col-xs-12 blue-block">
        <img [src]="'/assets/LinkExLogo-white.svg'" class="footer-img" alt="">
        <div class="content-text">
            Understanding the individual needs of our customers is vital to ensuring that we can create the best
            possible supply chain solutions. Not only are the solutions LinkEx creates Best in Class, but our service is
            too.
        </div>
        <div class="content-text">
            Offices in Dallas, Atlanta and Guadalajara.
        </div>
    </div>

    <div class="col-md-6 col-xs-12 red-block">
        <div class="header-text mb-4">Resources</div>
        <div class="row">
            <div class="col-md-6 col-xs-12 p-0">
                <div class="content-text">
                    <ul class="list-style">
                        <li routerLink="/documents">Documents <span class="material-icons">arrow_forward</span></li>
                        <li routerLink="/resources">Collateral <span class="material-icons">arrow_forward</span></li>
                        <li routerLink="/careers">Careers <span class="material-icons">arrow_forward</span></li>
                        <li routerLink="/sitemap">Site Map <span class="material-icons">arrow_forward</span></li>
                    </ul>
                </div>
            </div>
            <div class="col-md-6 col-xs-12 p-0">
                <div class="content-text">
                    <ul class="list-style">
                        <li routerLink="/linkex-tms">Transportation Management System (TMS)<span
                                class="material-icons">arrow_forward</span></li>
                        <li routerLink="/track">Shipment Tracking <span class="material-icons">arrow_forward</span></li>
                        <li><a href="/assets/docs/Saia-Inc-Privacy-Policy.docx" target="_blank">Privacy Policy <span
                                    class="material-icons">arrow_forward</span></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row center-xs black-block">

    <div class="col-md-12 col-xs-12">
        <!-- OneTrust Cookies Settings button start -->
        <button id="ot-sdk-btn" class="ot-sdk-show-settings mb-3" [ngClass]="{'cookie-consent': applyCustomClass}">Do not Sell or Share My Personal Information</button>
        <!-- OneTrust Cookies Settings button end -->
    </div>

    <div class="col-md-12 col-xs-12">
        <a href="https://www.linkedin.com/company/linkex-inc/" target="_blank" class="social-media"> <img
                [src]="'/assets/images/iconset/linkedin.png'" class="mb-4" alt=""> </a>
        <a href="https://twitter.com/LinkExInc" target="_blank" class="social-media"> <img
                [src]="'/assets/images/iconset/twitter.png'" class="mb-4" alt=""></a>
    </div>

    <div class="col-md-12 col-xs-12">
        <div class="content-text p-0">
            © 2024, LinkEx Inc. All Rights Reserved.
        </div>
    </div>
</div>