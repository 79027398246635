import { BLOCKS, INLINES } from '@contentful/rich-text-types';

export const richTextOptions = {
    renderNode: {
        [BLOCKS.EMBEDDED_ASSET]: (node: any) => {
            if (node.data && node.data.target && node.data.target.fields && node.data.target.fields.file) {
                return `<img src="${node.data.target.fields.file.url}">`;
            } else {
                return ``;
            }
        },
        [INLINES.ASSET_HYPERLINK]: (node: any) => {
            if (node.data && node.data.target && node.data.target.fields && node.data.target.fields.file
                && node.content && node.content[0].value) {
                return `<a target="_blank" rel="noreferrer noopener" href="${node.data.target.fields.file.url}">${node.content[0].value}</a>`;
            } else {
                return ``;
            }
        }
    }
};
