<!-- Banner -->
<div class="banner resources" *ngIf="pageHeader" [heroImage]="pageHeader.heroImageURL">
    <h1 class="banner-text">{{pageHeader.heroImageTitle}}</h1>
</div>

<!-- Breadcrumb Wrapper -->
<div class="breadcrumb-wrapper" *ngIf="pageHeader">
    <div class="row">
        <div class="col-md-10 col-xs-12 col-md-offset-1">
            <ol>
                <li routerLink="/home">
                    <span class="material-icons">home</span>
                </li>
                <ng-container *ngFor="let breadcrumb of getBreadcrumbs(); let last = last">
                    <li [ngClass]="{'child': !last, 'active': last}">
                        {{ breadcrumb }}
                    </li>
                </ng-container>
            </ol>
        </div>
    </div>
</div>


<div class="content-section resources-content">
    <div class="row">
        <div class="col-md-10 col-md-offset-1" *ngIf="pageContent">
            <div class="header-text text-blue"> {{pageContent.header}}</div>
            <div class="row ">
                <div class="col-md-6 col-xs-12 p-0">
                    <div class="content-text" [innerHTML]="leftSanitizedDescription">
                    </div>
                </div>
                <div class="col-md-6 col-xs-12 p-0">
                    <div class="content-text" [innerHTML]="rightSanitizedDescription">
                    </div>
                </div>
            </div>

            <div class="header-text text-blue">Collateral</div>

        </div>
    </div>
    <div class="row">
        <div class="col-md-10 col-md-offset-1 col-xs-12" *ngIf="pageContent">
            <mat-accordion>
                <mat-expansion-panel [(expanded)]="services" class="mat-elevation-z0 accordion-custom">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Services Collateral
                        </mat-panel-title>
                    </mat-expansion-panel-header>

                    <div class="content-text" [innerHTML]="pageContent.accordion.accordionItems[0].description">
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </div>
</div>