import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { PageContentModel } from 'src/app/core/model/page-content.model';
import { PageHeaderContentModel } from 'src/app/core/model/page-header.model';
import { ContentfulService } from 'src/app/core/service/contentful.service';

@Component({
  selector: 'app-final-mile',
  templateUrl: './final-mile.component.html',
  styleUrls: ['./final-mile.component.scss']
})
export class FinalMileComponent implements OnInit {
  
  pageHeader!: PageHeaderContentModel;
  pageContent!: PageContentModel;

  constructor(private router: Router, private contentfulService: ContentfulService, private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.loadHeaderEntries();
    this.loadBodyEntries();
  }

  loadHeaderEntries() {
    this.contentfulService.getEntry('pageHeader', 'Final Mile Header').subscribe(
      (entry) => {
        this.pageHeader = new PageHeaderContentModel(entry);
      }, error => {
        console.error('Error fetching entries:', error);
      });
  }

  getBreadcrumbs(): string[] {
    return this.pageHeader.breadCrumbs.split(',').map(breadcrumb => breadcrumb.trim());
  }

  loadBodyEntries() {
    this.contentfulService.getEntry('pageInsights', 'Final Mile Content').subscribe(
      (entry) => {
        this.pageContent = new PageContentModel(entry);
      }, error => {
        console.error('Error fetching entries:', error);
      });
  }

  get sanitizedDescription(): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(this.pageContent.description);
  }
  
  routing() {
    this.router.navigate(['/quote'], { queryParams: { tab: 1 }, skipLocationChange: false });
  }
}
