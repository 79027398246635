<!-- Banner -->
<div class="banner careers" *ngIf="pageHeader" [heroImage]="pageHeader.heroImageURL">
    <h1 class="banner-text">{{pageHeader.heroImageTitle}}</h1>
</div>

<!-- Breadcrumb Wrapper -->
<div class="breadcrumb-wrapper" *ngIf="pageHeader">
    <div class="row">
        <div class="col-md-10 col-xs-12 col-md-offset-1">
            <ol>
                <li routerLink="/home">
                    <span class="material-icons">home</span>
                </li>
                <ng-container *ngFor="let breadcrumb of getBreadcrumbs(); let last = last">
                    <li [ngClass]="{'child': !last, 'active': last}">
                        {{ breadcrumb }}
                    </li>
                </ng-container>
            </ol>
        </div>
    </div>
</div>

<!-- Section -->
<div class="content-section">
    <div class="row">
        <div class="col-md-10 col-md-offset-1" *ngIf="pageContent">

            <h4 class="header-text text-blue py-2">
                {{pageContent.header}}
            </h4>

            <div class="content-text" [innerHTML]="pageContent.description">

                <!-- <a href="https://saia.wd1.myworkdayjobs.com/en-US/SaiaCareers/details/Brokerage-Admin-Clerk-LinkEx_JR2447-2022?q=LINKEX" target="_blank" class="link-share">Brokerage Admin Clerk</a>

                <a href="https://saia.wd1.myworkdayjobs.com/en-US/SaiaCareers/details/Brokerage-Carrier-Sales-Rep_JR723-2022?locations=69df071aac8701012d9a2f9abf110000" target="_blank" class="link-share">Brokerage Carrier Sales Rep</a>

                <a href="https://saia.wd1.myworkdayjobs.com/en-US/SaiaCareers/details/Brokerage-Customer-Service-LinkEx_JR2533-2022?q=LINKEX" target="_blank" class="link-share">Brokerage Customer Service</a>

                <a href="https://saia.wd1.myworkdayjobs.com/en-US/SaiaCareers/details/Mgr--Client-Implementation_JR2476-2022-1?q=LINKEX" target="_blank" class="link-share">Client Implementation Manager</a>

                <a href="https://saia.wd1.myworkdayjobs.com/en-US/SaiaCareers/details/Dockworker-LinkEx_JR2616-2022?q=LINKEX" target="_blank" class="link-share">Dockworker</a>

                <a href="https://saia.wd1.myworkdayjobs.com/en-US/SaiaCareers/details/Straight-Truck-Driver-LinkEx_JR2450-2022?q=LINKEX" target="_blank" class="link-share">Straight Truck Driver</a>

                <a href="https://saia.wd1.myworkdayjobs.com/en-US/SaiaCareers/details/Local-City-Driver-LinkEx_JR2452-2022?locations=69df071aac8701012d9a2f9abf110000" target="_blank" class="link-share">Local City Driver</a> -->
            </div>

        </div>
    </div>
</div>