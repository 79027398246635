import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { PageContentModel } from 'src/app/core/model/page-content.model';
import { PageHeaderContentModel } from 'src/app/core/model/page-header.model';
import { ContentfulService } from 'src/app/core/service/contentful.service';

@Component({
  selector: 'app-contract-logistics',
  templateUrl: './contract-logistics.component.html',
  styleUrls: ['./contract-logistics.component.scss']
})
export class ContractLogisticsComponent implements OnInit {

  recipientEmail = 'dmiller@saia.com';
  pageHeader!: PageHeaderContentModel;
  pageContent!: PageContentModel;
  constructor(private router: Router, private contentfulService: ContentfulService, private sanitizer: DomSanitizer) { }
  routing() {
    this.router.navigate(['/linkex-tms']);
  }

  loadHeaderEntries() {
    this.contentfulService.getEntry('pageHeader', 'Contract Logistics Header').subscribe(
      (entry) => {
        this.pageHeader = new PageHeaderContentModel(entry);
       console.log(this.pageHeader)
      }, error => {
        console.error('Error fetching entries:', error);
      });
  }

  getBreadcrumbs(): string[] {
    return this.pageHeader.breadCrumbs.split(',').map(breadcrumb => breadcrumb.trim());
  }

  loadBodyEntries() {
    this.contentfulService.getEntry('pageInsights', 'Contract Logistics Content').subscribe(
      (entry) => {
        this.pageContent = new PageContentModel(entry);
        console.log(this.pageContent)
      }, error => {
        console.error('Error fetching entries:', error);
      });
  }

  get sanitizedDescription(): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(this.pageContent.description);
  }
  sendEmail() {
    const emailUrl = `mailto:${this.recipientEmail}`;
    window.location.href = emailUrl;
  }



  ngOnInit(): void {
    this.loadHeaderEntries();
    this.loadBodyEntries();
  }

}
