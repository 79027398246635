<div class="banner services" *ngIf="pageHeader" [heroImage]="pageHeader.heroImageURL">
    <h1 class="banner-text">{{pageHeader.heroImageTitle}}</h1>
</div>

<!-- Breadcrumb Wrapper -->
<div class="breadcrumb-wrapper">
    <div class="row">
        <div class="col-md-10 col-xs-12 col-md-offset-1" *ngIf="pageHeader">
            <ol>
                <li routerLink="/home">
                    <span class="material-icons">home</span>
                </li>
                <ng-container *ngFor="let breadcrumb of getBreadcrumbs(); let last = last">
                    <li [ngClass]="{'child': !last, 'active': last}">
                        {{ breadcrumb }}
                    </li>
                </ng-container>
            </ol>
        </div>
    </div>
</div>

<!-- Section -->
<div class="content-section">
    <div class="row">
        <div class="col-md-10 col-md-offset-1" *ngIf="pageContent">
            <h4 class="header-text text-blue py-2">
                {{pageContent.header}}
            </h4>

            <div class="row">
                <div class="col-md-6 col-xs-12 p-0">
                    <div class="content-text service" [innerHTML]="leftSanitizedDescription">

                        <!-- <ul class="service-list-style">
                            <li routerLink="/contract-logistics">Contract Logistics <span class="material-icons">arrow_forward</span></li>
                            <li routerLink="/international-shipping">International Shipping <span class="material-icons">arrow_forward</span></li>
                            <li routerLink="/truckload-brokerage">Truckload Brokerage <span class="material-icons">arrow_forward</span></li>
                            <li routerLink="/warehousing">Warehousing <span class="material-icons">arrow_forward</span></li>
                        </ul> -->
                    </div>
                </div>
                <div class="col-md-6 col-xs-12 p-0">
                    <div class="content-text service" [innerHTML]="rightSanitizedDescription">

                        <!-- <ul class="service-list-style">
                            <li routerLink="/expedited-delivery">Expedited Delivery <span class="material-icons">arrow_forward</span></li>
                            <li routerLink="/pool-distribution">Pool Distribution <span class="material-icons">arrow_forward</span></li>
                            <li routerLink="/drayage-transloading">Drayage / Transloading <span class="material-icons">arrow_forward</span></li>
                            <li routerLink="/final-mile">Final Mile <span class="material-icons">arrow_forward</span></li>
                        </ul> -->
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>


<!-- AllCert -->
<section class="section-sprite center-xs" *ngIf="pageContent">
    <img [src]="pageContent.thumbnailURL" height=" " alt="">
</section>