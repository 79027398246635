import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { PageContentModel } from 'src/app/core/model/page-content.model';
import { PageHeaderContentModel } from 'src/app/core/model/page-header.model';
import { ContentfulService } from 'src/app/core/service/contentful.service';

@Component({
  selector: 'app-truckload-brokerage',
  templateUrl: './truckload-brokerage.component.html',
  styleUrls: ['./truckload-brokerage.component.scss']
})
export class TruckloadBrokerageComponent implements OnInit {

  routing() {
    this.router.navigate(['/quote'], { queryParams: { tab: 0 }, skipLocationChange: false });
  }
  pageHeader!: PageHeaderContentModel;
  pageContent!: PageContentModel;

  constructor(private router: Router,  private contentfulService: ContentfulService, private sanitizer: DomSanitizer) { }

  loadHeaderEntries() {
    this.contentfulService.getEntry('pageHeader', 'Truckload Brokerage Header').subscribe(
      (entry) => {
        this.pageHeader = new PageHeaderContentModel(entry);
        console.log(this.pageHeader)
      }, error => {
        console.error('Error fetching entries:', error);
      });
  }

  getBreadcrumbs(): string[] {
    return this.pageHeader.breadCrumbs.split(',').map(breadcrumb => breadcrumb.trim());
  }

  loadBodyEntries() {
    this.contentfulService.getEntry('pageInsights', 'Truckload Brokerage Content').subscribe(
      (entry) => {
        this.pageContent = new PageContentModel(entry);
        console.log(this.pageContent)
      }, error => {
        console.error('Error fetching entries:', error);
      });
  }

  get sanitizedDescription(): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(this.pageContent.description);
  }
  ngOnInit(): void {
    this.loadHeaderEntries();
    this.loadBodyEntries();
  }

}
