<!-- Banner -->
<div class="banner contract-logistics">
    <h1 class="banner-text">Site map</h1>
</div>

<!-- Breadcrumb Wrapper -->
<div class="breadcrumb-wrapper">
    <div class="row">
        <div class="col-md-10 col-xs-12 col-md-offset-1">
            <ol>
                <li routerLink="/">
                    <span class="material-icons">home</span>
                </li>
                <li class="active">
                    Site map
                </li>
            </ol>
        </div>
    </div>
</div>

<!-- Section -->
<div class="content-section">
    <div class="row">
        <div class="col-md-10 col-xs-12  col-md-offset-1">
            <h4 class="header-text text-blue py-2">
                Site map
            </h4>

            <div class="row">
                <div class="col-md-6 col-xs-12 p-0">

                    <div class="content-text">

                        <ul class="list-style">
                            <li class="link-share" routerLink="/quote">Quote</li>
                            <li class="link-share" routerLink="/track">Track</li>
                            <li class="link-share">Services</li>
                            <ul class="list-style pl">
                                <li routerLink="/service-offering">Service Offering</li>
                                <li routerLink="/contract-logistics">Contract Logistics</li>
                                <li routerLink="/truckload-brokerage">Truckload Brokerage</li>
                                <li routerLink="/expedited-delivery">Expedited</li>
                                <li routerLink="/final-mile">Final Mile</li>
                                <li routerLink="/international-shipping">International</li>
                                <li routerLink="/warehousing">Warehousing</li>
                                <li routerLink="/pool-distribution">Pool Distribution</li>
                                <li routerLink="/drayage-transloading">Drayage / Transloading</li>
                            </ul>

                            <li class="link-share">Resources</li>
                            <ul class="list-style pl">
                                <li routerLink="/resources">Tools And Resources</li>
                                <li routerLink="/documents">Documents</li>
                                <li routerLink="/linkex-tms">Transportation Management System (TMS)</li>
                                <li routerLink="/careers">Careers</li>
                                <li routerLink="/track">Shipment Tracking</li>
                                <li routerLink="/holiday-schedule">Holiday Schedule</li>
                                <li> <a href="/assets/docs/Saia-Privacy-Policy.pdf" target="_blank">Privacy Policy</a> </li>
                            </ul>

                            <li class="link-share">News</li>
                            <ul class="list-style pl">
                                <li routerLink="/videos">Videos</li>
                                <li routerLink="/news">Blog</li>
                            </ul>

                            <li class="link-share">Company</li>
                            <ul class="list-style pl">
                                <li routerLink="/company">About Us</li>
                                <li routerLink="/management-team">Management Team</li>
                                <li routerLink="/core-values">Core Values</li>
                                <li routerLink="/linkex-testimonials">Testimonials</li>
                                <li routerLink="/credentials-affiliations">Credentials And Affiliations</li>
                            </ul>

                            <li class="link-share" routerLink="/contact">Contact</li>
                        </ul>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>