import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { PageHeaderContentModel } from 'src/app/core/model/page-header.model';
import { ContentfulService } from 'src/app/core/service/contentful.service';
import { BlogContentModel } from 'src/app/core/model/blog-content.model';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {

  data: any;
  pageContent!: BlogContentModel;
  pageHeader!: PageHeaderContentModel;

  constructor(private HttpClient: HttpClient, private router: Router, private contentfulService: ContentfulService, private sanitizer: DomSanitizer) {
  }
  ngOnInit(): void {
    this.loadBodyEntries();
    this.loadHeaderEntries();
  }

  readMore(id: any) {
    this.router.navigate(['/news-post'], { queryParams: { Id: id }, skipLocationChange: false })
  }

  loadHeaderEntries() {
    this.contentfulService.getEntry('pageHeader', 'Blog Header').subscribe(
      (entry) => {
        this.pageHeader = new PageHeaderContentModel(entry);
      }, error => {
        console.error('Error fetching entries:', error);
      });
  }

  getBreadcrumbs(): string[] {
    return this.pageHeader.breadCrumbs.split(',').map(breadcrumb => breadcrumb.trim());
  }
  loadBodyEntries() {
    this.contentfulService.getEntry('blogPost', 'Blog Content').subscribe(
      (entry) => {
        this.pageContent = new BlogContentModel(entry);
        this.data = this.pageContent.blogJsonData;
      }, error => {
        console.error('Error fetching entries:', error);
      });
  }
 
}
