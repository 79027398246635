import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { xml2json } from 'xml-js'
import 'jspdf-autotable';
import { Location } from '@angular/common';
@Component({
  selector: 'app-bill-of-lading',
  templateUrl: './bill-of-lading.component.html',
  styleUrls: ['./bill-of-lading.component.scss']
})
export class BillOfLadingComponent implements OnInit {
  private pdf: any;
  private yMargin = 0;
  loader: boolean = false;
  bol!: FormGroup;
  bolData: any = null;
  totalWeight: string = 'NA';
  totalItems: string = 'NA';
  distance: string = '0.0';
  constructor(private formBuilder: FormBuilder, private router: Router, private location: Location) {
    const storedData = localStorage.getItem('bolData');
    if (storedData) {
      this.bolData = JSON.parse(storedData);
    } else {
      if (this.router.getCurrentNavigation()?.extras.state) {
        this.bolData = this.router.getCurrentNavigation()?.extras.state;
        if (this.bolData) {
          this.bolData = this.bolData.bolData ? JSON.parse(this.bolData.bolData) : '';
          localStorage.setItem('bolData', JSON.stringify(this.bolData));
        }
      }
    }
    this.totalItems = this.bolData.tenderPayload.items.reduce((accu: number, item: any) => accu + Number(item.quantity), 0);
    this.totalWeight = this.bolData.tenderPayload.items.reduce((accu: number, item: any) => accu + Number(item.weight), 0);
    
    // if(this.bolData.liveRateQuoteData?.jsonResponse?.quote?.distance) {
    //   this.distance = Number(this.bolData.liveRateQuoteData.jsonResponse.quote.distance || 0).toFixed(1);
    // } else {
    //   this.distance = Number(this.bolData.liveRateQuoteData?.quote?.distance || 0).toFixed(1);
    // }
    if(this.bolData.bolInfo.xmlResponse) {
      let bolTenderdata:any = xml2json(this.bolData.bolInfo.xmlResponse, {compact: true, spaces: 4});
      bolTenderdata = JSON.parse(bolTenderdata);
      let base64info = JSON.parse(xml2json(atob(bolTenderdata['service-response'].data._text), {compact: true, spaces: 4}));
      let shipmentDistance = base64info?.MercuryGate?.MasterBillOfLading?.Shipments?.Shipment?.PriceSheets?.PriceSheet?.Carrier?.Distance?._text || 0;
      let exeDistance = base64info?.MercuryGate?.MasterBillOfLading?.ExecutionLoads?.MasterBillOfLading?.PriceSheets?.PriceSheet?.Carrier?.Distance?._text  || 0;
      this.distance = Number(shipmentDistance) > Number(exeDistance) ? Number(shipmentDistance).toFixed(1): Number(exeDistance).toFixed(1);
    }
    this.bol = this.formBuilder.group({
      frightChargeItem: ['third-party'],
      masterBillofLading: [''],
      trailerLoaded: [''],
      freightCounted: ['']
    })
  }

  ngOnInit(): void {
    this.location.subscribe((event) => {
      // Prevent the default back navigation behavior
      this.location.forward();
    });
  }
  async printBolDocument(): Promise<void> {
    if (!this.pdf) {
      await this.createPdfForIe();
      const htmlData: any = this.getById('bol-info-section');
      htmlData.style.background = "#F6F6F6"
    }
    const pdfData = this.pdf.output('datauristring');
    const iframe = '<iframe id="iframe" width=\'100%\' height=\'100%\' src=\'' + pdfData + '\'></iframe>';
    let newWindow = window.open();
    newWindow?.document.open();
    newWindow?.document.write(iframe);
    newWindow?.document.close();
  }

  async downloadBOL(): Promise<void> {
    if (!this.pdf) {
      await this.createPdfForIe();
      const htmlData: any = this.getById('bol-info-section');
      htmlData.style.background = "#F6F6F6"
    }
    this.pdf.save('BOL_' + this.bolData.bolInfo.resp.customerLoadNum + '.pdf');
  }
  private async createPdfForIe() {
    this.pdf = new jsPDF('p', 'mm', 'a4', true);
    await this.addTableInfoToPdf('bol-info-section');
  }
  private async addTableInfoToPdf(id: string) {
    const headerY = this.yMargin;
    const tableY = headerY + .1;
    this.setPdfFont('Helvetica', 'normal', 12, '#212529');
    const htmlData: any = this.getById(id);
    htmlData.style.background = "#ffffff"
    let that = this
    return html2canvas(htmlData).then((canvas) => {
      let fileWidth = 220;
      let fileHeight = (canvas.height * fileWidth) / canvas.width;
      const FILEURI = canvas.toDataURL('image/png');
      let position = 0;
      that.pdf.addImage(FILEURI, 'PNG', -5, position, fileWidth, fileHeight, undefined, 'FAST');
      return true;
    });
  }
  private setPdfFont(name: string, style: string, size?: number, color?: string) {
    this.pdf.setFont(name, style);
    this.pdf.setFontSize(size);
    this.pdf.setTextColor(color);
  }
  private getById(id: string) {
    return document.getElementById(id);
  }
}
