<!-- Banner -->
<div class="banner news" *ngIf="pageHeader" [heroImage]="pageHeader.heroImageURL">
    <h1 class="banner-text">{{pageHeader.heroImageTitle}}</h1>
</div>

<!-- Breadcrumb Wrapper -->
<div class="breadcrumb-wrapper" *ngIf="pageHeader">
    <div class="row">
        <div class="col-md-10 col-xs-12 col-md-offset-1">
            <ol>
                <li routerLink="/home">
                    <span class="material-icons">home</span>
                </li>
                <ng-container *ngFor="let breadcrumb of getBreadcrumbs(); let last = last">
                    <li [ngClass]="{'child': !last, 'active': last}">
                        {{ breadcrumb }}
                    </li>
                </ng-container>
            </ol>
        </div>
    </div>
</div>

<!-- Section -->
<div class="content-section">
    <div class="row">
        <div class="col-md-10 col-md-offset-1">
            <h4 class="header-text text-blue py-2">
                Blog
            </h4>

            <div class="row row-form" skipLocationChange *ngFor="let item of data?.blogs" (click)="readMore(item.id)">

                <div class="col-md-4">
                    <img src="{{item.url}}" class="full-width" />
                </div>
                <div class="col-md-8">
                    <div class="header-text">{{ item.title }}</div>
                    <div class="header-date">{{ item.date }}</div>
                    <div class="content-text" [innerHTML]=" item.description "></div>   
                    <button class="btn-link" >Read More..</button> 
                </div> 

            </div>

        </div>
    </div>
</div>