import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { PageContentModel } from 'src/app/core/model/page-content.model';
import { PageHeaderContentModel } from 'src/app/core/model/page-header.model';
import { ContentfulService } from 'src/app/core/service/contentful.service';

@Component({
  selector: 'app-credentials',
  templateUrl: './credentials.component.html',
  styleUrls: ['./credentials.component.scss']
})
export class CredentialsComponent implements OnInit {
  entry: any;
  contentfulSysFields: any;
  pageContent!: PageContentModel;
  pageHeader!: PageHeaderContentModel; 
  accordionSubArray: any = []
  constructor(private contentfulService: ContentfulService, private sanitizer: DomSanitizer,) { 
    this.contentfulSysFields = this.contentfulService.getContentfulSysFields();
  }

  ngOnInit(): void {
    this.loadBodyEntries();
    this.loadHeaderEntries();
  }

  loadBodyEntries() {
    this.contentfulService.getEntry('pageInsights', 'Credentials and Affiliations Content').subscribe(
      (entry) => {
        
        this.pageContent = new PageContentModel(entry);
        if (this.pageContent && this.pageContent.accordion && this.pageContent.accordion.accordionItems) {
          let chunkSize = 2;
          this.accordionSubArray = Array.from({length: Math.ceil( this.pageContent.accordion.accordionItems.length / chunkSize)}, (_, i) =>
          this.pageContent.accordion.accordionItems.slice(i * chunkSize, i * chunkSize + chunkSize)
         );
        }
      }, error => {
        console.error('Error fetching entries:', error);
      });
  }

  loadHeaderEntries() {
    this.contentfulService.getEntry('pageHeader', 'Credentials And Affiliations Header').subscribe(
      (entry) => {
        this.pageHeader = new PageHeaderContentModel(entry);
      }, error => {
        console.error('Error fetching entries:', error);
      });
  }

  getBreadcrumbs(): string[] {
    return this.pageHeader.breadCrumbs.split(',').map(breadcrumb => breadcrumb.trim());
  }

}
