<!-- Breadcrumb Wrapper -->
<div class="breadcrumb-wrapper">
  <div class="row">
    <div class="col-md-10 col-xs-12 col-md-offset-1"  *ngIf="pageHeader">
      <ol>
        <li routerLink="/home">
          <span class="material-icons">home</span>
        </li>
        <ng-container *ngFor="let breadcrumb of getBreadcrumbs(); let last = last">
          <li [ngClass]="{'child': !last, 'active': last}">
              {{ breadcrumb }}
          </li>
      </ng-container>
      </ol>
    </div>
  </div>
</div>

<!-- Section -->
<div class="content-section" *ngIf="pageContent">
  <div class="row">
    <div class="col-md-10 col-md-offset-1" (focusout)="toggleState()">

      <h4 class="header-text text-blue py-2">
        {{pageContent.header}} 
      </h4>

      <mat-accordion class="management" *ngIf="pageContent && pageContent.accordion && pageContent.accordion.accordionItems">
        <mat-expansion-panel [(expanded)]="managementAccordionFlags[i]"  *ngFor="let accordionItem of pageContent.accordion.accordionItems; let i = index" class="mat-elevation-z0 accordion-custom custom-height" hideToggle="true">
          <mat-expansion-panel-header>
            <mat-panel-title class="text-alignment">
              <span class="black-color">
                <img [src]="accordionItem.imageURL" height="" alt="" />
                <br>
                {{ accordionItem.header }}
              </span>
              <span class="underline">Read Bio</span>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="content-text" [innerHTML]="sanitizedAccordionItems[i]">
          </div>
        </mat-expansion-panel>
      </mat-accordion>

    </div>
  </div>
</div>