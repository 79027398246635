import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ContentfulService } from '../../core/service/contentful.service';
import { PageContentModel } from '../../core/model/page-content.model';
import { NavigationEnd, Router } from '@angular/router';
import { PageHeaderContentModel } from 'src/app/core/model/page-header.model';

interface PageContentListItem {
  value: any;
  nodeType: string;
}

@Component({
  selector: 'app-aboutus',
  templateUrl: './aboutus.component.html',
  styleUrls: ['./aboutus.component.scss']
})

export class AboutusComponent implements OnInit {
  panelOpenState: boolean = false;

  robart: boolean = false;
  linkex: boolean = false;
  saia: boolean = false;
  saiaEx: boolean = false;

  entry: any;
  contentfulSysFields: any;
  pageContent!: PageContentModel;
  pageHeader!: PageHeaderContentModel;

  @Input() pageHeaderContent!: PageHeaderContentModel;

  toggleState() {
    if (this.robart === true || this.linkex === true || this.saia === true || this.saiaEx === true) {
      this.robart = false;
      this.linkex = false;
      this.saia = false;
      this.saiaEx = false;
    }
  }

  constructor(private contentfulService: ContentfulService, private sanitizer: DomSanitizer, private router: Router) {
    this.contentfulSysFields = this.contentfulService.getContentfulSysFields();
  }

  ngOnInit(): void {
    this.loadBodyEntries();
    this.loadHeaderEntries();

    // this.router.events.subscribe(event => {
    //   if (event instanceof NavigationEnd) {
    //     console.log(event.url);
    //     this.contentfulSysFields = this.contentfulService.getContentfulSysFields();
    //   }
    // });
  }

  loadBodyEntries() {
    this.contentfulService.getEntry('pageInsights', 'Company Page Content').subscribe(
      (entry) => {
        this.pageContent = new PageContentModel(entry);
        // console.log(this.pageContent);

      }, error => {
        console.error('Error fetching entries:', error);
      });
  }

  loadHeaderEntries() {
    this.contentfulService.getEntry('pageHeader', 'Company Page Header').subscribe(
      (entry) => {
        this.pageHeader = new PageHeaderContentModel(entry);
        // console.log(this.pageHeader);

      }, error => {
        console.error('Error fetching entries:', error);
      });
  }

  getBreadcrumbs(): string[] {
    return this.pageHeader.breadCrumbs.split(',').map(breadcrumb => breadcrumb.trim());
  }

  get sanitizedDescription(): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(this.pageContent.description);
  }

  get sanitizedAccordionItems(): SafeHtml[] {
    if (this.pageContent && this.pageContent.accordion && this.pageContent.accordion.accordionItems) {
      return this.pageContent.accordion.accordionItems.map(item => this.sanitizer.bypassSecurityTrustHtml(item.description));
    }
    return [];
  }



}
