<div class="spinner-wrapper" *ngIf="loader">
    <div class="spinner">
        <div></div>
    </div>
</div>

<!-- Breadcrumb Wrapper -->
<div class="breadcrumb-wrapper">
    <div class="row">
        <div class="col-md-10 col-xs-12 col-md-offset-1">
            <ol>
                <li routerLink="/">
                    <span class="material-icons">home</span>
                </li>
                <li class="child" routerLink="/quote">Get Quote</li>
                <li class="child" routerLink="/live-quote">Quote Details</li>
                <li class="child rm-underline">Accept Quote</li>
            </ol>
        </div>
    </div>
</div>

<!-- Section -->
<div class="content-section m-section pt-0">
    <div class="col-md-10 col-xs-12 col-md-offset-1 p-0">

        <div *ngIf="liveRateQuoteData?.statusMessage === 'SUCCESS'" class="content-section py-0">
            <div class="row">
                <div class="col-md-12 col-xs-12">
                    <h4 class="header-text text-blue py-2">Quote Reference Number # <span>{{liveRateQuoteData.quote.id}}</span></h4>
                    <hr>
                    <div class="error" *ngIf="tenderFormStatusError">
                        <span>Error</span> | Please fill out all highlighted form fields correctly and then try submitting again
                    </div>
                    <div class="error" *ngIf="timeErrorMessage">
                        <span>Error</span> | Please check and fix pickup and delivery times for errors
                    </div>
                    <div class="success" *ngIf="isFormSubmittedTruckloadApiError === 'success'">
                        Thank you for your business. A member of our Truckload Team will contact you shortly.
                    </div>
                    <div *ngIf="isFormSubmittedTruckloadApiError === 'error'" class="error">
                        {{tenderResponse.message}}
                    </div>
                    <form action="" autocomplete="off" [formGroup]="tlTenderQuote">

                        <div class="row row-form">
                            <div class="col-md-12 col-xs-12">
                                <h4 class="header-text header-sub-size py-2 remove-underline">
                                    Shipper Information
                                </h4>
                            </div>
                        </div>

                        <div class="row row-form">
                            <div class="form-group col-md-3 col-xs-12 readonly">
                                <label for="">Pickup Date<sup>&nbsp;</sup></label>
                                <mat-form-field appearance="outline" class="col-md-12 p-0">
                                    <input matInput type="input" autocomplete="off" formControlName="pickupDate" [readonly]="true" />
                                </mat-form-field>
                            </div>

                            <div class="form-group col-md-3 col-xs-12 display-block">
                                <app-time-picker class="form-group col-md-12 col-xs-12 p-0" formControlName="earliestPickupSI" label="Earliest Pickup Time" [(isAM)]="eptAM" [(inputTime)]="eptValue" [minumumTime]="'08:00'" [maximumTime]="'17:00'" [instance]="'ept'" [(isError)]="eptError" (onTimeChange)="onTimeChange($event)"></app-time-picker>
                            </div>

                            <div class="form-group col-md-3 col-xs-12 display-block">
                                <app-time-picker class="form-group col-md-12 col-xs-12 p-0" formControlName="latestPickupSI" label="Latest Pickup Time" [(isAM)]="lptAM" [(inputTime)]="lptValue" [minumumTime]="'08:00'" [maximumTime]="'17:00'" [instance]="'lpt'" [(isError)]="lptError" (onTimeChange)="onTimeChange($event)"></app-time-picker>
                            </div>

                            <!-- <app-timepicker class="form-group col-md-3 col-xs-12" formControlName="earliestPickup" label="Earliest Pickup Time"></app-timepicker>

                            <app-timepicker class="form-group col-md-3 col-xs-12" formControlName="latestPickup" inputHours="5" label="Latest Pickup Time"></app-timepicker> -->

                            <div class="form-group col-md-3 col-xs-12 counter counter-astrik">
                                <label for="">Company Name<sup>*</sup></label>
                                <mat-form-field appearance="outline" class="col-md-12 p-0">
                                    <input matInput type="input" autocomplete="off" placeholder="Enter Company Name" formControlName="pickupCompanyName" (input)="alphaOnly($event)" maxlength="50" (input)="updateCharacterCount($event, 'pickupCompanyName')" />
                                    <mat-error>Enter Company Name</mat-error>
                                </mat-form-field>
                                <mat-hint>{{ characterCount['pickupCompanyName'] }}/50</mat-hint>
                            </div>
                        </div>

                        <div class="row row-form">
                            <div class="form-group col-md-12 col-xs-12 counter counter-astrik">
                                <label for="">Address Line 1<sup>*</sup></label>
                                <mat-form-field appearance="outline" class="col-md-12 p-0">
                                    <input matInput type="input" autocomplete="off" placeholder="Enter Address Line 1" formControlName="pickupAddressline1" (input)="alphaOnly($event)" maxlength="50" (input)="updateCharacterCount($event, 'pickupAddressline1')" />
                                    <mat-error> Enter Address Line 1</mat-error>
                                </mat-form-field>
                                <mat-hint>{{ characterCount['pickupAddressline1'] }}/50</mat-hint>
                            </div>

                            <div class="form-group col-md-12 col-xs-12 counter counter-astrik">
                                <label for="">Address Line 2<sup>&nbsp;</sup></label>
                                <mat-form-field appearance="outline" class="col-md-12 p-0">
                                    <input matInput type="input" autocomplete="off" placeholder="Enter Address Line 2" formControlName="pickupAddressline2" (input)="alphaOnly($event)" maxlength="50" (input)="updateCharacterCount($event, 'pickupAddressline2')" />
                                </mat-form-field>
                                <mat-hint>{{ characterCount['pickupAddressline2'] }}/50</mat-hint>
                            </div>
                        </div>

                        <div class="row row-form">
                            <div class="form-group col-md-3 col-xs-12 readonly">
                                <label for="">City<sup>*</sup></label>
                                <mat-form-field appearance="outline" class="col-md-12 p-0">
                                    <input matInput type="input" autocomplete="off" formControlName="pickupShipperCity" [readonly]="true" />
                                </mat-form-field>
                            </div>

                            <div class="form-group col-md-3 col-xs-12 readonly">
                                <label for="">State<sup>*</sup></label>
                                <mat-form-field appearance="outline" class="col-md-12 p-0">
                                    <input matInput type="input" autocomplete="off" formControlName="pickupShipperState" [readonly]="true" />
                                </mat-form-field>
                            </div>

                            <div class="form-group col-md-3 col-xs-12 readonly">
                                <label for="">Zip Code<sup>*</sup></label>
                                <mat-form-field appearance="outline" class="col-md-12 p-0">
                                    <input matInput type="input" autocomplete="off" formControlName="pickupShipperZip" [readonly]="true" />
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="row row-form">
                            <div class="form-group col-md-3 col-xs-12 counter counter-astrik">
                                <label for="">Contact Name<sup>&nbsp;</sup></label>
                                <mat-form-field appearance="outline" class="col-md-12 p-0">
                                    <input matInput type="input" autocomplete="off" formControlName="pickupContactName" placeholder="Enter Contact Name" (input)="alphaOnly($event)" maxlength="50" (input)="updateCharacterCount($event, 'pickupContactName')" />
                                </mat-form-field>
                                <mat-hint>{{ characterCount['pickupContactName'] }}/50</mat-hint>
                            </div>

                            <div class="form-group col-md-3 col-xs-12 counter counter-astrik">
                                <label for="">Phone<sup>&nbsp;</sup></label>
                                <mat-form-field appearance="outline" class="col-md-12 p-0">
                                    <input matInput type="input" autocomplete="off" formControlName="pickupPhone" placeholder="Enter Phone" maxlength="12" (input)="numberOnly($event)" maxlength="10" (input)="updateCharacterCount($event, 'pickupPhone')" />
                                </mat-form-field>
                                <mat-hint>{{ characterCount['pickupPhone'] }}/10</mat-hint>
                            </div>

                            <div class="form-group col-md-3 col-xs-12 counter counter-astrik">
                                <label for="">Email<sup>&nbsp;</sup></label>
                                <mat-form-field appearance="outline" class="col-md-12 p-0">
                                    <input matInput type="input" autocomplete="off" formControlName="pickupEmail" placeholder="Enter Email" maxlength="50" (input)="updateCharacterCount($event, 'pickupEmail')" />
                                    <mat-error>Enter Valid Email</mat-error>
                                </mat-form-field>
                                <mat-hint>{{ characterCount['pickupEmail'] }}/50</mat-hint>
                            </div>
                        </div>


                        <div class="row row-form">
                            <div class="col-md-12 col-xs-12">
                                <h4 class="header-text header-sub-size py-2 remove-underline">
                                    CONSIGNEE INFORMATION
                                </h4>
                            </div>
                        </div>

                        <div class="row row-form">
                            <div class="form-group col-md-3 col-xs-12 readonly">
                                <label for="">Delivery Date<sup>*</sup></label>
                                <mat-form-field appearance="outline" class="col-md-12 p-0">
                                    <input matInput type="input" autocomplete="off" formControlName="deliveryDate" [readonly]="true" />
                                </mat-form-field>
                            </div>

                            <div class="form-group col-md-3 col-xs-12 display-block">
                                <app-time-picker class="form-group col-md-12 col-xs-12 p-0" formControlName="earliestDeliveryCI" label="Earliest Delivery Time" [(isAM)]="edtAM" [(inputTime)]="edtValue" [minumumTime]="'08:00'" [maximumTime]="'17:00'" [instance]="'edt'" [(isError)]="edtError" (onTimeChange)="onTimeChange($event)"></app-time-picker>

                            </div>

                            <div class="form-group col-md-3 col-xs-12 display-block">
                                <app-time-picker class="form-group col-md-12 col-xs-12 p-0" formControlName="latestDeliveryCI" label="Latest Delivery Time" [(isAM)]="ldtAM" [(inputTime)]="ldtValue" [minumumTime]="'08:00'" [maximumTime]="'17:00'" [instance]="'ldt'" [(isError)]="ldtError" (onTimeChange)="onTimeChange($event)"></app-time-picker>

                            </div>
                            <!-- <app-timepicker class="form-group col-md-3 col-xs-12" formControlName="earliestDelivery" label="Earliest Delivery Time"></app-timepicker>

                            <app-timepicker class="form-group col-md-3 col-xs-12" formControlName="latestDelivery" label="Latest Delivery Time"></app-timepicker> -->

                            <div class="form-group col-md-3 col-xs-12 counter counter-astrik">
                                <label for="">Company Name<sup>*</sup></label>
                                <mat-form-field appearance="outline" class="col-md-12 p-0">
                                    <input matInput type="input" autocomplete="off" placeholder="Enter Company Name" formControlName="deliveryCompanyName" (input)="alphaOnly($event)" maxlength="50" (input)="updateCharacterCount($event, 'deliveryCompanyName')" />
                                    <mat-error>Enter Company Name</mat-error>
                                </mat-form-field>
                                <mat-hint>{{ characterCount['deliveryCompanyName'] }}/50</mat-hint>
                            </div>
                        </div>

                        <div class="row row-form">
                            <div class="form-group col-md-12 col-xs-12 counter counter-astrik">
                                <label for="">Address Line 1<sup>*</sup></label>
                                <mat-form-field appearance="outline" class="col-md-12 p-0">
                                    <input matInput type="input" autocomplete="off" placeholder="Enter Address Line 1" formControlName="deliveryAddress1" (input)="alphaOnly($event)" maxlength="50" (input)="updateCharacterCount($event, 'deliveryAddress1')" />
                                    <mat-error> Enter Address Line 1</mat-error>
                                </mat-form-field>
                                <mat-hint>{{ characterCount['deliveryAddress1'] }}/50</mat-hint>
                            </div>

                            <div class="form-group col-md-12 col-xs-12 counter counter-astrik">
                                <label for="">Address Line 2<sup>&nbsp;</sup></label>
                                <mat-form-field appearance="outline" class="col-md-12 p-0">
                                    <input matInput type="input" autocomplete="off" placeholder="Enter Address Line 2" formControlName="deliveryAddress2" (input)="alphaOnly($event)" maxlength="50" (input)="updateCharacterCount($event, 'deliveryAddress2')" />
                                </mat-form-field>
                                <mat-hint>{{ characterCount['deliveryAddress2'] }}/50</mat-hint>
                            </div>
                        </div>

                        <div class="row row-form">
                            <div class="form-group col-md-3 col-xs-12 readonly">
                                <label for="">City<sup>*</sup></label>
                                <mat-form-field appearance="outline" class="col-md-12 p-0">
                                    <input matInput type="input" autocomplete="off" formControlName="deliveryCity" [readonly]="true" />
                                </mat-form-field>
                            </div>

                            <div class="form-group col-md-3 col-xs-12 readonly">
                                <label for="">State<sup>*</sup></label>
                                <mat-form-field appearance="outline" class="col-md-12 p-0">
                                    <input matInput type="input" autocomplete="off" formControlName="deliveryState" [readonly]="true" />
                                </mat-form-field>
                            </div>

                            <div class="form-group col-md-3 col-xs-12 readonly">
                                <label for="">Zip Code<sup>*</sup></label>
                                <mat-form-field appearance="outline" class="col-md-12 p-0">
                                    <input matInput type="input" autocomplete="off" formControlName="deliveryZip" [readonly]="true" />
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="row row-form">
                            <div class="form-group col-md-3 col-xs-12 counter counter-astrik">
                                <label for="">Contact Name<sup>&nbsp;</sup></label>
                                <mat-form-field appearance="outline" class="col-md-12 p-0">
                                    <input matInput type="input" autocomplete="off" formControlName="deliveryContact" placeholder="Enter Contact Name" (input)="alphaOnly($event)" maxlength="50" (input)="updateCharacterCount($event, 'deliveryContact')" />
                                </mat-form-field>
                                <mat-hint>{{ characterCount['deliveryContact'] }}/50</mat-hint>
                            </div>

                            <div class="form-group col-md-3 col-xs-12 counter counter-astrik">
                                <label for="">Phone<sup>&nbsp;</sup></label>
                                <mat-form-field appearance="outline" class="col-md-12 p-0">
                                    <input matInput type="input" autocomplete="off" formControlName="deliveryPhone" placeholder="Enter Phone" maxlength="12" (input)="numberOnly($event)" maxlength="10" (input)="updateCharacterCount($event, 'deliveryPhone')" />
                                </mat-form-field>
                                <mat-hint>{{ characterCount['deliveryPhone'] }}/10</mat-hint>
                            </div>

                            <div class="form-group col-md-3 col-xs-12 counter counter-astrik">
                                <label for="">Email<sup>&nbsp;</sup></label>
                                <mat-form-field appearance="outline" class="col-md-12 p-0">
                                    <input matInput type="input" autocomplete="off" formControlName="deliveryEmail" placeholder="Enter Email" maxlength="50" (input)="updateCharacterCount($event, 'deliveryEmail')" />
                                    <mat-error>Enter Valid Email</mat-error>
                                </mat-form-field>
                                <mat-hint>{{ characterCount['deliveryEmail'] }}/50</mat-hint>
                            </div>
                        </div>

                        <div class="row row-form">
                            <div class="col-md-12 col-xs-12">
                                <h4 class="header-text header-sub-size py-2 remove-underline">
                                    Additional Information
                                </h4>
                            </div>
                        </div>

                        <div class="row row-form">
                            <div class="form-group col-md-12 col-xs-12 counter counter-astrik">
                                <label for="">Special Instructions<sup>&nbsp;</sup></label>
                                <mat-form-field appearance="outline" class="col-md-12 p-0">
                                    <input matInput type="input" autocomplete="off" placeholder="Enter Special Instructions" formControlName="specialInstruction" maxlength="100" (input)="updateCharacterCount($event, 'specialInstruction')" />
                                </mat-form-field>
                                <mat-hint>{{ characterCount['specialInstruction'] }}/100</mat-hint>
                            </div>
                        </div>

                        <div class="row row-form">
                            <div class="col-md-12 col-xs-12">
                                <h4 class="header-text header-sub-size py-2 remove-underline">
                                    Bill To
                                </h4>
                            </div>
                        </div>

                        <div class="row row-form">
                            <div class="col-md-12 col-xs-12">
                                <div class="textarea-custom">
                                    <!-- <h4 class="header-text header-sub-size remove-underline">
                                        Bill To
                                    </h4> -->
                                    <div class="text-area">
                                        <span>{{billTo.name}}</span> <br>
                                        <span>{{billTo.address1}} {{billTo.address2}}</span> <br>
                                        <span>{{billTo.city}}</span>, <span>{{billTo.state
                                            }}</span>, <span>{{billTo.zip}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <br>

                        <div class="row row-form">
                            <div class="col-md-12 col-xs-12">
                                <h4 class="header-text header-sub-size py-2 remove-underline">
                                    Freight Information
                                </h4>
                            </div>
                        </div>


                        <ng-container *ngFor="let control of freightDetailsAsFormArray.controls; let i = index;" formArrayName='freightDetails'>
                            <div class="row row-form" [formGroupName]="i">
                                <div class="col-md-12 col-xs-12">
                                    <h4 class="header-text header-sub-size py-2 remove-underline">
                                        Item: {{i+1}}
                                    </h4>
                                </div>

                                <div class="form-group col-md-12 col-xs-12 counter counter-astrik">
                                    <label for="">Freight Description/Commodity<sup>*</sup></label>
                                    <mat-form-field appearance="outline" class="col-md-12 p-0">
                                        <input matInput type="input" autocomplete="off" formControlName="freightDescription" placeholder="Enter Freight Description/Commodity" (input)="alphaOnly($event)" maxlength="100" (input)="updateCharacterCountDescription($event, i)" />
                                        <mat-error> Enter Freight Description</mat-error>
                                    </mat-form-field>
                                    <mat-hint>{{ characterCount[i] }}/100</mat-hint> <!-- Use index i to access character count -->
                                </div>


                                <div class="form-group col-md-3 col-xs-12">
                                    <label for="">Package Type<sup> &#42;</sup></label>
                                    <mat-form-field appearance="outline" class="col-md-12 p-0">
                                        <mat-select placeholder="Select Package Type" formControlName="packageType">
                                            <mat-option *ngFor="let packagetype of packagetype" [value]="packagetype.value">
                                                {{ packagetype.viewValue }}
                                            </mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="freightDetailsAsFormArray.controls[i].get('packageType')?.invalid">Select Package Type</mat-error>
                                    </mat-form-field>
                                </div>

                                <div class="form-group col-md-3 col-xs-12">
                                    <label for="">Weight<sup> &#42;</sup></label>
                                    <mat-form-field appearance="outline" class="col-md-12 p-0">
                                        <input matInput type="input" placeholder="Enter Weight" formControlName="weight" (blur)="addWeight($event,i)" maxlength="6" (input)="commaNumberOnly($event)" />
                                        <mat-error *ngIf="freightDetailsAsFormArray.controls[i].get('weight').hasError('required')">Enter Valid Weight</mat-error>
                                        <mat-error *ngIf="freightDetailsAsFormArray.controls[i].get('weight').hasError('maxWeight')">Maximum weight is 44,000 lbs</mat-error>
                                    </mat-form-field>
                                </div>

                                <div class="form-group col-md-3 col-xs-12">
                                    <label for=""># of Pieces<sup>*</sup></label>
                                    <mat-form-field appearance="outline" class="col-md-12 p-0">
                                        <input matInput type="input" autocomplete="off" formControlName="pieces" (blur)="addPieces($event,i)" placeholder="Enter # of Pieces" (input)="numberOnly($event)" />
                                        <mat-error *ngIf="freightDetailsAsFormArray.controls[i].get('pieces')?.invalid">Enter Pieces</mat-error>
                                    </mat-form-field>
                                </div>

                                <div class="form-group col-md-3 col-xs-12">
                                    <label for="">Dimensions (L x W x H in.) </label>
                                    <mat-form-field appearance="outline" class="col-md-12 p-0">
                                        <input matInput type="input" maxlength="25" inputmode="numeric" placeholder="Enter Dimensions" autocomplete="off" formControlName="dimensions" />
                                        <mat-error *ngIf="freightDetailsAsFormArray.controls[i].get('dimensions').hasError('invalidDimensions')">Invalid Dimensions</mat-error>
                                    </mat-form-field>
                                </div>


                                <div class="form-group col-md-3 col-xs-12 readonly" *ngIf="i === 0">
                                    <label for="">Hazmat<sup>*</sup></label>
                                    <mat-form-field appearance="outline" class="col-md-12 p-0">
                                        <input matInput type="input" autocomplete="off" formControlName="hazmat" (input)="numberOnly($event)" [readonly]="true" />
                                    </mat-form-field>
                                </div>
                            </div>
                        </ng-container>


                        <div class="row row-form">
                            <div class="col-md-12 col-xs-12 floating-right">
                                <button mat-flat-button class="primary-btn" (click)="addControl()" type="button">Add <span class="material-icons icon-size">add</span></button> &nbsp;&nbsp;
                                <button mat-flat-button class="primary-btn" (click)="removeControl()" *ngIf="freightDetailsAsFormArray.controls.length > 1" type="button">Remove <span class="material-icons icon-size">remove</span></button>
                            </div>
                        </div>

                        <br>
                        <hr>

                        <div class="row row-form">
                            <div class="col-md-12 col-xs-12 floating-right">
                                <h4 class="header-text header-sub-size  mr-3 center remove-underline">
                                    Total Items: <span>{{totalItem}}</span>
                                </h4>
                            </div>

                            <div class="col-md-12 col-xs-12 floating-right">
                                <h4 class="header-text header-sub-size mr-3 center remove-underline">
                                    Total Weight: <span>{{totalWeight}}</span> lbs

                                </h4>
                            </div>
                            <div class="col-md-12 col-xs-12 floating-right" *ngIf="totalWeight>=44000">
                                <mat-error *ngIf="true">Total Weight cannot exceed 44,000 lbs</mat-error>
                            </div>

                        </div>


                        <div class="row row-form">
                            <div class="col-md-12 col-xs-12">
                                <h4 class="header-text header-sub-size py-2 remove-underline">
                                    REFERENCE INFORMATION
                                </h4>
                            </div>
                        </div>

                        <div class="row row-form">

                            <div class="form-group col-md-4 col-xs-12 counter counter-astrik">
                                <label for="">Billing Reference/PO #<sup>*</sup></label>
                                <mat-form-field appearance="outline" class="col-md-12 p-0">
                                    <input matInput type="input" autocomplete="off" formControlName="billingReference" placeholder="Enter Billing Reference/PO #" (input)="alphaOnly($event)" maxlength="50" (input)="updateCharacterCount($event, 'billingReference')" />
                                    <mat-error>Enter Billing Reference #</mat-error>
                                </mat-form-field>
                                <mat-hint>{{ characterCount['billingReference'] }}/50</mat-hint>
                            </div>

                            <div class="form-group col-md-4 col-xs-12 counter counter-astrik">
                                <label for="">Shipper Reference #</label>
                                <mat-form-field appearance="outline" class="col-md-12 p-0">
                                    <input matInput type="input" autocomplete="off" formControlName="shipperReference" placeholder="Enter Shipper Reference #" (input)="alphaOnly($event)" maxlength="50" (input)="updateCharacterCount($event, 'shipperReference')" />
                                </mat-form-field>
                                <mat-hint>{{ characterCount['shipperReference'] }}/50</mat-hint>
                            </div>

                            <div class="form-group col-md-4 col-xs-12 counter counter-astrik">
                                <label for="">Consignee Reference #</label>
                                <mat-form-field appearance="outline" class="col-md-12 p-0">
                                    <input matInput type="input" autocomplete="off" formControlName="consigneeReference" placeholder="Enter Consignee Reference #" (input)="alphaOnly($event)" maxlength="50" (input)="updateCharacterCount($event, 'consigneeReference')" />
                                </mat-form-field>
                                <mat-hint>{{ characterCount['consigneeReference'] }}/50</mat-hint>
                            </div>

                        </div>

                        <br>

                        <div class="row center-xs">
                            <div class="col-md-4"></div>
                            <div class="col-md-4">
                                <button mat-flat-button class="primary-btn" type="submit" (click)='submitTenderRequest()'>
                                    Submit
                                </button>
                            </div>
                            <div class="col-md-4 end-xs p-0">
                                <button mat-button class="primary-btn link" type="clear" (click)="resetForm()">Clear</button>
                            </div>
                        </div>


                    </form>
                </div>

            </div>
        </div>


    </div>
</div>