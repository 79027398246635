<!-- Breadcrumb Wrapper -->
<div class="breadcrumb-wrapper">
  <div class="row">
    <div class="col-md-10 col-xs-12  col-md-offset-1" *ngIf="pageHeader">
      <ol>
        <li routerLink="/home">
          <span class="material-icons">home</span>
        </li>
        <ng-container *ngFor="let breadcrumb of getBreadcrumbs(); let last = last">
          <li [ngClass]="{'child': !last, 'active': last}">
              {{ breadcrumb }}
          </li>
      </ng-container>
      </ol>
    </div>
  </div>
</div>

<!-- Section -->
<div class="content-section" *ngIf="pageContent">
  <div class="row">
    <div class="col-md-10 col-md-offset-1">

      <h4 class="header-text text-blue py-2">
        {{pageContent.header}} 
      </h4>

      <div class="content-text"  [innerHTML]="pageContent.description">
      </div>


      <mat-accordion>
        <mat-expansion-panel [(expanded)]="coreAccordionFlags[i]"  *ngFor="let accordionItem of pageContent.accordion.accordionItems; let i = index"  class="mat-elevation-z0 accordion-custom">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ accordionItem.header }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="content-text"  [innerHTML]="accordionItem.description">
          </div>
        </mat-expansion-panel>
        <!-- <mat-expansion-panel [(expanded)]="values" class="mat-elevation-z0 accordion-custom">
          <mat-expansion-panel-header>
            <mat-panel-title>
              Living the Values
            </mat-panel-title>
          </mat-expansion-panel-header>

          <div class="content-text pb-0">


            See how employees are living the values:
            <br>
            <ul class=" ">
              <li><a href="https://vimeo.com/808346656" target="_blank" class="list-style">Customer First</a></li>
              <li><a href="https://vimeo.com/827766526" target="_blank" class="list-style">Safety</a></li>
              <li><a href="https://vimeo.com/817751683" target="_blank" class="list-style">Dignity and Respect</a></li>
              <li><a href="https://vimeo.com/798400627" target="_blank" class="list-style">Community</a></li>
            </ul>

          </div>
        </mat-expansion-panel> -->

      </mat-accordion>

    </div>
  </div>
</div>