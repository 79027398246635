import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { PageContentModel } from 'src/app/core/model/page-content.model';
import { PageHeaderContentModel } from 'src/app/core/model/page-header.model';
import { ContentfulService } from 'src/app/core/service/contentful.service';

@Component({
  selector: 'app-corevalue',
  templateUrl: './corevalue.component.html',
  styleUrls: ['./corevalue.component.scss']
})

export class CorevalueComponent implements OnInit {

  customerFirst: boolean = false;
  safty: boolean = false;
  takingCareOfEachOther: boolean = false;
  dignityRespect: boolean = false;
  doTheRight: boolean = false;
  community: boolean = false;
  values: boolean = false;


  entry: any;
  contentfulSysFields: any;
  pageContent!: PageContentModel;
  pageHeader!: PageHeaderContentModel; 
  
  coreAccordionFlags: boolean[]=[]

  toggleState() {
    if(this.coreAccordionFlags.indexOf(true)>-1) {
      console.log(this.coreAccordionFlags)
      for(let index=0; index < this.coreAccordionFlags.length;index++ ) {
        this.coreAccordionFlags[index] = false;
      }
    }
  }

  constructor(private contentfulService: ContentfulService, private sanitizer: DomSanitizer) {
    this.contentfulSysFields = this.contentfulService.getContentfulSysFields();
  }
   

  ngOnInit(): void {
    this.loadBodyEntries();
    this.loadHeaderEntries();
  }
  loadBodyEntries() {
    this.contentfulService.getEntry('pageInsights', 'Core Values Content').subscribe(
      (entry) => {
        
        this.pageContent = new PageContentModel(entry);
        if (this.pageContent && this.pageContent.accordion && this.pageContent.accordion.accordionItems) {
          this.pageContent.accordion.accordionItems.forEach((ele) => {
            this.coreAccordionFlags.push(false)
          });
        }

      }, error => {
        console.error('Error fetching entries:', error);
      });
  }

  loadHeaderEntries() {
    this.contentfulService.getEntry('pageHeader', 'Core Values Header').subscribe(
      (entry) => {
        this.pageHeader = new PageHeaderContentModel(entry);
      }, error => {
        console.error('Error fetching entries:', error);
      });
  }

  getBreadcrumbs(): string[] {
    return this.pageHeader.breadCrumbs.split(',').map(breadcrumb => breadcrumb.trim());
  }

}
