<!-- Breadcrumb Wrapper -->
<div class="spinner-wrapper" *ngIf="loader">
  <div class="spinner">
    <div></div>
  </div>
</div>

<div class="breadcrumb-wrapper" *ngIf="pageHeader">
  <div class="row">
    <div class="col-md-10 col-xs-12 col-md-offset-1">
      <ol>
        <li routerLink="/home">
          <span class="material-icons">home</span>
        </li>
        <ng-container *ngFor="let breadcrumb of getBreadcrumbs(); let last = last">
          <li [ngClass]="{'child': !last, 'active': last}">
            {{ breadcrumb }}
          </li>
        </ng-container>
      </ol>
    </div>
  </div>
</div>

<!-- Section -->
<div class="content-section">
  <div class="row">
    <div class="col-md-10 col-xs-12 col-md-offset-1" *ngIf="pageContent">
      <h4 class="header-text text-blue py-2">{{pageContent.header}}</h4>

      <div class="content-text contact-us" [innerHTML]="pageContent.description"></div>

      <br />

      <h4 class="header-text text-blue py-2">GET IN TOUCH WITH US</h4>
      <div *ngIf="isInfoSubmittedError" class="error">
        {{apiResponse.message}}
      </div>
      <form [formGroup]="contactus" class=" ">

        <div class="error" *ngIf="errorMsg">
          <span>Error</span> | Please fill out all highlighted form fields correctly and then try submitting again
        </div>

        <div class="success" *ngIf="successMsg">
          Thank you for submitting a contact form. LinkEx team will get back to you shortly.
        </div>


        <div class="row row-form">
          <div class="form-group col-md-6 col-xs-12">
            <label for="">Contact Name <sup> &#42;</sup></label>
            <mat-form-field appearance="outline" class="col-md-12 p-0">
              <input matInput type="input" placeholder="Enter Contact Name" formControlName="yourName" autocomplete="off" (input)="alphaOnly($event)" />
              <mat-error> Enter Valid Contact Name </mat-error>
            </mat-form-field>
          </div>

          <div class="form-group col-md-6 col-xs-12">
            <label for="">Company Name<sup> &#42;</sup></label>
            <mat-form-field appearance="outline" class="col-md-12 p-0">
              <input matInput type="input" placeholder="Enter Company Name" formControlName="yourCompany" (input)="alphaOnly($event)" />
              <mat-error> Enter Valid Company Name </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="row row-form">
          <div class="form-group col-md-6 col-xs-12">
            <label for="">Email Address<sup> &#42;</sup></label>
            <mat-form-field appearance="outline" class="col-md-12 p-0">
              <input matInput type="email" placeholder="Enter Email Address" formControlName="yourEmail" autocomplete="off" />
              <mat-error>Enter Valid Email Address</mat-error>

            </mat-form-field>
          </div>

          <div class="form-group col-md-6 col-xs-12">
            <label for="">Phone Number<sup> &#42;</sup></label>
            <mat-form-field appearance="outline" class="col-md-12 p-0">
              <input matInput type="input" placeholder="Enter Phone Number" formControlName="yourPhone" autocomplete="off" maxlength="12" (input)="numberOnly($event)" inputmode="numeric" />
              <mat-error> Enter Valid Phone Number </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="form-group">
            <label for="">Select Service<sup> &#42;</sup></label>
            <mat-form-field appearance="outline" class="col-md-12 p-0">
              <mat-select placeholder="All Services/General Inquiry" ngDefaultControl formControlName="menu">
                <mat-option *ngFor="let search of search" [value]="search.value">
                  {{ search.viewValue }}
                </mat-option>
              </mat-select>
              <mat-error> Please Select Service </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="form-group">
            <label for="">Message</label>
            <mat-form-field appearance="outline" class="col-md-12 p-0 textarea">
              <textarea matInput placeholder="Enter Message" rows="3" formControlName="yourMessage" autocomplete="off" maxlength="255" (input)="alphaOnly($event)"></textarea>
            </mat-form-field>
          </div>
        </div>

        <div class="row center-xs">
          <div class="col-md-4"></div>
          <div class="col-md-4">
            <button (click)="onSubmit()" type="reset" mat-flat-button class="primary-btn">
              Submit
            </button>
          </div>
          <div class="col-md-4 end-xs p-0">
            <button (click)="onReset()" type="reset" mat-button class="primary-btn link">Clear</button>
          </div>
        </div>

        <br /><br />
      </form>
    </div>
  </div>
</div>