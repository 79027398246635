import { Component, OnInit, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-timepicker',
  templateUrl: './timepicker.component.html',
  styleUrls: ['./timepicker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TimepickerComponent),
      multi: true
    }
  ]
})
export class TimepickerComponent implements OnInit {

  @Input() label: string = '';

  // Internal values for time components
  selectedHour: number | null = null;
  selectedMinute: number | null = null;
  selectedPeriod: string | null = null;

  hours: number[] = Array.from({ length: 12 }, (_, i) => i + 1);
  minutes: number[] = Array.from({ length: 60 }, (_, i) => i);
  periods: string[] = ['AM', 'PM'];

  // ControlValueAccessor methods
  private onChange: (value: any) => void = () => { };
  private onTouch: () => void = () => { };

  writeValue(value: any): void {
    console.log('Received value:', value);
    if (value) {
      this.selectedHour = value.selectedHour;
      this.selectedMinute = value.selectedMinute;
      this.selectedPeriod = value.selectedPeriod;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  // Methods to update values and propagate changes
  updateHour(hour: number) {
    this.selectedHour = hour;
    this.propagateChanges();
  }

  updateMinute(minute: number) {
    this.selectedMinute = minute;
    this.propagateChanges();
  }

  updatePeriod(period: string) {
    this.selectedPeriod = period;
    this.propagateChanges();
  }

  private propagateChanges() {
    this.onChange({
      selectedHour: this.selectedHour,
      selectedMinute: this.selectedMinute,
      selectedPeriod: this.selectedPeriod
    });
    this.onTouch();
  }

  constructor() { }

  ngOnInit(): void {
  }

}
