<!-- Breadcrumb Wrapper -->
<div class="breadcrumb-wrapper">
    <div class="row">
        <div class="col-md-10 col-xs-12 col-md-offset-1" *ngIf="pageHeader">
            <ol>
                <li routerLink="/home">
                    <span class="material-icons">home</span>
                </li>
                <ng-container *ngFor="let breadcrumb of getBreadcrumbs(); let last = last">
                    <li [ngClass]="{'child': !last, 'active': last}">
                        {{ breadcrumb }}
                    </li>
                </ng-container>
            </ol>
        </div>
    </div>
</div>

<!-- Section -->
<div class="content-section">
    <div class="row">
        <div class="col-md-10 col-md-offset-1" *ngIf="pageContent">

            <h4 class="header-text text-blue py-2">
                {{pageContent.header}}
            </h4>

            <div class="content-text" [innerHTML]="pageContent.description">
            </div>



        </div>
    </div>
</div>