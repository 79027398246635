import { ParseTreeResult } from '@angular/compiler';
import { Component, OnInit, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { ContentfulService } from '../core/service/contentful.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { PageHeaderContentModel } from '../core/model/page-header.model';
import { PageContentModel } from '../core/model/page-content.model';
import { NotificationContentModel } from '../core/model/notification.model';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  value = 'Clear me';
  trackCheck: boolean = false;
  //trackingForm: FormGroup;

  contentfulSysFields: any;
  pageContent!: PageContentModel;
  pageNotification!: NotificationContentModel;
  pageHeader!: PageHeaderContentModel;

  constructor(private fb: FormBuilder, private router: Router, private elementRef: ElementRef, private location: Location, private contentfulService: ContentfulService, private sanitizer: DomSanitizer,) { }

  trackingForm = this.fb.group({
    trackRequest: ['', [Validators.required]],
  })

  ngOnInit(): void {
    this.location.replaceState('/');
    this.loadBodyEntries();
    this.loadHeaderEntries();
    this.loadNotificationEntry();
  }

  navigate(url: string) {
    window.location.href = url;
  }

  clear() {
    this.trackingForm.reset();
  }

  submit(): void {
    if (this.trackingForm.status === 'INVALID') return;
    this.router.navigate(['/track'], { queryParams: { shipOrder: this.trackingForm.value.trackRequest } });
  }

  onKeyDown(event: KeyboardEvent): void {
    if (event.key === 'Enter' || event.keyCode === 13) { // Enter key code
      event.preventDefault(); // Prevent form submission
      const trackRequestControl = this.trackingForm.get('trackRequest');
      if (trackRequestControl && trackRequestControl.value && trackRequestControl.value.trim() !== '') {
        this.router.navigate(['/track'], { queryParams: { shipOrder: trackRequestControl.value } });
      } else {
        trackRequestControl?.markAsTouched();
      }
    }
  }

  loadBodyEntries() {
    this.contentfulService.getEntry('pageInsights', 'Home Content').subscribe(
      (entry) => {
        this.pageContent = new PageContentModel(entry);
        // console.log(this.pageContent);

      }, error => {
        console.error('Error fetching entries:', error);
      });
  }

  loadNotificationEntry() {
    this.contentfulService.getEntry('notificationScroller', 'Notification').subscribe(
      (entry) => { 
        this.pageNotification = new NotificationContentModel(entry);
        //console.log(this.pageNotification);

      }, error => {
        console.error('Error fetching entries:', error);
      });
  }

  loadHeaderEntries() {
    this.contentfulService.getEntry('pageHeader', 'Home Header').subscribe(
      (entry) => {
        this.pageHeader = new PageHeaderContentModel(entry);
        // console.log(this.pageHeader);

      }, error => {
        console.error('Error fetching entries:', error);
      });
  }

  get sanitizedDescription(): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(this.pageContent.description);
  }

  get sanitizedNotification(): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(this.pageNotification.description);
  }

  get sanitizedAccordionItems(): SafeHtml[] {
    if (this.pageContent && this.pageContent.accordion && this.pageContent.accordion.accordionItems) {
      return this.pageContent.accordion.accordionItems.map(item => this.sanitizer.bypassSecurityTrustHtml(item.description));
    }
    return [];
  }

}
