
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { richTextOptions } from './rich-text-options.model';

export class LinkItemContentModel {
    header!: string;
    leftPanelLinks!: string;
    rightPanelLinks!: string;

    constructor(pageLinkDetails: any) {
        if (pageLinkDetails) {
            // this.identifier = pageLinkDetails.sys.id; 
            this.header = pageLinkDetails.header;
            this.leftPanelLinks = documentToHtmlString(pageLinkDetails.leftPanelLinks, richTextOptions);
            this.rightPanelLinks = documentToHtmlString(pageLinkDetails.rightPanelLinks, richTextOptions);
        }
    }
}