import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { LinkexService } from '../services/linkex.service.service';
import { DomSanitizer } from '@angular/platform-browser';
import { PageContentModel } from '../core/model/page-content.model';
import { PageHeaderContentModel } from '../core/model/page-header.model';
import { ContentfulService } from '../core/service/contentful.service';
interface Search {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  contactus!: FormGroup;
  errorMsg = false;
  successMsg = false;
  loader: boolean = false;
  apiResponse: any = {};
  pageContent!: PageContentModel;
  pageHeader!: PageHeaderContentModel;
  constructor(private fb: FormBuilder, private service: LinkexService, private contentfulService: ContentfulService, private sanitizer: DomSanitizer) { }

  recipientEmail = 'info@linkex.us';

  sendEmail() {
    const emailUrl = `mailto:${this.recipientEmail}`;
    window.location.href = emailUrl;
  }

  search: Search[] = [
    { value: 'contract-logistics', viewValue: 'Contract Logistics' },
    { value: 'international-shipping', viewValue: 'International Shipping' },
    { value: 'truckload-brokerage', viewValue: 'Truckload Brokerage' },
    { value: 'warehousing', viewValue: 'Warehousing' },
    { value: 'expedited-delivery', viewValue: 'Expedited Delivery' },
    { value: 'pool-distribution', viewValue: 'Pool Distribution' },
    { value: 'final-mile', viewValue: 'Final Mile' },
    { value: 'transloading', viewValue: 'Transloading' },
  ];
  submitDisabled = false;
  isInfoSubmitted: boolean = false;
  isInfoSubmittedError: boolean = false;
  ngOnInit(): void {
    this.contactus = this.fb.group({
      yourName: ['', Validators.required],
      yourEmail: ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      yourCompany: ['', Validators.required],
      yourPhone: ['', [Validators.required, Validators.pattern('[- +()0-9]+')]],
      menu: ['', Validators.required],
      yourMessage: ['']
    });
    this.loadBodyEntries();
    this.loadHeaderEntries();
  }
  onSubmit(): any {
    if (this.contactus.status === 'INVALID') return
    this.submitDisabled = true
    this.loader = true
    const payload = this.contactus.value;
    this.isInfoSubmittedError = false;
    this.successMsg = false;
    this.isInfoSubmitted = false;
    this.service.contactUs(payload).subscribe((response: any) => {
      this.loader = false;
      this.apiResponse = response;
      if (response.status === 'mail_sent') {
        this.successMsg = true;
        this.isInfoSubmitted = true;
        this.contactus.reset();
      } else {
        this.isInfoSubmittedError = true;
      }
    }, (error) => {
      this.loader = false;
    });
  }

  numberOnly(event: any) {
    const input = event.target as HTMLInputElement;
    const inputValue = input.value;
    input.value = inputValue.replace(/[^0-9]/g, '');
  }

  alphaOnly(event: any) {
    const input = event.target as HTMLInputElement;
    const inputValue = input.value;
    input.value = inputValue.replace(/[^0-9a-zA-Z ]/g, '');
  }
  loadHeaderEntries() {
    this.contentfulService.getEntry('pageHeader', 'Contact Header').subscribe(
      (entry) => {
        this.pageHeader = new PageHeaderContentModel(entry);
      }, error => {
        console.error('Error fetching entries:', error);
      });
  }

  loadBodyEntries() {
    this.contentfulService.getEntry('pageInsights', 'Contact Content').subscribe(
      (entry) => {
        this.pageContent = new PageContentModel(entry);
      }, error => {
        console.error('Error fetching entries:', error);
      });
  }

  getBreadcrumbs(): string[] {
    return this.pageHeader.breadCrumbs.split(',').map(breadcrumb => breadcrumb.trim());
  }
  onReset() {
    this.contactus.reset();
  }

}
