<div class="form-group">
    <label>{{ label }}<sup> &#42;</sup></label>
    <div class="d-flex">
        <mat-form-field appearance="outline" class="col-md-4 p-0">
            <mat-select placeholder="Hours" [(ngModel)]="selectedHour" (ngModelChange)="updateHour($event)">
                <mat-option *ngFor="let hour of hours" [value]="hour">{{ hour }}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" class="col-md-4 p-0">
            <mat-select placeholder="Minutes" [(ngModel)]="selectedMinute" (ngModelChange)="updateMinute($event)">
                <mat-option *ngFor="let minute of minutes" [value]="minute">{{ minute }}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" class="col-md-4 p-0">
            <mat-select placeholder="Period" [(ngModel)]="selectedPeriod" (ngModelChange)="updatePeriod($event)">
                <mat-option *ngFor="let period of periods" [value]="period">{{ period }}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</div>