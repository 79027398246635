<div class="form-group">
    <label>{{ label }}<sup> &#42;</sup></label>
    <div class="section-flex">
        <mat-form-field appearance="outline" class="col-md-8 time-picker p-0" [ngClass]="{ 'mat-form-field-invalid': errorMessage ? true : false }">
            <input matInput type="text" placeholder="hh:mm" maxlength="5" [value]="inputTime" [(ngModel)]="inputTime" (input)="onSelectedTimeChange($event)">
        </mat-form-field>
        <mat-button-toggle-group class="col-md-4 p-0 border-0">
            <button mat-flat-button type="button" class="primary-btn grey-out col-md-6" value="AM" [class.selected]="isAM" (click)="onPeriodChange(true)">AM</button>
            <button mat-flat-button type="button" class="primary-btn grey-out col-md-6" value="PM" [class.selected]="!isAM" (click)="onPeriodChange(false)">PM</button>
        </mat-button-toggle-group>
    </div>
    <mat-error class=" mat-error custom-error">{{ errorMessage }}</mat-error>
</div>