import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LinkexService {

  private url = `${environment.apiUrl}/api/v1`;
  public trackingHistory:any = {}
  constructor(private httpClient: HttpClient) { }

  contactUs(body:any){
    return this.httpClient.post(this.url+'/contactus', body);
  }
  getQuotes(body:any){
    return this.httpClient.post(this.url+'/rateQuote', body);
  }
  submitWarehouseQuestionnaire(body:any){
    return this.httpClient.post(this.url+'/warehouse-questionnaire', body);
  }
  submitTruckloadRateQuotes(body:any){
    return this.httpClient.post(this.url+'/rateQuote/truckload', body);
  }
  submitExpeditedRateQuotes(body:any){
    return this.httpClient.post(this.url+'/rateQuote/expedited', body);
  }
  submitFinalMileCartageRateQuotes(body:any){
    return this.httpClient.post(this.url+'/rateQuote/finalMileAndCartage', body);
  }
  submitInternationalRateQuotes(body:any){
    return this.httpClient.post(this.url+'/rateQuote/international', body);
  }
  submitDrayageTransloadingRateQuotes(body:any){
    return this.httpClient.post(this.url+'/rateQuote/drayageTransloading', body);
  }
  submitFinalMileQuestionnaire(body:any){
    return this.httpClient.post(this.url+'/finalMileAndWhiteGloveQuestionnaire', body);
  }
  validateSaiaAccount(body:any){
    return this.httpClient.post(this.url+'/validate-customer', body);
  }
  submitLiveRateQuotesRequest(body:any){
    return this.httpClient.post(this.url+'/liveRateQuote', body);
  }
  submitTenderRequest(body:any): any {
		return this.httpClient.post(this.url+'/quote-tendering', body);
  }
  trackShipment(body:any){
    return this.httpClient.post(this.url+'/tracking', body);
  }
  emailQuote(body:any){
    return this.httpClient.post(this.url+'/email-quote', body);
  }
  skipWeekendHoliday(body:any){
    return this.httpClient.post(this.url+'/skip-weekend-holiday', body);
  }
  getPreviousQuote(body:any){
    return this.httpClient.post(this.url+'/load-quote', body);
  }
  downloadFile(fileUrl:string): any {
		return this.httpClient.get(fileUrl, {responseType: 'blob'});
  }
}
