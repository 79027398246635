import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { PageContentModel } from 'src/app/core/model/page-content.model';
import { PageHeaderContentModel } from 'src/app/core/model/page-header.model';
import { ContentfulService } from 'src/app/core/service/contentful.service';

@Component({
  selector: 'app-pool-distribution',
  templateUrl: './pool-distribution.component.html',
  styleUrls: ['./pool-distribution.component.scss']
})
export class PoolDistributionComponent implements OnInit {

  pageHeader!: PageHeaderContentModel;
  pageContent!: PageContentModel;
  recipientEmail = 'dmiller@saia.com';

  sendEmail() {
    const emailUrl = `mailto:${this.recipientEmail}`;
    window.location.href = emailUrl;
  }

  constructor(private contentfulService: ContentfulService, private sanitizer: DomSanitizer) { }

  loadHeaderEntries() {
    this.contentfulService.getEntry('pageHeader', 'Pool Distribution Header').subscribe(
      (entry) => {
        this.pageHeader = new PageHeaderContentModel(entry);
      }, error => {
        console.error('Error fetching entries:', error);
      });
  }

  getBreadcrumbs(): string[] {
    return this.pageHeader.breadCrumbs.split(',').map(breadcrumb => breadcrumb.trim());
  }

  loadBodyEntries() {
    this.contentfulService.getEntry('pageInsights', 'Pool Distribution Content').subscribe(
      (entry) => {
        this.pageContent = new PageContentModel(entry);
      }, error => {
        console.error('Error fetching entries:', error);
      });
  }

  get sanitizedDescription(): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(this.pageContent.description);
  }

  ngOnInit(): void {
    this.loadHeaderEntries();
    this.loadBodyEntries();
  }

}
