<div *ngFor="let item of data?.blogs"> 
    <div *ngIf="item.id == id">
        <!-- Banner -->
        <div class="banner-area">
            <img src="{{item.url}}" />
        </div>

        <!-- Breadcrumb Wrapper -->
        <div class="breadcrumb-wrapper">
            <div class="row">
                <div class="col-md-10 col-xs-12 col-md-offset-1">
                    <ol>
                        <li routerLink="/home">
                            <span class="material-icons">home</span>
                        </li>
                        <li class="child" routerLink="/news">
                            Blog
                        </li>
                        <li class="active">
                            {{ item.title }}
                        </li>
                    </ol>
                </div>
            </div>
        </div>
 
        <!-- Section -->
        <div class="content-section">
            <div class="row">
                <div class="col-md-10 col-md-offset-1">
                    <h4 class="header-text text-blue py-2">
                        {{ item.title }}
                    </h4>
                    <div class="content-text" [innerHTML]=" item.author "></div> 
                    <div class="content-text" [innerHTML]=" item.description "></div> 
                </div>
            </div>
        </div> 
    </div>

</div>